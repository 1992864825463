import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Bizerte = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});
  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='630.167px'
        height='507.533px'
        viewBox='0 0 630.167 507.533'
        enable-background='new 0 0 630.167 507.533'
      >
        <a xlinkTitle={`Bizerte Sud ${groupedData['Bizerte Sud']}`}>
          <path
            onClick={() => setSelectedVill('Bizerte Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Bizerte Sud']) }}
            d='M291.121,148.799c0.479,0,0.959,0,1.438,0c-0.021,2.255,0.573,4.738,2.881,4.8
	c0.662-0.721,1.418-1.264,2.642-1.44c1.229-0.329,4.361,1.521,4.801,2.88c0.824,0.917,1.174,2.922,1.199,4.56
	c-0.563,0.72-1.119,1.44-1.68,2.16c-2.053,1.497-3.271,0.563-4.32,3.36c-5.783,0.098-6.929,4.72-13.199,4.08
	c-0.399,0.56-0.801,1.12-1.198,1.68c-0.281,1.132,0.942,2.628,0.479,4.32c-0.791,2.873-2.543,5.762-6,6
	c-0.828-0.878-7.23-2.348-8.16-0.72c-0.191,0.283-0.188,0.404-0.24,0.96c0.562,0.64,1.121,1.28,1.682,1.92
	c1.424,1.077,3.961,2.268,4.801,3.84c0.723,1.353-1.217,10.626-1.921,11.52c-1.397,1.425-3.353,2.48-4.562,4.08
	c-0.617,0.818-1.104,1.809-1.92,2.4c-0.822,0.599-1.857,0.804-2.64,1.44c-1.233-0.283-2.327-1.011-3.36-1.44
	c-2.719-0.8-5.439-1.6-8.16-2.4c-6.43-1.847-12.953-2.18-18.719-4.561c-2.222-0.917-4.74-2.043-6.72-3.36
	c-1.206-0.802-1.861-2.088-3.6-2.4c-0.78,1.003-2.725,1.398-3.36,2.16c-0.16,0.8-0.32,1.6-0.48,2.4
	c-0.913,1.782-3.952,3.727-6.24,4.08c-1.083-1.575-4.035-2.02-6.48-2.16c-1.04,0-2.08,0-3.12,0c-4.655-1.552-6.96-5.068-12-6.24
	c-5.238-1.218-10.478,3.415-13.68,4.8c-1.698,0.734-5.203,2.291-7.92,1.44c-0.983-0.308-1.274-0.909-2.88-0.96
	c0.063-5.796-3.299-9.782-6.24-12.72c-0.08,0-0.16,0-0.24,0c-0.942-0.94-1.999-2.424-3.36-2.88c-0.24,0.08-0.48,0.16-0.72,0.24
	c0-1.04,0-2.08,0-3.12c-0.578-0.924-0.24-3.427-0.24-4.8c-0.08-3.52-0.16-7.041-0.24-10.56c-0.078-0.866-0.511-3.384-0.24-3.84
	c0.802-0.733,1.902-0.844,2.88-1.44c1.808-1.103,3.728-2.344,5.52-3.601c5.431-3.806,13.968-11.732,21.84-12.48
	c4.721,6.305,14.28,2.979,19.2-0.479c7.092-4.986,11.88-13.131,20.88-16.08c2.008-0.658,7.144-1.804,9.84-1.2
	c2.879,0.645,7.339,1.647,10.8,0.72c3.364-0.901,5.813-2.619,8.641-4.08c0.992-0.513,2.685-1.705,4.563-1.2
	c4.143,1.115,4.926,5.872,8.399,7.68c-1.449,2.368-3.968,5.813-2.642,10.08c0.496,1.597,1.662,3.117,2.399,4.56
	c3.287-0.241,4.676,0.991,7.199,1.68c1.872,0.512,3.331-0.546,4.801,0c0.924,0.344,2.082,1.407,2.64,2.16
	c0.079,0.32,0.159,0.64,0.239,0.96c1.84,0,3.681,0,5.521,0c0.257,0.187,0.888,0.357,1.201,0.48
	C291.121,148.319,291.121,148.559,291.121,148.799z'
          />{' '}
        </a>
        <a xlinkTitle={`Bizerte Nord ${groupedData['Bizerte Nord']}`}>
          <path
            onClick={() => setSelectedVill('Bizerte Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Bizerte Nord']) }}
            d='M294.242,124.559c3.572-0.026,5.375,1.283,8.16,1.92c1.039-0.08,2.08-0.16,3.113-0.24
	c1.229,2.164,2.26,5.138,2.16,7.92c0.453,0.294,0.787,0.976,1.199,1.2c1.412,0.768,4.521,0.645,6,0.96
	c0.146,0.414,0.215,0.756,0.24,1.44c-2.311,2.212-5.777,0.97-6.24,5.52c0.365,0.129,0.406,0.162,0.961,0.24
	c0.818-0.588,3.029-0.236,3.604,0.24c0.365,0.555,0.27,1.945,0.234,2.88c0,0-1.275,8.794-1.68,9.36
	c-0.732,0.772-1.887,1.697-3.121,1.92c-1.145-1.071-3.354-0.509-4.08-0.96c-0.621-0.887-0.586-2.225-1.199-3.12
	c-0.813-1.193-2.797-1.639-3.84-2.64c-2.551-0.164-3.08,0.5-4.801,1.44c-0.08,0-0.158,0-0.236,0c0-0.08,0-0.16,0-0.24
	c-1-0.878-0.959-2.938-1.44-4.32c-2.14-1.249-4.46-1.46-8.159-1.44c-0.82-1.602-2.221-2.866-4.08-3.36
	c-1.279,0.08-2.561,0.16-3.84,0.24c-1.57-0.418-3.152-1.505-4.801-1.92c-0.861-0.217-1.818,0.123-2.398-0.24
	c-3.385-3.168-2.045-9.951,0.721-12.72c0.787,0.738,1.865,0.625,3.119,0.96c6.254,1.67,10.666-1.824,14.881-3.6
	C290.16,125.393,293.053,125.365,294.242,124.559z'
          />{' '}
        </a>
        <a xlinkTitle={`Ras Jebel ${groupedData['Ras Jebel']}`}>
          <path
            onClick={() => setSelectedVill('Ras Jebel')}
            style={{ fill: getColorBasedOnCount(outputData['Ras Jebel']) }}
            d='M388.558,186.719c-0.729-4.706-8.014-10.388-11.52-12.72c-1.496-0.994-3.326-1.628-4.564-2.88
	c-2.357,0.738-3.711,2.462-6.721,2.88c-0.211-4.181-2.725-5.359-3.6-8.88c2.09-0.119,3.875-1.07,5.52-1.68
	c3.07-1.138,5.902-1.65,8.881-2.88c2.646-1.091,5.521-2.626,8.645-3.12c1.318,2.926,3.545,4.981,5.275,7.44
	c0.766,1.078,1.109,2.835,2.4,3.36c2.164,1.438,5.502,0.984,8.4,1.68c5.555,1.333,11.371,1.514,15.84,3.84
	c3.096,1.613,5.246,4.446,8.16,6.24c3.855,2.377,8.449,3.603,12,6.24c2.77,2.063,3.633,6.506,7.193,7.68
	c1.855,0.61,5.834,0.412,7.686,0c2.055-0.458,4.602,0.313,6,0.48c0.461,0.055,1.139-0.408,1.438-0.48
	c1.414-0.335,3.479,0.204,4.564,0.48c2.465,0.627,5.482,0.604,6,3.12c-4.191,2.497-17.043,1.74-22.805,0.48
	c-3.355-0.24-6.723-0.48-10.074-0.72c-9.896-2.232-20.189-2.108-29.281-5.04c-4.006-1.292-8.342-2.238-12.24-3.84
	c-1.439-0.593-3.74-2.241-5.279-2.4C389.681,185.917,389.427,186.575,388.558,186.719z'
          />{' '}
        </a>
        <a xlinkTitle={`Sejnane ${groupedData['Sejnane']}`}>
          <path
            onClick={() => setSelectedVill('Sejnane')}
            style={{ fill: getColorBasedOnCount(outputData['Sejnane']) }}
            d='M156.24,160.799c0.24,7.52,0.48,15.041,0.72,22.56c0.744-0.02,0.965-0.141,1.2,0
	c4.257,2.167,9.345,8.657,9.36,15.12c-6.278,2.033-10.862,6.701-18.72,2.16c0,0.16,0,0.32,0,0.48c-0.554,0.875,0.101,2.684,0.48,3.6
	c1.131,2.732,2.837,5.314,3.36,8.64c-1.289,0.866-2.466,2.089-4.08,2.64c-5.037,1.72-14.115-3.62-18.24-4.8
	c-1.422-0.407-3.825-0.867-5.52-0.48c-0.684,0.156-1.942,0.691-2.88,0.48c-3.211-0.723-9.417-3.614-13.92-1.92
	c-1.792,0.674-2.814,2.162-4.08,3.36c0.001,3.609,1.88,9.309,3.12,12.24c1.358,0.485,2.649,0.758,3.12,2.16
	c2.419,2.57-0.892,6.07-1.2,8.16c0.918,1,1.183,2.396,1.92,3.6c2.268,3.703,5.535,7.646,6,12.96
	c-1.767,1.038-5.408,4.331-5.52,6.959c2.6,1.35,1.26,5.35,0.24,7.682c-2.645,6.048-9.22,11.996-9.6,19.682
	c-2.782-0.229-5.166-1.334-7.68-1.92c-3.033-0.709-5.577,1.713-6,1.68c-2.779-0.229-6.457-1.271-9.36-1.92
	c-2.178-0.486-5.456-0.438-7.44,0c-2.063,0.455-3.503,0.131-5.28,0.723c-4.667,1.549-8.026,4.914-10.32,8.879
	c-0.26-0.217-0.021,0.021-0.24-0.239c-1.458-0.698-4.013-2.243-4.56-3.841c1.085-1.332,1.338-5.258,0.72-7.438
	c-1.353-4.772-3.951-8.021-6.48-11.521c-0.928-1.283-1.816-3.479-3.36-4.08c-1.748-1.246-7.582-1.11-10.08-0.479
	c-3.053,0.772-10.992,3.666-14.64,2.882c-1.238-0.268-2.663-0.703-3.84-0.959c-0.981-4.691-3.942-8.824-7.2-11.281
	c-1.644-1.238-5.07-2.736-5.28-5.278c0.524-0.644,0.702-1.488,1.2-2.16c1.371-1.851,4.014-2.834,4.8-5.28
	c0.46-1.432-0.476-3.002-0.24-3.6c1.617-4.378,6.992-2.413,6.96-9.84c-3.949-2.189-13.441-6.714-13.68-12
	c2.249-0.562,4.562-1.557,7.2-2.16c3.354-0.767,6.621,0.178,9.36-0.96c0.838-0.348,2.943-1.365,3.36-2.16
	c0.562-1.071,0.053-1.982,0.96-2.64c1.053-0.763,2.25-0.992,3.36-1.68c2.752-1.707,5.575-3.578,8.16-5.521
	c4.053-3.044,6.413-9.305,9.12-13.68c2.473-3.996,4.294-7.257,8.16-9.84c0.814-0.544,3.39-2.469,4.32-1.92
	c1.597,2.089,1.518,5.497,3.12,7.68c6.042,6.046,17.236-0.598,22.08-3.12c3.524-1.835,7.247-2.335,11.76-3.36c1.36,0,2.72,0,4.08,0
	c2.941-0.001,5.331-0.284,7.44-1.2c4.83-2.097,10.486-4.672,14.64-7.68c3.564-2.58,5.063-6.976,9.6-8.64
	c4.768-1.749,12.337-0.353,17.04,0.72C148.482,163.317,152.817,161.265,156.24,160.799z'
          />{' '}
        </a>
        <a xlinkTitle={`Menzel Jemil ${groupedData['Menzel Jemil']}`}>
          <path
            onClick={() => setSelectedVill('Menzel Jemil')}
            style={{ fill: getColorBasedOnCount(outputData['Menzel Jemil']) }}
            d='M324.718,162.959c5.168,0.988,10.445,1.888,15.359,3.12c2.438,0.612,5.031,0.44,7.438,0.96
	c4.863,1.05,9.646-1.166,13.684-1.44c0.914,2.525,2.645,4.139,3.355,6.48c0.803,2.618-0.084,5.056,0.961,7.44
	c1.348,3.073,4.996,4.941,5.279,8.88c-0.326,0.495-0.16,1.334-0.479,1.92c-0.521,0.951-1.6,1.774-2.16,2.88
	c-9.105-0.103-13.561,9.7-22.564,6.72c0,0.24,0,0.48,0,0.72c0.689,1.055,0.063,3.254-0.234,4.08
	c-1.436,3.972-3.617,5.643-7.201,7.44c-0.834,0.418-3.242,1.688-4.08,1.2c-0.965-0.686-3.666-4.802-4.08-6
	c0.561-1.68,1.121-3.36,1.68-5.04c2.355-5.614,6.994-15.105,2.646-21.6c-1.041-1.555-1.521-3.051-4.08-3.12
	c-1.15,0.716-4.818,0.335-6.48,0.24c-4.959-0.8-9.92-1.6-14.879-2.4c-7.311-1.589-10.932,3.27-16.319,3.12
	c-0.246-0.829-0.569-1.134-0.722-2.16c4.402-1.993,7.408-5.248,11.041-7.92c1.586-1.167,3.688-1.956,5.039-3.36
	c3.799,2.193,6.604,1.554,12.241,1.68c1.213,0.027,3.268,0.965,3.6,0.72C324.744,166.735,324.765,164.746,324.718,162.959z'
          />{' '}
        </a>
        <a xlinkTitle={`El Alia ${groupedData['El Alia']}`}>
          <path
            onClick={() => setSelectedVill('El Alia')}
            style={{ fill: getColorBasedOnCount(outputData['El Alia']) }}
            d='M378.24,216.239c-5.189,0.745-10.486,7.446-13.199,11.04c-1.641,2.174-4.369,3.338-6.24,5.28
	c-2.246-0.376-4.291-1.617-6.721-2.16c-6.316-1.411-10.178,3.565-13.92,4.8c0-0.08,0-0.16,0-0.24
	c-3.74-2.512-1.748-6.293-3.604-10.32c-0.443-0.973-1.648-1.052-2.156-1.92c-0.646-0.94,1.854-7.835,2.396-8.16
	c0.91-0.619,2.27-0.498,3.359-0.96c4.563-1.929,8.711-5.613,8.641-12.24c10.229,0.163,12.488-7.482,21.84-6.96
	c0.984-1.927,2.574-3.194,3.119-5.52c0.541-2.299-1.156-3.68-1.92-4.8c-1.789-2.63-4.234-5.072-3.84-9.12
	c2.273-0.158,5.678-2.64,6.479-2.4c1.961,0.616,3.518,2.265,5.041,3.36c3.514,2.524,9.6,6.749,10.318,11.76
	C380.574,192.577,378.386,204.276,378.24,216.239z'
          />{' '}
        </a>
        <a xlinkTitle={`Ghar El Melh ${groupedData['Ghar El Melh']}`}>
          <path
            onClick={() => setSelectedVill('Ghar El Melh')}
            style={{ fill: getColorBasedOnCount(outputData['Ghar El Melh']) }}
            d='M414.24,215.999c-3.199,0.335-8.861,2.877-11.764,4.08c-11.59,4.809-21.188,7.425-28.076,17.04
	c-1.695,2.366-3.861,5.112-4.32,8.64c-7.313-0.491-8.619-6.835-11.039-12.24c6.51-3.83,12.645-15.487,20.16-16.56
	c-0.176-10.402,1.871-19.679,6.24-25.68c1.17-1.607,3.172-2.663,4.559-4.08c2.959,0.352,6.783,2.557,9.602,3.6
	c5.564,2.059,11.785,3.043,17.766,4.56c7.09,1.801,14.764,1.502,22.08,3.12c2.596,0.574,5.332,0.208,7.68,0.72
	c4.535,0.991,9.326,0.079,13.68,0.96c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c-1.855,2.546-12.855,1.195-18,1.2
	c-4.336,0.004-9.354,0.114-13.195,0.96c-2.055,0.451-4.105-0.115-5.764,0.72C420.617,204.782,414.769,212.054,414.24,215.999z'
          />{' '}
        </a>
        <a xlinkTitle={`Ghezala ${groupedData['Ghezala']}`}>
          <path
            onClick={() => setSelectedVill('Ghezala')}
            style={{ fill: getColorBasedOnCount(outputData['Ghezala']) }}
            d='M190.079,193.679c4.465-0.078,6.852,1.498,9.36,3.36c0.48,0.56,0.96,1.12,1.44,1.68c0.56,0,1.12,0,1.68,0
	c0.66,0.2,1.409,0.999,1.92,1.2c1.28,0,2.56,0,3.84,0c2.155,0.482,3.984,0.773,5.52,1.68c-0.218,0.26,0.019,0.021-0.24,0.24
	c-0.497,0.791-1.5,1.351-1.92,2.16c-0.486,0.937-0.102,2.794-0.96,3.36c-2.421,1.425-6.451-0.666-8.4,1.68
	c-0.718,0.864-1.696,2.423-2.16,3.36c-2.769,5.592,4.76,8.763,7.44,10.8c0.843,0.641,1.276,2.66,2.16,3.12c1.76,0,3.52,0,5.28,0
	c1.238-0.725,3.322-0.305,4.8-0.72c3.555-1,9.486-2.547,11.76-4.8c2.278,0.618,5.602,2.292,8.879,1.2c0,0.24,0,0.48,0,0.72
	c-1.287,1.269-2.027,3.094-3.119,4.56c-2.831,3.802-7.584,6.674-12.959,7.92c-2.399-0.08-4.8-0.16-7.2-0.24
	c-0.941,1.308-2.54,2.154-3.84,3.12c-3.606,2.679-7.846,6.007-10.56,9.6c-1.633,2.162-3.613,2.454-3.6,6.479
	c3.079,2.37,4.01,8.416,2.16,12.96c-3.188,7.832-8.875,12.892-17.28,15.601c-4.238,1.363-9.617,1.406-12,4.56
	c-2.604,3.445-1.909,9.304-1.68,14.399c-6.387,3.171-9.355,11.541-18.48,11.761c-2.668-5.125-12.495-4.541-12.96-10.799
	c4.263-2.673,3.567-12.121,0.48-15.841c-1.613-1.508-4.351-1.205-6.48-0.239c-4.069,1.842-6.05,6.27-10.8,7.438
	c-2.221,0.547-4.833-0.297-6.24-0.722c-4.492-1.354-8.291-2.887-12.96-3.84c0-0.24,0-0.479,0-0.722
	c-1.367-2.721,2.028-8.599,3.12-10.562c2.175-3.905,4.152-7.471,6.24-11.521c0.626-1.215,2.085-3.769,1.68-5.76
	c-0.303-1.485-1.27-2.013-1.44-3.601c1.54-1.461,3.021-3.65,5.04-4.562c0.605-4.695-3.037-10.413-5.04-13.68
	c-0.854-1.392-2.138-2.57-2.4-4.56c1.652-1.293,2.451-6.185,0.96-8.4c-0.751-1.118-2.713-1.262-3.36-2.4
	c-0.973-1.416-2.589-8.427-2.88-10.56c1.311-1.267,2.27-2.553,4.32-3.12c4.13-1.141,9.606,1.45,12.48,2.16
	c2.399,0.592,4.622-0.883,7.2-0.24c4.724,1.179,8.596,3.376,12.96,4.8c4.016,1.311,10.778-0.202,11.76-3.12
	c0.504-1.497-0.834-3.219-1.2-4.08c-0.987-2.326-2.252-4.645-2.64-7.2c0.08,0,0.16,0,0.24,0c0.859,0.796,1.963,0.622,3.36,0.959
	c4.202,1.016,9.19-0.893,12-2.399c1.426-0.765,2.146-1.789,4.32-1.92c1.085,1.157,4.423,1.308,6.48,0.72
	c3.726-1.064,6.852-3.2,10.08-4.8C187.519,194.479,188.8,194.079,190.079,193.679z'
          />
        </a>

        <path
          fill='none'
          d='M212.16,208.319c-0.056-7.486,6.269-5.868,9.12-10.08c1.398-2.066,0.994-3.962,4.08-4.56
	c4.637,6.188,20.056,7.917,28.8,10.56c2.072,0.626,9.623,2.061,10.318,3.36c0,0.88,0,1.76,0,2.64c-0.318,1.6-0.641,3.2-0.959,4.8
	c-1.23,3.399-2.732,6.701-4.563,9.6c-0.438,0.696-2.563,3.954-3.119,4.32c-1.563,1.021-3.066-0.011-4.318,1.68
	c-1.307-0.549-2.811-1.108-3.602-2.16c-2.104-2.797-3.07-7.42-7.92-7.44c-2.35,1.558-6.908-1.12-8.4-1.44
	c-3.653,3.619-13.826,5.69-21.36,5.76c-1.546-5.306-9.276-4.582-9.6-11.28c0.98-1.26,1.296-3.174,2.4-4.32
	C204.512,208.231,209.404,208.399,212.16,208.319z'
        />
        <a xlinkTitle={`Tinja ${groupedData['Tinja']}`}>
          <path
            onClick={() => setSelectedVill('Tinja')}
            style={{ fill: getColorBasedOnCount(outputData['Tinja']) }}
            d='M274.318,199.919c0.24,0,0.479,0,0.723,0c0.561,2.075,2.451,2.84,3.119,4.8c-0.08,0-0.16,0-0.24,0
	c-1.195,1.729-4.654,2.538-5.279,3.6c0.363,0.607,0.234,1.944,0,2.88c-0.719,2.862-1.502,5.965-2.16,8.88
	c-0.965,4.284,1.895,7.483,2.643,10.56c0.713,2.936-0.766,5.013-0.961,7.2c2.564,1.784,1.75,6.486,1.682,10.32
	c-0.449,0.156-0.938,0.229-1.682,0.24c-2.396,1.414-7.842-2.834-10.08-3.12c-1.756,1.567-5.646,2.125-7.682,3.6
	c-1.395,1.014-1.229,2.749-3.6,2.88c-1.395-2.413-2.16-4.082-2.16-8.16c1.953-0.487,2.219-1.659,3.359-2.88
	c0.027-1.511,0.438-2.304,0-3.84c-0.104-0.368-0.578-0.465-0.479-1.2c0.183-1.367,0.694-2.724,0.72-4.56
	c1.071-0.593,2.827-0.499,4.079-1.2c1.074-0.601,1.707-2.557,2.399-3.6c2.158-3.245,4.429-6.696,5.761-10.8
	c0.517-1.589,0.539-6.865,1.201-7.92c0.797-0.742,1.993-1.024,2.879-1.68c0.894-0.661,1.479-1.738,2.159-2.64
	C271.917,202.159,273.121,201.039,274.318,199.919z'
          />{' '}
        </a>
        <a xlinkTitle={`Menzel Bourguiba ${groupedData['Menzel Bourguiba']}`}>
          <path
            onClick={() => setSelectedVill('Menzel Bourguiba')}
            style={{
              fill: getColorBasedOnCount(outputData['Menzel Bourguiba']),
            }}
            d='M273.839,208.559c2.451-0.39,3.438-2.699,5.039-3.12c1.438,0.24,2.881,0.48,4.32,0.72
	c0.981,1.3,2.512,1.104,2.641,3.36c0.886,1.455-0.418,6.1,0.479,8.16c1.523,3.5,11.834,4.11,16.561,4.8
	c3.988-5.874,13.246-7.345,20.16-10.32c1.137-0.489,6.316-3.511,6.479-3.36c1.021,0.787,3.732,4.886,3.846,6.479
	c-1.598,2.165-2.451,5.852-1.686,8.88c1.105,0.044,1.357,0.119,1.686,0.96c2.479,2.966-0.281,7.726,4.08,10.56c0,0.08,0,0.16,0,0.24
	c-1.736,0.518-3.352,1.989-4.807,2.88c-1.273,0.56-2.561,1.12-3.84,1.68c-2.533,1.319-5.268,2.909-7.92,4.08
	c-3.354,1.48-6.791,2.721-10.08,4.08c-1.953,0.807-4.281,0.799-6,1.92c-2.664,1.736-3.035,5.957-4.801,8.64
	c-1.107,1.685-3.178,1.986-4.561,3.359c-0.896,0.89-1.01,2.479-2.398,2.881c-0.258,0.181-0.229,0.146-0.723,0.238
	c-1.433-1.832-4.105-2.933-6-4.318c-4.064-2.983-8.16-7.117-10.801-11.521c-1.479-2.469,0.229-6.603-0.721-9.84
	c-0.238-0.818-1.223-1.742-1.438-2.4c-0.42-1.27,1.326-4.959,0.72-7.44c-0.697-2.846-3.531-5.962-2.64-9.84
	C272.305,216.557,273.714,212.902,273.839,208.559z'
          />{' '}
        </a>
        <a xlinkTitle={`Utique ${groupedData['Utique']}`}>
          <path
            onClick={() => setSelectedVill('Utique')}
            style={{ fill: getColorBasedOnCount(outputData['Utique']) }}
            d='M414,216.959c1.256,0.54,1.73,2.204,2.641,3.12c2.287,2.305,6.102,4.529,11.041,3.36
	c1.314-0.311,2.27-0.057,3.119-0.72c0.828-1.436,1.461-5.357,4.08-4.08c1.137,0.175,1.189,0.403,1.199,1.68
	c-1.896,1.448-3.432,3.601-5.279,5.04c1.365,2.802,4.619,3.458,5.521,5.28c-0.16,0-0.32,0-0.48,0
	c-2.887,2.588-11.973-0.264-15.119-0.96c-3.268-0.723-5.629,0.982-7.438,1.68c-1.041,0-2.08,0-3.121,0
	c-2.064,0.481-4.162,1.338-5.76,2.4c-3.771,2.507-7.906,7.554-9.119,12.479c-0.439,1.78,1.086,3.998,1.438,5.521
	c0.889,3.803-0.416,8.534-1.438,11.04c-1.943,4.759-4.73,9.136-6.725,13.681c-0.719,1.644-0.895,3.806-1.92,5.28
	c-0.525,0.763-1.844,0.91-2.641,1.438c-1.793,1.191-4.955,4.056-6.24,5.761c-4.725,6.278-12.818,18.981-12.959,29.278
	c1.215,0.685,2.654,1.099,4.32,1.438c0.787,1.563,1.434,2.513,1.438,5.041c-0.535,0.188-1.305,0.244-2.158,0.238
	c-1.104-1.742-3.271-4.736-6-4.802c-0.188,0.162-0.535,0.313-0.723,0.479c-0.754,0.912-0.363,1.943-1.92,2.16
	c-2.781-1.854-10.916,1.313-13.199,2.16c-0.861,0.317-3.266,1.461-4.078,0.961c-1.156-1.078-1.242-3.238-1.922-4.804
	c-1.195-2.76-2.783-5.688-4.318-8.157c-0.789-1.271-0.773-2.896-2.643-3.119c-2.297,1.66-9.658,1.683-13.68,1.683
	c-12.623,0-20.754-4.209-28.318-9.121c0.08-0.238,0.158-0.479,0.238-0.722c1.66-1.522,6.483-5.476,6.723-7.92
	c-0.391-0.381-0.533-1.106-0.961-1.438c-1.232-0.959-3.916-1.676-4.561-3.119c0-0.961,0-1.92,0-2.881
	c0.896-1.442,0.771-3.742,1.438-5.279c1.547-3.563,6.529-9.335,6.723-13.2c-1.395-1.519-1.924-3.815-3.361-5.278
	c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0c1.438-1.455,2.938-2.524,3.84-4.562c0.961-2.178,1.646-5.36,3.604-6.479
	c1.438-0.399,2.881-0.8,4.316-1.2c3.359-1.379,6.886-2.584,10.32-4.08c7.461-3.249,14.387-7.404,20.881-11.52
	c3.541-2.246,8.938-4.075,13.92-2.16c1.215,0.467,2.557,0.583,3.119,1.68c0.713,0.948,0.689,2.157,1.201,3.36
	c1.996,4.729,4.729,9.392,11.52,9.36c3.146-13.92,16.418-19.728,28.564-24.72c3.662-1.505,7.457-2.514,11.039-3.84
	C411.646,217.697,413.054,217.704,414,216.959z'
          />{' '}
        </a>
        <a xlinkTitle={`Mateur ${groupedData['Mateur']}`}>
          <path
            onClick={() => setSelectedVill('Mateur')}
            style={{ fill: getColorBasedOnCount(outputData['Mateur']) }}
            d='M188.639,348.718c-0.048-3.502-1.624-6.547-3.84-7.92c-1.12-0.691-2.827-0.654-3.6-1.684
	c-3.243-4.293-3.502-9.992-9.84-11.275c-0.04-4.74-3.949-4.344-6.48-6.48c0.218-0.26-0.019-0.02,0.24-0.238
	c2.298-3.299,7.781-4.127,8.88-8.641c0.276-1.137-0.51-2.658-0.72-3.602c-0.08-1.684-0.16-3.359-0.24-5.039
	c-0.64-1.199-1.28-2.4-1.92-3.604c-0.843-2.92,0.039-9.729,1.2-11.521c3.262-5.031,13.28-4.309,18.48-7.439
	c5.23-3.15,8.791-7.977,11.28-13.918c0.745-1.779,1.972-5.305,1.2-8.4c-0.696-2.791-3.017-3.85-3.12-6.959
	c1.457-1.127,2.531-3.011,3.84-4.32c2.901-2.901,6.019-5.447,9.36-7.92c1.595-1.181,2.906-3.549,5.28-3.84
	c1.853,1.18,6.769-0.047,8.16-0.48c5.751-1.786,9.893-5.191,12.96-9.6c0.82-1.182,1.173-2.605,2.158-3.6
	c0.722,0.64,1.44,1.28,2.16,1.92c1.442,1.988,2.264,4.406,4.08,6c1.133,0.993,2.916,0.646,3.12,2.64
	c-1.362,2.082-0.196,5.48-0.479,8.16c-0.291,0.207-2.809,2.035-2.881,2.16c-1.526,2.213,1.274,9.021,2.16,9.6
	c0.961,0,1.92,0,2.881,0c0.795-2.897,6.524-6.09,10.08-6.24c1.84,1.72,5.045,2.492,7.92,3.12c1.281,0.28,2.565-0.41,3.358-0.24
	c2.608,5.519,7.498,10.033,12.24,13.439c1.793,1.287,3.989,2.283,5.521,3.84c1,0.018,1.641-0.055,2.159-0.48
	c1.099-1.115,1.396-2.586,2.882-3.357c0.633,2.457,2.697,3.484,3.119,6.24c-1.393,1.145-1.906,3.471-2.88,5.04
	c-2.515,4.052-6.317,9.155-5.041,16.079c1.379,0.669,3.869,2.169,4.802,3.359c0.269,0.354,0.5,0.73,0.239,0.959
	c-0.979,1.098-1.948,2.719-3.121,3.604c-0.663,0.495-1.635,0.817-2.159,1.438c-0.343,0.398-1.188,2.465-1.438,2.641
	c-1.168,0.813-2.562,0.297-3.356,1.438c-1.531,2.188-0.563,6.646-2.16,8.881c-0.726,1.008-3.233,1.021-4.319,1.682
	c-2.759,1.682-4.906,4.844-6.24,7.922c-1.692,3.902-2.336,8.752-3.601,12.959c-0.694,2.314-0.446,4.264-2.644,5.041
	c-1.559,1.158-5.963,1.229-7.92,1.68c-2.026-2.568-7.978-4.545-12.719-4.563c-1.623,3.176-4.479,5.619-8.16,6.723
	c-2.203,0.656-4.714,0.177-6.479,1.197c-3.767,2.188-6.198,6.16-9.84,8.398c-0.616,0.381-1.196,0.109-1.92,0.479
	c-1.117,0.565-1.498,1.644-3.12,1.92c-0.328-0.813-0.675-2.453-1.44-2.882c-0.871-0.405-4.258,0.177-5.28,0.479
	c-2.483,0.738-4.273,1.191-6.48,2.16c-1.496,0.656-2.788,1.701-4.8,1.92c-0.409-1.25-1.491-2.121-2.16-3.12
	C195.805,351.199,194.291,348.628,188.639,348.718z'
          />{' '}
        </a>
        <a xlinkTitle={`Joumine ${groupedData['Joumine']}`}>
          <path
            onClick={() => setSelectedVill('Joumine')}
            style={{ fill: getColorBasedOnCount(outputData['Joumine']) }}
            d='M102,287.039c-0.177,1.313-0.197,2.233,0.24,3.36c5.79,0.658,13.693,6.142,20.16,4.56
	c4.842-1.183,7.001-5.435,11.04-7.438c1.344-0.666,4.131-1.416,5.28-0.238c1.376,1.406,2.971,9.236,1.92,11.761
	c-0.796,1.909-2.544,2.074-2.64,4.801c1.192,1.49,1.469,3.05,3.12,4.08c3.88,2.431,8.277,2.433,10.32,6.724
	c7.583-1.345,10.538-4.813,15.12-8.881c0.655-0.582,4.225-3.439,4.56-3.119c0.813,0.688,2.088,8.771,1.68,9.6
	c-1.219,4.429-8.475,6.109-8.88,9.359c0.265,0.332,0.358,0.688,0.48,1.196c2.818,0.591,4.365,1.869,6,3.604c0,0.802,0,1.603,0,2.396
	c7.026,0.326,6.985,7.955,10.56,11.765c0.457,0.481,1.183,0.228,1.92,0.479c1.561,0.543,3.137,1.965,3.84,3.357
	c0.743,1.479,0.303,3.841,1.2,5.281c0.992,0.502,3.856-0.127,5.28,0.719c2.923,1.738,3.896,5.371,6.24,7.684
	c-0.151,0.42-0.046,0.23-0.24,0.479c-0.682,1.896-2.971,2.381-4.08,3.84c-0.874,1.146-0.919,2.339-1.68,3.604
	c-0.637,1.058-5.661,4.308-6.96,5.276c-5.659,4.236-13.243,12.345-13.68,21.604c-2.149,1.334-4.011,3.276-6.48,4.316
	c-4.731,1.998-8.677,2.714-12.48,5.521c-3.972,2.936-6.864,7.563-9.84,11.52c-1.592,2.113-4.642,4.686-7.2,5.763
	c-1.463,0.613-2.912,0.586-4.32,1.197c-1.349,0.59-2.217,1.896-3.6,2.398c-1.493-0.961-6.998-1.43-9.36-1.438
	c0.153-1.179,0.233-2.453,0-3.604c-0.642-0.347-1.418-0.99-2.16-1.199c-1.04,0-2.08,0-3.12,0c0-0.159,0-0.317,0-0.479
	c-0.809-1.277,0.715-3.283,0.96-4.563c-5.384-2.627-5.995-8.777-3.84-15.357c0.625-1.91,1.189-4.293,1.68-6.479
	c0-1.119,0-2.239,0-3.357c0.538-1.646,1.999-3.602,2.88-5.039c0.794-1.302,1.116-2.564,1.92-3.843
	c2.181-3.458,4.978-6.671,7.2-10.077c-7.109-4.563-10.143-13.656-17.52-18.479c-2.245-1.472-6.628-3.731-10.08-2.399
	c-0.639,1.151-1.306,2.2-2.16,3.119c-3.542-1.374-5.654-4.14-8.88-5.521c-1.68-0.318-3.36-0.643-5.04-0.96
	c-4.617-1.506-12.488-4.682-12.48-10.563c0.832-0.989,1.124-2.343,2.16-3.119c0.502-0.377,1.837-0.702,2.16-1.198
	c0-0.722,0-1.438,0-2.16c-0.193-0.242-0.085-0.063-0.24-0.479c-2.138-0.708-3.792-3.124-5.04-4.801
	c-3.014-4.047-8.87-12.391-14.64-13.199c0.245-3.482,3.036-5.365,4.08-8.16c0.803-2.146-0.831-5.115-0.48-6
	c1.701-6.229,12.475-11.473,21.84-9.357c3.44,0.641,6.88,1.279,10.32,1.92c0.712-0.283,1.411-1.408,2.4-1.682
	C94.792,284.203,98.729,286.862,102,287.039z'
          />{' '}
        </a>

        <text
          transform='matrix(1 0 0 1 188.8599 171.5615)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bizerte Sud
        </text>
        <text
          transform='matrix(1 0 0 1 285.8594 141.5615)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bizerte Nord
        </text>
        <text
          transform='matrix(1 0 0 1 282.8594 239.5605)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Menzel{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 282.8594 254.2803)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bourguiba
        </text>
        <text
          transform='matrix(1 0 0 1 247.5718 229.2939)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Tinja
        </text>
        <text
          transform='matrix(1 0 0 1 133.8599 252.5605)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ghezala
        </text>
        <text
          transform='matrix(1 0 0 1 207.8599 296.5605)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Mateur
        </text>
        <text
          transform='matrix(1 0 0 1 100.8599 337.5605)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Joumine
        </text>
        <text
          transform='matrix(1 0 0 1 40.8599 238.5605)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sejenane
        </text>
        <text
          transform='matrix(1 0 0 1 396.478 181.042)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ras Jebel
        </text>
        <text
          transform='matrix(1 0 0 1 320.8589 276.5605)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Utique
        </text>
        <text
          transform='matrix(1 0 0 1 385.8589 213.5605)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ghar El Melh
        </text>
        <text
          transform='matrix(1 0 0 1 326.8589 181.5615)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Menzel{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 338.8589 194.3604)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Jemil
        </text>
        <text
          transform='matrix(1 0 0 1 339.3589 221.6416)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Alia
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Bizerte;
