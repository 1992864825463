import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Tataouine = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)

  const getColorBasedOnCount = (count) => {


    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='583.872px'
        height='552.579px'
        viewBox='0 0 583.872 552.579'
        enable-background='new 0 0 583.872 552.579'
      >
        <a xlinkTitle={`Bir Lahmar ${groupedData['Bir Lahmar']}`}>
          <path
            onClick={() => setSelectedVill('Bir Lahmar')}
            style={{ fill: getColorBasedOnCount(outputData['Bir Lahmar']) }}
            d='M250.8,104.413c-0.559-0.047-0.678-0.046-0.959-0.24c-2.182-0.712-2.52-2.941-5.041-3.36
	c0.406-2.17,2.227-2.291,2.4-4.8c-0.68-0.409-1.754-0.906-2.4-1.44c0.16,0,0.32,0,0.48,0c0.841-0.527,3.723-0.039,5.279,0
	c0-0.16,0-0.32,0-0.48c0.392-0.557,0.461-1.486,0.48-2.4c-0.439-0.502-0.65-1.3-1.199-1.68c-1.014-0.701-2.057-0.835-2.881-1.68
	c0.08,0,0.16,0,0.24,0c1.813-2.752,5.871-3.192,9.359-4.32c0.765-0.247,2.193-1.294,3.36-0.96c1.024,0.294,2.22,1.354,3.601,1.68
	c2.916,0.687,6.688-0.788,8.64,0.72c0.181,0.257,0.146,0.227,0.239,0.72c-1.381,0.937-2.809,3.98-3.6,4.32
	c-1.443,1.238-4.009-0.394-5.52-0.72c-1.396-0.301-2.376,0.483-3.36,0.72c-0.128,0.364-0.161,0.406-0.239,0.96
	c0.672,0.731,0.84,2.341,1.68,2.88c0.519,0.334,1.664,0.169,2.16,0.48c-0.758,0.868-1.185,2.003-2.16,2.64
	c-2.641,0.96-5.28,1.92-7.92,2.88c-1.229,0.766-1.674,2.104-2.641,3.12C250.8,103.773,250.8,104.093,250.8,104.413z'
          />{' '}
        </a>
        <a xlinkTitle={`Ghomrassen ${groupedData['Ghomrassen']}`}>
          <path
            onClick={() => setSelectedVill('Ghomrassen')}
            style={{ fill: getColorBasedOnCount(outputData['Ghomrassen']) }}
            d='M249.841,93.613c-2.264-0.042-4.131-0.415-5.761,0.24c-0.267,0.594-0.405,0.776-0.479,1.68
	c1.148,0.125,1.801,0.548,2.64,0.96c0,0.16,0,0.32,0,0.48c-1.345,0.866-2.5,2.501-2.64,4.56c3.687,0.281,3.896,3.719,7.92,4.08
	c1.218-5.883,6.287-4.768,10.56-7.44c1.125-0.704,1.509-1.955,2.399-2.88c0.08-0.56,0.16-1.12,0.24-1.68c-0.879,0-1.76,0-2.64,0
	c-0.449-0.779-0.933-1.4-1.2-2.4c0.414-0.144,0.756-0.214,1.439-0.24c1.717-0.861,4.307,2.027,6.48,0.72
	c1.061-0.326,1.807-1.569,2.16-1.68c0.243,0.603-0.729,1.454-0.24,2.64c0.11,0.267,0.84,0.612,0.961,0.96
	c0.426,1.235,0.252,3.697,0.479,4.8c0.851,0.241,2.896-0.001,3.36,0.72c-0.08,0-0.16,0-0.24,0c-1.376,1.888-8.375,3.124-11.52,3.36
	c-0.724,2.691-2.896,3.769-4.801,5.28c-0.979,0.776-1.136,2.119-1.92,3.12c-0.661,0.844-2.244,1.269-3.12,1.92
	c-1.44,1.52-2.88,3.04-4.319,4.56c-0.879,0.634-2.32,0.734-2.881,1.68c-0.847,0.99-0.195,3.771-0.959,4.32
	c-1.422-1.326-4.738-2.563-6.961-2.88c-0.459,0.779-1.328,2.467-2.16,2.88c0.016-0.697,0.012-1.313-0.24-1.68
	c-0.478-0.321-1.562-0.265-2.399-0.24c-0.591,0.397-1.347,0.223-2.159,0.48c-4.871,1.541-4.445-1.465-8.16-2.4
	c-1.5-0.377-2.168,0.661-3.121,0.96c0.064-1.24,0.166-1.789-0.719-2.16c-1.609-1.007-4.184,0.869-5.521,1.2
	c-2.557,0.634-4.684-1.153-7.439-0.479c-1.246,0.305-2.042,0.969-3.359,1.2c-2.602-2.526-4.959-1.837-9.121-1.68
	c-0.478-1.681-1.961-1.65-2.639-2.88c-0.724-1.311-0.039-3.17-0.479-4.32c-0.99-0.427-2.39-0.709-3.6-0.96
	c-0.151-0.421-0.046-0.233-0.24-0.48c0.148-0.946,0.08-1.456,0.24-2.4c0.675-0.382,1.254-0.799,1.68-1.44
	c1.79,0.998,2.112,2.594,4.8,2.64c0.245-0.193,0.065-0.085,0.479-0.24c0.301-1.481,0.689-2.22,2.641-2.16
	c0.875-0.538,3.738-0.03,4.8,0.24c1.735,0.44,3.298-0.698,4.56-0.96c0.805-0.167,2.498,1.263,2.641,0.96
	c2.021-1.523,2.667-2.397,5.76-3.12c1.917-0.448,5.012,0.679,6.48-0.24c2.515-0.86,6.401-5.948,8.879-7.68
	c3.007-2.101,6.813-2.331,10.561-3.84c2.047-0.824,3.777-2.052,5.76-2.88c0.358,1.672,2.029,1.484,2.881,2.64
	C250.163,92.183,249.929,92.513,249.841,93.613z'
          />{' '}
        </a>
        <a xlinkTitle={`Tataouine Nord ${groupedData['Tataouine Nord']}`}>
          <path
            onClick={() => setSelectedVill('Tataouine Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Tataouine Nord']) }}
            d='M276.72,98.413c4.18,0.12,6.525,1.612,11.041,1.68c-0.043,8.109,0.393,14.416,6.24,16.56
	c4.082,1.498,12.559-4.674,12.719,2.4c-0.932,1.418-0.438,2.62-2.879,2.64c-1.088-0.792-3.959-0.086-4.32,0.72c0,0.72,0,1.44,0,2.16
	c0.256,0.372,0.248,0.979,0.24,1.68c-3.631,1.588-2.529,3.646-4.318,6.72c-1.389,2.379-3.938,4.692-4.801,7.44
	c-0.582,1.857,0.664,3.135,0.961,4.08c0,1.04,0,2.08,0,3.12c0.354,0.26,0.41,0.729,0.719,0.96c1.35,1,3.479,0.965,4.803,1.92
	c1.688,1.221,2.6,3.579,3.84,5.28c0.721,0.64,1.439,1.28,2.16,1.92c0.959-0.08,1.92-0.16,2.881-0.24
	c1.486,2.334,4.133,2.634,6.719,3.84c0.4,7.688,6.965,14.422,10.561,19.2c1.41,1.873,1.932,3.843,4.32,4.8
	c0.008,5.879,6.307,8.841,8.879,12.48c0.635,0.899,0.256,1.639,0.721,2.88c0.365,0.978,2.979,3.435,3.84,4.08
	c3.264,2.444,7.402,4.695,7.68,10.08c-7.529,0.068-15.063,4.013-23.76,1.68c-2.463-0.661-3.031-2.774-5.039-3.84
	c-1.342-0.711-2.646-1.06-3.842-1.92c-2.309-1.669-3.945-4.507-6.238-6.24c-4.283-3.237-9.063-7.538-9.357-14.64
	c-5.912,0.029-14.514-0.932-19.201,0.24c-1.743,0.436-5.76,1.083-7.2,0c-0.901-1.246-0.59-2.457-2.64-2.64
	c-0.662,0.548-1.535,0.874-2.641,0.96c-0.15-0.421-0.046-0.233-0.24-0.48c1.031-5.491-2.26-9.025-5.52-11.04
	c-1.415-0.875-4.205-1.24-5.04-2.64c-2.542-4.264-2.952-11.797-0.96-16.8c-0.139-0.912-0.963-2.165-1.2-3.12
	c-0.877-3.541,1.685-9.162,2.64-11.52c1.816-0.697,4.123-0.381,4.08-3.12c-0.08,0-0.16,0-0.24,0c-0.586-1.701-2.391-3.036-4.079-3.6
	c-1.988-0.665-4.808-0.232-5.28-2.4c1.447-1.896,3.424-9.458,4.08-12.24c0-2.96,0-5.92,0-8.88c1.367-2.635,3.301-4.036,5.279-6
	c0.873-0.866,1.593-2.759,2.881-3.12c3.326-2.069,9.919-0.361,11.279-4.32C276.66,98.875,276.624,98.906,276.72,98.413z'
          />{' '}
        </a>
        <a xlinkTitle={`Smar ${groupedData['Smar']}`}>
          <path
            onClick={() => setSelectedVill('Smar')}
            style={{ fill: getColorBasedOnCount(outputData['Smar']) }}
            d='M355.678,202.572c3.094-0.007,6.16,0.045,8.643,0c0.133,0.385,0.189,0.577,0.238,1.2
	c-0.482,0.524-0.553,1.296-0.961,1.92c-1.066,1.634-2.949,3.716-4.559,4.8c-1.564,1.053-3.396,1.149-5.281,1.92
	c-1.393,0.569-2.768,1.499-4.08,2.16c-0.219-6.299-6.076-9.332-9.84-12.24c-1.566-1.212-1.473-3.651-2.641-5.28
	c-2.197-3.069-7.563-6.264-8.641-10.08c-0.242-0.858,0.146-1.579,0-2.4c-2.387-1.119-2.854-2.604-4.318-4.56
	c-0.705-0.941-1.939-1.691-2.643-2.64c-2.379-3.21-4.834-7.002-6.479-10.8c-0.814-1.88-0.963-4.001-1.68-6
	c-1.57-0.9-3.805-1.067-5.279-1.92c-1.543-0.891-0.758-2.105-3.602-2.16c-0.313,0.21-0.592,0.215-1.199,0.24
	c-0.992-1.488-4.223-6.021-5.521-6.96c-1.33-0.962-4.264-1.16-5.039-2.64c-0.474-0.744,0.078-2.092-0.239-3.12
	c-0.121-0.389-0.844-1.05-0.961-1.44c-0.773-2.589,3.383-6.864,4.319-8.4c0.537-0.878,1.115-2.237,1.438-3.12
	c0.08-0.64,0.16-1.28,0.24-1.92c1.196-2.303,3.036-0.549,3.12-4.8c-0.229-0.34-0.232-0.786-0.238-1.44
	c0.238-0.08,0.479-0.16,0.719-0.24c1.076-0.679,3.547-0.02,4.801-0.24c1.109-1.681,1.703-2.703,1.68-5.76
	c-1.168-0.769-1.971-1.773-3.6-2.16c-3.592-0.852-6.461,2.441-9.841,1.2c-3.84-1.41-4.563-4.978-5.52-9.36
	c-0.468-2.141,0.354-4.903-0.24-6.72c3.439,0,6.879,0,10.319,0c2.229,1.348,7.037-0.367,9.84,0.24
	c1.189,0.257,3.314-0.083,4.801,0.24c3.527,0.769,7.418,0.997,10.801,1.68c1.137,1.91,2.602,4.693,2.398,7.2
	c2.654,0.796,7.277,9.209,8.16,12.24c0.238,1.92,0.48,3.84,0.719,5.76c0.27,0.717,1.283,1.281,1.682,1.92
	c0.662,1.065,1.105,2.83,1.439,4.08c0.639,2.384-0.098,4.767,0.48,7.44c0.363,1.685-0.084,4.233,0.479,6
	c0.361,1.13,1.494,2.685,0.961,4.8c-0.922,3.661-3.615,6.234-3.84,10.32c0.822,0.924,1.074,2.195,1.682,3.36
	c1.541,2.964,2.965,6.055,4.561,9.12c3.445,6.626,6.486,13.2,9.6,20.4C353.614,196.853,355.223,199.652,355.678,202.572z'
          />{' '}
        </a>
        <a xlinkTitle={`Tataouine Sud ${groupedData['Tataouine Sud']}`}>
          <path
            onClick={() => setSelectedVill('Tataouine Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Tataouine Sud']) }}
            d='M261.601,140.893c-1.472,0.146-2.566,0.645-3.84,0.96c-1.149,2.565-3.906,8.851-2.881,12.96
	c0.32,0.88,0.641,1.76,0.961,2.64c-0.173,2.545-2.113,6.431-1.201,9.84c0.629,2.352,2.102,4.834,1.92,7.2
	c-1.463,0.4-3.387,1.542-4.799,1.92c-1.121-0.24-2.24-0.48-3.361-0.72c-0.271-1.193-2.488-5.812-3.359-6.24
	c-1.406,1.049-3.456-0.799-5.52-0.96c-2.364,2.555-3.086,1.704-6.24,1.2c-1.365,1.405-3.006,2.629-5.28,3.12
	c-2.08-0.16-4.159-0.32-6.239-0.48c-1.986,0.438-3.435,1.698-5.041,2.16c-1.216,0.35-2.647-0.293-3.6,0.24
	c-1.135,0.635-0.984,1.979-2.641,2.399c-3.547,0.902-6.418-1.342-9.6-1.44c0.471-3.216,0.055-7.58-3.119-8.16
	c0.229-1.511,0.769-1.44,0.719-2.88c-0.727-0.763-1.031-2.576-1.92-3.12c-0.644-0.396-1.349-0.098-1.92-0.48
	c-1.322-0.885-3.682-3.436-4.08-5.28c1.836-1.17,3.894-5.075,2.641-8.4c-0.368-0.975-1.18-1.448-1.439-2.64c0.159,0,0.319,0,0.479,0
	c0.739-0.45,2.327-0.045,3.12-0.48c1.381-0.758,2.556-3.223,2.88-5.04c0.653-3.673-3.39-8.184-4.32-11.04
	c-0.647-1.991-0.299-5.105,1.2-5.76c1.572-0.971,4.188,0.458,5.52,0.72c1.348,0.265,2.096-0.58,2.881-0.96c0-0.24,0-0.48,0-0.72
	c1.484-0.165,2.215-1.097,3.6-1.44c2.561,0.16,5.12,0.32,7.68,0.48c1.475-0.399,2.721-1.272,4.561-1.44
	c0.203,0.587,0.25,1.484,0.24,2.4c1.66,0.073,2.086-0.723,3.84-0.96c0.912,0.755,4.209,2.827,5.279,3.12
	c2.049,0.561,4.67-1.114,6.961-1.2c-0.08,0.96-0.16,1.92-0.24,2.88c0.414,0.144,0.757,0.214,1.439,0.24
	c0.245-0.193,0.065-0.085,0.48-0.24c0.271-1.261,1.123-3.292,2.4-3.6c1.008,0.771,5.457,2.635,6.719,2.88
	c0.776-1.405,0.324-3.407,0.961-5.04c2.054-0.841,4.272-2.189,5.52-3.84c0.895-1.184,1.594-2.34,3.12-2.88c0,2.8,0,5.6,0,8.4
	c-0.792,1.156-0.679,2.877-1.2,4.32c-1.066,2.949-2.767,5.915-2.88,9.6C254.903,136.39,261.239,137.124,261.601,140.893z'
          />{' '}
        </a>
        <a xlinkTitle={`Remada ${groupedData['Remada']}`}>
          <path
            onClick={() => setSelectedVill('Remada')}
            style={{ fill: getColorBasedOnCount(outputData['Remada']) }}
            d='M184.56,161.532c6.776,0.012,6.113,6.569,12,6.96c0.352-0.605,0.301-0.438,1.201-0.48
	c0.506,1.48,1.891,2.176,2.399,3.6c0.579,1.622-0.371,3.547-0.72,5.04c3.841,0.045,7.508,2.396,11.279,1.44
	c2.514-0.637,1.668-1.524,2.881-2.4c0.787-0.568,2.258,0.072,3.359-0.24c1.674-0.473,3.042-1.776,5.04-2.16
	c2.501-0.481,5.325,1.123,7.92,0.24c1.239-0.421,2.929-1.982,3.841-2.88c2.409,1.227,4.504,0.661,6.239-1.2
	c1.868,0.424,2.681,1.353,5.04,1.44c0.96,1.92,1.92,3.84,2.88,5.76c1.394,0.114,2.677,1.291,4.32,0.72
	c1.213-0.421,1.784-0.863,3.36-0.72c0-0.24,0-0.48,0-0.72c0.773-0.176,1.268-0.427,1.68-0.96c3.324,2.027,8.23,3.729,9.84,7.44
	c0.863,1.991,0.383,4.477,0.72,6.48c0.673,0.363,1.191,0.49,2.4,0.48c0.678-0.674,1.301-0.807,2.159-0.72
	c0.191,1.061,0.5,2.04,1.44,2.4c0.765,0.673,2.845,0.566,4.32,0.48c2.858-0.167,5.213-0.891,8.64-0.96
	c1.768,3.816,4.221,7.203,6.96,10.08c1.611,1.693,4.677,3.464,5.521,5.76c-1.961,1.59-2.73,4.252-4.08,6.48
	c-3.018,4.961-5.921,11.608-7.441,17.76c-3.928,0.117-4.607,3.313-7.681,4.32c-2.429,0.796-5.838,0.104-7.44,1.68
	c-2.492,2.453-2.322,9.61-6.959,9.84c0.146,6.923-0.033,10.436-3.36,13.92c-1.12-0.32-2.24-0.64-3.36-0.96
	c-2.175-0.493-6.115-0.146-7.92,0.24c-2.24,0.479-5.538-0.019-7.439-0.24c0,0.4,0,0.8,0,1.2c-0.08,0-0.16,0-0.24,0
	c0,0.08,0,0.16,0,0.24c-2.168,0.242-3.094,0.743-3.36,2.88c0,0.56,0,1.12,0,1.68c-0.399,0.79-1.479,0.376-2.399,0.72
	c-1.334,0.497-2.162,1.965-3.121,2.88c0.08,0.96,0.16,1.92,0.24,2.88c-2.314,0.29-4.195,2.078-6.479,2.64
	c-2.048,0.504-2.534-1.733-4.8-0.96c-0.899,0.307-1.449,1.438-2.16,1.92c-4.137,2.802-4.715,1.061-4.561,8.4
	c-0.64,2.16-1.279,4.32-1.92,6.479c-0.964-0.088-1.222-0.305-1.92,0.24c-1.061,1.223-0.596,1.989-3.119,1.92
	c-0.625,1.008-1.43,2.924-2.4,3.602c-0.916,0.64-2.254,0.779-2.881,1.681c-1.11,1.599-0.994,3.765-2.639,4.8
	c-0.832,0.523-1.777,0.162-2.881,0.479c-1.262,0.363-2.611,1.188-3.6,1.92c-1.2,1.281-2.4,2.562-3.6,3.841
	c-1.332,0.991-2.745,1.646-4.081,2.642c-2.412,1.793-4.013,4.713-5.76,7.199c-1.671,2.379-3.987,4.477-5.52,6.96
	c-1.042,1.688-1.342,3.646-2.16,5.521c-1.33,3.052-3.386,6.183-3.6,10.32c18.238,0.09,33.565-1.834,51.121-1.921
	c0-0.159,0-0.319,0-0.479c-0.123-0.577-0.39-0.293,0.239-0.719c0.255,0.187,0.886,0.354,1.2,0.479
	c1.643,3.592,1.895,7.604,3.359,11.521c1.166,3.109,2.826,6.354,4.08,9.358c1.16,2.774,1.48,5.578,2.641,8.399
	c1.111,2.703,2.087,5.925,3.6,8.398c0.801,1.313,2.288,2.395,2.641,4.08c-2.273,3.227,2.697,10.03,3.6,12.239
	c1.729,4.229-1.6,8.245-0.48,13.2c0.785,3.472,1.725,6.66,2.881,9.841c0.455,1.25,1.635,3.668,1.199,5.521
	c-1.197,5.098-3.437,10.26-5.039,15.119c-1.062,3.217-1.244,6.688-2.881,9.359c-2.836,4.631-7.566,7.754-10.56,12.24
	c-2.798,4.193-4.228,9.445-7.2,13.439c-2.299,3.09-6.801,4.395-9.12,7.438c-4.507,5.92-8.108,13.682-14.64,17.521
	c-2.227,1.309-5.612-0.068-8.64,0.719c-1.623,0.422-2.348,1.73-3.6,2.4c-1.572,0.84-3.026,1.123-4.56,1.92
	c-2.786,1.447-5.627,3.137-8.4,4.561c-0.96,0.32-1.92,0.639-2.88,0.959c-4.494,2.873-8.363,7.342-15.6,7.439
	c0-0.158,0-0.318,0-0.479c-1.247-1.918-0.998-5.055-1.68-7.441c-1.18-4.127-1.972-8.475-3.36-12.719
	c-3.694-11.295-6.535-23.328-9.84-35.041c-2.022-7.168-2.516-14.111-4.56-21.359c-2.627-9.316-4.103-19.521-6.72-28.801
	c-0.645-2.285-0.547-4.182-1.2-6.479c-2.753-9.686-4.141-19.854-6.96-29.762c-6.533-22.953-10.823-46.965-17.28-69.839
	c-3.003-10.64-4.748-21.522-7.68-31.92c-1.661-5.887-2.014-12.2-4.32-17.52c-2.597-5.99-8.178-7.911-13.44-11.28
	c-2.059-1.318-3.932-3.255-6-4.56c-1.418-0.894-2.878-1.28-4.32-2.16c-4.124-2.517-8.511-4.889-12.48-7.68
	c-1.576-1.108-2.772-3.028-4.32-4.08c-2-0.88-4-1.76-6-2.64c-0.24-0.72-0.48-1.44-0.72-2.16c-0.64-0.24-1.28-0.48-1.92-0.72
	c-1.684-0.881-3.392-1.84-5.04-2.88c-5.079-3.206-10.007-7.085-15.12-10.32c-4.161-2.632-8.171-5.395-12.24-7.92
	c-3.171-1.968-6.702-2.812-8.4-6.24c0.64,0,1.28,0,1.92,0c2.109-1.248,9.27-0.965,12.24-1.68c9.142-2.2,18.699-2.434,27.839-4.8
	c10.414-2.696,21.744-3.812,32.16-6.48c3.04-0.32,6.08-0.64,9.12-0.96c1.081-0.395,1.896-1.699,2.88-2.16
	c4.64-1.04,9.281-2.08,13.92-3.12c8.266-2.521,17.099-4.332,25.2-6.96c2.706-0.877,5.148-0.839,7.92-1.68
	c2.656-0.806,5.837-1.636,7.92-3.12c2.769-1.973,4.763-5.146,7.68-6.96c2.604-1.62,6.03-2.124,8.88-3.36
	c4.847-2.104,8.245-4.249,14.88-2.88c0.875,2.02,2.877,2.695,3.6,4.56c0.694,1.789,0.499,3.812,1.92,4.8
	c2.253,1.569,6.08,1.139,6.48,4.56C184.774,160.764,184.632,160.767,184.56,161.532z'
          />{' '}
        </a>
        <a xlinkTitle={`Dhehiba ${groupedData['Dhehiba']}`}>
          <path
            onClick={() => setSelectedVill('Dhehiba')}
            style={{ fill: getColorBasedOnCount(outputData['Dhehiba']) }}
            d='M288.24,190.812c3.761,0,7.52,0,11.28,0c0,0.16,0,0.32,0,0.48c1.072,1.636,1.104,4.53,2.16,6.24
	c2.428,3.93,6.205,7.093,9.84,9.84c1.443,1.09,2.357,3.007,3.84,4.08c1.326,0.96,2.832,1.33,4.32,2.16
	c1.594,0.891,1.91,3.093,4.08,3.36c1.592,1.142,5.1,0.96,7.68,0.96c2.463,0,5.328,0.319,7.439-0.24
	c2.918-0.772,5.822-1.784,9.359-1.92c0,0.16,0,0.32,0,0.48c-0.396,0.582-0.461,1.727-0.48,2.64c-1.377,0.475-2.258,1.863-3.359,2.64
	c-2.219,1.562-4.668,2.927-6.959,4.32c-3.309,2.011-6.988,3.02-10.32,5.04c-3.76,2.281-7.402,4.669-10.801,7.2
	c-1.186,0.882-2.721,1.715-3.602,2.88c-2.871,3.803-5.145,8.301-7.438,12.72c-0.818,1.576-2.664,5.563-4.08,6.24
	c-1.068,0.511-2.748,0.274-3.842,0.72c-1.656,0.676-2.896,2.089-4.563,2.64c-3.537-2.733-8.359,0.262-9.838,2.64
	c-0.438,0.704-2.326,5.336-1.92,6.48c0.521,1.47,2.143,3.153,1.439,6c-0.731,2.967-2.678,5.104-4.56,6.959
	c-0.34,0.334-1.178,0.588-1.44,0.961c-0.367,0.521-0.035,1.172-0.239,1.92c-0.378,1.377-0.948,3.142-1.681,4.32
	c-2.313,3.725-6.394,5.793-11.279,6.959c-2.094,0.5-5.104,0.486-7.2,0c-0.81-0.188-2.368-0.979-3.36-0.721
	c-1.186,0.313-4.188,2.118-5.279,2.88c-9.703,6.771-13.995,19.425-20.399,29.521c-1.44,1.841-2.88,3.681-4.319,5.521
	c-0.725,0.229-0.99-0.321-1.681,0.479c-0.323,0.404-0.394,0.754-0.479,1.439c-4.959-0.023-10.267-0.222-14.641,0.721
	c-3.479,0.75-7.705,0.002-11.039,0.722c-1.361,0-2.721,0-4.08,0c-5.526,1.194-12.722,0.448-18.959,0.479
	c0.267-3.738,2.352-6.738,3.6-9.603c0.801-1.839,1.109-3.675,2.16-5.279c1.634-2.499,4.028-4.737,5.76-7.198
	c1.458-2.072,2.793-4.511,4.8-6c1.423-1.06,2.905-1.822,4.319-2.881c1.678-1.255,2.818-3.404,4.561-4.562
	c1.471-0.978,4.001-0.688,5.52-1.681c1.726-1.123,1.483-3.067,2.641-4.801c0.572-0.855,1.773-0.851,2.641-1.439
	c1.027-0.7,1.823-2.457,2.399-3.6c0.914,0.021,1.817-0.035,2.399-0.24c0.455-1.174,0.41-1.91,2.16-1.92
	c0.258,0.181,0.228,0.146,0.721,0.239c0.871-4.3,2.098-6.7,2.16-12.479c1.707-1.288,4.877-4.451,7.439-4.56
	c2.396,2.437,6.732-0.409,8.641-1.44c0.582-0.315,1.631-0.172,1.92-0.72c0.444-0.847-0.147-2.513,0-2.88
	c1.071-3.003,3.354-1.554,4.799-2.88c0.818-0.749,0.326-3.17,0.961-4.08c0.634-0.554,1.576-0.267,2.4-0.72
	c0.32-0.177,0.461-1.042,0.719-1.2c4.721-0.08,9.44-0.16,14.16-0.24c1.069,0.25,2.936,1.209,3.601,0.96
	c4.37-1.398,4.385-8.004,4.319-13.92c4.392-0.79,4.515-8.697,7.201-10.08c1.526-1.369,4.457-0.747,6.719-1.44
	c2.246-0.688,3.287-2.722,5.041-3.84c0.746-0.476,1.99,0.054,2.879-0.48c0.57-0.342,2.182-6.557,2.641-7.68
	c1.587-3.883,3.128-8.227,5.279-11.76c1.188-1.952,3.82-4.14,4.318-6.48c-3.615-2.389-6.82-6.331-9.357-9.84
	C290.284,194.365,288.638,192.942,288.24,190.812z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 145.4224 250.6079)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Remada
        </text>
        <text
          transform='matrix(1 0 0 1 230.4224 289.6079)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Dhiba
        </text>
        <text
          transform='matrix(1 0 0 1 203.4224 154.6079)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Tataouine{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 203.4224 169.0083)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sud
        </text>
        <text
          transform='matrix(1 0 0 1 261.2388 167.6079)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Tataouine{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 287.4829 182.0083)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nord
        </text>
        <text
          transform='matrix(1 0 0 1 304.4224 147.6079)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Smar
        </text>
        <text
          transform='matrix(1 0 0 1 190.4224 112.6079)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ghomrassen
        </text>
        <text
          transform='matrix(1 0 0 1 270.4224 76.6079)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bir Lahmar
        </text>
        <line
          fill='none'
          stroke='#000000'
          x1='255.642'
          y1='90.01'
          x2='266.642'
          y2='76.01'
        />
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Tataouine;
