import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Beja = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});
  let minCount = 0;
  let maxCount = 'and more';
  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts);
  const getColorBasedOnCount = (count) => {
    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts);
    const lowerBound = i === 0 ? minCount : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4 ? maxCount : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);

  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='563.408px'
        height='529.012px'
        viewBox='0 0 563.408 529.012'
        enable-background='new 0 0 563.408 529.012'
      >
        <a xlinkTitle={`Nefza ${groupedData['Nefza']}`}>
          <path
            onClick={() => setSelectedVill('Nefza')}
            style={{ fill: getColorBasedOnCount(outputData['Nefza']) }}
            d='M71.76,116.247c0.019,2.842,0.214,5.807-0.96,7.44c-1.456,2.025-3.875,1.794-4.08,5.28
	c1.281,1.34,2.234,2.552,4.08,3.36c0.946,0.414,2.451,0.59,2.88,1.44c0.337,0.668-0.044,1.602,0.24,2.16
	c0.617,1.212,2.152,1.804,2.88,2.88c1.543,2.28,0.697,5.211,4.56,5.28c1.344-0.934,3.516-0.713,5.28-1.2
	c5.387-1.486,13.549-2.819,19.2-0.72c1.68,3.28,3.36,6.561,5.04,9.84c1.14,1.868,2.722,3.671,3.12,6.24
	c-1.111,1.251-2.31,2.3-2.4,4.56c3.971,2.159,5.055,2.332,6,7.68c0.1,0.565,0.123,2.014-0.24,2.88
	c-0.999,2.387-3.361,3.489-3.6,6.72c5.058,1.025,8.716,3.926,11.04,7.68c0.836,1.35,1.011,2.975,1.92,4.32
	c1.283,1.899,4.681,1.972,5.52,4.32c0.365,0.455,0.43,0.861,0.48,1.68c-1.953,1.891-3.924,3.475-5.04,6.24
	c-5.996,0.44-5.895-4.045-10.08-5.28c-0.655,5.31-8.715,3.781-12.96,3.12c-0.694,2.79-2.893,4.862-4.32,6.96
	c-2.079-1.331-4.471-2.605-7.68-2.88c-2.115,3.081-5.563,3.327-4.32,8.64c5.042,2.869,6.188-1.775,10.32-0.96
	c1.805,0.357,3.822,5.135,3.84,7.68c-2.02,0.292-3.822,1.468-5.52,2.16c-1.997,0.813-3.636,0.984-5.76,1.68
	c-0.95,0.312-2.945,1.304-4.32,0.479c-0.937-0.311-1.791-1.249-2.4-1.92c-1.037-0.015-1.898,0.087-2.64,0.24
	c-3.012,6.339-5.852,13.063-10.56,17.76c-0.24,0-0.48,0-0.72,0c-0.062-1.357-0.688-1.919-0.96-2.88c0.08-1.04,0.16-2.08,0.24-3.12
	c-0.16,0-0.32,0-0.48,0c-1.522,1.272-3.961,1.215-6,0.72c-0.892-1.386-1.656-2.167-1.68-4.56c1.152-1.346,1.942-3.023,3.36-4.08
	c2.854-2.128,7.563-3.881,7.68-8.64c-1.04-0.88-2.08-1.76-3.12-2.64c-2.359-1.495-4.879-2.298-7.68-3.36
	c-0.976-0.37-3.003-1.725-4.56-1.2c-1.61,0.542-1.318,3.177-2.88,3.84c-1.767,0.987-2.207-1.669-2.88-2.64
	c-0.56-0.4-1.12-0.8-1.68-1.2c-0.72,0-1.44,0-2.16,0c-0.287,5.148-3.959,7.92-8.4,8.88c-0.159-1.008-0.204-1.028-1.2-1.2
	c0.067-6.53-5.264-6.488-5.52-12.72c-2.311-0.257-4.703-1.413-6.72-2.16c-5.625-2.082-13.366-3.269-13.2-11.04
	c2.344-2.522,3.942-5.595,5.28-9.12c-0.16,0-0.32,0-0.48,0c-0.912,0.892-3.789,1.654-5.04,0.72c-3.433-1.254-4.253-11.819-4.8-16.32
	c-2.471-0.663-3-2.985-4.32-4.8c-0.589-0.809-2.496-1.4-2.88-1.92c-0.076-0.103-0.48-2.4-0.48-2.4c1.2-1.44,2.4-2.88,3.6-4.32
	c6.695-5.062,14.379-11.862,19.2-18.72c1.464-2.083,2.211-4.515,3.6-6.72c0.858-1.363,2.627-1.434,3.36-2.4
	c1.572-2.075,2.78-5.537,2.88-8.88c3.549-0.734,10.119-4.746,10.08-8.88c0.56,0,1.12,0,1.68,0c0.828-1.305,1.84-4.425,3.12-5.28
	c1.52-0.48,3.04-0.96,4.56-1.44c1.479-0.872,2.637-2.471,4.08-3.36c2.404-1.481,5.873-1.609,8.64-2.64
	c1.175,3.653,5.375,6.73,8.4,8.64c1.777,1.121,5.07,1.953,5.28,4.56C76.203,113.346,76.886,116.089,71.76,116.247z'
          />{' '}
        </a>
        <a xlinkTitle={`Beja Nord ${groupedData['Beja Nord']}`}>
          <path
            onClick={() => setSelectedVill('Beja Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Beja Nord']) }}
            d='M145.92,212.247c0.364,2.598,3.285,4.36,5.52,5.04c1.64-1.604,6.356-2.594,9.6-1.68
	c4.796,1.351,9.605,4.465,9.6,10.56c4.174,0.532,6.545,5.136,8.881,7.68c-0.08,0-0.16,0-0.24,0c-2.651,5.25-8.44,9.576-10.561,15.36
	c-0.719,1.964,0.616,2.61,0.96,3.84c0.142,0.502-0.724,3.369-0.96,4.08c-0.836,2.517-3.996,5.414-2.88,9.359
	c0.814,2.882,4.013,4.078,4.8,6.961c0.782,2.854-1.916,3.964-0.96,6c-3.221,0.882-6.454,3.337-7.68,6.24
	c-1.15,2.724-0.726,8.457-3.84,9.119c-0.663-0.273-2.469,0.907-4.32,0.479c-2.365-0.549-4.655-1.184-7.68-1.199
	c-0.711-1.979-3.612-4.473-5.52-5.279c-0.891-0.379-1.919,0.004-2.88-0.479c-0.648-0.327-2.406-2.932-3.12-3.602
	c-0.015-1.037,0.086-1.896,0.24-2.641c-0.24,0-0.48,0-0.72,0c-2.202,1.379-9.188-0.828-11.52-0.961
	c-2.75-0.156-5.346-0.029-6.96,0.961c-1.494,0.916-3.593,3.004-6.48,2.398c-1.811-0.379-2.586-1.896-4.32-2.398
	c-0.453,1.604-2.356,6.215-3.84,6.719c-0.797,0.65-1.784,0.399-2.88,0.24c0.127-1.42,0.488-2.009,0.24-3.357
	c-0.535-0.24-0.684-0.367-1.44-0.479c-1.701,1.688-4.048,2.899-6,4.319c-4.802,3.492-9.023,7.805-14.4,10.8
	c-1.903,1.063-7.029,3.534-8.16,2.399c-2.301-1.521-3.867-4.049-5.76-6c0.833-1.582,2.046-1.896,2.16-4.32
	c-1.021-0.584-2.086-1.021-2.16-2.643c2.825-1.938,4.736-5.592,6.24-8.879c2.018-0.264,5.488-1.806,6.24-3.359
	c0.16-0.879,0.32-1.762,0.48-2.641c0.625-0.652,1.869-0.404,2.4-1.199c0.671-1.004,0.527-2.356,1.2-3.358
	c0.588-0.875,1.574-0.743,2.64-1.199c1.691-0.728,3.126-2.14,3.84-3.842c1.946-4.642-0.013-9.104-0.96-13.199
	c-0.691-2.99,1.764-4.285,0.96-7.2c-0.547-1.983-1.875-3.983-2.4-6.24c0.08-1.2,0.16-2.4,0.24-3.6
	c1.967-1.514,4.601-5.028,5.04-7.92c4.286,0.042,8.483-2.444,11.76-3.84c0.826-0.352,2.735-0.473,3.12-1.2c0-0.96,0-1.92,0-2.88
	c-1.308-1.676-2.273-3.991-3.12-6c-1.851-0.054-3.313-0.38-5.04,0.24c-1.828,0.657-3.187,1.669-5.76,1.2
	c-0.299-0.591-0.999-1.341-0.72-2.4c0.438-1.663,3.243-4.715,5.28-4.8c1.118,0.766,2.819,0.547,4.08,1.2
	c0.826,0.428,1.162,1.314,2.16,1.68c1.058-1.438,2.613-2.47,3.6-4.08c0.676-1.102,0.511-2.486,1.92-2.88
	c0.925,0.654,2.2,0.19,3.6,0.48c3.604,0.745,7.636-0.845,8.88-3.36c0.26,0.218,0.021-0.019,0.24,0.24
	c2.01,1.306,3.266,3.718,5.76,4.561c2.305,0.778,4.253-0.271,6.24,0.72C135.61,208.521,138.051,212.16,145.92,212.247z'
          />
        </a>
        <a xlinkTitle={`Amdoun ${groupedData['Amdoun']}`}>
          <path
            onClick={() => setSelectedVill('Amdoun')}
            style={{ fill: getColorBasedOnCount(outputData['Amdoun']) }}
            d='M68.64,237.687c-0.018,2.465,0.896,3.349,1.2,5.52c4.795-0.448,8.177-8.195,9.84-12
	c0.824-1.884,1.357-5.099,3.12-6c0.311-0.21,0.592-0.215,1.2-0.24c0.734,0.778,1.669,1.379,2.4,2.16c0.32,0,0.64,0,0.96,0
	c-0.441,4.71-6.449,5.646-5.04,11.76c0.549,2.385,1.879,4.384,2.4,6.48c0.615,2.477-1.638,4.015-0.96,6.96
	c1.003,4.357,2.79,8.568,0.72,13.2c-1.011,2.263-3.354,2.021-5.28,3.359c-1.3,0.901-1.208,3.043-2.16,4.317
	c-0.461,0.621-1.232,0.287-1.92,0.722c-0.785,0.493-0.861,2.439-1.2,3.358c-1.912,1.169-3.987,2.073-6.24,3.118
	c-0.595,4.638-5.547,6.498-6,9.842c-0.298,0.376-0.154,0.122,0,0.479c0.23,1.289,1.164,1.687,2.16,2.159
	c-0.151,0.423-0.046,0.233-0.24,0.479c-0.474,1.64-1.711,1.63-1.92,3.601c0.408,0.425,0.364,0.92,0.72,1.44
	c1.285,1.877,4.521,3.005,5.04,5.521c-1.354,0.447-2.265,1.668-3.36,2.399c-3.441,2.297-6.946,4.035-10.56,6.238
	c-1.372,0.836-2.707,2.078-4.56,2.398c-0.656-0.969-1.444-1.188-1.68-2.645c4.441-3.271,2.229-10.691,3.6-17.039
	c0-1.119,0-2.238,0-3.359c0.596-2.604,1.77-4.77,2.4-6.959c-2.532-1.662-3.369-5.425-3.36-9.602
	c-2.646-1.713-4.225-4.021-7.68-5.279c-5.694-2.072-14.152-0.483-19.92-2.399c-2.446-0.813-5.133-3.661-7.2-3.84
	c-0.899,0.655-2.389,0.935-3.84,0.96c-0.477-1.006-0.893-1.621-0.96-3.12c1.315-1.274,3.463-3.736,3.84-5.76
	c-0.823-0.903-0.75-1.756-2.4-1.92c-0.456-0.294-1.834-0.376-2.4-0.48c0-0.16,0-0.32,0-0.479c1.652-1.608,2.692-4.151,4.08-6
	c1.065-1.419,3.373-2.16,4.32-3.6c0.326-0.496,0.028-1.002,0.24-1.68c0.545-1.741,1.705-3.437,3.12-4.32
	c4.862-3.032,15.482-1.494,17.52-7.2c1.727-2.301-1.566-5.776,0-7.92c0.542-0.354,1.471-0.234,2.16-0.48
	c1.819-0.648,4.36-1.752,5.52-3.12c0.684-0.807,1.516-2.145,1.92-3.12c0.16-0.8,0.32-1.6,0.48-2.4c0.32,0,0.64,0,0.96,0
	c1.164,1.292,2.172,2.816,3.36,4.08c0.8-0.08,1.6-0.16,2.4-0.24c0.537-0.925,1.346-3.176,2.4-3.6c0.909-0.491,2.68,0.615,3.6,0.959
	c2.901,1.088,9.79,3.292,10.32,6.24c-1.026,1.167-1.638,2.663-2.88,3.6c-2.465,1.859-6.225,3.694-7.68,6.48
	c-1.255,2.403-0.067,6.021,1.92,6.72C64.473,238.727,66.826,237.876,68.64,237.687z'
          />
        </a>
        <a xlinkTitle={`Mejez El Bab ${groupedData['Mejez El Bab']}`}>
          <path
            onClick={() => setSelectedVill('Mejez El Bab')}
            style={{ fill: getColorBasedOnCount(outputData['Mejez El Bab']) }}
            d='M275.519,329.846c-0.121,1.063-0.236,2.25-0.236,3.604c1.628,0.507,2.971,1.784,3.356,3.604
	c-1.564,1.086-2.729,2.957-4.32,4.075c-3.139,2.222-7.32,4.183-9.12,7.685c-1.561-0.332-2.891-1.209-4.316-1.685
	c-2.691-0.892-5.32-0.877-8.16-1.682c-2.08-0.646-4.158-1.279-6.236-1.923c0.027-1.813-0.187-3.16-0.48-4.563
	c-4.586-1.291-6.823-6.619-8.641-10.801c-2.787-6.416-5.457-14.609-12.24-17.039c-2.189-0.785-5.232-0.363-7.68-0.24
	c-0.399-1.201-0.803-2.396-1.199-3.601c-0.836-3.554,1.271-10.7,1.92-13.681c0.77-3.545-0.771-9.033-2.881-9.84
	c-0.403-0.322-0.754-0.396-1.439-0.479c-1.457,1.19-4.041,3.077-6.48,3.12c-0.914-2.48-3.937-3.488-6.479-4.32
	c-2.044-0.668-4.627-0.295-4.8-2.88c0.481-0.663,0.37-1.556,0.72-2.399c0.752-1.813,1.979-3.746,3.12-5.279
	c3.504-4.715,10.696-7.521,16.08-10.318c2.763-1.436,8.854-3.089,8.88-6.96c0.4-1.44,0.801-2.88,1.2-4.32
	c0.796-1.89,2.161-3.901,3.36-5.52c3.521-4.761,9.172-7.291,13.197-11.521c0.159,0,0.319,0,0.479,0
	c0.79,2.511,2.344,4.987,3.116,7.92c0.687,2.581,0.185,5.656,1.44,7.68c1.737,2.792,5.158,4.425,7.44,6.72
	c0.871,0.877,1.41,2.126,2.16,3.12c3.271,4.346,8.649,8.521,13.92,10.8c5.802,2.515,12.883,2.983,18.479,5.761
	c3.469,1.721,6.492,4.576,9.12,7.199c1.943,1.939,2.809,4.281,6.72,4.318c0.704-0.709,1.813-1.105,2.4-1.92
	c1.499-2.047,1.309-3.881,5.04-3.84c0.694,0.569,0.976,0.291,1.2,1.438c-0.695,0.741-0.947,1.938-0.96,3.358
	c0.566,0.614,0.519,1.104,1.438,1.439c1.521,0.986,4.793,0.387,5.521,2.16c0.744,1.25-0.563,3.371-0.96,4.318
	c-1.209,2.869-2.07,5.66-1.44,9.119c5.738,1.528,7.271,7.477,10.08,12c0.939,1.515,2.747,2.852,3.36,4.563
	c-2.44,1.629-3.936,4.502-6.24,6.238c-3.19,2.396-6.188,4.201-10.8,5.279c-3.379,0.789-5.283-2.109-7.2-3.119
	c-3.634-1.914-9.259-2.496-12.48-4.801c-0.647-0.47-0.91-1.539-1.438-2.16c-0.66-0.775-2.398-1.76-3.359-2.16
	c-3.449-1.434-9.157,0.252-9.354,3.602c-0.688,1.157-0.098,3.098-0.48,4.563c-0.399,1.521-1.248,2.156-2.16,3.115
	C276.96,329.686,276.239,329.766,275.519,329.846z'
          />{' '}
        </a>
        <a xlinkTitle={`Testour ${groupedData['Testour']}`}>
          <path
            onClick={() => setSelectedVill('Testour')}
            style={{ fill: getColorBasedOnCount(outputData['Testour']) }}
            d='M173.04,279.686c-0.096,1.375-0.639,1.754-0.96,2.881c4.218,2.675,8.378,3.777,14.159,1.439
	c1.684-0.681,6.692-3.234,9.357-2.4c0.823,0.262,1.967,1.109,2.883,1.441c1.959,0.707,4.145,0.635,5.76,1.68
	c1.264,0.815,1.58,2.625,3.357,2.879c1.829,1.123,5.014-2.514,6.96-2.879c0.245,0.193,0.066,0.084,0.479,0.24
	c0.527,1.157,1.32,2.566,1.681,3.84c1.388,4.939-1.418,9.709-1.681,14.16c-0.211,3.574-0.143,9.066,2.881,9.359
	c2.547-1.618,6.99,0.271,8.641,1.199c6.879,3.875,8.063,14.277,12.239,20.881c0.874,1.381,2.524,3.363,3.84,4.32
	c0.622,0.453,2.072,0.758,2.4,1.438c0,0.723,0,1.438,0,2.16c0.271,0.402,0.255,1.176,0.237,1.92
	c3.705,1.896,8.869,2.514,13.199,3.84c1.801,0.555,4.743,0.625,5.521,2.16c-1.725,1.23-2.813,3.568-4.08,5.279
	c-3.125,4.224-7.523,8.701-13.439,10.078c-2.439,0.567-5.029-0.332-6.959-0.479c0,0.16,0,0.319,0,0.479
	c0.906,1.392,0.229,3.979-0.24,5.278c-1.527,4.229-4.529,7.035-8.16,9.119c0,0.079,0,0.159,0,0.239c0.08,0,0.16,0,0.24,0
	c2.131,3.064,8.42,3.371,8.642,8.158c-2.718,3.068-2.55,12.271-5.28,15.117c-2.427-1.932-5.588-3.104-8.398-4.559
	c-0.697-0.361-1.864-1.543-3.119-1.201c-1.086,0.297-2.553,1.309-3.84,1.682c-2.723,0.398-5.439,0.803-8.16,1.199
	c-0.803,0.262-3.213,1.787-3.602,1.438c-1.256-0.774-2.597-1.901-4.078-2.397c-2.65-0.883-6.324,0.021-8.16,0.721
	c-4.908,1.867-8.73,4.742-13.439,6.48c-2.273,0.838-4.814,0.332-7.439,0.959c-2.224,0.527-4.343,1.252-6.24,2.16
	c0-0.16,0-0.32,0-0.48c2.58-3.231-1.75-8.607-2.397-10.316c1.42-1.604-0.28-4.846,0.24-6.96c0.094-0.384,0.731-1.208,0.72-1.683
	c0-0.08,0-0.158,0-0.237c-0.08,0-0.16,0-0.24,0c-0.396-2.625-4.259-4.396-6-6c-0.103-6.618,1.695-10.767,2.16-16.08
	c-0.808-0.576-1.32-1.642-2.16-2.16c-1.894-1.173-5.973-0.733-8.88-0.72c0-0.16,0-0.319,0-0.479c-0.595-0.882-0.35-1.911-0.72-3.12
	c-0.734-2.396-2.13-4.805-4.08-6c-2.746-1.687-9.275-1.598-12.72-0.479c-1.129,0.364-3.212,1.754-4.08,1.438
	c-2.155-2.193-2.247-6.623-3.6-9.603c3.603-2.796,8.083-9.304,8.16-15.603c0.821-0.561,0.967-1.201,2.16-1.438
	c1.408,0.93,5.293,0.021,6.48-0.479c4.603-1.927,7.568-5.639,11.28-8.397c1.523-1.134,3.354-1.104,3.36-3.841
	c-0.654-1.002-0.027-4.131,0.24-5.278c0.526-2.271,0.396-3.873,1.2-5.761c0.857-2.014,2.623-3.764,3.36-5.762
	c-0.527-0.561-3.135-4.395-3.36-5.039c2.461-1.729,2.057-4.943,3.12-8.16C163.698,283.68,168.214,279.67,173.04,279.686z'
          />{' '}
        </a>
        <a xlinkTitle={`Beja Sud ${groupedData['Beja Sud']}`}>
          <path
            onClick={() => setSelectedVill('Beja Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Beja Sud']) }}
            d='M133.68,283.286c-0.023,0.741-0.034,1.519,0.24,1.92c0.625,1.862,2.505,1.925,2.88,4.08
	c4.661-0.054,7.356,3.146,9.12,6c2.585-0.515,5.23,0.442,7.44,0.96c1.52,0,3.04,0,4.56,0c0.825,1.277,2.248,3.01,3.36,4.08
	c-0.58,1.826-2.129,3.307-2.88,5.04c-1.471,3.397-2.151,8.483-1.68,12.96c-5.743,3.359-9.168,9.07-16.56,10.801
	c-1.6-0.08-3.2-0.16-4.8-0.24c-0.669,1.813-1.913,1.479-1.92,4.319c-0.633,0.997-0.486,2.478-0.96,3.601
	c-0.63,1.492-1.699,3.227-2.64,4.563c-0.629,0.896-3.256,4.348-4.08,4.801c-1.086,0.603-1.774,0.457-2.4,1.438
	c-1.18,1.854-0.469,5.393-1.68,7.201c-1.08,1.61-3.221,2.213-4.8,3.356c-1.302,0.942-2.143,2.54-3.12,3.84
	c-0.673,0.896-1.836,1.39-2.64,2.16c-0.48,0-0.96,0-1.44,0c0,1.356,0,2.724,0,4.079c-3.227,0.07-8.025,2.447-10.8,1.684
	c-1.23-0.345-2.302-1.356-3.12-2.16c0-0.16,0-0.317,0-0.479c-0.72,0-1.44,0-2.16,0c-1.506-0.353-6.132-1.071-6.96-1.92
	c-0.498-0.511-1.711-5.604-2.4-6.722c-1.646-2.668-4.337-2.651-4.32-6.961c-1.626-2.438-1.915-5.697-2.4-8.642
	c-0.708-0.438-1.398-1.009-2.4-1.2c-0.48,0.079-0.96,0.159-1.44,0.238c-2.082-0.536-2.294-2.354-5.28-2.396
	c-0.45,0.385-1.399,0.637-1.68,0.479c-4.238-2.189-8.891-9.33-8.88-16.08c-2.316-1.464-7.222-5.063-7.68-8.159
	c5.119-2.254,9.88-5.389,14.4-8.397c1.214-0.812,3.367-3.139,4.56-3.603c0.56,0.08,1.12,0.159,1.68,0.239
	c3.706-1.063,8.519-4.13,11.52-6.239c3.707-2.604,7.084-5.706,10.8-8.399c1.409-1.021,2.518-2.775,4.56-3.118
	c0,0.157,0,0.317,0,0.479c-0.438,0.512-0.703,1.711-0.48,2.158c0.269,0.688,0.042,0.441,0.72,0.723c0.778,0.666,2.789,0.631,3.6,0
	c2.711-1.025,2.311-5.646,4.32-6.24c1.104,1.57,3.962,2.468,6.72,1.681c2.18-0.622,3.497-2.548,5.76-3.12
	c2.591-0.654,5.863,0.282,7.68,0.721C128.384,283.381,130.884,283.309,133.68,283.286z'
          />{' '}
        </a>
        <a xlinkTitle={`Goubellat ${groupedData['Goubellat']}`}>
          <path
            onClick={() => setSelectedVill('Goubellat')}
            style={{ fill: getColorBasedOnCount(outputData['Goubellat']) }}
            d='M343.919,333.686c0.139,3.408,4.863,10.043,3.12,12.479c-1.077,1.071-2.652,1.417-4.08,2.16
	c-3.026,1.575-6.401,3.625-8.16,6.479c-2.045,3.313,0.158,10.271-1.438,14.157c-1.531,3.736-6.099,6.254-9.116,8.642
	c-0.707,0.562-0.903,1.649-1.44,2.397c-0.987,1.381-2.697,3.088-4.08,4.08c-3.699,2.659-8.603,4.592-12.96,6.479
	c-1.704,0.736-4.042,0.695-4.317,2.883c-0.682,1.254,1.009,3.024,1.199,4.563c-1.632,1.305-2.078,3.854-3.355,5.521
	c-0.563,0.399-1.12,0.801-1.685,1.199c-6.205-0.043-16.253-1.164-21.122,0.479c-0.021,2.455-1.112,3.563-1.438,5.762
	c-4.563,0.803-9.12,1.604-13.682,2.398c-4.508,1.021-9.909,0.223-14.642,0.236c-1.459-2.248-3.847-4.447-6-6
	c-1.512-1.088-4.046-1.808-4.317-4.078c1.082-1.252,1.101-3.271,1.681-5.041c0.733-2.243,1.295-4.866,2.16-7.197
	c0.493-1.335,1.263-2.396,1.438-4.08c-0.944-0.982-1.294-2.531-2.397-3.355c-1.746-1.311-4.502-2.063-5.76-3.842
	c3.068-1.021,9.149-8.318,7.68-12.961c0.881,0,1.761,0,2.641,0c2.463,1.286,7.749-1.966,9.12-2.882
	c7.798-5.206,10.876-14.313,18-19.438c2.558-1.842,8.755-4.787,8.88-8.642c-0.279-0.327-0.217-0.806-0.479-1.198
	c-0.479-0.714-2.625-1.668-2.884-2.16c0-0.563,0-1.119,0-1.681c5.452-0.696,3.606-5.416,5.28-9.842c3.148-2.048,6-3.104,9.604-0.479
	c0.938,0.688,1.271,2.188,2.154,2.881c2.438,1.9,8.354,2.943,11.521,4.32c2.176,0.94,4.063,3.211,6.24,3.84
	c2.213,0.641,5.129-0.445,6.479-0.961c5.585-2.123,11.404-6.629,14.4-11.277c0.236,0.078,0.479,0.158,0.72,0.238
	c0.146,0.414,0.214,0.756,0.24,1.438c-0.795,0.813-1.777,2.222-1.92,3.603c0.52,0.75,0.479,1.813,0.96,2.644
	c1.468,2.521,3.333,5.108,5.276,7.196C340.102,334.84,341.269,333.803,343.919,333.686z'
          />{' '}
        </a>
        <a xlinkTitle={`Teboursouk ${groupedData['Teboursouk']}`}>
          <path
            onClick={() => setSelectedVill('Teboursouk')}
            style={{ fill: getColorBasedOnCount(outputData['Teboursouk']) }}
            d='M110.64,369.206c0-1.36,0-2.72,0-4.079c3.721-0.521,4.485-4.447,6.96-6.24c1.939-1.4,4.617-2.174,5.52-4.561
	c0.74-1.957,0.465-4.557,1.2-6.24c0.594,0.116,0.884,0.207,1.68,0.24c0.416,4.172,2.24,7.377,4.08,10.08
	c4.194-2.473,14.645-4.98,18.24,0c0.988,1.367,1.841,7.041,2.88,7.68c1.439,0.865,4.368-0.184,6.24,0.24
	c2.247,0.507,2.814,1.393,4.32,2.4c-1.646,4.521-2.563,10.696-1.92,16.56c2.066,1.334,4.076,3.048,5.76,4.8
	c-0.054,0.854-0.345,1.361-0.48,1.92c-0.208,0.857,0.137,2.154,0.24,2.641c0.449,2.125-0.368,3.951-0.72,5.279
	c1.758,1.125,4.036,4.846,3.12,8.4c-0.278,1.083-0.964,1.721-1.2,2.88c1.253,0.028,1.895-0.138,2.64-0.479
	c0.305,1.035,0.492,2.229,0.479,3.84c0.371,0.563,0.143,1.027,0,1.686c-0.479,0-0.96,0-1.439,0
	c-0.242-0.188-0.063-0.085-0.479-0.236c0,0.077,0,0.156,0,0.236c1.623,0.979,4.561,3.005,4.801,5.277
	c-0.832,0.854-1.39,1.969-2.397,2.643c-1.9,1.271-4.095,1.894-5.28,3.843c-0.927,1.521,0.022,4.988-0.96,6.479
	c-1.625,2.462-5.142,3.438-7.68,5.04c-2.795,1.757-5.117,4.2-7.68,6.234c-2.066,1.646-5.723,8.129-7.68,8.884
	c-2.476-3.729-5.256-4.125-5.28-10.804c-1.149-0.629-2.794-0.816-2.88-2.641c-5.953,0.104-13.715,1.903-19.68,0.24
	c-1.601-0.446-3.676-1.069-5.04-1.921c-1.104-0.688-1.685-2.059-3.12-2.399c-1.755,1.352-4.372,1.737-6.24,2.881
	c-2.41,1.476-5.028,2.926-7.44,4.563c-0.708,0.479-1.193,1.396-2.16,1.681c-2.81,0.813-6.52-1.518-7.44-2.88
	c-0.761-1.129-1.518-4.032-0.96-6.239c0.212-0.842,1.305-3.127,0.96-3.601c-0.867-3.195-4.492-4.616-7.92-5.28
	c-0.13-1.604-0.911-2.313-1.2-3.602c-0.374-1.656,0.451-2.298-0.48-3.603c-2.854-3.998-6.733-2.146-6.24-9.842
	c-9.579,0.32-12.73-1.223-17.76-6c-0.979-0.934-2.124-1.479-2.88-2.643c0.505-0.435,0.48-0.699,0.48-1.681
	c4.015,0.073,8.428-0.448,10.8-2.159c0.984-0.712,1.547-2.021,2.4-2.882c0.25-4.491-1.333-3.696-2.16-6.959
	c1.054-0.678,1.639-1.647,1.68-3.36c-0.607-0.742-1.171-1.871-1.44-2.879c2.685-1.847,4.069-5.507,6.72-7.438
	c1.453-1.063,3.973-1.176,4.08-3.604c-0.6-0.84-0.629-2.006-1.2-2.881c-0.896-1.373-2.308-2.063-2.64-4.08
	c1.395-0.998,2.757-4.662,2.16-7.438c-1.059-4.926-1.788-10.729-0.72-15.602c0.08,0,0.16,0,0.24,0c0.4,0.467,0.346,0.398,1.2,0.479
	c0.615-1.021,1.559-1.369,3.12-1.438c0.816,0.822,2.438,2.043,3.6,2.396c1.18,0.363,2.037-0.587,3.12,0.725
	c0.195,0.504-0.118,2.264,0.24,3.354c1.079,3.304,2.095,3.243,1.92,7.921c2.254,1.105,4.243,3.006,5.28,5.277
	c0.658,1.441,0.878,4.949,2.16,5.764c1.554,1.396,4.883,1.604,7.2,2.156c0.56,0,1.12,0,1.68,0c1.183,0.555,1.745,2.193,3.36,2.645
	C101.757,371.811,106.803,369.243,110.64,369.206z'
          />{' '}
        </a>

        <text
          transform='matrix(1 0 0 1 48.4785 179.1426)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nefza
        </text>
        <text
          transform='matrix(1 0 0 1 94.9736 255.8105)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Béja Nord
        </text>
        <text
          transform='matrix(1 0 0 1 77.3066 324.2246)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Béja Sud
        </text>
        <text
          transform='matrix(1 0 0 1 28.751 260.5869)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Amdoun
        </text>
        <text
          transform='matrix(1 0 0 1 175.2158 357.5586)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Testour
        </text>
        <text
          transform='matrix(1 0 0 1 76.418 408.1836)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Téboursouk
        </text>
        <text
          transform='matrix(1 0 0 1 234.8115 304.9619)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Medjez el-Bab
        </text>
        <text
          transform='matrix(1 0 0 1 257.124 375.7383)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Goubellat
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Beja;
