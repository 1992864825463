import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Zaghouan = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)

  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='607.299px'
        height='537.383px'
        viewBox='0 0 607.299 537.383'
        enable-background='new 0 0 607.299 537.383'
      >
        <a xlinkTitle={`Bir Mchergua ${groupedData['Bir Mchergua']}`}>
          <path
            onClick={() => setSelectedVill('Bir Mchergua')}
            style={{ fill: getColorBasedOnCount(outputData['Bir Mchergua']) }}
            d='M329.999,189.917c-0.223-1.773-1.234-2.938-1.921-4.32c-12.146-0.012-15.479-9.404-20.159-16.8
	c-1.609-2.542-3.508-6.373-6.479-7.44c-1.688,1.292-4.771,1.376-6,3.12c-0.688,0.967-0.586,2.285-0.961,3.6
	c-1.808,6.348-0.19,12.449,0,18.72c-1.295,0.863-2.037,1.976-4.08,2.16c-0.562-1.52-1.119-3.041-1.68-4.56
	c-1.049-3.444-1.173-7.122-2.4-10.08c-0.611-1.476-0.514-3.875-2.159-4.32c-0.726,0.446-3.646,0.491-4.8,0.24
	c-4.564-0.996-9.256-1.392-13.92-2.4c-9.779-2.115-17.922,7.697-21.359,12.48c-1.367,1.902-4.248,3.99-4.561,6.72
	c1.16,1.313,1.041,2.87,3.6,2.88c1.574,1.068,3.652,1,5.041,2.16c1.648,1.38,2.543,4.014,3.84,5.76c0.561,0.48,1.119,0.96,1.68,1.44
	c0.16,1.44,0.32,2.88,0.479,4.32c0.586,2.036,1.791,4.211,2.399,6.24c0.08,0.8,0.16,1.6,0.238,2.4
	c0.479,1.335,1.639,2.11,1.922,3.84c-1.119,1.2-2.24,2.4-3.359,3.6c-3.021,2.253-8.606,5.658-12.721,6.479
	c-1.754,0.351-4.313-0.714-6.479-0.72c-6.56,0-13.121,0-19.681,0c0,0.16,0,0.32,0,0.48c-1.114,1.424-2.119,7.958-1.438,11.04
	c1.09,4.949,1.349,9.995,2.399,15.12c0.427,2.076-0.596,4.372-0.721,6.24c-0.959,0.16-1.922,0.32-2.882,0.48
	c-1.934-2.821-5.822-4.062-8.4-6.24c-0.741-0.626-2.464-2.116-1.92-3.84c0.604-1.912,2.074-1.706,2.16-5.04
	c-1.312-1.406-2.092-3.624-4.08-4.32c-1.262,1.007-4.629,0.922-6.24,0.24c-4.425-1.873-7.092-6.447-10.8-9.12
	c-1.176-0.848-3.975-1.526-4.56-2.88c-3.393-3.541,1.68-10.248,3.12-12.24c0.838-1.159,0.971-2.514,2.88-2.64
	c1.292,0.973,6.463,2.599,7.92,1.2c0.462-0.444,0.427-1.283,0.96-1.68c1.226-0.916,2.567-1.037,3.12-2.64
	c0.435-0.837-1.211-3.202-1.68-4.08c-1.992-3.736-4.622-7.064-5.76-11.52c-0.759-2.971,1.349-5.219-0.24-7.2
	c-1.034-1.289-3.638-0.709-5.28-1.44c-0.614-0.273-0.782-0.902-1.44-1.2c-1.369,1.234-8.066,3.664-11.28,2.88
	c-1.279-0.312-6-2.16-6-2.16c-2.449,2.418,0.889,7.608,2.4,9.12c-0.83,3.725-7.062,9.649-11.28,9.84
	c-1.569-1.903-6.406-4.188-8.64-5.76c-4.636-3.264-8.978-6.727-13.92-9.84c-2.205-1.389-4.557-3.752-7.68-4.08
	c-1.617,1.289-3.8,1.715-5.04,3.36c-1.63,2.164-3.464,6.115-5.52,7.68c-2.372,1.805-5.803,3.013-7.44,5.52
	c-0.93,1.425-0.941,4.222-1.68,5.76c-0.56,0.08-1.12,0.16-1.68,0.24c-1.49-1.197-3.413-1.842-4.56-3.36
	c-1.778-2.355-3.643-5.447-6-7.2c-1.095-0.814-2.616-1.226-3.6-2.16c-6.29,3.244-7.43-0.697-12.24-2.4
	c-1.288-0.456-3.897,0.251-4.32,0c-1.307-0.896-1.688-2.585-2.64-3.84c-0.477-0.629-1.74-0.814-2.16-1.44
	c-0.08-0.4-0.16-0.8-0.24-1.2c-0.626-1.218-1.659-2.027-1.92-3.6c2.271-1.033,4.406-2.577,6.48-3.84
	c5.014-3.054,11.14-5.262,17.52-6.72c3.458-7.439,9.612-13.536,15.84-18.24c3.471-2.621,5.953-6.641,9.12-9.6
	c-0.55-3.751-2.033-8.798-0.72-13.2c2.989-10.025,13.438-21.696,26.88-18.72c3.477,0.77,6.275,0.512,9.12,1.68
	c3.568,1.465,5.441,3.764,7.92,6.24c1.201,0.046,2.253,0.027,2.88-0.479c1.303-1.473,1.727-3.968,2.88-5.521
	c0.88-0.8,1.76-1.6,2.64-2.4c2.791-2.791,5.877-5.614,7.92-9.12c0.855-1.469,0.394-3.111,0.96-5.04
	c0.51-1.736,2.729-4.263,4.08-5.28c8.414-6.335,19.296-10.102,25.2-18.96c3.316-4.976,5.683-10.885,9.12-15.6
	c0.858-1.181,3.091-4.79,4.563-4.32c1.479,0.458,1.684,1.924,2.641,2.88c-0.168,1.212-0.951,2.865-0.48,4.56
	c1.236,4.468,8.674,7.915,12.721,9.84c0.006,13.43,12.994,19.75,21.119,25.439c6.332,4.433,12.519,9.366,18.961,13.68
	c2.942,1.97,5.951,3.92,8.879,5.76c0.955,0.599,2,0.769,2.883,1.44c2.01,1.533,3.146,6.63,4.801,7.92
	c1.541,1.203,3.232,1.973,4.801,3.12c3.162,2.32,5.884,5.404,8.642,8.16c1.04,0.959,2.08,1.92,3.118,2.88
	c4.172,5.515,8.96,10.627,12.962,16.32c1.229,1.752,3.538,6.585,5.276,7.44c2.882,1.948,14.315,1.276,17.762,0.24
	c1.326-0.4,4.082-2.034,5.04-1.68c1.969,0.656,2.765,2.53,4.078,3.84c-0.278,4.844-2.911,10.547-4.316,14.88
	c-1.477,4.537-1.025,10.11-0.479,14.88c-0.16,0-0.316,0-0.479,0c-1.655-1.118-4.32-0.863-6.479-1.44
	C338,191.676,333.999,190.796,329.999,189.917z'
          />{' '}
        </a>
        <a xlinkTitle={`Zaghouan ${groupedData['Zaghouan']}`}>
          <path
            onClick={() => setSelectedVill('Zaghouan')}
            style={{ fill: getColorBasedOnCount(outputData['Zaghouan']) }}
            d='M217.438,226.876c5.438-0.08,10.881-0.16,16.318-0.24c0.982,0.204,8.951,1.074,9.359,0.96
	c3.771-1.055,7.604-3.308,10.561-5.28c2.289-1.529,6.279-3.505,6.479-6.96c-1.57-1.301-4.39-10.375-5.039-12.96
	c-0.349-1.368,0.157-2.806-0.479-3.84c-0.479-0.4-0.962-0.8-1.438-1.2c-1.443-1.944-2.617-5.068-4.562-6.48
	c-1.139-0.826-2.882-1.516-4.32-1.92c-0.721-0.08-1.438-0.16-2.159-0.24c-0.479-0.64-0.959-1.28-1.439-1.92
	c0.08-0.24,0.16-0.48,0.24-0.72c1.744-1.256,2.813-3.763,4.08-5.52c2.633-3.662,6.433-7.716,10.562-9.84
	c8.016-4.125,18.938,0.66,28.561,0.72c0,0.08,0,0.16,0,0.24c0.08,0,0.158,0,0.238,0c0.1,1.97,1.162,3.352,1.682,5.04
	c0.979,3.184,1.146,6.723,2.16,9.6c0.585,1.663,0.611,3.849,2.641,4.08c1.821-1.35,4.229-1.489,5.04-3.84
	c-0.708-1.034-0.387-2.643-0.721-4.08c-0.999-4.301-0.854-14.632,1.438-17.52c0.967-0.909,3.631-1.886,5.039-2.16
	c1.673,2.55,4.464,4.861,6.24,7.68c2.938,4.666,5.188,10.284,9.6,13.44c1.521,1.087,3.571,2.102,5.521,2.64
	c1.208,0.333,3.319-0.128,4.32,0.479c0.824,0.93,1.043,3.251,2.156,3.84c2.1,1.463,9.393,1.829,12.48,2.64
	c1.886,0.495,5.447,0.816,7.199,1.68c1.048,0.517,2.187,2.046,4.08,1.68c6.92-1.338,8.868-5.93,13.438-9.36
	c7.011-5.261,14.201-9.9,21.84-14.64c2.234-1.386,7.416-5.437,10.08-5.76c0.634,0.946,1.584,1.604,1.92,2.88
	c0.875,3.317-2.764,8.006-1.92,11.76c0.654,2.912,2.857,3.711,2.88,6.96c-1.211,1.87-0.638,6.745-1.196,9.36
	c-2.122,9.868,1.166,22.977,6.479,26.88c1.673,1.229,4.333,1.698,6.72,2.16c0.16,1.04,0.32,2.08,0.479,3.12
	c1.446,3.551,4.485,5.852,7.921,7.44c-0.641,2.08-1.28,4.16-1.921,6.24c-0.608,2.182-0.384,4.001-0.96,6.24
	c-0.81,3.134-1.654,6.561-2.399,9.6c-2.479,0.057-4.118,1.059-6,1.68c-2.768,0.915-5.68,1.438-8.396,2.16
	c-9.051,2.4-18.841-0.478-24.24-4.08c-1.912-1.276-2.98-3.671-4.8-5.04c-7.566-5.692-13.394-12.938-21.12-18.48
	c-2.332-1.671-4.446-3.133-7.199-4.56c-0.554-0.287-2.08-1.664-2.881-1.44c-0.563,0.158-1.421,1.553-1.92,1.92
	c-2.238,1.646-4.646,3.34-6.96,4.8c-8.911,5.626-18.696,10-24.239,18.96c-1.248,2.017-2.001,4.7-2.641,7.2
	c-0.335,1.313,0.176,3.497-0.479,4.56c-0.34,0.23-0.786,0.234-1.438,0.24c-2.262-2.219-5.68-3.562-8.399-5.28
	c-4.659-2.941-9.021-6.035-14.397-8.4c-1.607-0.706-2.943-1.841-5.041-2.16c-0.98,3.228-3.707,5.886-5.521,8.4
	c-5.145,7.132-11.617,12.939-16.799,19.92c-1.619,2.182-3.369,4.453-4.803,6.721c-0.42,0.666-0.779,2.525-1.438,2.879
	c-0.563,0-1.121,0-1.68,0c-2.4-0.561-4.804-1.117-7.201-1.68c-5.771-1.91-11.541-4.432-18.479-5.039
	c-0.826-1.33-2.063-2.523-2.879-3.842c-2.971-4.797-3.531-10.978-3.602-18.719c1.029-0.515,2.738-0.754,3.84-1.2
	c1.031-8.1,0.156-14.769-1.439-22.08C215.656,233.426,217.078,229.59,217.438,226.876z'
          />{' '}
        </a>
        <a xlinkTitle={`El Fahs ${groupedData['El Fahs']}`}>
          <path
            onClick={() => setSelectedVill('El Fahs')}
            style={{ fill: getColorBasedOnCount(outputData['El Fahs']) }}
            d='M28.079,336.795c-5.62,0.041-8.221,3.209-12.479,4.318c-2.616,0.684-4.527-1.725-5.76-2.641
	c-2.929-2.176-9.572-10.938-9.84-15.357c1.516-1.552,2.178-3.607,4.08-4.802c0.971-0.606,2.472-1.149,3.6-1.438c0.8,0,1.6,0,2.4,0
	c0.404-0.164,1.056-0.965,1.44-1.197c2.162-1.325,4.974-2.528,7.44-3.36c4.964-1.67,10.693-1.681,15.36-3.603
	c4.768-1.959,9.154-4.844,13.2-7.68c4.077-2.854,9.627-6.808,11.76-11.521c1.564-3.459,0.876-8.353,2.16-12.24
	c0.798-2.418,2.554-4.687,4.08-6.479c4.996-5.875,17.816-9.521,18-19.919c-0.51-0.526-2.501-3.187-2.64-3.84
	c2.065-2.56,3.026-11.734,1.92-16.08c-0.372-1.462-1.841-3.82-1.44-5.52c0.482-2.043,3.487-3.903,2.4-7.2
	c-0.565-1.715-2.637-3.607-3.36-6c-1.037-3.431-0.081-7.499-0.96-11.28c-0.747-3.211-1.707-5.569-3.12-7.92c0.72,0,1.44,0,2.16,0
	c1.375,1.095,6.098,3.638,7.92,4.08c2.334,0.567,4.313-1.427,5.52-0.96c3.506,1.174,7.01,6.786,9.6,9.36
	c1.713,1.702,3.844,3.48,7.2,2.16c0.215-0.443,1.179-1.321,1.2-1.68c0.113-1.939-0.102-3.333,0.96-4.8
	c1.364-1.884,4.102-2.701,6-4.08c1.798-1.306,3.558-3.905,4.8-5.76c0.981-1.466,4.117-6.149,6.24-5.28
	c2.985,0.752,5.38,3.026,7.68,4.56c5.257,3.506,10.202,6.945,15.36,10.56c2.073,1.453,4.408,3.997,7.2,4.56
	c0.974-0.844,2.462-0.986,3.6-1.68c2.82-1.721,5.13-3.738,6.96-6.48c0.563-0.843,1.897-2.536,1.44-3.6
	c-0.432-2.968-2.907-3.73-3.12-6.96c0.16,0,0.32,0,0.48,0c0.689,0.641,1.608,0.613,2.64,0.96c5.076,1.706,9.453-0.796,13.44-1.92
	c1.177,1.37,4.789,0.665,5.52,2.4c0.362,0.685-0.072,1.654-0.24,2.4c-0.426,1.889,0.206,4.372,0.72,5.76
	c1.672,4.518,5.962,7.909,6.72,12.96c-0.844,0.44-1.853,0.859-2.64,1.44c-0.563,0.416-0.768,1.678-1.44,1.92
	c-1.263,0.455-5.667-1.746-7.68-1.92c-4.765,5.049-8.164,8.879-6,18c1.827,1.043,3.86,1.889,5.52,3.12
	c5.827,4.319,7.219,10.326,17.52,9.12c0.8,0.88,1.6,1.76,2.4,2.64c0,0.56,0,1.12,0,1.68c-1.057,0.792-1.952,2.21-2.16,3.84
	c1.246,1.867,0.834,3.549,2.88,5.04c2.438,1.777,7.542,2.521,7.68,6.48c-0.982,1.479-0.177,5.927,0,7.68
	c0.544,5.372,2.639,9.69,5.041,13.2c1.076,1.572,1.322,3.15,4.08,3.121c2.479,0.561,4.959,1.119,7.439,1.68
	c5.703,1.891,11.246,3.65,17.76,5.039c0,0.16,0,0.32,0,0.48c-0.734,1.143-0.352,2.746-0.721,4.318
	c-1.355,5.783,0.275,13.454-1.199,19.197c-0.846,3.287-1.84,6.475-3.6,8.883c-0.734,1.006-2.141,1.609-2.881,2.641
	c-2.117,2.94-5.74,10.967-4.32,17.039c0.535,2.285,1.388,4.438,1.92,6.479c-6.215,1.695-8.465,9.274-16.318,9.359
	c-0.719-1.035-1.992-1.533-2.881-2.398c-2.391-2.336-7.555-7.525-13.44-6c-3.718,0.967-6.639,3.146-9.84,4.561
	c-3.239,1.432-7.07,2.36-10.56,3.36c-4.759,1.36-16.723,3.075-22.8,1.681c-0.88,0-1.76,0-2.64,0c-3.6-0.643-7.201-1.277-10.8-1.92
	c0,0.08,0,0.16,0,0.239c0.287,0.472,0.03,1.198,0.24,1.921c0.396,1.357,1.311,3.084,1.92,4.799c0.506,0.285,1.008,0.98,1.44,1.201
	c0.95,0.477,1.824,0.217,2.64,0.719c1.111,0.688,2.019,1.953,3.12,2.643c1.82,1.137,3.834,0.588,4.56,2.881
	c-2.044,2.281-1.192,9.895-1.44,13.682c-2.849,1.584-6.764,2.549-9.84,3.84c-0.8,0.24-1.6,0.479-2.4,0.721
	c-1.345,1-1.354,3.775-3.12,4.318c-1.309,0.965-3.678,0.482-5.28,1.201c-1.49,0.666-3.437,2.984-4.32,4.318
	c-6.066,9.152-0.385,21.096,4.32,27.357c1.131,1.51,3.265,3.463,4.8,4.563c0.84,0.602,2.268,0.873,2.88,1.682
	c1.788,2.355-0.297,3.582,0.48,6c0.502,1.563,2.313,2.301,2.88,3.84c-1.52,0-3.041,0-4.56,0c-0.636,0.396-1.561,0.227-2.4,0.479
	c-1.802,0.543-3.963,1.211-5.52,2.16c-2.208,1.348-4.29,3.926-6.72,4.799c0-0.08,0-0.158,0-0.236c-3.234-2.363-4.238-9.861-6-13.922
	c-1.202-2.77-3.29-5.67-5.76-7.197c-1.167-0.727-2.636-0.65-3.84-1.439c-0.664-0.436-0.759-1.699-1.2-2.396
	c-0.89-1.414-2.49-2.887-4.32-3.361c-2.028,6.225-12.837,3.459-17.52,1.682c-1.673-0.635-4.876-0.783-5.52-2.396
	c1.969-1.646,2.768-5.398,3.84-7.921c3.034-7.134,6.107-17.771,4.08-28.319c-4.546-1.219-6.833-8.228-10.08-8.642
	c-0.539,0.466-1.488,0.64-1.92,1.2c-0.755,0.979-0.747,2.307-1.92,2.88c-2.667,1.681-8.899-3.067-9.84-4.319
	c-2.144-2.854-4.091-5.771-6.24-8.641c-4.38-5.843-12.874-10.447-20.16-13.439c-4.033-1.652-7.976-2.711-11.52-4.561
	c-1.409-0.732-3.267-1.098-3.84-2.643c-0.543-0.896-0.003-2.479,0-3.84C28.568,342.102,28.246,339.438,28.079,336.795z'
          />{' '}
        </a>
        <a xlinkTitle={`Hammam Zriba ${groupedData['Hammam Zriba']}`}>
          <path
            onClick={() => setSelectedVill('Hammam Zriba')}
            style={{ fill: getColorBasedOnCount(outputData['Hammam Zriba']) }}
            d='M416.158,261.196c-0.032,3.687,0.029,7.073,1.438,9.36c1.253,2.028,3.292,1.896,3.36,5.28
	c0.724,1.199,0.126,3.244,0.479,4.799c0.766,3.363,1.14,6.927,2.399,9.841c0.42,0.971,1.793,2.401,1.438,3.36
	c-1.14,3.771-4.245,7.031-6.721,9.601c-2.668,0.086-4.898-0.468-8.159-0.479c-0.739,1.016-1.857,1.135-2.157,2.644
	c-1.165,1.586,0.45,8.18,1.438,8.879c1.12,0,2.24,0,3.359,0c1.315,0.801,4.02-0.14,5.761,0.237c2.486,0.537,5.378,0.729,7.68,1.199
	c0,0.08,0,0.16,0,0.24c-1.498,2.184-1.957,5.622-3.356,7.92c-3.479,5.696-7.846,10.321-13.199,14.159
	c-1.926,1.38-4.206,3.673-6.721,4.32c-1.885,0.485-3.979-0.89-5.04-1.438c-3.374-1.752-6.155-4.267-9.604-5.761
	c-8.137-3.532-19.504,0.146-25.92,2.879c-2.316,0.988-5.085,1.681-6.72,3.361c-0.422-0.152-0.233-0.048-0.48-0.24
	c-3.461-3.362-6.243-7.844-9.119-11.76c-5.078-6.919-11.047-13.406-16.078-20.399c-1.928-2.675-3.904-5.287-5.763-7.921
	c-0.586-0.832-1.269-2.937-2.159-3.36c-0.801,0-1.601,0-2.396,0c-0.552,0.44-1.348,0.522-1.92,0.961
	c-0.4,0.563-0.801,1.119-1.2,1.683c0.174,1.713,0.854,2.621,0.479,4.563c-0.803,0.521-1.357,1.428-2.157,1.92
	c-2.331,1.438-6.527,2.289-9.843,2.397c-2.059-2.76-6.894-3.386-10.316-4.801c-3.958-1.638-6.718-3.813-9.36-6.724
	c-3.793-0.328-8.322-0.313-10.8,1.44c-0.776,0.552-1.138,1.821-1.681,2.64c-3.484,5.246-5.293,13.897,0,18.961
	c-0.18,0.938-0.418,1.563-1.2,1.92c-1.796,1.234-4.606,1.021-6.959,1.683c-3.5,0.979-7.666,1.836-10.562,3.604
	c-1.544,0.938-3.729,2.401-4.802,3.84c-0.823,1.103-1.378,3.213-2.88,3.601c-0.245-0.192-0.064-0.086-0.479-0.239
	c-1.438-2.882-2.879-5.761-4.319-8.642c3.817-2.69,4.341-8.771,5.521-14.16c0.358-1.638-0.05-3.938,0.24-5.278
	c0.9-4.166-0.438-8.449,0.479-12.24c3.354-13.82,14.935-21.242,22.32-31.199c1.691-2.28,3.543-4.592,5.041-6.96
	c0.734-1.163,0.861-2.757,2.398-3.12c0.957,0.753,2.193,0.86,3.359,1.44c2.86,1.423,5.894,3.073,8.642,4.8
	c3.325,2.091,6.729,4.259,10.08,6.479c1.12,0.744,2.52,2.217,3.84,2.641c3.574,1.144,3.609-1.57,3.6-4.32
	c0.59-0.947,0.353-2.457,0.724-3.6c0.729-2.239,1.846-4.656,3.116-6.48c5.258-7.516,14.424-11.655,22.32-16.56
	c2.146-1.332,4.395-2.82,6.479-4.32c0.688-0.493,1.588-2.189,2.881-1.92c3.104,0.647,7.921,5.174,10.317,6.96
	c6.761,5.032,11.973,11.489,18.723,16.56c1.219,0.916,2.177,2.38,3.119,3.6c1.035,1.34,5.042,3.592,6.721,4.32
	c5.894,2.559,15.813,3.223,23.04,1.199C408.638,263.438,412.399,262.316,416.158,261.196z'
          />{' '}
        </a>
        <a xlinkTitle={`Saouaf ${groupedData['Saouaf']}`}>
          <path
            onClick={() => setSelectedVill('Saouaf')}
            style={{ fill: getColorBasedOnCount(outputData['Saouaf']) }}
            d='M281.519,299.356c1.172-0.039,2.444-0.008,3.121,0.479c1.545,0.608,2.471,2.683,3.84,3.604
	c3.476,2.338,7.918,3.252,11.999,5.039c1.993,0.873,3.115,2.729,5.764,2.881c1.31-0.793,3.469-0.479,5.04-0.961
	c3.287-1,7.267-2.396,8.16-5.76c0.407-1.55-0.388-2.679-0.479-4.08c0.816-0.392,1.147-0.66,2.399-0.724
	c1.729,3.022,4.271,5.619,6.236,8.399c5.83,8.244,12.057,15.904,18,24c0.804,1.091,2.079,2.033,2.88,3.121
	c4.424,6.004,9.153,11.89,12.724,18.72c5.763,11.034,2.616,24.237-6.239,29.76c-1.625,1.019-5.244,2.187-7.92,1.201
	c-1.054-0.388-2.616-1.838-3.36-1.681c-2.273,0.576-3.646,2.17-5.276,3.358c-4.81,3.498-8.679,7.709-13.684,11.041
	c-1.169,0.777-2.569,1.992-4.08,2.396c-0.8-0.076-1.6-0.156-2.396-0.236c-9.354,1.373-22.375-2.556-26.159,4.799
	c-0.422-0.149-0.232-0.045-0.48-0.239c-3.908-1.058-6.934-5.588-8.16-9.358c-0.479-1.479,0.092-4.201-0.719-5.521
	c-2.83-2.949-14.441-1.901-19.201-0.722c-11.846,2.938-36.34,7.578-45.356-0.723c1.688-7.125,10.517-13.211,15.84-17.04
	c1.729-1.243,5.519-3.508,6.479-5.276c0.613-1.129,0.252-4.146,0-5.28c-0.62-2.8-0.402-4.968-1.199-7.438
	c-0.715-2.214-1.951-4.33-2.641-6.479c-2.34-7.311,1.418-13.902,3.602-18.24c0.159,0,0.318,0,0.479,0
	c0.559,2.275,3.531,7.354,5.039,8.881c1.162,0.021,2.273-0.064,3.119-0.238c2.287-8.254,15.619-10.551,24.48-12.479
	c0.75-1.287,1.514-2.352,1.92-4.08c-1.367-0.896-1.955-2.463-2.641-4.08c-2.972-7.006,0.84-10.818,3.358-15.601
	C276.842,300.709,280.885,299.788,281.519,299.356z'
          />{' '}
        </a>
        <a xlinkTitle={`Ennadhour ${groupedData['Ennadhour']}`}>
          <path
            onClick={() => setSelectedVill('Ennadhour')}
            style={{ fill: getColorBasedOnCount(outputData['Ennadhour']) }}
            d='M236.64,352.157c1.812,2.072,2.156,8.701,2.159,12.719c-1.601,1.045-2.804,2.729-4.319,3.84
	c-5.174,3.805-10.625,7.043-14.397,12.24c-1.554,2.133-3.687,4.189-3.84,7.68c9.651,10.135,35.057,5.141,48.719,1.924
	c3.498-0.822,7.481-0.955,11.281-0.959c1.805-0.006,4.099-0.104,4.8,0.959c0,0.721,0,1.438,0,2.156
	c0.454,0.717,0.188,1.684,0.479,2.645c0.66,2.197,1.854,4.533,3.121,6.24c1.065,1.438,6.183,4.523,6.479,5.521
	c-0.987,1.229-1.312,2.971-2.159,4.318c-1,1.59-4.391,5.34-4.801,6.959c-0.454,1.789,2.896,10.383,3.602,11.762
	c0.627,1.225,1.762,3.268,0.721,4.803c-1.012,3.291-4.733,4.346-7.438,6c-6.415,3.918-11.255,8.521-18.721,11.52
	c-3.405,1.367-12.351,2.141-12.479,6.24c-0.848,1.471-0.234,5.797-0.721,7.68c-1.834,7.141-4.105,13.258-6.721,19.199
	c-0.766,1.74-1.334,4.857-3.119,5.521c-0.834-0.604-1.99-0.646-2.881-1.199c-3.217-1.996-5.051-5.408-10.801-4.316
	c-0.188-7.789-5.609-13.686-11.521-15.844c-2.429-0.887-5.137-0.607-7.68-1.438c-3.019-0.982-4.669-3.301-8.64-3.357
	c-0.476,0.463-2.508,2-3.12,2.16c-1.453-1.023-6.689-2.592-8.64-3.121c-0.88-0.08-1.76-0.16-2.64-0.24
	c-1.313,0.486-2.813,1.699-4.56,2.16c-3.827,1.014-9.567,0.174-13.2-0.238c-0.88-0.959-1.76-1.922-2.64-2.881
	c-1.077-2.43-1.432-8.34-1.44-11.76c-1.207-0.746-2.484-1.811-3.84-2.398c-1.889-0.826-4.459-1.227-5.76-2.643
	c-0.24-0.641-0.48-1.277-0.72-1.92c-0.686-1.098-1.964-2.404-2.88-3.359c1.698-7.463-3.894-7.576-7.2-11.277
	c-5.756-6.445-11.675-18.998-4.08-28.08c1.985-2.375,5.541-2.968,8.88-3.84c0.511-1.226,1.163-3.335,2.16-4.08
	c1.017-0.765,2.575-0.734,3.84-1.201c2.837-1.043,6.014-2.113,8.64-3.358c0.138-1.136,0.483-2.517,0.72-3.601
	c0.692-3.16-0.745-5.666,0-8.642c0.251-1.001,1.235-2.389,0.96-3.118c-0.233-1.738-1.371-2.433-2.16-3.603
	c-2.972,0.329-4.425-2.097-6.48-3.359c-1.039-0.641-2.413-0.471-3.12-1.438c-0.635-0.957-0.975-2.389-1.44-3.357c0.4,0,0.8,0,1.2,0
	c1.1,0.723,2.846,0.365,4.32,0.721c3.533,0.849,9.749,1.623,13.68,0.724c2-0.08,4-0.16,6-0.24c4.391-1.022,8.844-1.304,12.96-2.644
	c4.357-1.412,8.41-3.057,12.24-5.037c1.669-0.864,4.36-2.717,7.44-1.922c4.019,1.037,9.452,5.685,12,8.398
	c6.797,0.133,8.293-3.027,12-5.762C232.672,354.674,235.06,353.715,236.64,352.157z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 170.1978 149.3325)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bir M&apos;chergua
        </text>
        <text
          transform='matrix(1 0 0 1 282.1982 221.3325)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Zaghouan
        </text>
        <text
          transform='matrix(1 0 0 1 317.1982 284.3325)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ezzeriba
        </text>
        <text
          transform='matrix(1 0 0 1 274.4385 359.4116)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Saouaf
        </text>
        <text
          transform='matrix(1 0 0 1 188.439 427.4116)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ennadhour
        </text>
        <text
          transform='matrix(1 0 0 1 120.439 304.4116)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Fahs
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Zaghouan;
