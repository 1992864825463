import React from 'react';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';


const CustomSelect = ({ divisions, setDivision, placeholder = 'offers' }) => {
    const dispatch = useDispatch();
    const options = divisions?.map((division) => ({ label: division, value: division }))
    const handleChange = (value) => {
        dispatch(setDivision(value))

    };
    return <Select
        mode="tags"
        style={{
            width: '100%',
        }}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
    />
}

export default CustomSelect;