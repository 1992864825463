import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Nabeul = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {
    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });

  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='541.65px'
        height='521.416px'
        viewBox='0 0 541.65 521.416'
        enable-background='new 0 0 541.65 521.416'
      >
        <a xlinkTitle={`El Haouaria ${groupedData['El Haouaria']}`}>
          <path
            onClick={() => setSelectedVill('El Haouaria')}
            style={{ fill: getColorBasedOnCount(outputData['Houmet Essouk']) }}
            d='M284.638,109.252c0.123,6.675,6.836,6.566,7.2,12.48c-2.729,3.083-5.508,5.123-6.721,9.84
 c-1.142,4.436,2.192,9.977,3.6,12.24c0.39,0.625,1.791,3.297,1.681,3.36c-2.944,4.428-10.912,9.401-15.358,12.72
 c-1.458,1.089-4.676,5.147-6.24,5.52c-0.728-0.766-1.738-1.332-3.12-1.44c-2.323,1.677-4.936,1.686-6.72,4.08
 c-1.302,1.748-2.879,7.021-5.28,7.2c-1.123-0.945-2.78-1.695-4.56-1.92c-0.7,0.758-1.416,1.679-2.16,2.4
 c0.076,3.073,2.332,2.847,2.64,5.52c-1.52,1.6-3.04,3.2-4.56,4.8c-4.186,3.159-8.014,6.215-11.04,10.56
 c-1.36,1.28-2.72,2.56-4.08,3.84c-2.598,3.462-4.659,7.26-7.2,10.8c-1.461,2.037-3.506,4.51-5.52,6
 c-0.559,0.414-2.09,0.855-2.4,1.44c0,1.52,0,3.04,0,4.56c-1.437-0.025-2.154,0.223-3.12,0.48c-0.57,1.151-1.221,2.022-1.44,3.6
 c-1.275-0.1-2.479-0.476-3.6-0.72c-1.728-0.376-2.905,0.37-4.32,0.48c-0.204-0.587-0.25-1.484-0.24-2.4
 c0.845-1.214-0.081-3.506-0.48-4.32c-6.084-0.784-11.917,0.214-18.72,0.24c0.023-4.983,3.332-6.611,4.32-10.56
 c1.848-7.386-10.22-10.682-16.32-10.8c-0.56-0.72-1.12-1.44-1.68-2.16c0.151-0.421,0.046-0.233,0.24-0.48
 c0.668-1.914,2.488-3.08,3.6-4.56c4.07-5.421,9.593-9.707,15.12-13.68c13.505-9.708,27.819-19.496,35.28-35.28
 c1.792,0.441,2.932,0.724,4.8,0.24c0.231-0.424,0.302-0.403,0.48-0.96c0.597-0.933,0.077-3.623-0.24-4.8
 c-1.095-4.068-2.274-7.217-2.4-12.48c4.408-0.451,8.618-2.448,12.72-3.6c1.506-0.423,5.701-1.089,7.44-0.72
 c1.202,0.255,3.813,1.3,5.04,0.96c1.733-0.48,3.624-2.15,5.28-2.88c3.644-1.606,7.206-3.051,10.56-4.8
 c2.783-1.451,9.737-4.273,10.8-6.96c0.754-1.086-0.113-3.016-0.479-3.84c0.16,0,0.318,0,0.479,0c0,0.08,0,0.16,0,0.24
 c1.449,0.509,1.807,1.758,2.641,2.88c0.864,1.163,2.359,1.841,3.359,2.88C284.157,109.252,284.397,109.252,284.638,109.252z'
          />
        </a>
        <a xlinkTitle={`Hammam Ghezèze ${groupedData['Hammam El Ghezaz']}`}>
          <path
            onClick={() => setSelectedVill('Hammam El Ghezaz')}
            style={{
              fill: getColorBasedOnCount(outputData['Hammam El Ghezaz']),
            }}
            d='M318.237,213.412c-3.351,0.344-6.607-0.961-9.603-1.68c-1.941-0.467-3.162-0.006-5.039,0
 c0.019-1.417-0.102-2.725-0.238-3.84c-0.598-0.373-1.416-0.938-1.922-1.44c-1.806-0.015-3.54,0.125-5.038,0.24
 c-1.184,2.077-0.896,4.854-1.44,7.44c-0.319,0-0.64,0-0.959,0c-0.603-2.357-2.392-4.19-3.601-6
 c-3.703-5.539-8.127-10.241-12.722-14.88c0.88-4,1.761-8,2.642-12c0.281-1.271,0.265-4.693-0.239-5.52
 c-1.375-4.031-9.397-4.224-10.321-9.12c2.288-1.502,3.819-4.125,6-5.76c3.461-2.595,6.854-5.092,10.32-7.68
 c1.806-1.349,3.294-3.987,5.52-4.8c0,0.08,0,0.16,0,0.24c1.475,1.507,2.367,3.526,3.6,5.28c2.641,3.755,5.568,7.43,7.2,12.24
 c1.463,4.31,1.188,9.42,2.642,13.92c0.508,1.572,0.991,3.444,1.68,5.04c0.229,0.531,1.105,1.578,1.2,2.16
 c0.108,0.659-0.599,1.039-0.72,1.44c-0.546,1.799,0.672,4.306,1.198,5.521c1.418,3.257,3.742,6.58,5.999,9.12
 c1.396,1.571,4.359,3.179,4.563,5.76C318.717,210.531,318.477,211.972,318.237,213.412z'
          />{' '}
        </a>
        <a xlinkTitle={`Kelibia ${groupedData['Kelibia']}`}>
          <path
            onClick={() => setSelectedVill('Kelibia')}
            data-id='3'
            style={{ fill: getColorBasedOnCount(outputData['Kelibia']) }}
            d='M268.078,244.611c0-5.245-0.157-9.995,0.96-14.64c0.314-1.308,1.667-5.934,1.2-7.44
 c-0.917-2.963-4.616-3.462-6-5.521c-0.24-0.96-0.48-1.92-0.72-2.88c-1.292-3.081-2.61-6.514-7.2-6.48
 c-0.419,0.421-0.921,0.36-1.44,0.72c-0.952,0.66-1.729,2.713-2.4,2.88c-0.872-1.122-0.285-4.386-0.24-6.48
 c-0.8-0.88-1.6-1.76-2.4-2.64c-2.087-1.525-5.068-2.738-7.68-3.6c-1.659-0.548-6.005-0.933-6.72-2.16
 c3.715-3.478,7.147-6.961,11.28-10.08c1.601-1.208,2.637-3.179,4.08-4.56c0.049-2.654-1.283-3.818-2.64-5.04
 c0.151-0.421,0.046-0.233,0.24-0.48c0.225-0.748,0.602-1.088,0.96-1.68c2.125,0.88,3.625,2.23,6.48,1.68
 c1.677-3.478,3.399-7,6.48-9.12c0.77-0.53,2.889-2.209,3.84-1.68c2.461,0.644,2.381,2.987,3.84,4.56
 c2.467,2.661,6.53,3.93,9.12,6.48c-0.025,4.563-0.633,8.075-1.68,11.76c-0.379,1.332-1.586,3.814-0.961,5.76
 c0.564,1.757,2.64,2.879,3.84,4.08c2.648,2.649,5.48,5.587,7.682,8.64c1.33,1.848,2.676,3.859,3.84,5.76
 c0.53,0.868,0.783,2.689,1.68,3.12c0.562,0,1.119,0,1.682,0c0.244-0.193,0.064-0.085,0.479-0.24c1.041-2.128,0.859-4.881,1.439-7.44
 c0.48,0,0.96,0,1.439,0c0.91-0.625,2.28,0.018,3.119,0.24c0.348,0.641,0.479,1.013,0.479,2.16c0.361,0.573,0.354,2.436,0.479,3.12
 c0.08,0,0.16,0,0.24,0c2.424-1.735,8.759,0.649,10.801,1.2c1.215,0.328,3.474-0.218,4.08,0.72c-0.641,1.84-1.281,3.68-1.921,5.52
 c-0.937,2.167-2.306,4.191-3.36,6.24c-5.223-1.051-9.666,0.988-12.719,2.88c-6.809,4.219-11.67,9.809-18,14.4
 c-0.72,0.521-1.394,1.451-2.159,1.92c-0.24,0-0.479,0-0.722,0c-1.058,0.796-0.461,2.003-2.16,2.4
 C275.127,246.653,271.526,244.699,268.078,244.611z'
          />{' '}
        </a>
        <a xlinkTitle={`Manzel Temime ${groupedData['Menzel Temime']}`}>
          <path
            onClick={() => setSelectedVill('Manzel Temime')}
            data-id='4'
            style={{ fill: getColorBasedOnCount(outputData['Manzel Temime']) }}
            d='M236.878,297.651c0-1.041,0-2.08,0-3.12c-2.341-0.74-4.479-2.837-6.48-4.08
 c-4.076-2.533-7.99-4.675-11.76-7.438c-0.821-0.604-1.126-2.095-1.92-2.642c-0.937-0.646-1.758-0.552-2.4-1.438
 c-1.298-1.797-2.045-4.582-2.64-6.961c-0.393-1.57-0.06-3.015-0.72-4.32c-1.054-2.082-3.919-2.502-5.76-3.84
 c-0.755-0.549-0.957-1.889-1.68-2.398c-1.6-0.48-3.2-0.96-4.8-1.44c-4.019-1.481-8.479-2.69-13.2-3.6
 c-1.929-0.372-3.788,0.9-5.28,1.2c-2.4,0.482-9.388-3.199-10.56-4.32c0.08,0,0.16,0,0.24,0c0.64-1.12,1.28-2.24,1.92-3.36
 c0.568-0.762,1.497-0.692,1.92-1.68c-0.827-1.25-0.586-4.972-0.24-6.48c0.372-1.621,0.009-2.883,0.48-4.32
 c1.355-4.14,3.446-7.478,5.52-10.8c0.857-1.374,1.609-4.414,3.12-5.04c2.64,0,5.28,0,7.92,0c2.035-1.318,8.19-0.567,10.56-0.24
 c0.122,2.196-0.019,4.768,0.48,6.72c0.424,0.231,0.403,0.302,0.96,0.48c1.928-1.885,5.7-0.369,8.16,0
 c0.496-1.226,0.896-3.579,2.16-4.08c0.763-0.513,1.943-0.1,2.88,0c0-1.68,0-3.36,0-5.04c5.887-3.795,9.401-10.216,13.44-15.84
 c1.227-1.708,3.546-5.546,5.76-6c2.113,1.541,6.969,1.796,9.6,2.88c2.824,1.163,4.711,2.866,6.72,4.8
 c-0.04,2.52-0.483,4.915,0.24,6.72c0.351,0.205,0.688,0.435,0.96,0.72c0.421-0.151,0.233-0.046,0.48-0.24
 c2.067-1.682,1.75-3.858,5.76-3.84c1.127,1.723,2.756,3.273,3.6,5.28c0.32,1.2,0.64,2.4,0.96,3.6c1.382,2.163,5.381,2.591,6,5.521
 c0.374,1.77-0.798,5.144-1.2,6.72c-1.171,4.587-1.662,10.859-0.72,15.84c2.982-0.071,8.829,2.682,9.12,4.8
 c0.192,0.283,0.188,0.403,0.239,0.96c-0.997,1.028-1.36,2.333-2.159,3.6c-2.513,3.987-4.883,8.125-7.438,12.241
 c-6.969,11.213-14.102,22.83-20.16,34.801C243.377,301.086,240.837,298.269,236.878,297.651z'
          />
        </a>
        <a xlinkTitle={`Takelsa ${groupedData['Takelsa']}`}>
          <path
            onClick={() => setSelectedVill('Takelsa')}
            data-id='5'
            style={{ fill: getColorBasedOnCount(outputData['Takelsa']) }}
            d='M165.599,253.971c0.318,2.671-0.901,3.299-0.96,6.24c-2.495,1.5-6.08,3.356-6,7.44c0.08,0,0.16,0,0.24,0
 c-0.08,0.158-0.16,0.317-0.24,0.479c-3.714,1.188-3.122,0.969-3.84,5.041c-0.641,0.395-6.097,4.037-6.24,4.319
 c0.72,0.79,0.887,2.353,0.24,3.36c-1.52,1.357-3.04,2.719-4.56,4.08c-1.45,2.125-0.82,5.504-2.16,7.92
 c-0.407,0.731-1.475,1.371-1.92,2.158c-0.497,0.878-0.53,1.936-1.44,2.399c-1.185-0.847-2.257,0.28-3.84,0.479
 c-0.914-0.725-2.179-0.993-2.88-1.92c-1.326-1.751-2.462-3.848-4.32-5.039c-1.824-1.171-4.076-0.36-5.28-2.159
 c-3.018,1.131-5.158,2.6-9.12,2.159c-0.331-1.372-1.063-2.838-1.44-4.319c-0.812-3.194-0.19-6.869-1.68-9.358
 c-0.575-0.961-1.609-1.414-2.4-2.16c-1.704-0.034-2.894,0.194-4.08,0.479c0-0.16,0-0.319,0-0.479c0.441-0.623,0.331-1.419,0.72-2.16
 c1.1-2.097,3.524-3.386,4.32-5.76c-1.503-1.646-1.67-4.545-2.88-6.48c-0.83-1.327-2.257-1.837-3.36-2.88
 c-2.438-0.009-3.524,0.765-5.28,1.2c-2.661,0.66-7.501,0.039-10.08,0.24c-0.408-3.941,2.912-5.81,4.08-8.88
 c1.033-2.716,0.515-6.299,1.2-9.36c0-1.52,0-3.04,0-4.56c0.582-2.576,1.131-5.156,1.68-7.44c0.72-0.16,1.44-0.32,2.16-0.48
 c0-0.72,0-1.44,0-2.16c0.311-1.196,0.997-2.659,1.68-3.6c1.704-2.347,5.099-3.54,7.44-5.28c1.407-1.045,2.773-4.111,4.08-4.8
 c0.97,0.705,4.162,1.149,6,0.72c4.271-0.997,9.058-2.174,13.2-3.12c2.345-0.535,2.693,1.41,3.84,1.92c1.28,0,2.561,0,3.84,0
 c3.69-0.003,6.873-0.466,9.84-1.44c5.764-1.893,11.151-4.799,15.84-7.92c2.141-1.425,4.18-3.935,6.72-4.8
 c1.194,3.527,6.546,2.691,10.08,3.84c4.235,1.377,4.606,2.803,7.2,5.521c0.109,4.646-2.51,6.255-3.84,9.36
 c-0.587,1.371-0.17,2.719-0.72,4.32c-1.369,3.984-5.373,8.307-7.2,12.48c-1.813,4.141-1.709,9.084-1.68,14.64
 C169.617,249.782,170.016,253.779,165.599,253.971z'
          />{' '}
        </a>
        <a xlinkTitle={`El Mida ${groupedData['El Mida']}`}>
          <path
            onClick={() => setSelectedVill('El Mida')}
            data-id='6'
            style={{ fill: getColorBasedOnCount(outputData['El Mida']) }}
            d='M177.838,297.892c0.089-1.97,0.474-4.431-0.48-6c-0.269-0.441-1.243-0.774-1.44-1.199
 c0-4.08,0-8.159,0-12.239c-0.48-0.08-0.96-0.16-1.44-0.24c-0.615-1.078-0.742-2.938-1.44-4.08c-0.937-1.53-2.402-2.061-3.84-3.118
 c-0.886-0.652-1.17-2.01-2.16-2.642c-1.524-0.974-6.304-1.906-6.72-3.601c0.9-0.578,4.927-3.303,5.28-4.081c0-0.8,0-1.6,0-2.4
 c0.129-0.385,0.8-0.823,0.96-1.2c0.16-0.8,0.32-1.6,0.48-2.4c0.867-0.075,1.403-0.41,1.68-0.24c3.116,0.788,5.649,2.976,8.64,3.84
 c3.217,0.929,5.463-1.385,7.92-0.96c4.651,0.804,9.024,2.148,12.96,3.6c1.6,0.48,3.2,0.959,4.8,1.44c0.706,0.66,0.85,1.834,1.68,2.4
 c1.463,0.996,3.249,1.279,4.56,2.398c1.785,1.523,1.288,5.727,2.16,8.159c0.625,1.745,1.927,4.507,3.36,5.521
 c0.493,0.349,1.093,0.252,1.44,0.721c0.573,0.771,0.68,1.605,1.439,2.158c4.132,3.015,8.615,5.699,12.96,8.399
 c1.025,0.638,4.868,2.261,5.28,3.12c0,1.04,0,2.08,0,3.12c0.08,0,0.16,0,0.24,0c0,0.08,0,0.159,0,0.239
 c2.525,0.127,8.96,3.123,10.32,4.561c-1.752,1.896-2.593,4.827-3.84,7.2c-2.025,3.853-4.3,7.767-6.24,11.76
 c-0.421-0.151-0.233-0.046-0.48-0.239c-2.069-0.537-2.744-2.437-4.56-2.879c-2.916,2.281-8.665,0.289-10.8-1.201
 c-2.381-1.662-6.25-5.842-7.68-8.398c-0.16-1.602-0.32-3.2-0.48-4.8c-1.365-3.83-5.02-6.353-8.64-7.921
 c-3.04-1.041-6.081-2.079-9.12-3.119c-1.66-0.719-3.351-2.342-6-1.44C184.419,293.563,183.689,297.867,177.838,297.892z'
          />{' '}
        </a>
        <a xlinkTitle={`Soliman ${groupedData['Soliman']}`}>
          <path
            onClick={() => setSelectedVill('Soliman')}
            data-id='7'
            style={{ fill: getColorBasedOnCount(outputData['Soliman']) }}
            d='M110.879,286.853c-2.29-0.086-4.919-0.496-6.96,0.239c-2.077,0.748-4.361,3.121-7.92,2.159
 c-2.328-0.628-3.79-2.359-5.76-3.359c0,0.159,0,0.319,0,0.479c-1.25,1.815-0.94,4.93-1.68,7.2c-0.719,2.205-2.332,2.701-2.4,5.521
 c0.727,1.033,2.035,4.562,2.16,5.999c-0.855,0.848-1.335,1.729-2.4,2.4c0.776,1.504,2.258,1.515,2.4,3.6
 c-0.24,0.721-0.48,1.44-0.72,2.16c-1.158,0.313-6.096,2.025-6.48,2.88c0,0.722,0,1.44,0,2.16c0.303,0.326,0.328,0.424,0.48,0.96
 c1.054,0.641,4.629,2.318,5.04,3.359c0.396,0.431,0.419,0.604,0.48,1.438c1.27,0.246,1.205,0.958,1.92,1.681
 c0.079,2.888-2.535,6.265-4.8,6.479c-0.522,0.188-2.835-0.517-4.08,0c-2.094,0.866-2.124,2.801-5.28,2.88
 c-2.116-9.359-11.201-16.414-18.24-21.119c-2.08-1.392-4.347-3.878-6.72-4.801c-2.814-1.094-5.192,0.859-7.92,0.96
 c-0.877-3.291-4.062-5.466-6.96-6.72c-1.102-0.479-2.093-0.471-2.88-1.2c0.08,0,0.16,0,0.24,0c0.838-1.229,2.091-2.317,2.88-3.601
 c0.623-1.012,0.783-2.879,1.68-3.601c0.72-0.239,1.44-0.479,2.16-0.721c3.5-1.541,7.535-3.271,11.28-4.8
 c0.863-0.354,2.163-0.161,2.88-0.721c0.501-0.392,0.749-1.447,1.2-1.92c1.627-1.705,4.158-3.128,6.48-4.08
 c2.59-1.063,5.16-1.246,7.92-2.16c2.914-0.965,5.623-2.319,7.92-3.84c1.266-0.839,3.044-1.097,3.6-2.642
 c0.609-0.839,0.33-2.774,0.96-3.601c0.854-0.842,2.124-0.944,3.12-1.68c1.815-1.341,1.955-5.218,1.92-8.4c0.08,0,0.16,0,0.24,0
 c0.618,0.254,1.559-0.414,2.64-0.48c2.719,0,5.44,0,8.16,0c1.621-0.388,2.551-1.107,4.56-1.2c0.692,0.658,1.83,0.905,2.4,1.68
 c1.246,1.695,2.76,4.926,3.12,7.201c-2.545,1.773-4.802,5.041-4.8,9.359c0.16,0,0.32,0,0.48,0c1.095-0.92,3.111-1.077,4.8-0.721
 C109.706,278.954,110.75,282.51,110.879,286.853z'
          />{' '}
        </a>
        <a xlinkTitle={`Menzel Bouzelfa ${groupedData['Menzel Bouzelfa']}`}>
          <path
            onClick={() => setSelectedVill('Menzel Bouzelfa')}
            data-id='8'
            style={{
              fill: getColorBasedOnCount(outputData['Menzel Bouzelfa']),
            }}
            d='M159.599,266.211c2.022,0.924,4.846,1.606,6.72,2.881c0.842,0.571,0.978,1.703,1.68,2.399
 c1.122,1.111,2.907,1.316,3.84,2.64c0.764,1.084,0.448,2.882,1.2,4.08c0.366,0.584,1.581,0.701,1.92,1.2c0,3.999,0,8.001,0,12
 c1.915,0.646,2.206,3.188,1.92,5.279c-0.157,1.147,0.609,2.311,0.48,2.399c-2.645,3.945-7.983,5.793-10.8,9.601
 c-1.21,1.636-1.188,4.228-2.4,6c-0.943,1.381-3.217,2.099-4.56,3.119c-1.01,0.771-1.406,2.282-2.64,2.881
 c-2.388,1.156-6.755,1.291-8.88,0c-0.886-0.539-1.875-1.821-2.88-2.159c-2.684-0.904-5.75,0.979-7.2,1.68
 c-1.869,0.899-3.999,0.295-4.08,3.12c0.595,0.846,0.646,1.988,1.2,2.88c1.146,1.845,2.655,3.063,3.6,5.28c-0.32,0-0.64,0-0.96,0
 c-1.181-0.855-2.712-1.082-3.84-1.922c-3.495-2.598-4.355-6.866-9.6-8.158c-3.043-0.75-4.999,0.826-7.2,0.96
 c-0.654-0.994-1.885-2.756-3.12-3.12c-2,0.08-4,0.16-6,0.24c-3.442-0.92-5.242-3.096-9.84-3.12c-1.779,3.026-5.229,6.63-9.6,6.96
 c-1.186-3.007-5.356-2.878-6.24-5.28c-0.231-0.34-0.234-0.784-0.24-1.438c1.626-0.864,5.816-1.536,6.48-3.359
 c0.711-0.783,0.749-2.324,0.72-3.84c-0.66-0.411-1.478-1.039-1.92-1.682c0.869-0.578,1.201-1.455,1.92-2.158
 c0.024-3.456-1.855-4.479-2.16-7.682c1.502-1.22,2.292-3.666,2.88-5.761c0.418-1.489,0.04-3.836,1.2-4.56
 c1.223,1.587,5.091,3.63,8.64,2.64c2.033-0.565,3.982-2.082,6.24-2.64c1.758-0.436,3.738,0.232,5.04,0.479
 c0.52,1.377,1.072,2.656,1.68,3.84c5.117,0.445,5.67-0.988,9.36-1.92c1.066,1.488,3.455,0.951,5.04,1.92
 c2.23,1.363,5.15,8.166,9.12,6.961c0.646-0.196,0.86-0.612,1.92-0.721c0.326,0.512,0.694,0.869,1.2,1.2
 c0.422-3,2.939-4.388,3.84-6.72c0.969-2.51,0.526-5.266,1.92-7.2c1.238-1.718,3.814-2.593,4.8-4.56
 c0.542-1.082-0.201-2.576,0.24-3.36c1.622-1.489,4.958-2.099,5.76-4.319c0.411-1.138,0.293-2.604,0.48-3.602
 c1.333-0.018,2.716-0.162,3.12-1.199C159.953,267.789,159.668,267.162,159.599,266.211z'
          />
        </a>
        <a xlinkTitle={`Korba ${groupedData['Korba']}`}>
          <path
            onClick={() => setSelectedVill('Korba')}
            data-id='9'
            style={{ fill: getColorBasedOnCount(outputData['Korba']) }}
            d='M190.078,293.092c2.396,0.188,4.095,1.621,6,2.399c5.233,2.14,11.283,3.018,14.16,7.439
 c0.665,1.021,1.139,2.584,1.2,4.079c0,0.88,0,1.761,0,2.64c0.5,1.175,2.125,2.345,2.88,3.36c3.982,5.368,8.192,9.324,17.28,7.439
 c0.976,1.454,2.608,1.717,3.84,2.88c-1.291,1.736-1.725,4.167-2.64,6.238c-1.678,3.804-3.537,7.576-5.28,11.521
 c-4.669,10.564-10.434,20.975-15.12,31.682c-1.853,4.231-3.551,8.547-5.28,12.72c-0.682,1.647-0.952,5.155-2.4,6
 c-1.958-1.95-5.131-2.99-7.68-4.319c-0.967-0.505-2.915-0.979-3.36-1.921c0-1.521,0-3.039,0-4.56
 c-0.458-0.698-0.229-1.674-0.48-2.642c-0.591-2.274-1.089-5.237,0.24-7.198c0.56-0.399,1.12-0.802,1.68-1.201
 c0-1.039,0-2.08,0-3.118c-0.611-0.802-0.655-1.998-1.2-2.882c-1.696-2.748-6.225-7.151-9.12-8.641
 c-0.053,1.775-0.563,3.112-1.68,3.842c-1.617,1.27-6.793-0.41-9.36-0.479c-0.52,1.646-1.744,2.61-2.64,3.84
 c-3.794,5.199-7.191,5.951-13.439,8.64c-0.678,0.293-1.73,1.229-2.4,1.44c-2-0.24-4-0.479-6-0.722
 c-0.134-0.385-0.192-0.575-0.24-1.198c0.193-0.246,0.085-0.065,0.24-0.479c4.026-0.873,5.583-8.988,7.2-12.721
 c3.809-8.786,14.572-11.109,14.64-23.04c-1.451-0.653-8.581-4.627-8.88-5.76c-0.277-1.054,0.77-2.181,0.96-2.88
 c0.252-0.93-0.134-2.008-0.24-2.641c-1.054-0.445-3.625-1.074-4.08-2.16c0.457-0.529,0.671-1.474,1.2-1.92
 c1.435-1.215,3.709-1.832,4.8-3.359c0.973-1.363,0.752-3.58,1.68-5.04c2.111-3.322,6.463-6.036,9.6-8.399
 c0.88-0.96,1.76-1.921,2.64-2.88c1.078-0.502,2.164-0.058,3.12-0.479c2.062-0.911,3.499-3.084,5.28-4.319
 C188.016,293.726,189.252,293.683,190.078,293.092z'
          />{' '}
        </a>
        <a xlinkTitle={`Grombalia ${groupedData['Grombalia']}`}>
          <path
            onClick={() => setSelectedVill('Grombalia')}
            data-id='10'
            style={{ fill: getColorBasedOnCount(outputData['Grombalia']) }}
            d='M41.04,311.091c2.739-0.005,5.772-1.57,7.44-1.199c2.326,0.519,4.208,2.647,6,3.84
 c5.097,3.391,10.546,6.914,14.16,11.762c1.541,2.064,3.271,4.531,4.32,6.96c0.59,1.366,0.403,3.144,1.92,3.6
 c2.448,1.173,4.269-2.577,6.48-2.88c0.642-0.458,1.678-0.169,2.16,0.24c3.398,4.322,6.695,8.854,10.56,12.72
 c0.839,0.841,5.77,4.772,6,5.279c0,1.601,0,3.2,0,4.801c-1.567,2.351-2.653,7.313-6.24,7.438c-1.215,0.746-3.112,0.485-4.56,0.961
 c-2.532,0.828-5.152,2.007-7.44,3.119c-0.222,3.813-1.132,4.979,0.48,8.16c-2.744,0.737-3.429,3.813-5.52,4.319
 c-2.199-1.701-4.8,0.981-6.48,1.438c-1.155,0.313-2.475-0.153-3.36-0.239c-0.531,5.573-1.776,11.034-4.8,14.159
 c-3.781-0.646-6.417,1.901-9.12,3.602c-3.582,2.25-7.099,4.311-10.56,6.479c-3.252,2.039-7.056,4.24-10.8,5.521
 c-0.892,0.307-3.142,0.585-4.32,0.24c-3.046-0.891-5.284-2.983-8.4-3.842c-4.945-1.356-6.735,3.77-12,1.682c0,1.041,0,2.08,0,3.121
 c-0.16,0-0.32,0-0.48,0c-0.258,0.18-0.227,0.145-0.72,0.238c-0.779-1.303-1.864-2.158-2.4-3.84
 c-2.234-7.002,2.281-13.521,6.72-15.601c-2.939-6.194,1.197-12.462,3.84-16.8c1.066-1.75,3.264-5.166,1.92-8.4
 c-0.681-1.639-2.23-2.416-3.36-3.6c-5.047-0.08-5.843,1.912-9.84,2.16c-0.352-1.359-1.495-2.313-2.64-2.881
 c0.08-0.24,0.16-0.479,0.24-0.722c1.394-1.476,1.958-3.825,3.6-5.039c1.097-0.812,3.539-0.802,4.32-1.921
 c0.4-1.117,0.8-2.238,1.2-3.357c0.769-1.143,2.001-2.188,2.88-3.36c0.891-1.187,3.617-3.161,3.6-4.32
 c-0.502-1.382-1.869-1.368-2.4-2.64c0.16,0,0.32,0,0.48,0c0.372,0.252,0.98,0.247,1.68,0.24c0.157-0.45,0.229-0.939,0.24-1.68
 c1.533-0.074,2.252-0.463,4.08-0.48c0-0.561,0-1.12,0-1.68c0.26-0.221,0.021,0.018,0.24-0.24c0.24,0,0.48,0,0.72,0
 c-0.274-5.725-6.436-4.771-6.96-9.12c0.654-0.41,0.599-0.608,1.68-0.72c0.963-0.625,3.843-0.271,5.28-0.24
 c0.284-6.436,1.488-15.451,3.6-20.4c0.912-2.137,1.839-5.023,4.56-5.279c0.744,0.429,1.031-0.084,1.92,0.24
 C34.704,304.295,40.731,306.334,41.04,311.091z'
          />{' '}
        </a>
        <a xlinkTitle={`Beni Khalled ${groupedData['Beni Khalled']}`}>
          <path
            onClick={() => setSelectedVill('Beni Khalled')}
            data-id='11'
            style={{ fill: getColorBasedOnCount(outputData['Beni Khalled']) }}
            d='M139.918,332.451c-0.289-4.386-4.074-5.886-5.04-10.08c2.35-0.535,6.891-3.812,9.84-2.88
 c1.598,0.504,2.905,2.324,4.56,2.88c2.995,1.004,6.054-0.447,8.4-0.96c0.987,1.345,3.299,1.576,4.56,2.64
 c-0.42,1.871-1.161,3.257-0.72,5.521c1.467,0.81,8.548,4.985,8.64,6.479c-0.254,0.419-0.047,1.023-0.24,1.681
 c-0.48,1.639-1.451,3.515-2.4,4.802c-3.54,4.801-9.188,7.584-11.76,13.438c-1.513,3.443-3.169,11.533-6.48,12.721
 c-0.551-6.774-8.426-11.279-14.64-12.721c-1.571-0.365-4.32-0.018-5.52,0.239c-2.959-0.16-5.92-0.319-8.88-0.479
 c-3.753-0.85-5.056-2.646-9.6-2.641c-0.38,1.143-1.299,1.776-1.92,2.641c-2.677,3.725-5.046,7.498-11.52,7.439
 c0-0.16,0-0.32,0-0.479c3.282-2.139,4.263-6.85,3.6-12.24c-1.98-0.688-3.429-2.71-4.8-4.08c-3.9-3.9-7.028-8.705-11.04-12.48
 c1.943-2.809,6.681-4.633,4.32-9.6c2.631-0.592,5.063-1.977,6.72-3.6c1.275-1.25,1.682-3.223,4.08-3.36
 c0.51,0.331,1.271,0.212,1.92,0.479c1.837,0.762,3.656,2.117,5.76,2.641c2.033,0.506,4.565-0.898,6,0
 c1.847,0.678,1.826,2.732,4.08,3.119c2.137-1.74,6.864-1.404,9.12,0C131.69,326.519,132.063,332.338,139.918,332.451z'
          />
        </a>
        <a xlinkTitle={`Bou Argoub ${groupedData['Bou Argoub']}`}>
          <path
            onClick={() => setSelectedVill('Bou Argoub')}
            data-id='12'
            style={{ fill: getColorBasedOnCount(outputData['Bou Argoub']) }}
            d='M127.919,357.41c0.6,4.485,5.175,5.715,5.28,9.36c-1.001,0.756-4.298,3.976-4.8,5.039
 c2.283,2.439,0.128,5.871,0.48,9.121c0.245,2.264,1.578,3.153,2.16,4.799c-0.496,0.845-0.577,2.938-1.44,4.08
 c-0.311,0.211-0.592,0.215-1.2,0.239c-0.809-0.784-2.114-0.756-3.36-0.479c-1.564,4.481-1.081,10.644-2.88,14.882
 c-1.978,4.657-8.664,8.237-6.24,15.6c-0.645,0.15-1.049,0.336-1.44,0.72c-2.082-0.47-3.555-2.476-4.8-3.84
 c-1.04-0.08-2.08-0.16-3.12-0.24c-1.653,0.526-2.675,1.929-4.8,2.16c0-0.08,0-0.16,0-0.24c2.834-2.907,1.419-7.834,0.48-11.278
 c-5.857-2.828-8.494,4.3-14.16,4.562c-0.459-0.389-0.837-0.453-1.2-0.959c-1.363-1.922-0.231-3.752-3.36-4.08
 c-1.656,2.09-6.383,2.696-9.36,1.438c-5.441-2.301-7.647-7.668-11.52-11.521c0-0.158,0-0.318,0-0.479
 c3.534-1.847,4.824-8.647,5.04-13.681c4.151,0.035,4.215-1.157,6.72-1.92c1.474-0.448,2.53,1.174,3.12,0.72
 c1.545-1.021,2.299-3.021,3.84-4.08c0.865-0.595,2.336-0.517,2.88-1.438c-2.801-1.744-1.785-4.232-1.44-7.681
 c3.264-0.733,6.396-2.455,9.84-3.358c3.833-1.008,8.184-0.158,11.04-1.92c1.882-1.162,3.987-3.273,5.28-5.041
 c1.162-1.586,1.48-3.646,4.08-3.84C116.305,356.767,122.22,357.149,127.919,357.41z'
          />{' '}
        </a>
        <a xlinkTitle={`Beni Khiar ${groupedData['Beni Khiar']}`}>
          <path
            onClick={() => setSelectedVill('Beni Khiar')}
            data-id='13'
            style={{ fill: getColorBasedOnCount(outputData['Beni Khiar']) }}
            d='M176.398,404.931c0.24,0,0.48,0,0.72,0c0-0.16,0-0.32,0-0.479c1.423-2.358-3.201-7.813-4.32-8.881
 c-4.646-4.438-10.457-7.836-13.92-13.439c-0.8-1.295-1.299-3.219-1.68-4.801c0-0.719,0-1.439,0-2.159
 c-0.334-0.823-1-1.108-1.2-2.399c0.16,0,0.32,0,0.48,0c1.057-0.945,2.655-1.082,4.08-1.681c3.546-1.491,7.897-3.343,10.08-6.239
 c1.18-1.564,2.938-5.391,5.28-5.521c1.733,1.208,7.166,1.478,8.64,0c0.587-0.589,0.655-1.776,1.2-2.399c0,0.08,0,0.159,0,0.239
 c3.307,1.673,8.417,6.734,8.4,11.761c-3.648,2.33-2.421,7.777-1.44,12c0.313,1.346-0.341,3.991,0,4.561
 c2.141,2.986,11.539,4.547,11.76,8.158c-0.996,1.598-0.818,4.141-1.44,6c-1.251,3.748-3.135,7.113-4.56,10.563
 c-1.902,4.604-2.252,10.27-6.24,12.72c-2.084,1.281-3.485,0.45-5.76,0.239c-2.128-0.197-3.438,0.604-5.28,0.721
 c0-0.158,0-0.318,0-0.479c-0.751-1.102-0.665-2.773-1.2-4.08C178.027,414.517,176.37,411.457,176.398,404.931z'
          />{' '}
        </a>
        <a
          xlinkTitle={`Dar Chaabane El Fehri ${groupedData['Dar Chaabane El Fehri']}`}
        >
          <path
            onClick={() => setSelectedVill('Dar Chaabane El Fehri')}
            data-id='14'
            style={{
              fill: getColorBasedOnCount(outputData['Dar Chaabane El Fehri']),
            }}
            d='M129.359,357.171c9.12-0.143,16.635,3.737,18.72,10.319c0.529,1.672-0.312,2.997,0,4.801
 c1.725,0.746,4.909,0.711,6.72,1.438c1.051,2.264,1.159,5.073,2.16,7.439c0.642,1.518,1.891,3.229,2.88,4.561
 c4.546,6.112,14.46,9.526,16.32,18c-0.939,0.258-0.921,0.272-0.96,1.439c-0.682,1.044-0.331,4.542,0,5.762
 c1.295,4.754,4.329,8.409,4.56,13.918c-0.351,0.205-0.688,0.437-0.96,0.722c-8.424,0.313-12.566-8.113-16.32-13.439
 c-1.474-2.091-3.867-4.819-6-6.239c-1.447-0.966-4.471-1.283-5.52-2.642c-1.905-2.465-1.32-6.703-2.64-9.84
 c-1.815-4.313-6.064-6.724-9.12-9.841c-0.8-0.08-1.6-0.159-2.4-0.239c-1.232,0.317-2.89,1.974-4.08,1.44
 c-4.867-0.968-1.021-7.244-2.16-11.521c-0.138-0.52-0.562-1.034-0.72-1.682c1.522-0.506,4.133-3.227,4.32-5.039
 c0.859-1.434-0.53-2.44-1.2-3.12C131.614,362.049,128.774,359.521,129.359,357.171z'
          />{' '}
        </a>
        <a xlinkTitle={`Nabeul ${groupedData['Nabeul']}`}>
          <path
            onClick={() => setSelectedVill('Nabeul')}
            data-id='15'
            style={{ fill: getColorBasedOnCount(outputData['Nabeul']) }}
            d='M175.198,426.051c-0.093,1.365,0.102,1.018,0.479,2.16c-4.05,2.432-9.18,1.969-14.16,3.6
 c-3.561,1.166-6.696,2.832-10.08,4.32c-1.684,0.74-2.984,1.928-5.28,2.16c-0.388-0.557-0.351-0.564-1.2-0.721
 c-0.16,0.238-0.32,0.479-0.48,0.721c-1.814-0.756-5.416-1.038-5.52-3.601c-0.558-1.236,2.047-3.274,2.88-4.08c0-0.399,0-0.802,0-1.2
 c-1.349-0.056-8.68-3.934-9.12-4.562c-0.878-1.252-1.028-2.874-1.92-4.078c-0.56-0.399-1.12-0.802-1.68-1.201
 c-5.893-0.086-5.181,2.849-8.64,3.601c-0.875-1.772-2.471-2.59-3.84-3.84c-0.262-8.146,4.135-8.886,6.48-14.399
 c1.717-4.035,1.675-9.813,2.64-14.397c0.385-0.136,0.577-0.193,1.2-0.24c0.661,0.547,1.535,0.873,2.64,0.959
 c0.339-0.362,0.664-0.528,0.96-0.959c0.747-1.133,0.772-3.862,1.92-4.562c1.385,0.858,3.877-1.172,5.52-1.438
 c3.449,2.817,7.524,5.237,9.36,9.601c1.317,3.131,0.843,7.287,2.64,9.84c1.066,1.517,3.672,1.644,5.28,2.642
 c2.311,1.435,4.665,4.051,6.24,6.239C164.647,416.956,169.08,425.102,175.198,426.051z'
          />{' '}
        </a>
        <a xlinkTitle={`Hammamet ${groupedData['Hammamet']}`}>
          <path
            onClick={() => setSelectedVill('Hammamet')}
            data-id='16'
            style={{ fill: getColorBasedOnCount(outputData['Hammamet']) }}
            d='M7.92,410.69c1.444,0.037,2.129,0.418,3.6,0c2.109-0.601,3.977-2.967,7.2-2.159
 c3.857,0.965,7.655,5.698,13.44,3.6c6.491-2.356,13.464-7.237,19.2-10.801c2.551-1.584,5.066-4.476,8.64-4.799
 c0.558,0.588,1.661,0.823,2.16,1.438c3.538,4.367,5.251,7.556,10.32,10.563c1.456,0.862,4.138,2.374,6.959,1.68
 c1.938-0.478,3.212-1.709,5.28-1.92c0,0.16,0,0.319,0,0.479c0.507,0.75,0.429,2.146,0.96,2.879c0.582,0.651,0.993,1.088,2.16,1.201
 c1.351,0.854,3.241-0.279,4.08-0.722c2.817-1.479,5.146-4.75,9.12-4.08c1.26,2.601,1.917,6.527,0.24,9.359
 c-0.491,0.829-1.311,1.374-1.68,2.399c0.16,0,0.32,0,0.48,0c3.097,2.331,6.029-3.416,9.12-1.438c2.375,0.917,2.173,3.493,5.76,3.6
 c0.57-0.614,1.689-0.934,2.64-0.239c1.773,0.521,1.505,2.152,2.88,2.881c0.597-0.579,1.464-0.699,2.16-1.2
 c2.038-1.466,2.95-3.386,6.72-2.64c0.89,1.431,1.592,3.395,2.64,4.8c0.623,0.836,7.251,4.229,8.64,4.801c0,0.159,0,0.319,0,0.479
 c-1.574,0.866-2.824,1.644-2.88,4.08c0.252,0.364,0.381,1.181,0.48,1.682c0.672,0.397,1.411,1.152,2.16,1.438
 c1.23,0.469,2.285,0.191,3.12,0.959c-0.08,0-0.16,0-0.24,0c-1.052,1.328-2.687,2.119-4.08,3.121c-3.045,2.189-6.129,4.688-9.36,6.72
 c-1.568,0.987-3.196,1.428-4.8,2.399c-2.247,1.365-4.938,3.393-7.92,3.84c-1.45-4.376-7.669-5.532-12.24-3.6
 c-7.792,3.295-14.641,9.527-18.72,16.561c-0.08,0-0.16,0-0.24,0c0-0.08,0-0.16,0-0.24c-3.674-1.887-6.092-5.624-9.6-7.92
 c-0.762-0.499-2.974-2.195-3.84-1.68c-1.522,1.116-2.297,2.963-3.36,4.56c-2.282,3.431-4.676,7.139-6.96,10.562
 c-1.082,1.622-2.15,4.604-4.32,5.04c-1.792-1.342-4.382-1.702-6.24-2.88c-1.92-1.602-3.84-3.2-5.76-4.8
 c-0.458-0.455-0.928-1.591-1.44-1.922c-2.343-1.52-6.894-1.454-9.84-2.398c-2.624-0.841-5.662-2.693-8.16-3.12
 c-0.471,0.498-1.213,0.906-1.68,0.721c-1.709-0.563-3.886-2.936-4.56-4.561c-0.444-1.07,0.134-2.566-0.48-2.88
 c-0.763-0.505-1.939-0.373-2.88-0.722c-2.098-0.771-4.41-2.299-5.52-4.078c-1.443-2.316-2.871-9.744-1.92-14.16
 c0.392-1.818,0.717-5.729,0.24-7.92c-0.263-1.209-2.185-7.803-2.16-7.922c0.37-1.762,2.291-3.76,1.92-6.238
 C9.07,413.556,7.973,412.953,7.92,410.69z'
          />{' '}
        </a>

        <text
          data-id='1'
          transform='matrix(1 0 0 1 23.374 361.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Grombalia
        </text>
        <text
          data-id='2'
          transform='matrix(1 0 0 1 39.374 290.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Soliman
        </text>
        <text
          data-id='3'
          transform='matrix(1 0 0 1 118.374 246.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Takelsa
        </text>
        <text
          data-id='4'
          transform='matrix(1 0 0 1 232.374 151.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Haouaria
        </text>
        <text
          data-id='5'
          transform='matrix(1 0 0 1 281.374 182.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hammam Ghezèze
        </text>
        <text
          data-id='6'
          transform='matrix(1 0 0 1 272.374 228.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kelibia
        </text>
        <text
          data-id='7'
          transform='matrix(1 0 0 1 182.374 247.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Menzel Temime
        </text>
        <text
          data-id='8'
          transform='matrix(1 0 0 1 179.374 290.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Mida
        </text>
        <text
          data-id='9'
          transform='matrix(1 0 0 1 184.374 341.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Korba
        </text>
        <text
          data-id='10'
          transform='matrix(1 0 0 1 186.252 406.8281)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Béni Khiar
        </text>
        <text
          data-id='11'
          transform='matrix(1 0 0 1 186.374 435.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Dar Châabane El Fehri
        </text>
        <line
          fill='none'
          stroke='#1A171B'
          x1='172.16'
          y1='414.41'
          x2='184.16'
          y2='434.41'
        />
        <text
          data-id='12'
          transform='matrix(1 0 0 1 122.374 417.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nabeul
        </text>
        <text
          data-id='13'
          transform='matrix(1 0 0 1 36.0557 440.4268)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hammamet
        </text>
        <text
          data-id='14'
          transform='matrix(1 0 0 1 67.374 396.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bou Argoub
        </text>
        <text
          data-id='15'
          transform='matrix(1 0 0 1 101.374 345.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Béni Khalled
        </text>
        <text
          data-id='16'
          transform='matrix(1 0 0 1 86.374 309.5879)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Menzel Bouzelfa
        </text>
      </svg>
      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Nabeul;
