import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Kebili = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='588.234px'
        height='500.807px'
        viewBox='0 0 588.234 500.807'
        enable-background='new 0 0 588.234 500.807'
      >
        <a xlinkTitle={`Souk El Ahad ${groupedData['Souk El Ahad']}`}>
          <path
            onClick={() => setSelectedVill('Souk El Ahad')}
            style={{ fill: getColorBasedOnCount(outputData['Souk El Ahad']) }}
            d='M240.783,132.582c1.74,0.609,2.49,2.579,3.84,3.6c1.957,1.482,9.131,7.353,9.84,9.36
	c-0.956,1.157-1.605,2.803-2.881,3.6c-1.119,0.24-2.239,0.48-3.359,0.72c-2.048,0.852-4.304,2.827-5.52,4.56
	c-0.938,1.334-4.857,8.871-3.84,11.52c0.106,0.278,0.817,0.863,0.959,1.2c0.529,1.254,0.685,2.951,0.961,4.32
	c0.781,3.868-1.905,8.485-0.961,12.72c0.535,2.397,1.533,4.071,2.16,6c-0.479,0-0.96,0-1.439,0c-1.594-1.004-4.106-0.225-6.24-0.72
	c-4.854-1.127-12.07-1.376-17.28-0.24c-1.343,0.293-4.477-0.394-5.28,0.48c-2.258,2.457-0.779,6.672-1.68,10.56
	c-0.958,4.136-1.242,10.64-4.32,12.72c-1.92,0.72-3.84,1.44-5.76,2.16c-1.039,0.648-1.234,1.849-2.64,2.16
	c-1.493-1.083-3.719-1.188-5.52-1.92c-1.888-0.769-7.498-3.654-10.32-2.88c-0.868,0.238-1.489,1.083-2.16,1.44
	c-1.381,0.734-1.893-1.12-2.4,0.96c1.085,1.405,0.746,4.6,0.72,6.96c-2.841,1.67-5.286,4.017-7.92,6
	c-0.585,0.44-0.702,1.083-1.44,1.44c-1.64-1.077-3.867-1.138-5.76-1.92c-4.423-1.828-9.253-3.835-13.92-5.28
	c-1.455-0.45-2.483-0.213-3.84-0.72c-4.404-1.645-8.69-3.986-12.96-5.76c-2.149-0.893-4.343-0.808-6.72-1.68
	c-2.532-0.929-5.223-2.34-7.68-3.36c-1.732-0.719-3.484-0.597-5.28-1.44c-0.274-0.128-0.695-0.797-0.96-0.96
	c-1.196-0.733-3.028-1.199-4.32-1.68c-2.024-0.754-4.354-0.824-4.8-3.12c5.081-2.542,9.348-7.1,13.92-10.32
	c3.47-2.444,8.458-5.197,10.8-8.64c0.58-0.854,0.655-1.773,1.2-2.64c1.187-1.888,2.724-3.715,4.08-5.52
	c3.847-5.119,9.027-9.038,11.76-15.36c0.934-2.16,0.865-4.791,1.68-7.2c2.281-0.291,4.832-1.145,7.2-1.68
	c3.925-0.888,9.292-0.48,13.68-0.48c1.52,0,3.04,0,4.56,0c1.819-0.397,4.027-0.651,6-0.72c0.73-2.88,1.727-1.542,2.64-2.64
	c0.08-0.56,0.16-1.12,0.24-1.68c0.305-0.581,1.058-1.104,1.44-1.68c0.08,0,0.16,0,0.24,0c0,0.08,0,0.16,0,0.24
	c3.738,2.579,7.373,7.667,14.64,6c2.526-0.579,6.271-0.301,6.48-3.12c0.853-1.417-0.877-3.736-1.2-5.04
	c-0.382-1.543,0.252-3.113,0.479-4.08c3.42-2.083,8.394-3.97,12.721-5.04c2.898,4.543,9.895,2.654,14.88,1.44
	c2.235-0.544,5.306,0.684,6.72,0.24C237.742,134.502,239.262,133.542,240.783,132.582z'
          />{' '}
        </a>
        <a xlinkTitle={`Kebili Nord ${groupedData['Kebili Nord']}`}>
          <path
            onClick={() => setSelectedVill('Kebili Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Kebili Nord']) }}
            d='M242.462,191.141c0.555,3.958,4.609,6.275,6.48,9.12c0.928,1.413,1.494,3.486,1.92,5.28
	c0.234,0.99-0.197,2.201,0.239,2.88c1.457,1.233,2.948,2.313,3.601,4.32c0.08,1.04,0.16,2.08,0.24,3.12
	c0.194,0.556,0.922,1.213,1.199,1.68c0.754,1.268,0.719,2.982,0.721,5.04c2.789-0.085,7.683-0.935,8.64,0.96
	c0.391,0.528,0.46,1.243,0.479,2.16c-2.285,0.569-4.582,2.003-6.72,2.88c-3.219,1.321-6.931,1.57-10.56,2.4
	c-5.608,1.282-9.776-1.519-13.2-2.4c-2.066-0.532-7.211,0.655-8.88,1.2c-6.238,2.036-11.146,5.447-17.28,7.92
	c-1.315,0.53-6.009,2.412-7.919,1.92c-2.281-0.587-4.618-3.863-7.2-4.8c-2.934-1.065-6.058,2.196-10.32,0.96
	c-5.098-1.478-9.078-4.555-14.16-5.76c0-0.24,0-0.48,0-0.72c2.529-1.446,4.415-3.854,6.72-5.52c1.003-0.725,2.079-0.719,2.4-2.16
	c-0.644-1.012-0.48-5.005-0.48-6.48c1.771-0.207,2.181-1.358,3.6-1.68c1.578-0.358,3.734,0.807,4.8,1.2
	c3.294,1.214,8.503,3.612,12,3.84c1.037-3.838,7.482-3.432,9.601-6.96c1.667-2.777,1.864-7.625,2.64-11.04
	c0.987-4.346-1.525-10.099,3.84-10.08c1.523-0.962,6.715-0.587,9.121-0.72C230.348,189.349,236.123,191.046,242.462,191.141z'
          />{' '}
        </a>
        <a xlinkTitle={`El Faouar ${groupedData['El Faouar']}`}>
          <path
            onClick={() => setSelectedVill('El Faouar')}
            style={{ fill: getColorBasedOnCount(outputData['El Faouar']) }}
            d='M139.742,220.181c0,0.32,0,0.64,0,0.96c2.275,1.82,4.891,7.23,6.72,9.84
	c4.849,6.918,10.298,13.428,15.36,20.161c2.227,2.961,5.177,5.406,7.44,8.399c1.145,1.515,3.375,2.399,4.08,4.319
	c-1.597,0.496-2.98,1.824-4.32,2.641c-4.14,2.521-8.131,4.316-11.76,7.2c-1.161,0.922-1.71,2.665-2.88,3.601
	c0.218,0.26-0.019,0.021,0.24,0.239c2.337,3.46,10.28,7.403,15.84,7.681c0.478,2.041,1.746,4.045,2.4,6
	c0.8,3.279,1.6,6.562,2.4,9.84c0.24,1.92,0.48,3.84,0.72,5.762c1.861,7.355,2.125,15.345,4.8,21.84
	c2.477,6.018,4.966,12.084,7.92,17.76c1.851,3.559,4.163,6.961,5.52,11.04c0.996,2.993,1.02,5.945,1.92,9.12
	c1.564,5.516,2.827,11.45,4.32,17.279c1.042,4.066,0.984,8.313,1.92,12.238c0.8,6.319,1.6,12.644,2.4,18.961
	c0.815,3.369,0.949,7.097,1.92,10.562c0.634,2.267,1.572,4.834,1.92,7.2c-2.978,0.056-6.065,1.189-8.641,1.92
	c-5.082,1.438-10.435,1.846-15.36,3.12c-1.76,0.239-3.52,0.479-5.28,0.72c-11.033,2.861-23.353,3.432-34.56,6.24
	c-3.444,0.863-6.642,0.557-10.08,1.438c-6.15,1.58-12.826,1.729-19.2,3.36c-4.663,1.194-10.063,2.61-15.12,2.88
	c-0.8-5.679-1.6-11.359-2.4-17.04c-1.527-6.763-0.672-14.678-2.16-21.36c-0.08-1.68-0.16-3.357-0.24-5.039
	c-0.785-3.521-0.647-7.808-1.44-11.278c-0.16-1.683-0.32-3.36-0.48-5.04c-0.689-1.704-2.772-3.122-3.84-4.563
	c-2.617-3.523-5.529-6.597-8.16-10.08c-0.64-0.56-1.28-1.119-1.92-1.68c-3.926-5.188-8.802-9.451-12.72-14.64
	c-0.914-1.21-2.446-2.149-3.36-3.36c-1.657-2.195-3.672-4.089-5.28-6.24c-1.332-1.781-2.476-3.771-4.08-5.277
	c0.793-1.229,4.5-4.026,3.12-6.24c-1.261-2.021-7.285-0.703-9.6-1.682c-8.781-3.705-14.524-11.021-23.52-14.641
	c-2.191-0.881-4.68-0.98-7.68-0.959c-1.385,0.01-3.046,0.641-3.84,0.239c-2.667-0.871-4.951-3.419-6.48-5.521
	c-2.425-3.332-4.047-6.789-5.28-11.521c-2.145-8.229-0.25-19.883-0.24-29.04c0.26-0.218,0.02,0.021,0.24-0.238
	c3.673-0.086,6.877-1.719,9.6-2.882c8.222-3.51,14.242-8.313,20.88-13.197c3.278-2.414,6.539-4.553,9.84-6.961
	c2.16-2.24,4.32-4.479,6.48-6.722c5.6-4.237,10.713-8.969,16.32-13.2c4.14-3.124,7.588-7.43,11.76-10.56
	c4.923-3.694,9.476-7.903,14.4-11.521c3.457-2.539,7.636-4.827,10.56-7.92c0.16,0,0.32,0,0.48,0c1.176,3.071,5.664,2.986,8.64,4.32
	c0.597,0.268,1.155,1.192,1.68,1.44c1.402,0.663,2.729,0.406,4.08,0.96c2.5,1.024,5.203,2.344,7.68,3.36
	c2.119,0.869,4.618,0.819,6.72,1.68C133.166,217.339,136.4,219.61,139.742,220.181z'
          />
        </a>
        <a xlinkTitle={`Kebili Sud ${groupedData['Kebili Sud']}`}>
          <path
            onClick={() => setSelectedVill('Kebili Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Kebili Sud']) }}
            d='M141.422,221.141c5.664,1.188,11.754,3.69,16.8,5.76c4.603,1.888,9.185,2.841,13.68,4.8
	c3.474,1.514,6.951,3.56,10.8,4.8c1.836,0.591,5.162,0.836,7.2,0.24c0.856-0.25,2.046-1.383,3.12-1.2
	c2.06,0.351,6.608,3.668,7.921,5.04c11.004-0.006,18.557-7.638,28.079-10.32c2.257-0.636,5.601-1.11,7.681-0.24
	c0.813,0.341,1.732,1.158,2.64,1.44c3.008,0.938,10.106,0.776,13.2,0c3.72-0.933,7.447-1.903,10.8-3.36
	c1.375-0.597,3.403-2.532,6-1.68c2.821,0.926,3.776,4.937,4.8,7.68c0.566,1.521,0.469,3.434,2.16,3.84
	c2.977-2.365,7.572,3.828,8.16,5.76c-3.604,2.304-6.012,5.729-10.801,6.959c-1.858,0.478-3.541-0.479-4.56-0.72
	c-0.399,0.08-0.8,0.16-1.2,0.24c-0.978-0.178-2.263-0.388-3.359-0.72c-3.174-0.96-8.289-3.375-12-1.92
	c-0.155,0.475-0.012,1.472-0.24,2.16c-1.814,5.473-8.294,4.412-14.4,5.76c-2.052,0.453-5.965,1.061-8.64,0.48
	c-5.183-1.125-11.388-0.321-16.56-1.44c-1.041,0-2.08,0-3.121,0c-4.012-0.873-8.957-0.335-12.959-1.2c-1.2,0-2.4,0-3.601,0
	c-1.741-0.384-3.081-1.072-4.56-1.439c-2.419-0.6-4.759,0.438-6.959,0.48c-1.067,3.315-3.983,9.902-7.44,10.561
	c-3.279-3.521-6.56-7.041-9.84-10.561c-5.204-6.899-10.563-13.453-15.6-20.641C146.708,228.971,142.355,224.361,141.422,221.141z'
          />{' '}
        </a>
        <a xlinkTitle={`Douz ${groupedData['Douz']}`}>
          <path
            onClick={() => setSelectedVill('Douz')}
            style={{ fill: getColorBasedOnCount(outputData['Douz']) }}
            d='M363.421,363.701c-4.365-0.082-8.92-0.257-11.281,1.681c-0.606,0.5-0.893,1.579-1.438,2.159
	c0.94,5.688,3.447,15.33,6.959,18c-1.064,2.153-2.627,5.181-4.562,6.479c-1.114,0.75-4.92,0.707-5.278,1.201
	c2.246,2.473,2.623,7.783,2.16,11.52c-0.613,0.438-1.005,1.33-1.683,1.68c-1.811,0.934-3.223-0.02-3.358,2.641
	c1.457,0.969,2.223,2.799,3.602,3.84c2.885,2.184,5.559,4.509,8.16,6.961c0.121,2.271-0.133,3.92-2.16,4.08
	c-1.252-0.83-2.914-0.852-4.08-1.682c-2.354-1.673-4.065-4.391-6.479-6c-1.166-0.775-2.918-0.8-4.08-1.68
	c-1.177-0.893-1.453-3.204-2.642-4.08c-1.461-1.078-3.063-1.412-4.317-2.641c-4.207-4.119-6.482-10.414-12.961-12.239
	c-5.261-1.481-8.109,1.97-11.279,3.36c-5.828,2.56-12.473,3.97-17.761,7.199c-3.261,1.99-5.453,5.219-8.64,7.199
	c-7.229,4.493-18.215,5.084-27.36,7.682c-9.408,2.666-19.283,4.441-29.039,7.197c-3.441,0.975-7.121,1.533-10.801,2.643
	c-1.703,0.512-3.547,1.254-5.52,1.438c0-0.16,0-0.32,0-0.479c-1.916-2.652-2.145-10.26-3.12-14.16
	c-2.235-8.934-2.167-19.334-4.32-28.319c-0.619-2.584-0.31-4.668-0.96-7.2c-1.477-5.742-2.775-11.513-4.32-17.039
	c-1.002-3.586-1.082-6.892-2.399-10.08c-2.187-5.297-5.626-10.146-7.92-15.359c-2.518-5.727-4.814-11.454-6.72-17.76
	c-2.915-9.647-2.699-21.228-6-30.24c-0.822-2.245-1.378-7.323-4.08-7.44c-1.129-0.686-2.75-0.509-4.08-0.959
	c-2.456-0.834-9.07-4.053-9.84-6.239c1.2-1.279,2.4-2.563,3.6-3.84c1.428-1.076,3.074-2.199,4.56-3.121
	c2.8-1.733,8.199-3.326,10.08-5.761c0.654,0.41,0.6,0.609,1.68,0.722c-0.129-0.828-0.269-0.748-0.48-1.439
	c2.324-1.571,4.24-3.879,5.52-6.479c0.868-1.765,0.068-3.672,2.64-3.841c1.078-0.679,4.447-0.729,5.76-0.239
	c5.434,2.021,13.799,1.022,20.16,2.398c3.199,0.16,6.4,0.32,9.6,0.479c6.469,1.396,16.838,1.438,23.04,0
	c2.312-0.533,4.487-0.133,6.48-0.959c1.565-0.649,3.487-2.426,4.319-3.84c0.484-0.823,0.176-2.346,0.961-2.881
	c1.297-0.796,3.922,0.385,5.039,0.72c4.213,1.263,8.707,2.672,13.681,2.64c4.505-0.03,7.388-2.786,10.079-4.8
	c1.543-1.153,2.729-4.072,6.24-3.12c2.254,0.611,3.373,2.238,5.04,3.36c0,0.08,0,0.16,0,0.24c-0.08,0-0.159,0-0.239,0
	c-1.471,2.375-3.373,4.724-4.561,7.44c-1,2.291-1.507,4.586-2.88,6.48c-0.962,1.324-3.642,1.771-4.319,2.879
	c2.09,2.056,3.354,4.713,5.76,6.479c4.371,3.209,10.034,5.938,13.2,10.318c1.252,1.734,0.381,2.597,0.959,5.039
	c0.475,2.004,1.174,4.142,2.16,5.763c2.061,3.381,4.955,6.577,7.682,9.358c1.279,1.199,2.559,2.399,3.84,3.602
	c2.941,3.917,6.354,7.706,10.562,10.318c2.846,1.771,6.063,2.75,9.84,3.602c3.018,0.68,7.387-1.475,8.641-0.96
	c6.242,1.983,2.89,14.249,1.681,19.681c-0.574,2.582,0.264,5.688,0.479,7.682c-1.268,0.416-1.578,1.341-2.4,2.16
	c0.021,0.797,0.117,1.086,0.24,1.68c0.924,0.248,3.943,1.332,4.318,2.16c0,0.879,0,1.76,0,2.64c1.477,2.201,0.646,3.772,3.602,5.04
	C356.279,360.332,362.688,358.903,363.421,363.701z'
          />{' '}
        </a>

        <text
          transform='matrix(1 0 0 1 97.4409 318.9375)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Faouar
        </text>
        <text
          transform='matrix(1 0 0 1 230.4409 326.9375)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Douz
        </text>
        <text
          transform='matrix(1 0 0 1 153.4409 182.9375)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Souk Lahad
        </text>
        <text
          transform='matrix(1 0 0 1 193.4409 221.9375)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kébili Nord
        </text>
        <text
          transform='matrix(1 0 0 1 193.4409 248.9375)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kébili Sud
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Kebili;
