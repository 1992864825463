import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Kairouan = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='570.149px'
        height='543.894px'
        viewBox='0 0 570.149 543.894'
        enable-background='new 0 0 570.149 543.894'
      >
        <a xlinkTitle={`Sbikha ${groupedData['Sbikha']}`}>
          <path
            onClick={() => setSelectedVill('Sbikha')}
            style={{ fill: getColorBasedOnCount(outputData['Sbikha']) }}
            d='M264.988,92.723c-0.667,11.838,1.351,20.922,3.6,30.48c3.57,2.184,7.886,2.025,12.24,3.36
	c1.613,0.495,3.274,0.399,3.839,1.92c1.812,2.311,1.175,7.749,1.919,11.28c0.468,2.209,0.484,6.432,0,8.64
	c-0.541,2.47-0.094,4.473-0.719,6.72c-2.045,7.343-4.67,13.389-8.641,18.72c-1.145,1.537-2.997,2.455-4.56,3.6
	c-1.924,1.411-4.01,3.714-5.28,5.76c-3.37,5.433-1.146,15.39-7.2,18.24c-0.292,3.425-1.133,7.141,0,10.56
	c0.876,2.644,2.814,3.759,2.88,7.2c-0.16,0-0.32,0-0.48,0c-0.575,0.363-1.527,0.052-2.4,0.24c-0.914,0.197-9.401,1.002-10.08,0.72
	c-0.715-0.297-0.644-0.82-1.92-0.96c-0.475,0.749-0.678,0.921-1.92,0.96c-0.481-0.472-1.111-0.548-1.68-0.96c-1.84-2-3.68-4-5.52-6
	c-2,0-4,0-6,0c-2.443-1.604-8.05-0.07-10.08,0.48c-0.497,0.781-1.291,1.511-1.68,2.4c-0.32,1.04-0.64,2.08-0.96,3.12
	c-0.517,0.673-1.891,0.736-2.64,1.2c-1.808,1.118-3.472,2.609-5.28,3.84c-0.905,0.616-3.735,3.265-4.8,2.64
	c-3.369-2.316-5.72-7.579-7.92-11.04c-0.857-1.349-1.911-3.916-3.36-4.56c-1.04,0-2.08,0-3.12,0c-1.021,0.657-6.542,0.689-7.92,0.24
	c-4.812-1.569-8.564-6.487-14.4-6.24c-2.98-5.073-7.063-10.085-15.12-10.08c-0.597-2.23-2.897-2.727-4.08-4.32
	c-0.264-0.356-0.38-1.447-0.72-1.68c-2.08,0-4.16,0-6.24,0c-0.345-0.252-0.941-0.374-1.44-0.48c-0.403-1.192-0.861-2.322-1.2-3.36
	c4.208-4.984,10.005-8.18,13.92-13.44c1.448-1.946,3.623-3.821,4.08-6.72c-2.656-3.397-1.65-14.069-0.48-18.479
	c0.388-1.463,1.919-5.129,1.2-6.48c-0.758-2.331-3.389-3.089-3.6-5.76c0.861-1.043,1.08-2.546,2.16-3.36
	c2.609-1.966,7.691-2.671,7.68-7.2c-0.773-0.443-2.514-1.024-2.88-1.92c-0.252-0.345-0.374-0.941-0.48-1.44
	c0.975-0.678,1.824-1.853,2.88-2.4c2.776-1.438,9.957-1.558,11.28,1.2c0.843,1.26,0.579,3.316,1.2,4.8
	c0.632,1.512,1.831,2.505,2.88,3.6c9.728-0.063,20.688-1.174,26.88,2.88c1.49,0.976,3.455,2.97,4.32,4.56
	c0.613,1.125,0.846,3.423,1.92,4.08c0.567-0.605,1.027-0.876,2.16-0.96c1.394,1.531,2.874,3.337,5.76,3.36
	c3.286-5.459,5.306-12.151,6.96-19.44c5.466-0.582,11.572-5.404,15.6-7.92c1.358-0.848,4.041-1.245,4.32-3.12
	c1.367-2.082-1.564-7.568-1.68-10.08c1.559-1.726,3.248-7.166,5.28-7.92C255.508,91.632,261.823,92.641,264.988,92.723z'
          />{' '}
        </a>
        <a xlinkTitle={`Oueslatia ${groupedData['Oueslatia']}`}>
          <path
            onClick={() => setSelectedVill('Oueslatia')}
            style={{ fill: getColorBasedOnCount(outputData['Oueslatia']) }}
            d='M152.188,104.003c0.582,3.896,4.321,4.262,6.24,6.72c1.399,1.793,1.182,4.383,2.16,6.72
	c0.416,0.993,2.025,2.117,2.16,2.4c0.124,0.258-0.094,1.858,0.24,2.64c0.735,1.721,2.896,0.729,3.12,3.12
	c-3.871,2.376-9.462,4.671-10.08,10.32c1.814,1.206,2.599,3.271,4.08,4.8c-1.131,3.09-3.608,13.8-2.64,18.24
	c0.513,2.354,1.321,4.781,1.44,7.2c-0.96,0.995-1.349,2.445-2.16,3.6c-1.921,2.736-5.73,6.653-8.4,8.64
	c-2.575,1.916-6.968,3.543-7.2,7.68c0.719,0.77,0.807,2.001,1.2,3.12c1.856,1.067,4.826,0.987,7.92,0.96
	c1.186,2.34,4.271,3.271,4.8,6c5.072-0.012,9.105,1.683,11.28,4.56c1.409,1.865,2.731,5.438,5.76,5.52
	c0.371-0.17,0.032-0.031,0.72,0c0,0.08,0,0.16,0,0.24c-1.161,1.05,0.387,3.863,0.48,5.76c-1.677,1.066-5.284,4.272-5.28,6.96
	c-0.642,1.018-0.357,2.526-0.72,3.84c-0.811,2.934-1.798,6.034-1.92,9.6c-4.584,2.613-9.248,5.721-13.44,8.88
	c-1.471,1.108-3.057,2.956-4.32,4.32c-0.16,0.32-0.32,0.64-0.48,0.96c-1.469,0.944-3.497,0.278-5.52,0.72
	c-2.177,0.475-5.249,0.245-7.44,0.72c-1.52,0-3.04,0-4.56,0c-1.107,0.24-4.06,0.684-5.52,0.24c-0.408-0.124-1.271-0.836-1.68-0.96
	c-3.114-0.943-3.909,1.65-6,1.92c-0.914-1.816-2.684-3.208-4.56-4.08c-0.116-2.643-1.044-4.832-1.68-6.96
	c-0.24-1.52-0.48-3.04-0.72-4.56c-0.102-0.43-0.571-2.163-0.48-2.64c0.102-0.534,0.979-1.408,1.2-1.92
	c0.64-1.919,1.28-3.84,1.92-5.76c-0.16,0-0.32,0-0.48,0c-2.459-2.063-10.035-0.965-10.56,1.92c0.93,1.408,0.037,3.562-0.48,4.8
	c-1.333,3.193-3.043,5.442-5.28,7.68c-1.041,1.041-2.932,2.027-3.6,3.36c0.16,0,0.32,0,0.48,0c0.745,0.656,3.077,0.521,3.36,0.96
	c-1.367,1.184,0.098,3.11-0.48,4.32c-0.221,0.664-3.772,4.558-4.32,4.8c-0.959,0-1.92,0-2.88,0c-0.684,0.417-2.016,0.274-2.64,0.72
	c-4.351,1.359-3.218,7.61-3.12,12.96c-0.08,0-0.16,0-0.24,0c0-0.16,0-0.32,0-0.48c-1.392-0.919-3.775-2.59-4.56-4.08
	c-2.234-4.241-0.041-10.09,0.96-13.92c0.734-2.809,0.421-6.853-2.16-7.2c0-0.64,0-1.28,0-1.92c-1.754-1.141-3.21-2.941-5.04-4.08
	c-3.705-2.305-8.424-3.856-10.8-7.44c-1.173-1.77-1.758-5.766-0.48-7.92c1.807-3.045,9.689-6.081,10.32-8.64
	c-0.506-0.311-0.754-0.911-1.2-1.2c-1.451-0.94-3.871-0.464-4.56-2.16c-0.297-0.441-0.262-1.369-0.24-2.16
	c0.4-0.56,0.8-1.12,1.2-1.68c2.119-1.623,3.845-1.902,3.84-5.76c-1.271-0.716-8.791-4.079-9.12-4.8c0-4.159,0-8.32,0-12.48
	c3.283,0.059,5.619-0.399,7.92-0.96c0.262-0.479,0.349-1.37,0.72-1.92c0.604-0.893,3.462-1.873,4.32-2.88
	c2.568-3.014,5.376-5.616,8.16-8.4c1.12-1.2,2.24-2.4,3.36-3.6c1.925-1.431,4.076-2.408,6-3.84c0.918-0.683,1.782-1.898,2.16-3.12
	c0-0.4,0-0.8,0-1.2c0.35-0.717,1.599-1.658,2.16-2.4c1.6-1.52,3.2-3.04,4.8-4.56c3.072-3.072,5.863-6.478,9.36-9.12
	c2.103-1.588,3.27-5.172,5.28-6.48c1.12-0.4,2.24-0.8,3.36-1.2c0.8-0.88,1.6-1.76,2.4-2.64c3.688-2.746,6.955-6.337,9.6-10.08
	c0.783-1.107,0.673-2.883,1.44-4.08c1.785-2.786,4.767-4.471,5.76-8.16c1.666,0.239,2.291,0.913,3.84,1.44
	c1.875,0.637,6.504,1.177,8.64,1.2c0.415-0.715,1.157-2.49,1.92-2.88C151.511,104.05,151.632,104.057,152.188,104.003z'
          />{' '}
        </a>
        <a xlinkTitle={`Chebika ${groupedData['Chebika']}`}>
          <path
            onClick={() => setSelectedVill('Chebika')}
            style={{ fill: getColorBasedOnCount(outputData['Chebika']) }}
            d='M173.308,207.202c4.966,0.006,6.821,3.329,10.32,4.8c3.497,1.47,8.198,0.213,12.24,0.24
	c1.025,1.573,2.583,2.947,3.6,4.56c1.66,2.635,2.792,5.406,4.56,7.92c1.057,1.502,2.963,2.166,3.12,4.56
	c-0.935,1.461-0.676,4.006-1.2,5.76c-1.561,5.226-4.273,14.514-0.48,19.44c0.327,0.424,6.772,4.089,7.44,4.32
	c1.428,0.495,2.83-0.221,3.84,0.48c0.209,0.178,0.271,0.302,0.48,0.48c-0.279,1.471-0.153,2.841-0.24,4.32
	c-0.559,0.071-0.595,0.108-0.96,0.24c-1.759,3.803-0.999,9.625-2.64,13.439c-1.075,2.498-3.549,2.014-3.6,5.76
	c1.031,0.334,1.862,1.16,2.88,1.439c4.449,1.225,7.92-1.717,12-0.959c0.269,0.502,0.344,0.438,0.48,1.199
	c-0.47,0.483-0.548,1.111-0.96,1.68c-1.497,2.066-4.498,2.986-4.8,6.24c2.791,1.824,4.617,3.953,8.88,4.561
	c-0.045,1.254-1.513,3.799-1.44,3.84c2.285,3.568,3.269,6.786,1.92,12c-1.342,0.852-2.92,2.227-4.56,2.641
	c-3.146,0.795-5.845-0.926-7.68-1.439c-1.161-0.325-1.917,0.429-3.12,0.479c-0.684-1.074-1.907-1.658-2.64-2.639
	c-1.039-1.393-2.001-3.107-2.88-4.561c-0.673-1.113-0.354-2.142-1.68-2.642c0-0.079,0-0.159,0-0.239c-0.08,0-0.16,0-0.24,0
	c0,0.08,0,0.159,0,0.239c-2.172,1.521-2.915,4.646-4.32,6.961c-3.39,5.58-6.054,11.074-12.48,13.681
	c-0.55-1.492-2.229-1.473-3.84-1.92c-3.467-0.965-7.814-0.254-10.56,0.959c-1.459,0.646-1.994,1.754-4.08,1.92
	c-0.799-0.642-2.034-0.857-2.64-1.681c-1.302-1.764-1.465-4.341-2.4-6.479c-0.976-2.23-3.385-4.637-5.28-6
	c-1.995-1.434-8.617-3.715-9.36-5.76c-0.751-1.106-0.232-2.847-0.72-4.318c-0.525-1.588-1.844-2.65-2.16-4.561
	c4.514,0.021,8.081-0.627,11.76-1.439c2.252-0.498,4.37,0.355,6.24,0.479c-0.025-4.094-0.54-8.736-1.68-12.24
	c-0.625-1.92-1.917-3.604-2.16-5.76c0.836-0.804,1.215-1.932,1.92-2.88c1.669-2.245,4.61-4.436,6.96-6
	c1.382-0.92,5.418-2.453,6-3.84c-2.068-2.694-1.999-7.486-3.36-10.8c-0.712-1.734-2.139-3.101-2.16-4.8
	c0.928-0.639,1.79-1.646,2.64-2.4c0-0.72,0-1.44,0-2.16c-1.233-1.151-3.191-2.399-4.08-3.84c-0.4-1.12-0.8-2.24-1.2-3.36
	c-0.622-0.608-1.43-0.139-1.92-0.96c-0.798-1.015-1.172-4.729-0.72-6.72c1.012-4.453,2.466-8.691,2.64-13.68
	c1.76-1.68,3.52-3.36,5.28-5.04c0-0.96,0-1.92,0-2.88C173.742,209.197,173.438,208.218,173.308,207.202z'
          />{' '}
        </a>
        <a xlinkTitle={`Kairouan Nord ${groupedData['Kairouan Nord']}`}>
          <path
            onClick={() => setSelectedVill('Kairouan Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Kairouan Nord']) }}
            d='M264.028,220.162c0-0.56,0-1.12,0-1.68c0.08,0,0.16,0,0.24,0c0.524,0.228,0.914-0.214,1.44,0
	c4.468,1.328,8.273,9.15,10.08,13.44c0.944,2.241,1.295,7.091,3.6,7.92c0.372,0.252,0.98,0.248,1.68,0.24
	c0.63-0.65,1.531-1.03,2.16-1.68c0.24,0.08,0.48,0.16,0.72,0.24c1.413,3.007,6.57,7.024,6.958,9.36
	c0.246,1.469-1.806,3.134-2.16,4.56c-0.582,2.352,0.713,4.427,0.961,6.24c-0.547,0.294-0.541,0.393-1.439,0.48
	c-0.895-0.524-3.463,0-5.04,0c-3.665,0-6.593-0.373-9.36-0.96c-0.199-2.347-1.312-8.412-2.88-9.12c-1.36,0-2.72,0-4.08,0
	c-0.963,0.583-2.45,0.34-3.6,0.72c-2.801,0.927-5.501,2.502-8.16,3.6c-1.04,0.24-2.081,0.48-3.12,0.72
	c-0.572,0.317-1.127,1.291-1.68,1.68c-1.021,0.718-1.971,0.594-3.12,1.2c-0.48,0.56-0.96,1.12-1.44,1.68
	c-1.424,1.028-3.645,1.683-5.52,2.16c-2.357,0.6-6.815,0.529-9.12,0c-1.04,0-2.08,0-3.12,0c-2.964-0.667-6.354-0.718-9.84-0.72
	c-0.082-0.751-0.25-1.126-0.48-1.68c-0.448-0.185-1.206-0.598-1.68-0.72c-0.88,0-1.76,0-2.64,0c-0.696-0.248-6.872-3.654-7.2-4.08
	c-3.802-4.94-0.438-14.256,0.96-19.2c0.641-2.267,0.35-5.166,1.68-6.72c0.857-0.804,2.09-1.028,3.12-1.68
	c1.438-0.911,2.697-2.389,4.08-3.36c1.505-1.057,3.294-1.515,4.8-2.64c1.845-1.379,0.854-4.835,3.36-5.76
	c2.308-1.641,10.589-0.281,14.4-0.24c1.507,2.316,5.615,6.766,8.88,7.2c0.314-0.354,1.246-1.265,1.44-0.96
	C251.604,222.063,260.274,220.375,264.028,220.162z'
          />{' '}
        </a>
        <a xlinkTitle={`Haffouz ${groupedData['Haffouz']}`}>
          <path
            onClick={() => setSelectedVill('Haffouz')}
            style={{ fill: getColorBasedOnCount(outputData['Haffouz']) }}
            d='M111.148,246.562c0.608,0.018,0.892,0.029,1.2,0.24c2.323,0.86,2.099,3.53,5.28,3.6
	c3.031-3.134,4.739-0.956,9.6-0.96c5.839-0.32,11.681-0.64,17.52-0.96c4.044-0.953,4.721-3.706,7.44-5.76
	c2.766-2.09,5.694-3.801,8.64-5.76c1.416-0.942,2.748-2.358,4.56-2.88c-0.038,2.105,0.354,3.44,0.72,5.04
	c3,1.324,2.159,2.489,3.84,4.8c1.236,1.699,3.285,1.264,3.36,4.32c-1.034,0.49-3.357,2.058-2.88,3.84
	c0.263,0.98,1.711,2.31,2.16,3.36c1.006,2.354,1.078,4.649,1.92,7.2c0.277,0.838,1.617,3.256,1.2,3.84
	c-0.881,0.816-2.05,1.016-3.12,1.68c-3.583,2.223-10.689,7.156-11.52,11.759c2.431,2.633,3.786,11.021,3.84,16.08
	c-6.506-0.324-11.034,1.144-17.76,1.201c-0.029,2.832,1.176,3.48,1.92,5.279c0.32,1.84,0.64,3.68,0.96,5.52
	c4.291,2.795,10.465,4.99,13.44,9.121c1.644,2.281,1.835,5.719,3.36,8.158c0.787,1.26,2.706,1.621,3.36,2.881c-0.08,0-0.16,0-0.24,0
	c-0.739,1.133-2.155,1.034-4.08,0.959c-1.099,0.699-2.765,0.537-3.84,1.201c-1.257,0.775-1.879,2.336-3.36,2.879
	c-1.045-0.729-2.549-0.729-3.6-1.438c-0.767-0.519-0.889-1.304-1.92-1.681c-2.683,4.539-8.731,7.049-13.44,9.601
	c-1.354,0.733-3.04,2.179-4.56,2.642c-1.071,0.323-5.982-2.228-6.24-2.642c-1.632-1.751-2.211-4.781-3.6-6.72
	c-4.199-5.864-13.486-11.596-23.28-11.762c0.011,4.994-1.106,16.578-4.56,17.762c-1.333,1.134-4.718,0.236-5.28-0.961
	c1.156-0.875,1.894-2.561,1.92-4.561c-0.951-0.954-1.366-2.227-2.16-3.359c-3.362-4.799-8.213-10.006-8.64-17.52
	c2.863-1.939,3.72-4.902,8.64-4.32c1.11-2.175,2.614-4.703,4.08-6.721c0.67-0.92,2.659-1.678,3.12-2.641c0-1.039,0-2.08,0-3.119
	c0.612-0.945,0.475-2.273,0.96-3.359c0.323-0.725,1.044-1.388,1.44-2.16c3.247,0.743,6.192,2.986,7.92,5.279
	c1.25,1.661,1.637,3.538,4.56,3.602c0.903,0.549,2.426,0.191,3.6,0.479c2.457,0.604,5.08,1.464,7.68,1.68c0-0.158,0-0.318,0-0.479
	c-1.49-2.012,0.108-6.926-1.68-9.12c-1.429-1.753-6.331-4.147-8.88-4.8c-1.881-0.479-3.103,0.554-4.32-0.239
	c-3.235-2.11-4.341-6.729-8.16-8.399c-3.202-1.4-7.144,0.717-10.32,0c-2.363-0.533-3.188-1.57-4.8-2.642
	c0.038,1.308-0.021,1.801-0.96,2.16c-0.708,0.584-4.518,0.922-5.52,0.239c-2.061-0.692-4.015-3.551-4.32-6c0.16,0,0.32,0,0.48,0
	c2.108-1.387,4.865-0.742,5.76-3.36c2.6-3.586-2.463-13.511,2.64-15.36c1.339-1.187,4.209-0.301,5.52-0.96
	c2.204-0.773,3.91-3.748,5.52-5.28c-0.021-1.22-0.753-2.705-0.48-3.6c0.223-0.73,1.159-1.021,1.2-1.44
	c-0.304-0.989-0.628-1.176-1.92-1.2c-0.311,0.21-0.592,0.215-1.2,0.24c0-0.16,0-0.32,0-0.48c3.081-2.033,7.301-7.327,8.4-11.28
	c0.461-1.658,0.028-2.627,0-4.32c2.66-0.879,5.42-1.94,8.64-0.72c-0.78,1.674-1.13,3.511-1.92,5.28
	c-0.165,0.368-0.845,0.82-0.96,1.2c-0.381,1.257,0.465,3.814,0.72,4.8C109.725,239.296,110.655,242.7,111.148,246.562z'
          />
        </a>
        <a xlinkTitle={`El Ala ${groupedData['El Ala']}`}>
          <path
            onClick={() => setSelectedVill('El Ala')}
            style={{ fill: getColorBasedOnCount(outputData['El Ala']) }}
            d='M123.869,299.841c-3.744-0.118-6.926-1.557-11.04-1.68c-1.961-3.062-4.852-5.933-8.16-7.681
	c-0.765-0.403-2.55-1.659-3.36-1.2c-2.324,1.565-3.146,5.198-3.12,9.12c-2.309,1.229-3.693,3.593-5.04,5.761
	c-0.485,0.778-0.878,2.496-1.68,2.879c-0.8,0-1.6,0-2.4,0c-0.93,0.67-2.2,0.787-3.12,1.44c-0.466,0.33-2.643,3.127-2.88,2.64
	c-0.972-1.41-0.77-3.875-1.44-5.521c-1.175-2.884-2.792-5.588-4.56-7.92c-1.975-2.604-7.342-5.587-12.24-4.32
	c-1.784,0.462-3.741,2.166-6.479,1.44c-4.044-1.071-6.732-3.879-11.76-4.079c0,0.159,0,0.319,0,0.479
	c0.578,0.821,2.22,5.982,1.44,7.199c-0.647,1.961-6.987,7.521-10.08,6.721c-2.007-0.521-4.213-1.971-5.76-3.121
	c-8.102-6.021-10.952-21.323-16.56-30.239c-2.159-3.433-6.399-7.903-9.6-10.32c-1.823-1.377-4.049-3.117-5.28-5.04
	c-2.34-3.655,1.396-6.33,3.12-8.16c3.092,0.06,4.543,1.899,6.48,3.12c2.379,1.5,5.214,3.103,8.16,3.84
	c3.859,0.966,7.141-2.243,10.8-0.72c3.708,1.543,1.49,4.982,8.4,5.04c2.388-2.366,4.771-5.159,7.68-6.96
	c1.797-1.113,5.074-1.227,6.72-2.4c1.619-1.715,2.151-3.563,4.56-4.56c4.124-1.706,9.655-0.068,14.4-0.96
	c0.892-2.344,2.269-4.342,5.52-4.32c0.08,0.24,0.16,0.48,0.24,0.72c0.888,0.982,0.913,3.404,0.48,5.04
	c-0.8,3.027-2.894,7.281-1.92,11.52c0.29,1.26,0.755,3.063,1.44,4.08c1.77,2.627,5.712,2.868,4.8,6.96
	c-2.119,1.35-3.728,1.99-6.72,2.64c0,0.08,0,0.16,0,0.24c0.56,0.826,0.516,1.91,0.96,2.88c1.849,4.037,4.001,4.916,10.08,4.801
	c0.73-0.572,1.389-0.652,1.92-1.441c1.17,0.541,2.384,1.111,3.84,1.441c3.444,0.777,6.928-1.324,10.08,0
	c2.636,1.106,4.14,3.821,5.76,6c0.988,1.326,2.664,1.975,3.84,3.119c3.351-1.557,10.849,2.693,12,4.801
	C124.201,294.607,123.93,297.667,123.869,299.841z'
          />{' '}
        </a>
        <a xlinkTitle={`Kairouan Sud ${groupedData['Kairouan Sud']}`}>
          <path
            onClick={() => setSelectedVill('Kairouan Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Kairouan Sud']) }}
            d='M289.948,260.242c0.158,3.071,1.645,5.992,2.639,8.4c2.609,6.318,4.783,12.215,8.16,17.76
	c1.344,2.206,3.945,4.794,4.32,7.68c-0.783,0.504-1.439,1.373-2.4,1.681c-1.752,0.562-3.988-0.218-6,0.239
	c-1.842,0.42-3.844,0.98-5.278,1.92c-4.673,3.06-6.426,9.398-11.04,12.48c2.131,4.574,6.076,7.631,8.88,11.52
	c0.44,0.611,2.716,3.875,2.399,4.08c-1.112,1.638-3.157,2.674-4.319,4.32c-0.521,0.739-0.953,2.643-1.681,3.119
	c-1.6,0-3.2,0-4.799,0c-1.005,0.65-4.032,0.271-5.52,0.24c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.24,0
	c-0.287-2.656-1.616-5.369-2.64-7.439c-0.72-0.08-1.44-0.16-2.16-0.239c-2.883,3.004-6.962,4.868-6.24,10.8
	c-6.857,0.02-11.694-4.975-14.64-8.88c-1.877-2.489-2.982-4.771-7.44-4.8c-1.68,2.683-4.87,3.998-6.72,6.479
	c-0.986,1.324-1.501,3.35-2.64,4.561c0-0.08,0-0.159,0-0.239c-1.669-1.188-1.924-3.918-4.56-4.08
	c-1.421,0.862-4.05,0.269-5.76,0.722c-4.671,1.231-9.258,2.776-12.72,5.278c-1.867,1.351-3.944,3.944-4.8,6.239
	c-0.593,1.592-0.007,2.854-1.68,3.359c-1.141-0.772-4.165-0.633-5.76-0.24c-3.018,0.746-6.001,1.604-9.36,0.961
	c-0.697-2.914-5.517-1.67-5.76-4.801c0.479-1.68,0.96-3.358,1.44-5.039c1.002-2.348,2.601-4.74,4.08-6.721
	c1.779-2.381,4.326-3.26,6.72-5.039c2.138-1.59,4.094-4.676,5.52-6.961c1.246-1.996,4.875-10.29,6.24-10.561
	c2.431,3.023,3.54,8.922,8.16,9.602c2.101-1.766,6.047,1.756,10.08,0.721c2.251-0.58,4.929-2.264,5.76-4.32
	c1.489-3.688-0.469-9.648-2.16-11.28c0.507-1.577,1.192-2.127,1.2-4.56c-2.589-0.295-8.404-2.487-8.64-5.041
	c1.644-1.141,5.566-5.412,5.76-7.92c-3.568-1.756-6.13-0.879-10.08,0c-2.535,0.564-5.274-0.201-5.52-2.16
	c3.749-2.229,3.717-5.295,4.8-10.319c0.08-1.439,0.16-2.88,0.24-4.32c0.797-2.773,2.584-2.13,2.16-6
	c8.273,0.088,19.022,2.781,26.16-0.24c1.517-0.643,2.477-2.285,3.6-2.88c0.883-0.468,1.629-0.242,2.4-0.72
	c0.853-0.529,1.578-1.646,2.4-2.16c1.6-0.48,3.2-0.96,4.8-1.44c4.04-1.74,7.164-3.531,13.2-3.6c1.354,2.449,1.604,6.368,2.88,9.12
	c1.472,0.192,3.272,0.628,4.8,0.96C281.614,260.986,286.899,260.206,289.948,260.242z'
          />{' '}
        </a>
        <a xlinkTitle={`Hajeb El Ayoun ${groupedData['Hajeb El Ayoun']}`}>
          <path
            onClick={() => setSelectedVill('Hajeb El Ayoun')}
            style={{ fill: getColorBasedOnCount(outputData['Hajeb El Ayoun']) }}
            d='M47.789,291.921c3.847,0.283,6.23,2.584,9.36,3.6c4.88,1.586,7.826-2.491,12.48-1.199
	c6.668,1.85,10.136,8.205,12,14.881c1.155,4.135,1.166,7.937,2.88,11.279c1.785,3.48,3.871,6.666,6,9.84
	c0.916,1.365,2.393,2.28,2.64,4.319c-0.688,0.804-2.346,3.392-1.92,4.319c1.602,1.563,4.754,1.553,7.2,0.961
	c0.696-1.098,1.854-1.836,2.4-3.121c1.115-2.623,1.011-6.084,1.68-9.119c0.411-1.865-0.438-4.688,0.96-5.52
	c1.02,0.642,2.527,0.352,3.84,0.719c2.16,0.721,4.32,1.44,6.48,2.16c7.044,3.061,11.514,8.797,14.4,16.08
	c1.802-0.006,4.951,1.533,6,2.399c-0.08,0-0.16,0-0.24,0c0,0.08,0,0.16,0,0.239c-3.756,2.066-8.578,3.476-12,5.761
	c1.37,3.713,3.033,14.103,1.92,19.2c-0.335,1.533-2.071,8.837-1.68,10.078c0.77,2.445,4.203,2.681,4.56,4.802
	c-1.419,2.082,0.179,6.489-0.48,9.358c-0.578,2.517-1.569,4.82-2.16,7.201c-1.052,4.232,2.092,7.232,0.96,11.279
	c-0.444,1.587-1.859,2.788-2.16,4.561c-0.267,1.574,1.418,2.707,0.96,3.84c-0.629,1.929-1.879,2.234-1.92,5.039
	c0.434,0.391,0.943,0.879,1.2,1.441c-0.56,0-1.12,0-1.68,0c-1.328-1.014-3.083-1.32-4.08-2.641c-2.17-2.875-4.312-6.277-7.44-8.16
	c-3.156-1.9-7.515-1.965-10.56-3.84c-0.996-0.613-4.73-5.776-6.48-4.32c-1.311,0.424-2.943,1.932-3.84,2.881
	c-2.306,0.047-3.432-0.518-5.04-0.961c-1.163-9.135-13.212-20.018-19.44-24.72c-2.546-1.923-4.159-5.034-6.48-7.2
	c-0.064-2.209,1.516-7.219,2.4-8.4c3.457-3.226,8.442-3.473,8.4-10.318c-2.021-1.264-8.75-5.508-7.68-9.359
	c0.626-2.255,2.218-4.144,2.88-6.479c-2.426-0.843-6.07-5.56-8.4-7.2c-2.073-1.459-4.673-2.678-7.44-3.359
	c-1.105-0.272-2.525,0.041-3.36-0.24c-0.533-3.801-2.799-7.371-5.04-9.6c-1.04-0.801-2.08-1.602-3.12-2.4
	c-1.967-3.426,1.507-10.51,2.16-13.68c4.984-1.203,7.355-4.479,10.32-7.68C49.311,295.572,48.659,293.939,47.789,291.921z'
          />{' '}
        </a>
        <a xlinkTitle={`Bou Hajla ${groupedData['Bou Hajla']}`}>
          <path
            onClick={() => setSelectedVill('Bou Hajla')}
            style={{ fill: getColorBasedOnCount(outputData['Bou Hajla']) }}
            d='M284.907,334.402c-0.219,1.631-1.201,3.109-1.68,4.561c-0.64,2.879-1.28,5.761-1.92,8.64
	c-7.891,2.169-10.048,10.974-12.24,18.96c-0.24,1.76-0.48,3.521-0.72,5.279c-0.72,3.039-1.44,6.08-2.16,9.119
	c5.255,2.022,10.969,4.949,11.76,11.281c-4.131,3.211-8.958,13.986-6.48,21.6c1.013,3.111,2.682,5.912,4.32,8.4
	c0.393,0.596,1.711,2.605,1.44,2.879c-1.377,0.951-6.705,0.762-8.64,0.24c-6.042-1.627-11.319-3.934-18.72-4.08
	c-1.25-2.121-2.932-7.527-6.96-6c-1.746,0.662-2.365,2.473-4.56,2.881c-3.563-3.343-10.135,2.344-13.44,2.879
	c-0.001-4.724,2.231-6.709,3.12-10.319c0.572-2.322-0.718-3.867-0.96-5.521c-3.893-0.076-7.58,0.193-11.04,0.961
	c-3.199,0.709-4.32-1.757-7.2-0.961c-3.648,1.008-5.577,4.404-9.84,4.801c-0.134-2.076-3.427-5.188-2.88-6.239
	c0.24-0.08,0.48-0.16,0.72-0.24c0.793-1.101,1.106-2.489,1.92-3.601c0.468-0.641,1.084-0.58,1.44-1.439
	c-0.845-0.923-1-2.238-1.68-3.359c-2.295-3.781-4.727-7.979-6.24-12.479c-0.367-1.093-1.55-6.382-1.2-6.96
	c1.475-1.633,4.01,0.168,4.08-3.12c-1.564-1.499-2.235-1.829-2.16-5.04c0.572-0.736,1.418-3.504,0.72-4.561
	c-1.375-3.603-8.496-2.822-8.64-6.719c0.79-1.209,2.732-7.396,2.88-9.121c4.307,0.119,7.563-0.633,11.28-1.439
	c1.911-0.415,3.322,0.525,4.8,0.721c1.135-1.959,1.811-5.148,3.12-6.961c2.818-3.898,7.587-5.535,12.72-7.199
	c1.544-0.502,7.209-2.195,8.88-1.439c1.356,1.046,2.676,3.104,3.6,4.561c0.421-0.15,0.233-0.047,0.48-0.24
	c1.204-0.648,1.184-2.232,1.92-3.359c1.396-2.139,6.659-7.912,9.84-7.92c1.002,1.525,2.766,2.42,3.84,3.84
	c3.935,5.204,7.874,10.27,17.04,9.6c0-0.16,0-0.318,0-0.479c-3.172-3.391,3.775-8.534,5.28-10.08
	c3.221,0.919,1.548,6.318,4.08,7.682C276.279,335.328,282.751,334.435,284.907,334.402z'
          />{' '}
        </a>
        <a xlinkTitle={`Nasrallah ${groupedData['Nasrallah']}`}>
          <path
            onClick={() => setSelectedVill('Nasrallah')}
            style={{ fill: getColorBasedOnCount(outputData['Nasrallah']) }}
            d='M198.028,411.201c5.009-0.121,6.939-3.755,11.04-4.8c0,0.239,0,0.479,0,0.72
	c-0.865,1.463-0.284,3.937-0.72,5.76c-0.969,4.059-1.975,9.949-1.2,15.121c0.24,1.439,0.48,2.879,0.72,4.32
	c-1.462,6.016-3.759,12.326-6,17.76c-1.042,2.525-1.959,4.863-2.88,7.199c-0.32,0.811-0.487,2.523-1.2,2.879c-0.56,0-1.12,0-1.68,0
	c-1.904-1.191-5.543-1.463-7.68-2.398c-4.854-2.127-9.387-5.168-14.88-6.721c-3.28-0.24-6.561-0.479-9.84-0.721
	c-2.05-0.73-3.921-2.467-6.24-3.119c-4.529-1.275-9.251,1.52-14.16,0.24c-5.178-1.352-9.594-5.521-13.44-8.4
	c-0.685-0.512-3.542-2.07-3.84-2.641c0-1.998,0-4,0-6c-0.579-0.803-0.998-2.068-1.44-2.879c-0.08,0-0.16,0-0.24,0
	c0.08-0.4,0.16-0.801,0.24-1.199c-0.414-1.398-1.607-1.02-1.68-3.121c1.099-0.848,2.093-2.451,2.16-4.32
	c-3.104-3.223,2.083-5.928,0.96-11.039c-0.414-1.887-1.575-4.639-0.96-7.2c0.58-2.414,1.574-4.665,2.16-7.199c0-2.88,0-5.76,0-8.641
	c0.205-0.742,0.736-1.558,0.96-2.399c-2.145-1.027-3.477-2.285-5.04-3.84c0.099-3.219,1.005-6.48,1.68-9.602c0-1.358,0-2.719,0-4.08
	c1.003-4.742-0.812-11.039-1.68-15.118c1.055-0.597,2.182-1.437,3.36-1.92c2.372-0.976,4.911-1.125,6.96-2.4
	c1.054-0.655,1.684-1.965,2.64-2.64c2.558-1.807,6.017-3.167,8.64-4.801c3.043-1.895,6.714-3.512,8.64-6.479
	c0.711,0.472,5.177,3.649,6.24,2.642c3.624-2.842,3.437-4.342,10.08-3.84c1.59-3.197,6.462-4.844,10.32-5.761
	c2.914-0.692,8.066,0.552,8.16,2.879c-3.816,3.66-6.381,8.978-7.2,15.601c1.662,0.369,4.595,1.422,5.52,2.641
	c1.386,1.99-2.328,8.404-2.4,12.48c1.858,1.105,8.035,3.521,8.64,5.278c0.971,1.701-1.972,4.979-0.48,7.438
	c0.307,0.803,1.316,1.613,1.92,2.16c-0.151,0.422-0.046,0.233-0.24,0.48c-0.721,0.545-2.662,0.754-3.6,0.96
	c-1.452,5.495,3.647,15.567,6,19.44c0.867,1.426,2.054,2.856,2.4,4.799c-1.15,0.798-3.594,4.498-3.6,6.48
	C196.59,406.664,198.025,408.322,198.028,411.201z'
          />{' '}
        </a>
        <a xlinkTitle={`Cherarda ${groupedData['Cherarda']}`}>
          <path
            onClick={() => setSelectedVill('Cherarda')}
            style={{ fill: getColorBasedOnCount(outputData['Cherarda']) }}
            d='M286.107,451.281c0,0.4,0,0.801,0,1.199c-2.147,0.529-4.302,1.932-6.48,2.641
	c-5.199,1.039-10.4,2.08-15.6,3.119c0.193,2.498,1.032,5.775-0.48,7.682c-0.547,0.688-1.926,0.406-2.88,0.719
	c-1.324,0.438-2.972,1.355-4.08,2.16c-1.78,1.293-3.379,3.881-4.56,5.76c-0.289,0.461-0.312,1.381-0.72,1.682
	c-0.532,0.395-2.565,0.631-3.36,1.201c-1.025,0.732-1.753,1.984-2.64,2.879c-2.111,2.127-4.357,4.123-6.72,6
	c-1.332,1.059-4.08,4.717-5.04,6.238c-0.778,1.234-0.498,2.449-2.4,2.643c-0.496,0.645-2.282-0.303-3.36-0.721
	c-3.503-1.357-7.554-2.451-10.8-4.08c-0.835-6.584-1.621-15.08,0-21.842c1.04-5.117,2.08-10.238,3.12-15.357
	c-4.657,0.059-7.835,2.412-11.52,3.6c-2.365,0.764-5.54,0.313-8.16,0.24c0.35-2.643,2.153-5.355,3.12-7.68
	c2.177-5.242,3.885-11.195,5.28-16.803c0.295-1.186-0.301-2.195-0.48-2.879c-0.531-2.023-0.77-6.297-0.24-8.641
	c1.053-4.656,1.689-9.94,1.92-14.879c1.071,0.172,1.564,0.73,2.4,0.959c2.833,0.775,6.52-0.479,8.64-0.959
	c2.019-0.457,3.501-0.033,5.28,0c0,0.159,0,0.319,0,0.479c0.94,1.207,0.868,4.008,0.24,5.521c-1.225,2.949-3.194,5.563-2.4,10.08
	c0.24,0,0.48,0,0.72,0c0.674-0.469,1.555-0.363,2.4-0.721c2.373-1.002,4.329-2.771,7.2-3.359c1.961-0.402,3.031,1.407,4.8,0.959
	c2.246-0.568,2.895-2.728,5.76-3.119c0.858,0.976,3.753,5.986,4.32,6.24c1.04,0,2.08,0,3.12,0c1.592,0.949,4.174,0.605,6,1.199
	c4.375,1.424,8.784,2.268,13.92,3.359c2.005,0.426,5.184-0.438,6.72-0.959c0.626,0.287,1.468,1.146,2.16,1.439
	c2.158,0.914,7.275,0.813,7.68,3.357c-0.6,0.752-0.673,1.787-1.199,2.643c-1.113,1.801-2.97,3.504-3.601,5.76
	c-0.915,3.27,0.833,6.617,1.681,8.641c0.317,0.76,1.414,2.607,0.959,3.359C286.57,451.22,286.601,451.185,286.107,451.281z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 82.3545 193.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Ouslatia
        </text>
        <text
          transform='matrix(1 0 0 1 38.3545 277.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Alaa
        </text>
        <text
          transform='matrix(1 0 0 1 73.3545 354.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hajeb{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 73.3545 369.3237)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Ayoun
        </text>
        <text
          transform='matrix(1 0 0 1 137.3545 395.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nasrallah
        </text>
        <text
          transform='matrix(1 0 0 1 210.3545 377.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bouhajla
        </text>
        <text
          transform='matrix(1 0 0 1 219.3545 443.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Echrarda
        </text>
        <text
          transform='matrix(1 0 0 1 229.3545 293.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kairouan Sud
        </text>
        <text
          transform='matrix(1 0 0 1 208.3545 247.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kairouan Nord
        </text>
        <text
          transform='matrix(1 0 0 1 195.3545 173.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sbikha
        </text>
        <text
          transform='matrix(1 0 0 1 167.3545 286.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Chebika
        </text>
        <text
          transform='matrix(1 0 0 1 116.3545 268.9233)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Haffouz
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Kairouan;
