import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    csvData: [],
    fileName: null,
    groupedDataState: [],
    modalData: null,
    icon: null,
    title: null,
    isModalOpen: false,

}

export const firstMapSlice = createSlice({
    name: 'firstMap',
    initialState,
    reducers: {
        setCsvData: (state, action) => {
            state.csvData = action?.payload
        },
        setFileName: (state, action) => {
            state.fileName = action?.payload
        },
        setGroupedDataState: (state, action) => {
            state.groupedDataState = action?.payload
        },
        setModalData: (state, action) => {
            state.modalData = action?.payload
        },
        setIcon: (state, action) => {
            state.icon = action?.payload
        },
        setTitle: (state, action) => {
            state.title = action?.payload
        },
        setModalOpen: (state, action) => {
            state.isModalOpen = action?.payload
        }



    },
})

// Action creators are generated for each case reducer function
export const { setCsvData, setFileName, setGroupedDataState, setModalData, setIcon, setTitle, setModalOpen } = firstMapSlice.actions

export default firstMapSlice.reducer