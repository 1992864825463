import { configureStore } from '@reduxjs/toolkit'
import firstMapReducer from './firstMapSlice'
import secondMapReducer from './secondMapSlice'
export const store = configureStore({
    reducer: {
        firstMap: firstMapReducer,
        secondMap: secondMapReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})