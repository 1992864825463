import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Medenine = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='585.587px'
        height='548.995px'
        viewBox='0 0 585.587 548.995'
        enable-background='new 0 0 585.587 548.995'
      >
        <a xlinkTitle={`Houmet Essouk ${groupedData['Houmet Essouk']}`}>
          <path
            onClick={() => setSelectedVill('Houmet Essouk')}
            style={{ fill: getColorBasedOnCount(outputData['Houmet Essouk']) }}
            d='M233.04,107.573c-2.64,0-5.28,0-7.92,0c-1.319-0.396-2.274-0.931-4.32-0.96c-2.241,2.144-5.647,3.978-6,7.92
	c-3.809-0.353-10.066-2.344-13.44-3.6c-1.051-0.391-3.365-0.544-3.84-1.44c0-1.2,0-2.4,0-3.6c-0.853-1.369-0.734-11.216-0.72-13.92
	c1.654-0.98,3.431-2.09,5.521-2.64c3.881-1.021,8.291,0.43,11.039,1.44c5.687,2.089,12.327,4.594,19.921,3.12
	c0.134-0.385,0.192-0.577,0.239-1.2c-0.617-0.771-1.167-2.094-1.439-3.12c0.16,0,0.32,0,0.48,0c1.637,2.324,7.33,4.163,7.68,7.2
	c-0.911,1.178-1.294,2.685-2.16,3.84C236.326,102.951,233.566,103.898,233.04,107.573z'
          />
        </a>
        <a xlinkTitle={`Midoun ${groupedData['Midoun']}`}>
          <path
            onClick={() => setSelectedVill('Midoun')}
            style={{ fill: getColorBasedOnCount(outputData['Midoun']) }}
            d='M223.2,149.093c0.297-8.32-0.579-14.396-5.04-18.48c0.235-0.677,0.776-0.899,0.96-1.44
	c1.095-3.224-2.31-4.998-2.399-7.44c-0.08-2.143,2.13-2.852,2.159-6c-0.45-0.157-0.938-0.229-1.68-0.24
	c-0.341,0.23-0.786,0.234-1.44,0.24c-0.129-0.364-0.162-0.407-0.24-0.96c1.955-2.573,2.715-7.027,6.961-7.2
	c2.211,1.588,7.623,0.987,11.279,0.96c1.516-4.573,3.711-5.132,6-8.4c0.603-0.859,0.783-2.574,1.68-3.12c0,0.08,0,0.16,0,0.24
	c1.838,1.756,2.029,4.364,4.561,5.52c2.697,1.231,9.047,0.761,10.32,3.36c0.871,1.379,0.74,6.351,0.72,8.64
	c-4.59,2.982-12.388,8.384-14.399,13.68c-1.082,2.845,0.25,6.425-0.721,8.64c-0.16,0-0.32,0-0.48,0
	c-0.204-0.351-0.434-0.688-0.719-0.96c-0.129-2.171,0.355-4.11,0.239-6.24c-0.239-0.08-0.479-0.16-0.72-0.24
	c-0.682-0.644-1.674-0.46-2.641-0.24c-0.771,2.641-1.854,5.96-3.84,7.44c-2.578,1.921-6.285,3.404-8.16,6
	c-0.427,0.592-1.01,1.721-1.199,2.4c0,1.2,0,2.4,0,3.6c-0.16,0-0.32,0-0.48,0C223.662,149.033,223.693,148.998,223.2,149.093z'
          />
        </a>
        <a xlinkTitle={`Ajim ${groupedData['Ajim']}`}>
          <path
            onClick={() => setSelectedVill('Ajim')}
            style={{ fill: getColorBasedOnCount(outputData['Ajim']) }}
            d='M204.721,135.173c-7.145,0.112-7.994-4.249-9.601-9.6c-0.31-1.032-1.193-2.681-1.2-3.84
	c-0.004-0.732,1.596-2.376,1.92-3.12c1.022-2.346,0.758-5.227,1.68-7.92c3.224,1.226,7.006,2.284,10.561,3.36
	c1.84,0.4,3.68,0.8,5.52,1.2c0.441,0.217,0.571,0.995,0.961,1.2c1.024,0.54,2.739-0.222,3.359,0.72
	c-4.295,4.966,0.063,6.351,0.24,11.76c-0.497,0.308-0.553,0.499-1.2,0.72c-1.161-1.656-4.292-3.298-6.96-3.36
	c-0.854,2.03-3.686,4.228-5.279,5.76C204.721,133.093,204.721,134.133,204.721,135.173z'
          />{' '}
        </a>
        <a xlinkTitle={`Sidi Makhlouf ${groupedData['Sidi Makhlouf']}`}>
          <path
            onClick={() => setSelectedVill('Sidi Makhlouf')}
            style={{ fill: getColorBasedOnCount(outputData['Sidi Makhlouf']) }}
            d='M188.16,135.173c3.098-0.112,6.219,0.651,6.96,2.88c2.131,2.247,0.165,6.584-0.479,8.88
	c-0.08,0-0.16,0-0.24,0c-1.172-1.13-1.646-0.02-2.881-0.24c-0.58-0.579-0.209-0.472-1.439-0.48c-0.561,0.4-1.12,0.8-1.68,1.2
	c-0.589,0.792-0.631,1.801-1.2,2.64c-2.56,2.479-5.12,4.96-7.681,7.44c-0.918,0.931-1.285,3.498-2.399,3.84
	c-0.311-0.21-0.593-0.214-1.2-0.24c-0.406,0.95-0.302,1.036-0.24,2.4c-1.412,0.712-4.202,1.349-4.8,2.88
	c-0.452,0.489-0.479,0.92-0.479,1.92c1.98,2.549,1.486,3.342,5.52,4.08c0.768,1.67,1.5,3.467,1.68,5.76
	c-0.861,0.91-1.399,2.112-2.16,3.12c-1.391,1.845-4.106,3.952-6,5.28c-1.846,1.295-4.98,2.233-6.239,4.08
	c-0.8,1.84-1.6,3.68-2.4,5.52c-0.854,1.305-2.448,2.136-3.36,3.36c-1.009,1.354-2.021,2.912-2.88,4.32
	c-0.985,1.616-1.449,4.653-3.12,5.52c-0.73,0.477-2.443,0.544-3.36,0.72c-0.462-0.652-0.783-0.495-1.44-0.96
	c-1.471-1.042-1.556-2.388-3.6-3.36c-0.289-4.33-4.16-10.905-7.44-12.24c-1.533-0.624-3.59,0.479-4.56,0
	c-3.588-1.77-4.686-6.234-6.96-9.36c-0.779-1.071-2.13-1.486-2.64-2.88c2.693-3.5,2.715-10.438,5.04-14.4
	c0.583-0.993,1.801-2.88,2.88-3.36c1.074-0.477,2.496-0.046,3.12-0.96c0.939-1.423,0.964-3.274,1.92-4.56
	c1.759-2.366,5.273-3.532,7.68-5.28c1.073-0.779,1.283-2.07,2.88-2.4c1.976,1.957,10.219-0.442,12.96-1.2
	c6.212-1.717,11.705-2.692,17.04-5.04c4.085-1.798,6.481-5.048,9.84-7.44C185.754,135.934,187.188,135.878,188.16,135.173z'
          />{' '}
        </a>
        <a xlinkTitle={`Medenine Sud ${groupedData['Medenine Sud']}`}>
          <path
            onClick={() => setSelectedVill('Medenine Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Medenine Sud']) }}
            d='M189.6,185.813c4.652-0.025,6.931,2.249,10.32,3.36c1.883,0.617,3.498,0.26,5.52,0.24
	c0.007,2.139-1.156,3.801-0.719,5.76c0.101,0.455,0.399,1.908,0.719,2.4c0.24,0.08,0.48,0.16,0.721,0.24
	c0.505,1.225-0.992,2.848-1.2,3.84c0.16,1.44,0.32,2.88,0.479,4.32c-0.224,0.724-1.141,1.458-1.439,2.16
	c-0.723,1.695-0.723,3.411-1.439,5.04c-0.453,1.027-1.482,2.055-1.92,3.12c-0.32,1.84-0.641,3.681-0.961,5.52
	c-0.904,1.77-2.838,1.982-3.119,4.56c3.046,3.038-1.551,8.124-2.881,10.8c-5.041-0.073-7.086,1.822-11.76,1.92
	c-1.82,2.983-4.255,10.017-7.199,11.52c-1.518,0.774-3.695,0.365-5.041,0.72c0,0.24,0,0.48,0,0.72
	c-6.479,0.048-11.941-1.144-18.479-1.2c-0.493-1.23-0.608-3.04-2.4-3.12c-1.494,1.058-3.87,1.945-5.76,2.4
	c0.184-1.904,0.765-4.037,1.2-5.76c0.24-2.08,0.48-4.16,0.72-6.24c1.104-4.487,1.954-9.638,2.88-13.92c-0.08-1.6-0.16-3.2-0.24-4.8
	c0.376-1.476,0.805-3.313,1.44-4.8c0.815-1.908,2.474-3.043,3.6-4.56c1.49-2.005,2.034-4.582,3.36-6.72
	c1.371-2.211,3.577-3.751,5.04-5.76c1.373-1.885,1.724-4.542,3.12-6.48c0.979-1.359,6.236-4.029,7.92-5.28
	c2.654-1.971,4.16-4.917,6.48-7.2c0.067-2.466-0.627-6.282-2.4-6.96c-1.997-1.635-4.297-0.406-4.801-4.32
	c0.304-0.326,0.328-0.424,0.48-0.96c2.037-0.521,4.844-1.3,4.801-4.08c0.56,0,1.119,0,1.68,0c0.481-0.876,0.46-2.215,0.96-3.12
	c1.6-1.52,3.2-3.04,4.8-4.56c2.295-2.294,4.018-5.064,6.24-7.439c0.511,0.163,0.696,0.645,0.96,0.72
	c1.011,0.289,1.11-0.787,1.92-0.48c0.711,0.199,0.655,0.106,0.96,0.72c-0.547,0.958,0.227,3.625,0.48,4.8
	c0.424,1.971-0.425,6.44-0.961,7.92c-0.99,2.737-3.092,5.851-5.279,7.44c-2.238,1.627-3.591,1.219-3.601,5.28
	c-0.181,0.258-0.144,0.227-0.239,0.72C188.148,176.093,188.99,181.176,189.6,185.813z'
          />{' '}
        </a>
        <a xlinkTitle={`Zarzis ${groupedData['Zarzis']}`}>
          <path
            onClick={() => setSelectedVill('Zarzis')}
            style={{ fill: getColorBasedOnCount(outputData['Zarzis']) }}
            d='M271.68,214.133c1.355-0.076,2.398-0.871,3.84-0.48c3.488,0.946,5.951,5.37,7.44,8.4
	c3.187,0.296,8.274,1.739,9.84,3.84c0.08,0,0.16,0,0.24,0c0,0.08,0,0.16,0,0.24c-0.72,0-1.44,0-2.16,0
	c-0.806-0.522-2.152-0.377-3.12-0.72c-3.025-1.074-9.139-3.681-12.96-1.44c-0.522,0.307-2.132,1.378-2.399,1.92
	c-0.748,1.514,0.136,2.451-1.44,3.12c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.239,0c0-0.08,0-0.16,0-0.24
	c-1.704-0.447-2.158-1.623-4.08-1.92c-1.022,0.865-2.08,1.867-3.841,1.92c-1.03-0.706-2.366-0.679-3.6-1.2
	c-2.261-0.955-4.734-2.317-7.2-3.12c-2.479-0.48-4.96-0.96-7.439-1.44c-4.165-0.935-6.782,2.024-10.561,0.96
	c-2.503-0.705-4.137-3.091-6.48-4.08c-2.841-1.2-7.338-1.22-10.319-1.92c-2.42-5.073-7.823-6.122-12.479-8.88
	c0.455-0.992,1.377-2.157,1.68-3.12c0.494-1.569-0.697-2.981-0.48-4.32c0.213-1.306,1.652-2.666,1.439-4.08
	c-0.109-0.729-0.933-0.754-1.199-1.2c-0.521-0.871-0.184-6.624,0.48-7.68c0.924-2.449,4.216-3.86,6.479-5.04
	c6.4-3.338,13.644-4.976,19.2-9.12c1.666-1.243,6.158-5.994,5.279-9.36c-0.666-2.557-3.025-5.142-4.8-6.72
	c-0.802-0.713-1.493-0.778-1.92-1.92c0.947-1.027,1.462-2.581,2.64-3.36c4.164-2.753,8.563,1.185,12.961,1.2
	c2.443,0.009,7.921-1.783,9.6-0.72c2.043,0.747,3.795,3.627,5.04,5.28c3.538,4.694,6.804,7.973,8.88,14.4
	c0.596,1.847,0.255,3.352,0.721,5.52c0.223,1.037,0.352,2.896,0,4.32c-1.036,4.186-5.742,8.375-4.32,14.16
	C267.82,203.353,271.582,206.779,271.68,214.133z'
          />{' '}
        </a>
        <a xlinkTitle={`Medenine Nord ${groupedData['Medenine Nord']}`}>
          <path
            onClick={() => setSelectedVill('Medenine Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Medenine Nord']) }}
            d='M120.72,182.693c4.883,1.81,5.037,11.597,11.52,12c2.135-1.585,5.085,0.901,6,2.16
	c1.34,1.845,2.552,4.503,3.36,6.72c0.337,0.925,0.009,1.792,0.48,2.64c0.269,0.483,0.982,0.396,1.44,0.72
	c1.849,1.31,2.262,3.851,5.28,4.08c0.34-0.23,0.786-0.234,1.44-0.24c0,0.08,0,0.16,0,0.24c-1.859,1.847-2.897,5.228-3.6,8.16
	c-0.38,1.585,0.453,3.587,0.24,4.8c-0.572,3.259-1.57,7.365-2.4,10.8c-1.566,6.481-1.201,12.823-3.84,18c-0.08,0-0.16,0-0.24,0
	c-0.71-7.159-5.273-15.778-8.4-20.88c-1.432-2.336-2.509-5.546-4.8-6.96c-0.935-0.577-2.703-0.169-3.6-0.72
	c-1.026-1.233-0.435-2.182-2.88-2.16c-0.34,0.23-0.786,0.234-1.44,0.24c-0.837-1.092-4.195-4.498-5.28-5.28
	c-0.801-0.577-1.804-0.627-2.64-1.2c-2.597-1.778-3.567-6.174-5.28-8.88c-0.694-1.096-2.141-1.907-2.64-3.12
	c2.52-0.423,3.419-2.476,4.56-4.32c1.761-2.846,3.298-5.999,5.28-8.64C115.462,187.945,119.473,186.566,120.72,182.693z'
          />{' '}
        </a>
        <a xlinkTitle={`Beni Khedech ${groupedData['Beni Khedech']}`}>
          <path
            onClick={() => setSelectedVill('Beni Khedech')}
            style={{ fill: getColorBasedOnCount(outputData['Beni Khedech']) }}
            d='M35.04,289.252c-0.692-10.829,1.817-21.684,2.64-31.92c-1.49-0.922-2.764-1.921-4.8-2.4
	c-4.567-1.075-8.226,1.967-12.72,0.96c-7.383-1.655-10.898-5.677-15.6-9.84c-2.103-1.862-4.278-3.009-4.56-6.72
	c0.16,0,0.32,0,0.48,0c1.779-0.956,6.365,1.742,9.6,0.96c3.991-0.965,5.651-4.199,9.36-5.28c4.584-1.336,9.272,2.376,13.44,1.2
	c1.36-0.72,2.72-1.44,4.08-2.16c1.921-0.437,3.305,1.729,5.52,1.2c2.281-0.544,4.028-2.125,6-2.88c1.747-0.668,5.76,0.72,5.76,0.72
	c1.134-0.359,1.949-1.349,2.88-1.92c1.464-0.898,2.928-1.2,4.56-1.92c2.14-0.944,4.294-2.458,6.24-3.6
	c2.131-1.25,4.447-1.027,4.56-4.32c-1.586-2.09-0.673-5.078,1.44-6c0.96-0.08,1.92-0.16,2.88-0.24c1.196-0.412,1.239-1.67,2.88-1.92
	c-0.083-6.749,3.658-7.443,8.4-9.36c1.308-0.529,3.789-2.125,5.76-1.68c2.005,0.453,3.521,2.028,5.28,2.64
	c1.33,0.462,2.388-0.323,3.12,0c3.183,1.177,4.071,5.634,5.76,8.4c1.749,2.865,2.731,2.75,5.28,4.56
	c3.094,2.196,3.384,5.665,8.64,5.28c0.345,0.977,0.422,1.792,1.44,2.16c0.838,0.553,2.476,0.174,3.36,0.72
	c2.425,1.497,3.533,5.199,5.04,7.68c3.055,5.03,7.068,12.802,7.68,19.92c-3.787,1.618-7.426,3.458-10.56,5.76
	c-2.528,1.857-4.653,4.66-7.68,6c-5.811,2.572-13.431,2.338-18.48,5.52c-4.836,3.047-7.801,8.281-12.72,11.28
	c-1.411,0.86-6.363,2.465-8.88,1.92c-1.536-0.332-4.599-1.345-6.48-0.72c-1.923,0.639-3.492,3.634-4.8,5.04
	c-7.839,0-15.681,0-23.52,0c-0.322,0.443-0.89,0.552-1.2,0.96c-0.58,0.762-0.73,2.026-1.44,2.64
	C41.461,293.811,37.962,289.379,35.04,289.252z'
          />
        </a>
        <a xlinkTitle={`Ben Guerdane ${groupedData['Ben Guerdane']}`}>
          <path
            onClick={() => setSelectedVill('Ben Guerdane')}
            style={{ fill: getColorBasedOnCount(outputData['Ben Guerdane']) }}
            d='M337.68,250.132c-3.328-0.018-6.332-1.069-6.72-4.08c0.862-0.18,1.042-0.893,1.92-0.72
	c2.937,0.578,6.952,3.568,9.36,5.04c5.188,3.17,10.59,4.728,18.96,4.8c0.25,0.847,0.474,1.328,0.479,2.64
	c-0.479,2.479-0.959,4.96-1.439,7.44c-2.063,6.28-4.163,12.055-5.76,19.2c-0.544,2.432-0.106,6.311,0.479,8.161
	c0.466,1.469,1.896,3.529,1.2,6c-0.512,1.813-1.775,3.869-2.4,5.76c-1.177,3.564-1.615,6.912-2.88,10.32
	c-0.673,1.814-1.935,3.4-2.159,5.52c3.478,2.746,3.506,12.117,2.159,17.52c-2.052,8.236-0.788,18.289,0.96,26.4
	c1.125,5.215-2.682,8.891-1.439,14.16c0.543,2.307,2.744,7.723,4.32,8.879c2.527,1.857,6.08,2.471,8.639,4.32
	c2.007,1.451,3.17,4.389,4.561,6.48c4.354,6.545,7.605,11.713,7.68,22.799c-1.531,1.029-2.729,2.85-3.84,4.32
	c-2.354,3.117-5.099,5.254-6.96,9.121c-4.287,0.172-12.29,4.523-14.159,6.959c-1.076,1.402-2.096,3.135-3.601,4.08
	c-2.85,1.789-7.274,1.617-10.08,3.359c-2.915,1.813-4.94,4.963-7.92,6.721c-6.175,3.646-14.313,5.047-21.601,7.439
	c-4.635,1.523-9.695,3.287-14.159,5.041c-0.883,0.346-4.926,1.777-5.04,1.68c-1.754-1.92,0.566-4.967,1.199-6.721
	c0.08-0.879,0.16-1.76,0.24-2.639c0.402-0.525,2.524-0.641,3.12-0.961c1.06-0.568,1.921-1.871,2.88-2.4c2-0.398,4-0.799,6-1.199
	c6.943-2.908,11.286-9.264,17.521-12.961c0-0.078,0-0.158,0-0.238c-6.245-0.826-11.771,0.582-17.761,0.719
	c-6.227-16.475-14.361-31.859-21.359-47.76c-2.117-4.811-4.715-9.859-7.2-14.639c-1.085-2.09-2.909-4.156-3.12-6.961
	c0.867-1.111,0.936-2.852,1.68-4.08c2.021-3.332,4.277-6.053,5.281-10.561c0.795-3.574-2.483-11.408-3.361-14.158
	c-0.783-2.459-1.986-8.717-0.959-11.76c0.31-0.92,1.025-1.156,1.199-2.4c-0.514-0.68-0.495-1.609-0.959-2.4
	c-1.668-2.838-3.648-5.379-4.801-8.881c-1.539-4.68-1.514-9.939-3.359-14.16c-1.424-3.252-4.222-4.16-5.76-6.479
	c-0.809-1.219-0.178-3.23-0.721-4.801c-0.579-1.675-2.371-2.773-3.6-3.84c-0.24-3.04-0.48-6.081-0.721-9.12
	c-5.951,0.465-10.965-0.831-16.08-2.16c-1.949-0.507-5.652-1.398-8.399-0.72c-1.601,0.395-3.224,1.501-4.8,1.92
	c-2.639,0.701-5.824-0.688-7.68-1.2c-1.789-0.494-3.203-0.234-5.041-0.72c-2.262-0.599-4.846-1.371-7.199-1.92
	c-3.451-0.805-10.027,0.128-12.721,0.72c-3.143,0.69-13.217,0.671-16.32,0c-3.525-0.762-7.239,0.475-10.319-0.48
	c-2.849-0.883-4.834-4.057-6.48-6.24c-1.285-1.704-2.771-2.202-3.12-5.04c0.26-0.218,0.021,0.019,0.24-0.24
	c1.289-0.136,2.31-0.693,3.36-0.959c1.179-0.299,2.833,0.257,3.36,0c2.486-1.761,3.941-5.219,5.76-7.68
	c1.01-1.368,2.539-1.978,2.88-4.08c7.226-0.276,8.818-7.301,11.28-12.24c3.11,0.024,4.884-1.036,7.199-1.68
	c1.68-0.08,3.361-0.16,5.041-0.24c0.416-3.173,2.961-4.864,3.84-7.68c0.729-2.339-0.789-3.522-0.721-5.04
	c1.02-0.989,2.616-2.433,3.12-3.84c0.16-1.44,0.32-2.88,0.479-4.32c0.254-0.765,2.879-6.198,3.361-6.72
	c2.34,2.172,6.523,2.695,9.119,4.56c1.387,0.996,1.715,3.432,3.36,4.08c2.249,1.525,6.662,0.69,9.36,1.68
	c2.615,0.959,4.354,3.486,6.959,4.32c2.477,0.792,5.35-0.572,6.961-0.959c2.113-0.508,4.975,0.037,6.72,0.48
	c2.9,0.735,5.802,1.28,8.399,2.4c2.686,1.157,5.32,2.807,8.881,3.12c0.516-0.835,1.241-1.706,2.399-1.92
	c1.349-0.077,2.688,2.363,4.8,1.68c0.227,4.254,7.47,6.64,9.12,9.84c1.415,2.742,0.53,9.192,4.32,9.36
	c0.485-0.31,8.405-1.373,9.36-0.96c0.825,0.357,0.59,0.875,2.16,0.96c1.607-1.272,5.139-1.411,7.439-0.72
	c4.611,1.386,8.786,3.372,13.68,4.56c4.384,1.064,7.298-1.201,10.561-1.68c1.32,2.022,4.116,2.567,7.199,2.16
	c0.566-0.751,1.469-1.735,2.16-2.4C337.68,250.772,337.68,250.453,337.68,250.132z'
          />
        </a>

        <text
          transform='matrix(1 0 0 1 273.9834 322.1255)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ben Gardane
        </text>
        <text
          transform='matrix(1 0 0 1 45.3433 257.1353)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Beni Khedech
        </text>
        <text
          transform='matrix(1 0 0 1 63.1489 211.5137)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Médenine Nord
        </text>
        <text
          transform='matrix(1 0 0 1 156.0713 225.4839)'
          enable-background='new    '
        >
          <tspan x='0' y='0' font-family="'MyriadPro-Regular'" font-size='12'>
            Médenine{' '}
          </tspan>
          <tspan
            x='0'
            y='14.4'
            font-family="'MyriadPro-Regular'"
            font-size='12'
          >
            Sud
          </tspan>
        </text>
        <text
          transform='matrix(1 0 0 1 226.2646 200.3003)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Zarzis
        </text>
        <text
          transform='matrix(1 0 0 1 98.4307 177.1152)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi Makhlouf
        </text>
        <text
          transform='matrix(1 0 0 1 176.8877 94.0176)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Djerba-Houmet Souk
        </text>
        <text
          transform='matrix(1 0 0 1 231.1592 120.1338)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Djerba-Midoun
        </text>
        <text
          transform='matrix(1 0 0 1 153.0625 124.9883)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Djerba-Ajim
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Medenine;
