import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Jendouba = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='592.906px'
        height='519.007px'
        viewBox='0 0 592.906 519.007'
        enable-background='new 0 0 592.906 519.007'
      >
        <a xlinkTitle={`Tabarka ${groupedData['Tabarka']}`}>
          <path
            onClick={() => setSelectedVill('Tabarka')}
            style={{ fill: getColorBasedOnCount(outputData['Tabarka']) }}
            d='M233.875,162.482c-0.018-1.1,0.077-2.088,0.24-2.88c0.73-0.018,0.659-0.181,0.96,0
	c0.962,0.887,0.944,3.776,1.44,5.04c0.422,0.26,0.868,0.604,1.2,0.96c5.98,0.076,16.252-3.218,18-7.2
	c0.976,0.114,1.258,0.168,2.16,0c0.893-3.341,5.31-3.66,7.92-5.28c5.333-3.31,9.236-8.279,14.16-12c2.287-1.728,4.067-4.963,6.96-6
	c0.283,0.192,0.403,0.186,0.96,0.24c2.851,4.6,8.667,6.751,10.32,12.48c1.309,4.531-1.805,12.82,2.399,14.88
	c1.498,1.315,4.829-0.319,6.721,0c0.079,0.24,0.159,0.48,0.239,0.72c0.729,1.13-0.033,3.428-0.479,4.32
	c-1.538,3.077-3.939,4.126-4.079,8.88c1.244,0.926,1.87,2.667,3.119,3.6c2.834,2.117,6.539,3.708,10.08,5.04
	c2.115,0.796,8.281,1.964,9.119,3.6c0.571,0.928,0.01,3.746,0.24,5.04c0.591,0.368,0.546,0.55,1.44,0.72
	c0.311-0.21,0.592-0.215,1.199-0.24c0.665,2.103,2.316,3.659,2.88,6c0.704,2.929-1.614,4.548-2.159,6.24
	c-0.754,2.333,1.401,4.282,1.68,6.24c-2.827,2.674-5.063,5.997-9.12,7.44c-3.622,1.289-6.736-1.272-9.841,0.96
	c-1.949,1.403-4.041,4.42-5.039,6.72c-0.567,1.31-0.643,2.558-1.439,3.6c-1.092,1.427-3.444,1.863-4.561,3.36
	c-0.933,1.251-1.834,4.254-3.6,4.56c-1.453-1.117-3.714-1.183-5.521-1.92c-3.9-1.592-8.938-3.782-11.04-7.2
	c-1.336-2.171-0.812-5.287-0.24-7.92c1.157-5.334-1.443-12.535-4.08-14.16c-1.626-1.001-3.323-0.717-5.52-1.2
	c-1.621-0.356-4.046-0.237-5.76,0.24c-3.417,0.951-7.26,2.958-9.12,5.52c-1.084,1.495-1.4,3.471-3.36,4.08
	c-2.699,0.838-6.43-5.305-10.56-2.64c-1.199,0.411-2.097,1.535-3.12,2.16c-1.324,0.809-2.853,1.034-4.08,1.92
	c-1.134,0.819-1.657,2.708-2.88,3.36c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c0-0.08,0-0.16,0-0.24
	c-1.566-0.547-2.161-2.213-3.36-3.12c-2.63-1.99-5.867-4.192-7.68-6.96c-0.48,0.16-0.96,0.32-1.44,0.48
	c-0.624,1.145-1.104,2.867-1.68,4.08c-0.421-0.152-0.233-0.046-0.48-0.24c-3.277-0.749-4.849-3.796-7.2-5.52
	c-1.127-0.827-2.529-1.313-3.6-2.16c2.771-4.298,6.71-8.023,6.96-14.88c-0.56-0.16-1.12-0.32-1.68-0.48
	c-1.695-1.086-1.086-3.694-3.84-3.84c-0.862,0.821-2.054,1.304-2.88,2.16c-1.706-0.729-4.659-1.536-5.28-3.36
	c-0.549-0.741,0.046-2.142-0.48-2.64c-1.592-1.504-5.949-0.342-7.68-1.68c-2.372-3.14-3.359-5.785-3.36-11.52
	c1.945-1.145,3.671-2.528,5.52-3.6c1.12-0.32,2.24-0.64,3.36-0.96c0.677-0.482,0.501-1.95,1.2-2.4c1.52-0.48,3.04-0.96,4.56-1.44
	c1.2-0.72,2.4-1.44,3.6-2.16c1.941-0.78,3.754,0.791,5.76,0c1.617-0.637,1.916-2.483,3.84-2.88c0.609,1.374,1.042,2.632,3.12,2.64
	c0.64-0.811,9.181-3.989,9.84-3.6c1.46,0.854,1.45,2.236,2.4,3.6c0.56,0.4,1.12,0.8,1.68,1.2
	C233.076,162.482,233.476,162.482,233.875,162.482z'
          />{' '}
        </a>
        <a xlinkTitle={`Ain Draham ${groupedData['Ain Draham']}`}>
          <path
            onClick={() => setSelectedVill('Ain Draham')}
            style={{ fill: getColorBasedOnCount(outputData['Ain Draham']) }}
            d='M227.875,267.601c-6.288-0.747-9.873,2.131-12.48,5.76c-1.11,1.547-2.215,4.34-4.32,4.801
	c-1.148-1.091-4.704-0.471-6,0.479c-0.25,0.407-0.017,1.062-0.24,1.681c-0.21,0.582-0.994,1.225-1.2,1.92c0,0.639,0,1.279,0,1.92
	c-0.25,0.387-1.954,1.475-2.4,1.92c-2.724,2.724-5.891,5.779-7.44,9.601c-1.296,3.194,1.155,8.181-2.88,8.64
	c-1.025-1.234-2.903-1.535-4.32-2.398c-2.811-1.717-6.183-3.922-7.92-6.721c-0.974-1.568-1.452-3.51-1.44-6
	c0.006-1.379,0.8-3.799,0.48-4.32c-1.009-3.381-5.663-2.967-5.76-7.199c1.118-1.432,1.862-3.895,1.92-6.24
	c-1.438-0.023-2.154,0.225-3.12,0.48c-1.151,2.465-1.247,5.914-3.36,7.439c-1.689,1.221-5.506,1.747-6.48,3.6
	c-1.031,1.363-1.215,3.383-2.16,4.801c-2.654,3.986-11.75,9.396-19.68,7.199c-2.132-0.59-4.482-1.309-6.24-2.399
	c-0.615-0.382-0.868-0.911-1.68-1.2c-0.919,1.616-4.804,3.643-6.72,2.641c-2.976-3.303-5.37-12.178-6.72-17.279
	c-0.576-2.178,0.048-5.83-1.2-7.439c-0.922,0.682-2.237,0.424-3.6,0.721c-5.254,1.144-10.124-0.424-10.08-5.521
	c2.588-1.652,5.328-3.671,7.92-5.28c3.523-2.186,8.191-3.574,12.24-5.04c2.395-0.867,5.319,0.147,8.16-0.48
	c1.991-0.439,5.678-1.066,8.16-0.48c1.455,0.344,3.357,0.704,4.56,1.44c0.998,0.611,1.515,1.634,2.88,1.92
	c0.587-0.393,4.628-2.019,5.28-2.16c3.095-0.668,4.498,2.671,8.4,0.24c4.599-2.865,5.236-10.364,11.76-11.28
	c1.241,1.112,2.357,2.818,4.56,2.88c1.492-1.892,3.89-2.777,6-4.08c7.878-4.866,18.817-8.138,20.16-19.439
	c-4.655-2.575-10.064-4.539-15.36-6.72c-1.843-0.759-5.03-1.079-5.76-2.88c1.259-2.122-0.391-8.4,0.24-11.28
	c0.89-4.063,0.557-8.09,2.4-11.04c0.887-1.42,2.414-2.23,3.6-3.36c0.922-0.878,1.455-2.552,2.88-2.88
	c1.493,1.267,5.083,0.283,6.72,1.44c0.456,0.768,0.238,2.138,0.72,2.88c0.478,0.737,5.188,3.667,6.479,3.36
	c0.941-0.223,2.27-1.492,2.88-2.16c0.421,0.151,0.233,0.046,0.48,0.24c0.769,0.612,0.853,1.596,1.44,2.4
	c0.612,0.837,1.905,0.959,2.4,1.92c-1.094,1.603-1.144,4.219-1.92,6c-0.995,2.282-4.762,5.871-5.04,7.92
	c0.303,0.326,0.328,0.424,0.48,0.96c4.224,1.896,7.695,7.418,12.24,7.68c0.383-0.707,1.583-3.779,2.16-4.08c0-0.08,0-0.16,0-0.24
	c0.08,0,0.16,0,0.24,0c0,0.08,0,0.16,0,0.24c2.552,1.701,5.031,4.72,7.68,6.72c1.424,1.076,1.757,2.882,4.08,3.12
	c0.283-0.193,0.404-0.186,0.96-0.24c1.103-4.484,6.629-4.799,9.36-7.68c2.171-0.088,4.165-0.115,5.76,0.96
	c1.156,0.779,1.96,2.819,4.8,1.92c2.281-0.722,2.452-2.976,3.84-4.56c3.395-3.874,16.198-8.977,20.64-2.4
	c3.896,5.768-0.921,16.487,3.12,21.12c2.678,3.07,7.241,5.068,11.28,6.72c1.636,0.669,4.999,0.666,5.521,2.4
	c-0.266,0.333-0.358,0.69-0.479,1.2c1.634,0.104,2.988,0.006,5.039,0c0.135,0.385,0.192,0.577,0.24,1.2
	c-0.372,0.694,0.162,1.289-0.24,2.4c-0.307,0.849-3.248,4.018-4.08,4.32c-1.675-1.388-11.201-1.92-12.239,0.24c0,0.8,0,1.6,0,2.4
	c-0.479,0.723-0.365,1.769-0.72,2.64c-0.538,1.319-1.341,2.907-2.16,4.08c-1.836,2.629-4.457,5.174-6.72,7.439
	c-1.266,1.269-2.998,2.156-3.12,4.561c0.212,0.322,0.351,1.307,0.48,1.68c1.99-0.063,3.599,0.473,4.08,1.92
	c0.21,0.312,0.215,0.592,0.24,1.201c-0.424,0.23-0.403,0.301-0.96,0.479c-2.708,1.976-7.402-0.814-10.8,0
	c-5.048,1.211-5.785,6.1-5.04,12.24c-0.16,0-0.32,0-0.48,0c-1.981-1.305-4.816,0.367-7.44-0.24c-1.128-0.262-2.269-1.217-3.12-1.439
	c-1.197-0.313-4.57,1.754-6.479,1.92c-0.447-0.697-1.222-1.052-1.68-1.68c-2.411-3.307-2.928-6.326-8.88-6.24
	C230.223,277.679,228.027,273.949,227.875,267.601z'
          />{' '}
        </a>
        <a xlinkTitle={`Balta Bou Aouene ${groupedData['Balta Bou Aouene']}`}>
          <path
            onClick={() => setSelectedVill('Balta Bou Aouene')}
            style={{
              fill: getColorBasedOnCount(outputData['Balta Bou Aouene']),
            }}
            d='M297.955,251.281c0.059,1.729,0.797,3.387,2.16,3.84c1.557,0.959,4.141-1.462,6-1.68c0,0.24,0,0.48,0,0.72
	c0.742,0.564,1.158,1.649,1.92,2.16c1.707,1.144,4.2,0.838,6.479,1.44c2.492,0.658,5.076,1.342,7.681,1.92
	c4.063,0.9,8.885-1.222,12.721,0.48c1.916,0.85,7.352,5.349,8.159,7.199c0.16,2,0.319,4,0.479,6c0.425,1.574,1.675,3.162,2.642,4.32
	c-0.813,3.893-3.572,6.93-4.32,10.561c2.402,2.514,2.211,11.34,0.721,14.879c-0.967,2.295-2.975,3.242-3.12,6.48
	c-3.249,0.168-12.347,1.709-14.399,3.12c-0.625,0.429-0.921,1.368-1.44,1.92c-0.218,2.711,0.707,4.099,0.961,6.479
	c-0.545,0.693-0.668,1.682-1.199,2.399c-0.561,0.399-1.12,0.8-1.682,1.199c-1.332,0.038-1.914,0.368-3.358,0
	c-2.33-0.595-5.657-2.642-9.12-1.681c-1.407,0.392-2.108,1.617-3.602,1.92c-1.117-1.002-4.385-0.672-4.799-1.198
	c-0.791-1.354,0.021-2.315-2.16-2.399c-0.725,0.482-1.734,0.352-2.64,0.721c-1.975,0.803-4.186,1.766-6,2.879
	c-5.687,3.492-10.548,7.81-17.52,10.08c-3.553,1.158-6.195-1.447-9.6-0.24c-2.422,0.859-4.473,3.484-5.76,5.521
	c-0.551,0.871-0.503,1.672-1.44,2.159c0-0.159,0-0.319,0-0.479c-2.062-2.83-2.088-9.836-0.72-13.2c-1.04-1.119-2.08-2.24-3.12-3.359
	c-2.55-1.901-5.905-3.354-6-7.68c0.898-0.657,1.356-2.481,0.96-4.32c-0.728-3.377,0.078-7.489,0.72-10.079
	c1.657-0.938,3.712-1.746,5.28-2.881c1.664-1.203,2.512-3.475,4.32-4.561c1.648-0.989,4.135-0.965,5.04-2.641
	c0.878-1.623-0.484-3.756-0.72-5.279c-0.575-3.713,0.827-6.25,1.44-8.641c0.969-0.457,1.956-1.146,3.12-1.438
	c3.618-0.91,12.372,2.901,12.72-2.16c-1.391-1.826-1.53-3.332-4.8-3.358c-0.049-0.82-0.219-1.041,0-1.441
	c0.675-1.98,2.944-3.422,4.32-4.799c2.599-2.602,5.252-5.685,6.96-9.121c0.704-1.417,0.66-5.642,2.16-6.24
	C290.035,248.69,295.45,250.889,297.955,251.281z'
          />{' '}
        </a>
        <a xlinkTitle={`Fernana ${groupedData['Fernana']}`}>
          <path
            onClick={() => setSelectedVill('Fernana')}
            style={{ fill: getColorBasedOnCount(outputData['Fernana']) }}
            d='M226.916,268.322c0.121,6.73,2.752,9.896,5.04,14.159c6.007-0.065,6.227,2.437,8.64,5.761
	c0.8,0.719,1.6,1.439,2.4,2.159c2.559-0.085,3.905-1.295,5.76-1.68c1.271-0.265,2.558,0.942,3.6,1.2
	c3.273,0.811,5.801-0.643,8.64,0.479c0.134,0.385,0.192,0.577,0.24,1.2c0.923,2.051-2.959,2.647-4.32,3.358
	c-2.338,1.224-3.381,3.881-5.52,5.281c-1.488,0.973-3.818,1.198-4.8,2.639c-1.973,2.896-0.422,8.813-0.72,12.722
	c-0.118,1.547-0.942,1.938-0.96,4.08c0.924,1.019,1.275,2.553,2.16,3.601c0.775,0.916,2.133,1.436,3.12,2.159
	c1.35,0.99,2.385,2.463,3.6,3.601c-1.405,5.893,0.217,8.865,0.96,13.92c-5.235,1.996-8.783,5.775-13.68,7.92
	c-3.726,1.631-7.907,2.021-11.52,3.601c-2.919,1.274-5.455,3.384-8.16,4.8c0-1.039,0-2.08,0-3.119
	c-0.818-1.35-0.091-3.795-0.48-5.521c-0.32-1.439-0.64-2.879-0.96-4.318c-2.45-0.203-6.592-1.291-9.12-0.24
	c-0.451,0.188-1.169,0.979-1.68,1.199c-1.646,0.713-3.72,1.023-5.521,1.439c-4.341,1.004-9.666-0.287-12.96,1.681
	c-1.94,1.159-2.147,3.9-3.6,5.521c-1.04,0.08-2.08,0.16-3.12,0.24c-3.96-1.064-6.372-6.541-7.92-10.08
	c-1.01-2.311-1.555-5.688-4.8-5.76c-1.417,0.859-7.585,0.361-9.6,1.199c-1.261,0.523-2.882,1.783-3.6,2.881
	c-0.612,0.934-0.358,1.905-1.44,2.398c-0.283,0.193-0.403,0.186-0.96,0.24c-0.218-0.26,0.019-0.021-0.24-0.24
	c-0.169-1.729-0.351-4.33-0.72-5.76c-1.396-0.881-2.67-2.041-4.8-2.16c-0.978,1.285-2.646,2.005-3.6,3.359
	c-1.744,2.478-2.612,5.647-3.84,8.641c-0.88,0.16-1.76,0.32-2.64,0.48c-3.666-3.765-9.788-5.097-12.96-9.359
	c-0.893-1.201-2.822-6.26-3.84-6.721c-0.96,0-1.92,0-2.88,0c-2.241-1.289-8.268-0.385-8.88-3.121
	c2.177-2.383,3.962-15.552,4.56-19.92c5.143-1.654,10.108-9.64,10.08-16.8c1.587-0.174,5.331-2.756,6-2.64
	c0.301,0.052,1.148,1.025,1.44,1.199c1.76,0.641,3.52,1.279,5.28,1.92c6.967,1.836,13.633-1.276,17.28-3.84
	c1.758-1.235,3.537-3.326,4.56-5.279c0.543-1.038,0.771-3.17,1.68-3.842c2.555-1.887,6.128-2.006,7.92-4.799
	c1.344-2.096-0.247-5.352,2.88-5.76c0,0.079,0,0.159,0,0.239c-0.613,0.825-2.483,5.698-1.68,6.96c0.989,2.896,5.274,2.766,5.52,6.24
	c-0.758,1.107-0.612,4.344-0.24,5.76c1.607,6.121,4.927,8.336,9.84,11.04c1.158,0.638,4.087,3.134,5.28,2.64
	c4.088-1.215,1.789-6.154,3.36-9.84c1.421-3.332,4.526-6.209,6.96-8.64c0.96-0.8,1.92-1.601,2.88-2.4
	c-0.08-0.64-0.16-1.28-0.24-1.92c0.088-0.337,1.569-2.956,1.92-3.12c0.894-0.658,2.281-0.74,3.84-0.72
	c0.353,0.545,0.505,0.722,1.2,0.96c0.862-1.135,2.266-1.958,3.12-3.12C217.699,272.367,219.707,268.314,226.916,268.322z'
          />{' '}
        </a>
        <a xlinkTitle={`Bou Salem ${groupedData['Bou Salem']}`}>
          <path
            onClick={() => setSelectedVill('Bou Salem')}
            style={{ fill: getColorBasedOnCount(outputData['Bou Salem']) }}
            d='M301.795,398.4c-0.479,0.08-0.959,0.16-1.438,0.24c-0.194-1.248-0.32-2.021-1.439-2.398
	c-0.823-0.623-2.78-0.959-3.841-1.201c-1.242-2.201-2.665-4.496-3.36-7.199c-0.24-2.479-0.48-4.961-0.72-7.44
	c-0.515-2.028-1.083-3.239-0.96-5.521c0.24-0.08,0.48-0.159,0.72-0.239c0.441-0.298,1.369-0.261,2.16-0.239
	c-0.015-3.523-1.925-3.792-2.88-6c-0.643-1.486-0.37-3.165-0.96-4.561c-0.966-2.284-2.627-5.274-4.32-6.961
	c-0.751-0.748-2.006-1.063-2.64-1.92c-3.739-5.057-3.897-6.254-10.56-8.64c-1.506-3.046-2.858-4.688-2.88-9.601
	c0.08,0,0.16,0,0.24,0c0-0.079,0-0.159,0-0.239c7.146-0.11,11.87-4.224,16.32-7.2c4.459-2.982,8.541-4.904,14.161-6.96
	c0.169,1.16,0.264,2.427,1.199,2.88c0.844,0.395,1.664-0.693,3.119-0.24c0.939,0.293,1.756,1.135,2.881,1.44
	c1.494-4.341,8.465-1.512,11.521-0.72c1.834,0.475,2.953-0.074,4.561-0.24c1.293-1.666,2.754-2.555,3.119-5.279
	c-1.188-1.225-1.343-3.361-1.199-5.281c0.613-0.465,1.015-1.276,1.681-1.68c2.614-1.576,6.825-1.33,10.319-1.92
	c0.81-0.137,3.312-1.037,3.359-0.96c2.608,1.753,3.593,5.302,6,7.2c1.747,1.377,4.372,2.383,5.521,4.32
	c1.393,2.348,0.619,6.6,1.681,9.119c1.525,3.627,4.966,5.688,7.92,7.92c0.661,0.5,1.011,1.408,1.681,1.92
	c-2.546,5.426-1.586,12.986-0.24,19.199c0.892,4.112-1.193,6.785-1.68,9.36c0.399,0.56,0.799,1.12,1.199,1.681
	c1.501,1.526,2.881,1.612,2.881,4.801c-2.512,0.871-8.176,7.15-9.841,9.359c-1.073,1.426-2.956,2.481-3.601,4.319
	c2.157-0.129,3.151,0.005,3.12,2.16c-0.97,1.026-2.415,3.219-2.881,4.56c0.08,0,0.16,0,0.24,0c1.681,1.575,2.901,2.111,2.881,5.52
	c-1.307,0.9-2.756,2.74-3.601,4.08c-0.592,0.938-0.688,2.354-1.682,2.881c-2.01,1.68-6.33-0.061-9.358,0.721
	c-0.506,0.13-1.377,0.875-1.681,0.959c-0.513,0.145-0.729-0.447-0.961-0.479c-0.548-0.078-0.681,0.404-0.959,0.479
	c-1.852,0.504-4.211-0.482-5.76,0.24c-0.984,0.459-2.141,2.08-2.881,2.881c-2.096-0.107-2.881-1.033-4.561-1.439
	c-3.57-0.869-11.989,3.223-14.64,4.318c-0.19-1.844-1.949-3.227-2.641-4.799C304.354,407.593,302.49,403.182,301.795,398.4z'
          />{' '}
        </a>
        <a xlinkTitle={`Ghardimaou ${groupedData['Ghardimaou']}`}>
          <path
            onClick={() => setSelectedVill('Ghardimaou')}
            style={{ fill: getColorBasedOnCount(outputData['Ghardimaou']) }}
            d='M109.796,333.601c1.802,0.145,3.04,1.186,4.56,1.68c2.423,0.787,5.188,0.91,8.16,0.961
	c0.973,1.596,1.555,3.723,2.64,5.279c2.771,3.975,9.651,8.297,14.16,10.561c0,0.16,0,0.319,0,0.479
	c-0.985,0.473-1.338,1.561-2.16,2.16c-3.228,2.355-4.454,3.339-5.52,7.92c-0.874,3.757,0.337,8.13-0.96,11.281
	c-1.299,3.156-5.365,6.674-3.36,11.52c1.005,2.43,3.056,3.799,5.04,5.279c3.658,2.73,8.371,7.822,8.4,14.16
	c-0.414,0.145-0.756,0.213-1.44,0.24c-1.096-0.865-3.302-0.756-5.28-0.722c-0.942,2.617-1.613,4.485-4.08,5.521
	c-0.896,0.377-2.102,0.229-2.88,0.721c-0.297,0.441-0.261,1.369-0.24,2.16c0.772,1.258-0.119,3.846,0.24,5.52
	c0.731,3.411,0.456,8.631,0.24,12.48c-0.069,1.24-0.769,3.633-0.24,4.561c1.047,2.93,4.989,3.012,7.92,4.08
	c1.422,2.977,3.745,4.605,7.2,5.52c1.526,0.404,3.45-0.051,4.56,0.721c-4.069,0.518-5.933,4.404-9.12,6
	c-4.45,2.229-10.901,1.85-15.12,4.32c-4.865,2.85-4.636,5.684-7.68,9.84c-0.8,1.092-2.394,1.465-3.36,2.398
	c-2.585-1.035-9.074-2.607-13.2-1.68c-3.511,0.789-7.272,2.037-11.04,1.199c-0.491-0.777-0.717-0.967-0.72-2.398
	c0.859-0.9,3.115-3.996,3.36-5.281c-1.92-1.779-3.676-6.188-2.16-9.84c2.633-6.34,7.781-8.479,7.92-17.76
	c-0.838-0.488-1.79-1.623-2.64-1.92c-1.36-0.08-2.72-0.16-4.08-0.24c-2.705-0.746-5.375-1.879-7.44-3.359
	c-1.881-1.348-5.492-6.334-7.68-6.479c-2.787,2.485-4.052,5.151-8.4,6.239c-1.739,0.438-4.222-0.051-5.52-0.479
	c-5.062-1.674-16.658-9.773-22.08-2.879c-3.605-1.605-7.717-2.699-12-3.841c-2.4-0.399-4.8-0.802-7.2-1.201
	c-0.4-0.479-0.8-0.959-1.2-1.438c-1.243-1.178-3.146-2.982-4.8-3.601c-1.878-0.7-4.018,0.685-5.28-0.239
	c-1.646-1.511-6.649-7.836-7.2-10.08c-0.451-1.838,0.525-3.533,0.96-4.562c1.499-3.54,5.624-9.336,9.84-9.84
	c2.036-0.242,2.767,1.575,4.32,1.92c1.752-1.119,4.153-1.038,5.76-2.159c5.14-3.589,8.728-9.083,14.88-11.761
	c6.351-2.765,15.554-2.272,20.88-6c1.901-1.33,3.422-3.694,4.08-6.239c0.08-1.119,0.16-2.24,0.24-3.359
	c0.8-0.881,1.6-1.76,2.4-2.641c4.067-4.053,9.743-7.164,15.12-9.84c2.573-1.279,4.976-2.036,7.2-3.602
	c3.146-2.211,5.477-7.073,8.4-8.879c2.955-1.826,7.797-1.053,11.76-1.92C107.437,333.757,108.796,334.271,109.796,333.601z'
          />
        </a>
        <a xlinkTitle={`Jendouba Nord ${groupedData['Jendouba Nord']}`}>
          <path
            onClick={() => setSelectedVill('Jendouba Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Jendouba Nord']) }}
            d='M260.275,373.681c0.034,1.947-0.368,3.119-0.72,4.561c-0.548,0.293-0.541,0.393-1.44,0.479
	c-0.129-0.363-0.162-0.406-0.24-0.959c0.718-1.045,0.002-2.986-0.24-3.84c-0.56-0.4-1.12-0.801-1.68-1.201
	c-1.099-0.018-2.088,0.076-2.88,0.24c-1.211,1.83-0.852,1.981-0.48,4.319c-0.08,0-0.16,0-0.24,0
	c-0.767-0.562-2.089-0.706-3.36-0.721c-1.1,1.78-2.506,3.433-3.36,5.521c-1.313,3.215-1.354,6.854-2.4,10.319
	c-0.396,1.313-1.565,3.386-1.68,4.562c-0.099,1.006,0.899,1.674,0.96,3.119c-4.954,6.362-9.098,13.701-13.92,20.159
	c-1.72,2.306-4.006,5.513-7.92,5.521c-0.628-0.432-1.415-0.332-2.16-0.719c-1.124-0.582-2.293-1.74-3.36-2.4
	c-1.907-1.182-4.038-1.49-6.24-2.4c-3.037-1.254-6.477-3.051-10.08-3.6c0.032-2.438-0.704-3.141-0.96-4.801
	c1.084-0.992,4.792-2.986,5.04-4.318c0.341-1.832-3.689-5.785-4.56-6.961c-1.91-2.578-6.567-7.9-5.28-13.199
	c0.396-1.631,1.27-3.162,1.92-4.561c-1.031-0.663-2.463-1.76-3.36-2.641c-4.684-0.421-6.636,1.778-9.6,3.602
	c-5.003,3.074-10.858,4.631-15.84,7.68c-1.719,1.051-3.441,3.393-5.52,3.84c-1.155-1.367-5.354-1.793-5.76-2.4
	c0.843-1.365,0.273-5.973,0-7.438c-0.37-1.987,0.742-4.118,0.96-5.76c-4.67-2.906-5.217-6.153-5.04-13.201
	c-0.88-0.959-1.76-1.92-2.64-2.879c-1.309-0.953-2.792-1.797-4.08-2.642c2.21-5.489-3.371-5.438-3.84-8.64
	c1.04-0.239,2.08-0.479,3.12-0.72c0.439-3.379,2.562-7.246,4.32-9.6c0.88-0.722,1.76-1.441,2.64-2.16
	c1.173,0.326,3.195,1.216,3.6,2.398c0.642,1.036,0.499,4.452,0.72,5.521c0.588,0.203,1.484,0.248,2.4,0.24
	c0.185-0.164,0.536-0.315,0.72-0.48c0.861-0.969,0.934-2.766,1.92-3.6c2.73-2.313,8.013-2.338,12.72-2.642
	c0.724,1.541,1.847,3.034,2.64,4.562c1.961,3.77,2.879,7.057,6,9.6c1.231,1.002,4.2,2.748,6.48,1.199
	c2.158-0.783,1.427-3.863,3.12-5.039c2.81-1.951,6.965-0.791,11.04-1.68c1.919-0.32,3.84-0.642,5.76-0.961
	c1.337-0.435,2.637-1.5,3.84-1.92c2.232-0.777,5.837-0.148,7.92,0.239c0.476,4.31,1.163,8.597,1.2,13.681
	c2.187-0.332,6.396-4.195,8.88-5.279c3.638-1.588,7.698-1.98,11.52-3.602c3.956-1.675,7.271-4.566,10.8-6.72
	c1.449-0.884,3.647-0.879,5.04-1.92c0.536-0.399,0.588-1.323,0.96-1.92c1.673-2.685,3.176-4.843,6.72-5.761
	c0.909-0.235,2.343-0.101,3.12,0.479c0.754,1.272,0.025,4.054,0.48,5.521c0.651,2.1,2.186,3.857,3.12,5.761
	c5.706,0.264,7.571,4.416,10.08,7.92c1.022,1.429,3.038,2.167,4.08,3.601c1.305,1.795,2.638,4.26,3.36,6.479
	c0.16,1.039,0.32,2.08,0.479,3.118c0.752,1.822,2.351,2.564,2.88,4.802c-2.936,0.022-1.908,1.235-3.36,2.399
	c-0.584,0.468-1.313,0.519-1.92,0.959c-1.36,1.521-2.72,3.041-4.08,4.562c-0.799,0.795-2.365,1.375-2.88,2.399
	c-1.288,1.627,0.378,4.339,0.48,6.479c-0.501,0.269-0.439,0.345-1.2,0.479c-1.008,0.068-2.604-1.437-3.6-2.16
	c-4.034-2.932-5.163-6.92-4.8-13.68c-0.24-0.08-0.48-0.16-0.72-0.24c-1.787-1.182-4.346,1.154-6,1.439
	c-0.522-1-0.797-1.927-2.16-2.158C261.375,373.69,260.93,373.687,260.275,373.681z'
          />
        </a>
        <a xlinkTitle={`Oued Mliz ${groupedData['Oued Mliz']}`}>
          <path
            onClick={() => setSelectedVill('Oued Mliz')}
            style={{ fill: getColorBasedOnCount(outputData['Oued Mliz']) }}
            d='M196.916,413.521c0.24,0,0.48,0,0.72,0c0.128,1.115,0.241,2.424,0.24,3.84
	c2.381,0.471,11.146,3.303,12.479,4.561c-0.08,0-0.16,0-0.24,0c-0.693,0.813-1.993,1.061-2.64,1.92
	c-1.167,1.553-1.975,3.98-3.6,5.039c-1.133,0.738-2.842,0.605-3.6,1.68c-0.499,0.711-0.245,2.123-0.72,2.883
	c-1.213-0.781-4.227,0.252-5.28,0.719c-4.182,1.855-7.163,4.158-13.2,4.32c-1.04,1.279-2.08,2.561-3.12,3.84
	c-7.721,7.15-12.453-4.371-18.959-4.799c-0.562,0.793-1.481,1.24-2.16,1.92c-2.428,2.418-4.701,5.102-7.92,6.719
	c0.001-0.652-0.01-1.105-0.24-1.439c-1.22-0.721-3.235-0.102-4.8-0.48c-4.065-0.982-6.707-2.141-8.16-5.76
	c-3.439-0.367-5.99-1.721-7.92-3.6c1.325-6.846,0.502-16.287,0-23.279c3.352-1.238,6.474-2.195,6.96-6.24
	c2.987,0.021,4.539,0.842,6.96,0.24c0.144-0.414,0.214-0.758,0.24-1.439c-0.523-0.83-0.162-2.313-0.48-3.36
	c-0.445-1.464-1.522-3.345-2.4-4.56c-2.64-3.66-9.829-7.777-11.04-11.762c-1.302-4.281,2.433-7.414,3.6-10.318
	c1.588-3.955,0.061-10.266,1.68-14.16c1.25-3.008,4.335-3.766,6-6.239c0.26,0.218,0.02-0.021,0.24,0.239
	c2.039,1.144,4.085,2.545,4.08,5.761c-0.458,0.414-0.908,1.073-0.72,1.438c1.133,1.584,3.215,2.207,4.8,3.359
	c0.72,0.801,1.44,1.602,2.16,2.4c-0.529,4.244,0.083,8.033,1.92,10.561c0.88,1.211,2.566,1.559,2.88,3.359
	c-1.424,2.057-0.331,10.468-0.96,13.68c0.32,0,0.64,0,0.96,0c1.933-1.281,5.294,1.605,6.96,1.92
	c3.706-5.416,14.053-7.715,19.92-11.279c2.644-1.605,4.912-4.149,8.88-4.32c1.007,0.793,2.347,1.494,3.36,2.16
	c-0.62,1.097-1.355,2.513-1.68,3.84c-1.551,6.334,3.976,12.302,6.24,15.359c1.233,1.668,3.162,2.82,3.36,5.521
	c-1.71,1.074-3.376,2.242-4.8,3.602C196.916,412.082,196.916,412.8,196.916,413.521z'
          />
        </a>
        <a xlinkTitle={`Jendouba ${groupedData['Jendouba']}`}>
          <path
            onClick={() => setSelectedVill('Jendouba')}
            style={{ fill: getColorBasedOnCount(outputData['Jendouba']) }}
            d='M261.235,374.88c0.499,0.09,0.462,0.059,0.72,0.24c1.011,1.114,0.849,1.932,3.12,1.92
	c0.954-0.92,2.564-1.609,4.32-1.681c0,0.08,0,0.16,0,0.24c0.08,0,0.16,0,0.24,0c-2.469,7.01,4.8,16.069,9.84,16.319
	c1.22-1.111,1.751-1.037,1.68-3.601c-0.556-0.772-0.828-2.254-0.96-3.36c1.323-1.515,2.913-2.66,4.32-4.08
	c1.458-1.471,2.354-3.073,4.32-4.08c0,0.16,0,0.32,0,0.48c1.716,2.307,0.895,6.01,1.68,9.359c0.608,2.597,2.295,8.204,4.08,9.359
	c1.44,0.399,2.88,0.801,4.321,1.201c0.427,1.385,0.282,2.346,2.159,2.399c0.357,6.344,4.501,10.858,6,16.08
	c-5.972-0.339-6.405,2.079-8.88,5.76c-0.411,0.61-0.635,1.485-1.199,1.921c-1.634,1.25-4.455,2.201-6.48,2.879
	c-0.8,0.16-1.6,0.318-2.4,0.479c-0.24,0.4-0.48,0.803-0.72,1.201c-1.009,0.99-2.785,2.287-4.08,2.879
	c-4.852,2.223-11.001,1.803-16.8,3.359c-5.843,1.572-12.313,2.406-18.24,4.32c-1.828,0.59-4.032,1.641-5.76,2.16
	c-1.51,0.453-4.74-1.104-6.24-1.439c-0.81-0.182-1.962,0.141-2.4,0.24c-1.672,0.377-2.865,0.041-4.32,0.479
	c-3.356,1.012-6.244,2.391-10.56,1.92c-0.446-0.582-1.259-0.861-1.68-1.439c-1.433-1.969-1.676-4.781-4.08-5.76
	c-1.12-0.08-2.24-0.16-3.36-0.24c-1.139-0.369-2.792-1.775-3.84-1.92c-0.623-0.084-1.516,0.846-2.16,0.961
	c-0.991,0.176-2.091-0.525-2.88-0.721c-0.08-0.24-0.16-0.48-0.24-0.721c0.191-0.234,0.347-0.658,0.48-0.959
	c1.683-0.805,3.75-1.443,4.8-2.881c1.487-2.035,2.585-5.02,5.28-5.76c1.62-0.445,3.232,1.363,4.08,1.92
	c2.115,1.387,3.936,2.355,7.44,2.398c2.07-2.047,4.948-3.166,6.72-5.52c3.159-4.195,5.432-8.982,8.4-13.439
	c1.641-2.465,4.666-4.27,5.52-7.44c0.475-1.76-0.935-2.327-0.72-3.601c0.188-1.114,1.09-2.686,1.439-3.84
	c1.286-4.244,1.569-8.535,3.36-12c0.713-1.379,0.916-2.994,2.64-3.358c1,0.81,2.707,0.666,4.08,0.479
	c-0.185-1.666-1.07-1.703-1.2-3.601c0.814-0.515,1.125-0.733,2.64-0.72c0.339,0.363,0.664,0.529,0.96,0.959
	c0.906,1.193-0.125,3.209,0.24,4.08c0.44,0.691,0.581,0.865,1.68,0.961c0.292,0.178,0.238,0.031,0.96,0
	C260.427,378.304,261.145,377.158,261.235,374.88z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 64.062 513.0073)'
          font-family="'MyriadPro-Regular'"
          font-size='24'
        >
          Gouvernorat de Jendouba
        </text>
        <text
          transform='matrix(1 0 0 1 237.936 190.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Tabarka
        </text>
        <text
          transform='matrix(1 0 0 1 195.936 255.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ain Drahem
        </text>
        <text
          transform='matrix(1 0 0 1 165.936 329.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Fernana
        </text>
        <text
          transform='matrix(1 0 0 1 237.936 190.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Tabarka
        </text>
        <text
          transform='matrix(1 0 0 1 259.936 307.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Balta-Bou Aouane
        </text>
        <text
          transform='matrix(1 0 0 1 299.9355 368.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bou Salem
        </text>
        <text
          transform='matrix(1 0 0 1 240.936 418.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Jendouba
        </text>
        <text
          transform='matrix(1 0 0 1 177.936 372.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Jendouba Nord
        </text>
        <text
          transform='matrix(1 0 0 1 136.936 424.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Oued Meliz
        </text>
        <text
          transform='matrix(1 0 0 1 40.936 402.0366)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ghardimaou
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Jendouba;
