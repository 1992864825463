import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Siliana = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)

  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='532.167px'
        height='515.838px'
        viewBox='0 0 532.167 515.838'
        enable-background='new 0 0 532.167 515.838'
      >
        <a xlinkTitle={`Bou Arada ${groupedData['Bou Arada']}`}>
          <path
            onClick={() => setSelectedVill('Bou Arada')}
            style={{ fill: getColorBasedOnCount(outputData['Bou Arada']) }}
            d='M174.898,204.772c-0.006,7.586,5.369,8.877,7.92,13.68c-0.84,0.093-2.584-0.1-3.602,0.24
	c-1.02,0.34-1.175,1.652-3.119,1.2c-2.26-0.525-5.207-2.857-8.16-2.16c-2.537,0.6-3.742,2.613-6.479,2.88
	c-0.478-0.523-1.431-0.972-1.681-1.68c-0.26-0.741,0.174-2.028,0.24-2.4c0.26-1.441-0.5-3.438-0.959-4.56
	c-2.746-6.704-6.133-11.398-6.24-21.12c2.052,0.056,5.49-1.399,6-2.64c0.968-1.455,0.848-3.733,1.438-5.52
	c1.37-4.134,2.704-8.731,3.841-13.2c0.506-1.988-0.086-5.123,0.961-6.72c1.238-1.167,3.058-1.742,4.079-3.12
	c2.525-3.404,6.476-11.858,2.64-17.04c-2.055-2.776-5.24-1.328-8.159-2.16c-0.185-2.28-3.476-8.64-5.04-9.36
	c-2.639-1.214-7.377-1.312-8.4-4.08c0.897-0.26,2.477-1.051,3.12-1.68c-1.603-1.153-1.214-5.523-1.68-7.68
	c-0.654-0.41-0.599-0.608-1.681-0.72c-0.259-0.18-0.228-0.144-0.721-0.24c-0.818,1.149-3.229,5.374-4.318,5.76
	c-0.935,0.48-1.834-0.604-2.642-0.96c-2.912-1.281-5.703-2.25-8.4-3.6c0-0.32,0-0.64,0-0.96c-0.779-0.199-0.367,0.118-0.72-0.48
	c0.08,0,0.16,0,0.24,0c0.92-0.831,2.582-0.944,3.36-1.92c0.323-0.403,0.393-0.754,0.48-1.44c-0.44-0.5-0.65-1.3-1.2-1.68
	c-0.353-0.244-2.521-0.968-1.92-1.2c0.279-0.315,0.646-0.68,0.96-0.96c0.02-2.366-0.626-3.224-0.72-5.28
	c0.24-0.08,0.48-0.16,0.72-0.24c2.945-2.082,8.339-2.104,12.718-1.92c2.189-2.872,9.447-8.897,15.842-7.44
	c2.971,0.677,3.988,2.336,7.439,2.4c3.768-3.726,13.444-3.605,18.239-0.72c1.048,0.631,1.662,1.99,2.64,2.64
	c0.791,0.526,1.99,0.217,2.881,0.72c1.133,0.64,1.137,2.652,2.16,3.36c1.039,0.24,2.08,0.48,3.119,0.72
	c1.43,0.847,1.406,3.315,3.12,4.08c5.076,2.263,15.031-0.568,19.681-1.68c1.828-0.438,4.666,0.27,5.76-0.24
	c1.422-1.024,1.285-4.531,1.681-6.48c0.24-0.08,0.479-0.16,0.722-0.24c0.935-0.597,4.108-0.374,5.52-0.24
	c3.867,0.365,7.341,1.126,12,1.2c1.25-2.246,2.986-2.533,4.08-4.08c0.364-0.516,0.429-1.624,0.96-1.92c0.239,0,0.479,0,0.72,0
	c1.548,2.739,3.32,4.227,5.521,6.24c-0.964,3.723-0.822,6.072,0.72,9.12c0.334,0.661,1.206,2.585,0.96,2.88
	c-1.158,2.343-1.5,6.061-0.72,9.6c0.106,0.49,0.44,1.778,0.238,2.64c-0.31,1.324-1.839,3.228-1.68,3.84
	c0.422,0.644,1.021,0.313,1.44,0.96c0.457,0.644,0.186,2.545-0.24,3.12c-0.951,2.621-6.394,6.728-8.642,8.4
	c-0.842,0.626-2.692,1.453-3.118,2.4c0,1.28,0,2.56,0,3.84c-1.063,1.578-0.955,3.97-1.922,5.52
	c-1.935,3.106-9.441,9.042-12.72,11.04c-3.021,1.843-8.293,0.679-12,1.92c-1.417,0.474-1.592,1.807-2.64,2.4
	c-1.12,0.24-2.24,0.48-3.36,0.72c-1.292,0.551-2.118,1.752-3.358,2.4c0.804,4.7,3.006,6.537,5.52,9.36c0.561,0.8,1.12,1.6,1.68,2.4
	c0.959,0,1.92,0,2.881,0c1.604-1.287,3.994-2.375,6.721-2.4c0.667,1.982,0.26,5.496,0.238,7.92c-0.684,0.596-0.101,0.865-0.719,1.2
	c-2.209,2.758-8.603,4.678-8.641,9.12c1.158,1.028,3.248,2.054,3.359,4.08c-1.307,0.859-2.344,2.216-3.601,3.12
	c-2.188,1.575-4.435,2.821-6.72,4.32c-1.012,0.663-3.209,2.802-4.32,2.4c-3.688-1.303-6.188-7.285-10.561-9.12
	C191.167,200.187,182.728,204.723,174.898,204.772z'
          />{' '}
        </a>
        <a xlinkTitle={`Le Krib ${groupedData['Le Krib']}`}>
          <path
            onClick={() => setSelectedVill('Le Krib')}
            style={{ fill: getColorBasedOnCount(outputData['Le Krib']) }}
            d='M43.857,103.733c0.04,3.837,2.026,4.59,3.6,6.72c0.284,0.384,0.331,1.198,0.72,1.44
	c1.504,0.933,1.828-0.042,1.92,2.4c-0.414,0.81,0.893,2.569,1.2,3.84c1.153,0.678,5.825,2.332,6.24,3.36
	c1.376,1.847-0.245,5.395,0.24,7.68c0.257,1.209,0.691,2.546,0.96,3.6c9.289,4.789,12.309-4.912,19.92-6
	c2.106,1.7,5.656,3.627,8.64,4.32c5.17,1.199,11.476-1.14,16.08-1.2c0.455,0.717,1.294,1.419,1.68,2.16
	c1.169,2.243,1.462,4.802,2.64,6.96c0.792,1.45,2.037,1.616,2.16,3.84c-2.921,2.273-3.439,7.169-6.24,9.6
	c-1.026,0.891-4.054,0.8-4.8,1.92c0.84,1.321,0.06,4.633-0.24,5.76c-0.68,2.56-1.294,5.661-3.36,6.72
	c-0.88,0.16-1.76,0.32-2.64,0.48c-2.031,0.836-4.013,2.35-5.76,3.6c-0.996,0.712-4.951,3.762-6.48,2.88
	c-1.747-0.637-1.612-2.583-2.88-3.6c-1.31-1.051-4.293-0.256-5.76-1.2c-1.972-1.27-2.764-4.263-4.08-6.24
	c-0.801-1.204-2.104-1.889-3.12-2.88c-11.426-0.063-11.526,14.473-22.56,14.88c0.059,2.361,0.349,4.256-0.48,6.24
	c-1.418,3.392-4.155,5.147-4.56,9.84c-0.32,0-0.64,0-0.96,0c-0.199,0.778,0.118,0.366-0.48,0.72c-1.24-0.928-3.19-0.865-4.56-1.68
	c-1.366-0.812-7.896-7.22-8.16-8.64c0.344-0.469,0.336-1.691,0-2.16c-0.75-2.328-3.311-3.437-4.56-5.28
	c-1.804-2.661-1.753-6.869-3.6-9.36c-1.6-1.44-3.2-2.88-4.8-4.32c-0.24-0.72-0.48-1.44-0.72-2.16
	c-1.186-1.325-2.918-1.445-3.12-3.84c0.844-0.195,1.352-0.478,1.68-1.2c0.762-1.211-0.572-2.177-0.72-3.6
	c1.32-0.857,2.731-1.889,4.08-2.88c0.56-0.72,1.12-1.44,1.68-2.16c0-0.32,0-0.64,0-0.96c1.861,0.248,2.863-0.072,4.32-0.48
	c0.48-0.72,0.96-1.44,1.44-2.16c0.012-4.69-2.97-4.76-3.36-7.439c1.474-2.077-0.477-4.617-1.2-6
	c-2.261-4.326-2.472-10.308-8.16-11.28c-1.939-4.687-7.613-6.024-5.04-13.44c0.248-0.715,0.095-1.69,0.24-1.92
	c1.008-0.755,2.576-0.686,3.84-1.2c2.201-0.896,4.707-2.036,7.2-2.64c2.782-0.674,3.899,1.707,6.48,0.96
	c2.205-0.638,2.101-2.328,6-2.4c0.454,0.383,1.272,0.53,1.68,0.96c0.463,0.488,0.395,1.287,0.96,1.68c1.28,0.48,2.56,0.96,3.84,1.44
	c1.404,0.91,2.923,3.073,4.08,4.32C37.938,103.733,40.897,103.733,43.857,103.733z'
          />{' '}
        </a>
        <a xlinkTitle={`Gaafour ${groupedData['Gaafour']}`}>
          <path
            onClick={() => setSelectedVill('Gaafour')}
            style={{ fill: getColorBasedOnCount(outputData['Gaafour']) }}
            d='M162.898,141.893c2.924-0.416,6.588-0.71,8.16,1.44c4.277,5.855-2.183,17.798-7.201,18.48
	c0.147,6.583-1.944,12.271-3.601,17.28c-0.987,2.994-1.282,6.007-2.399,8.88c-1.905,0.928-3.231,2.219-6,1.68c0,1.68,0,3.36,0,5.04
	c0.57,0.977,0.746,5.112,1.201,6.48c0.904,2.729,2.508,5.189,3.6,7.68c0.871,1.99,1.328,4.005,2.16,6c-0.4,0-0.801,0-1.2,0
	c-2.607-1.802-10.947,1.376-14.4,1.44c-1.747,0.032-3.182-1.924-4.079-2.88c-6.716,0.04-6.298,8.251-12.479,6.96
	c0.025-3.148-1.084-5.129-1.68-7.68c-0.08,0-0.16,0-0.24,0c-0.678,0.428-1.793,0.166-2.64,0.48c-1.146,0.426-2.609,1.424-3.6,2.16
	c-2.38,1.769-4.601,7.145-7.68,7.68c-0.159-0.596-0.59-0.777-0.72-1.2c-0.4-1.301,0.937-2.134-0.48-2.88
	c-1.948-1.576-4.981-0.096-6.72,0.48c-1.392-5.092-7.036-5.901-8.88-10.32c-1.712-4.102,0.719-8.526,1.68-11.52
	c0.563-1.753,0.468-3.752,0.479-6c-1.997-0.281-10.084-2.625-11.28-3.6c-0.69-0.917-0.717-2.214-1.2-3.36
	c-1.227-2.913-3.138-5.789-3.36-9.6c5.121-0.583,7.538-3.855,11.04-6c1.268-0.776,2.881-0.455,4.32-1.2
	c1.069-0.554,2.629-2.949,3.12-4.08c1.15-2.648,0.491-6.12,1.44-9.12c5.746-0.138,6.357-5.458,8.64-9.12
	c0.735-1.18,2.28-1.852,2.88-3.12c0.168-0.361-0.237-0.428,0-0.96c1.014-3.086,5.657-6.309,8.16-8.16
	c5.183-3.834,10.538-8.279,13.44-14.4c2.54,1.114,9.093,3.372,10.8,5.04c3.874-0.951,3.075-5.607,6.24-6
	c0.08,0.16,0.16,0.32,0.24,0.48c0.585,0.745,0.929,6.356,0.72,6.72c-0.491,0.438-3.116,1.729-2.399,2.4
	c1.625,2.412,5.021,3.205,7.92,4.32c0.721,0.24,1.439,0.48,2.16,0.72c0.239,0.64,0.479,1.28,0.719,1.92
	C160.96,136.719,162.759,138.611,162.898,141.893z'
          />{' '}
        </a>
        <a xlinkTitle={`Sidi Bou Rouis ${groupedData['Sidi Bou Rouis']}`}>
          <path
            onClick={() => setSelectedVill('Sidi Bou Rouis')}
            style={{ fill: getColorBasedOnCount(outputData['Sidi Bou Rouis']) }}
            d='M61.618,160.853c5.764,0.041,5.615,4.992,8.4,7.92c2.156,2.266,5.822,0.898,7.44,3.12
	c1.646,2.261,1.506,4.356,2.64,7.2c0.677,1.699,1.708,3.629,2.4,5.28c0.48,1.52,0.96,3.041,1.44,4.56c1.12,0.32,2.24,0.64,3.36,0.96
	c2.45,0.898,5.106,1.831,7.92,2.4c0,0.959,0,1.92,0,2.88c-0.622,0.879-0.559,2.261-0.96,3.36c-2.028,5.559-2.786,11.349,1.92,14.88
	c1.4,1.05,6.616,4.417,5.76,7.2c-0.661,2.146-4.807,4.803-6.72,6c-5.03,3.146-11.337,4.978-16.56,8.16
	c-1.636,0.997-3.564,3.027-5.04,3.84c-0.833-0.993-5.323-1.197-6.72-1.92c-1.742-0.902-3.685-2.531-4.8-4.08
	c-0.712-0.99-0.956-2.417-2.4-2.64c-2.067-0.319-4.557,2.31-6.96,2.64c-0.667-0.511-1.909-0.774-2.4-1.44
	c-1.136-1.54,0.877-5.378,0.96-8.16c-0.605-0.417-1.191-1.139-1.68-1.68c-3.48-0.054-4.921,1.17-7.92,1.44
	c-0.782-1.541-2.232-2.646-3.12-4.08c-2.259-3.651-2.827-8.882-2.88-14.64c2.266-1.473,4.962-4.574,5.04-8.16
	c-1.879-0.632-2.16-2.33-3.36-3.6c0.672-1.941,0.899-4.584,1.92-6.24c1.744-2.829,4.455-5.55,3.6-10.32
	c6.196-0.726,10.145-6.512,13.2-10.56C57.543,163.256,59.763,162.382,61.618,160.853z'
          />{' '}
        </a>
        <a xlinkTitle={`Bargou ${groupedData['Bargou']}`}>
          <path
            style={{ fill: getColorBasedOnCount(outputData['Bargou']) }}
            onClick={() => setSelectedVill('Bargou')}
            d='M178.978,219.892c1.391-0.125,3.475-0.277,4.561-0.72c0.08-0.24,0.158-0.48,0.238-0.72
	c0.063-0.232,0.188-0.091,0-0.24c-1.065-3.237-5.006-5.308-6.719-8.64c-0.39-0.758-1.426-2.801-0.961-3.6
	c1.438-0.92,5.53-0.715,7.44-1.2c1.681-0.427,3.787-0.813,5.521-1.2c8.948-2.003,12.474,3.32,16.319,7.2
	c1.151,1.161,2.704,1.947,3.84,3.12c1.938-0.587,3.496-2.098,5.04-3.12c3.876-2.566,7.314-4.806,10.561-7.92c0-0.56,0-1.12,0-1.68
	c-0.521-0.468-3.248-2.659-3.359-3.12c-0.376-1.545,1.422-2.093,2.16-2.64c2.066-1.532,5.527-5.731,8.158-6.24
	c0.801,0.489,2.102,0.173,3.121,0.48c2.132,0.641,4.482,1.711,6.239,2.88c4.583,3.05,7.813,7.744,12.24,11.04
	c2.085,1.552,3.787,3.878,7.2,4.08c0.515-0.782,1.186-1.33,2.398-1.44c1.41,1.453,2.901,2.876,3.602,5.04
	c0.413,1.276,0.625,4.381,0.24,6c-1.328,5.587-3.158,11.541-6,15.84c-3.55,5.368-7.688,10.287-11.521,15.36
	c-0.96,0.88-1.92,1.76-2.88,2.64c-2.432,2.431-4.658,4.858-7.438,6.96c-1.36,1.44-2.722,2.881-4.08,4.319
	c-3.488,3.487-7.076,6.823-10.08,10.801c-3.043,4.024-8.539,7.399-11.28,11.521c-0.08,0-0.16,0-0.24,0
	c-0.715-2.308-7.241-8.967-9.6-9.601c-2.925-0.784-4.66,1.666-7.2,1.92c-1.564-3.489-2.387-8.614-5.76-10.319
	c-3.34-1.688-9.239-0.269-13.2-0.479c-0.634-0.942-0.974-1.104-0.96-2.879c1.816-1.17,2.816-2.832,3.359-5.28
	c-0.08-1.04-0.16-2.08-0.239-3.12c0.336-0.903,1.642-2.02,2.159-2.88c1.107-1.84,1.642-4.418,2.16-6.72
	c0.76-3.365-1.436-6.926-0.959-9.12c0.691-3.202,3.073-4.033,3.119-7.92c-0.621-0.637-0.965-1.625-1.681-2.16
	c-1.147-0.857-2.433-1.306-3.601-2.16c-0.801-0.585-1.213-1.708-1.92-2.4C178.978,221.012,178.978,220.452,178.978,219.892z'
          />{' '}
        </a>
        <a xlinkTitle={`Siliana Nord ${groupedData['Siliana Nord']}`}>
          <path
            style={{ fill: getColorBasedOnCount(outputData['Siliana Nord']) }}
            onClick={() => setSelectedVill('Siliana Nord')}
            d='M178.017,220.852c0.286,3.391,5.086,5.737,7.2,7.68c0.042,1.586-0.31,2.7-1.44,3.12
	c-2.123,1.917-6.42,0.713-9.6,1.68c-7.035,2.139-11.949,6.921-18.96,9.12c-2.815,0.883-6.411-0.002-8.159-0.96
	c-0.572-0.313-1.894-1.245-2.16-0.96c-2.646,1.683-4.604,4.644-7.201,6.479c-2.393,1.693-5.202,3.608-7.92,4.8
	c-1.171,0.513-2.31,0.555-3.36,1.2c-2.269,1.392-3.71,3.75-5.76,5.28c-1.53,1.144-3.429,2.149-5.04,3.12
	c-4.708,2.838-13.514,3.918-16.56,8.16c-4.032,5.613,0.329,13.279,2.64,17.039c0.804,1.309,1.175,3.719,2.64,4.32
	c2.347,1.438,4.28-4.396,5.04-5.762c0.26,0.221,0.021-0.02,0.24,0.24c2.622,1.516,5.018,3.553,8.64,4.08
	c-0.01,3.693-1.379,5.625-1.44,9.6c-4.622,2.682-8.46,5.287-10.8,10.32c-0.837,0.082-1.569,0.68-1.68,0.479
	c-1.531-0.994-2.415-2.779-3.84-3.842c-2.11-1.57-4.77-3.193-7.44-4.079c-2.173-0.722-4.97-1.103-7.68-0.96
	c-0.96,0.08-1.92,0.16-2.88,0.24c-2.379-0.729-8.057-3.602-9.12-5.521c-0.993-1.793-0.212-4.129-0.72-6.479
	c-0.24-2.24-0.48-4.479-0.72-6.721c-0.214-0.746-0.745-1.549-0.96-2.398c1.457-0.938,6.607-5.688,6.72-7.68
	c1.977-2.912-1.287-12.072-2.64-13.441c2.127-4.376,1.089-7.993,0-12.72c-1.014-4.401,1.744-3.564-0.72-7.44
	c1.896-0.508,5.375-3.491,7.44-4.56c6.5-3.366,13.595-5.186,18.48-10.08c1.738-1.741,4.922-7.311,8.88-4.32
	c0.648,1.339-0.474,3.093,1.44,3.84c0.311,0.21,0.592,0.215,1.2,0.24c0.955-0.937,2.313-1.567,3.12-2.64
	c2.252-2.996,4.59-7.061,9.36-7.44c0,0.16,0,0.32,0,0.48c1.104,1.623,0.66,4.906,1.68,6.48c0.577,0.56,1.382,0.835,2.4,0.96
	c0.868-0.593,2.057-0.608,2.88-1.2c2.608-1.877,3.175-6.029,7.44-6.24c0.732,0.816,1.801,2.244,2.88,2.64
	c1.351,0.496,2.875,0.025,4.08-0.24c0.96,0,1.92,0,2.88,0c4.083-0.965,5.597-1.701,10.08-0.72c0.785,2.717,0.232,5.461,3.359,5.76
	c0.924-0.734,5.877-3.439,7.439-3.12C172.384,219.307,174.203,220.79,178.017,220.852z'
          />{' '}
        </a>
        <a xlinkTitle={`Siliana Sud ${groupedData['Siliana Sud']}`}>
          <path
            style={{ fill: getColorBasedOnCount(outputData['Siliana Sud']) }}
            onClick={() => setSelectedVill('Siliana Sud')}
            d='M152.337,329.812c-7.381-0.103-10.943-8.215-16.56-9.358c-1.419,1.856-5.38,1.198-6.24,3.603
	c-1.077,1.6-0.104,4.012-0.72,6.237c-0.213,0.771-0.82,1.547-1.2,2.16c-0.08,0-0.16,0-0.24,0c-0.952-3.806-2.838-7.619-3.12-12.479
	c-0.785-0.584-1.311-1.961-2.16-2.398c-0.64-0.08-1.28-0.16-1.92-0.24c-1.342-0.451-2.87-1.049-4.32-1.682
	c-3.419-1.484-6.759-3.771-10.56-4.801c0.218-0.262-0.019-0.021,0.24-0.238c0.355-0.279,1.386-0.375,1.68-0.721
	c0.08-0.479,0.16-0.961,0.24-1.438c0.588-1.328,1.992-3.236,3.12-4.08c1.208-0.9,6.674-3.732,7.2-4.563c0-1.041,0-2.08,0-3.121
	c1.184-1.727,1.678-5.738,1.2-8.158c-3.547-0.166-7.486-2.518-9.36-4.561c-0.421,0.15-0.233,0.045-0.48,0.238
	c-1.832,1.313-1.916,5.5-4.32,6c-2.143-3.783-8.695-12.043-5.76-18.719c2.002-4.557,10.807-6.063,15.36-8.4
	c2.338-1.199,4.881-2.533,6.96-4.08c1.809-1.346,3.074-3.57,5.04-4.8c2.185-1.366,4.778-1.79,6.96-3.12
	c3.182-1.939,9.8-6.45,11.76-9.36c11.167,5.508,19.503-3.601,27.841-6.96c2.729-1.099,7.105-0.798,10.08-1.44c0,0.16,0,0.32,0,0.48
	c-2.537,3.245,1.118,6.228,0,11.04c-0.648,2.792-1.304,5.52-2.641,7.68c-0.359,0.581-1.427,1.332-1.68,1.92
	c-0.487,1.133,0.459,3.618-0.24,5.28c-1.129,2.683-2.872,1.49-2.88,6c0.584,0.396,0.772,0.886,1.44,1.199
	c2.726,1.844,9.313-0.931,12.719,0.479c4.854,2.008,3.214,10.549,8.16,10.562c1.275-1.029,3.396-1.863,5.521-1.922
	c1.701,1.438,3.942,2.553,5.28,4.319c1.168,1.545,2.484,3.711,4.08,4.801c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0
	c-2.02,3.125-6.143,6.869-9.12,9.119c-1.12,1.198-2.24,2.399-3.36,3.6c-4.02,3.041-7.644,6.666-12,9.359
	c-1.384,0.856-3.265,1.664-4.8,2.159c-0.72,0.08-1.44,0.16-2.16,0.24c-1.638,0.596-3.956,3.01-5.04,4.319c-0.4,0-0.8,0-1.199,0
	c-1.93-2.209-2.713-4.842-6.479-3.84c-0.067-1.642-0.444-3.332-0.96-4.562c-4.768-3.188-7.782,0.168-11.521,2.159
	c-1.281,0.685-3.748,1.585-4.318,2.88c0,0.881,0,1.762,0,2.642c0.52,0.834-0.014,2.422,0.238,3.601
	C152.676,320.913,152.424,325.567,152.337,329.812z'
          />{' '}
        </a>
        <a xlinkTitle={`Makthar ${groupedData['Makthar']}`}>
          <path
            onClick={() => setSelectedVill('Makthar')}
            style={{ fill: getColorBasedOnCount(outputData['Makthar']) }}
            d='M70.018,280.853c3.367,3.769-0.519,13.821,4.56,17.521c2.025,1.478,5.127,3.146,7.68,3.84
	c1.76-0.08,3.52-0.157,5.28-0.237c2.215,0.489,4.536,0.623,6.48,1.438c3.171,1.334,5.892,3.487,8.16,5.76
	c0.633,0.636,1.203,1.908,1.92,2.4c1.04,0.317,2.08,0.639,3.12,0.959c1.291,0.561,2.828,1.534,4.08,2.159
	c2.344,1.168,4.869,2.164,7.2,3.119c1.04,0.24,2.08,0.479,3.12,0.722c0.857,0.485,1.602,1.795,1.68,3.118
	c0.88,1.375,0.484,3.5,0.96,5.281c0.657,2.457,2.187,4.687,2.64,6.959c-0.624,0.789-0.656,2.002-1.2,2.881
	c-0.907,1.468-2.621,3.168-4.08,4.08c-1.696,1.062-3.991,1.797-6,2.399c-1.225,0.367-2.31-0.065-2.88,0.959
	c-0.941,1.535,0.437,3.476,0.72,4.562c0.607,2.332-0.794,5.647-1.2,7.438c-10.133-0.192-13.744,5.89-19.44,10.08
	c-1.773,1.308-4.414,2.063-6,3.601c-0.421-0.149-0.233-0.045-0.48-0.24c-4.278-2.924-3.955-9.969-6.72-14.157
	c-1.104-1.675-7.716-2.771-10.08-3.361c-0.802-1.256-1.748-2.133-1.92-4.077c1.333-1.306,2.343-3.738,2.4-6.24
	c-0.896-0.483-1.659-1.351-2.64-1.683c-5.283-1.78-11.57,3.185-14.88,4.804c-0.289,9.19-8.035,16.147-17.76,16.08
	c0-0.08,0-0.16,0-0.24c6.49-6.84-0.242-13.938-3.84-18.961c-0.903-1.26-3.728-3.815-2.88-6.479c0.745-2.338,5.032-2.489,6.24-4.563
	c0.24-0.719,0.48-1.438,0.72-2.157c1.332-2.041,3.507-3.64,4.8-5.763c0.608-0.998,0.505-2.679,1.2-3.601
	c4.163-4.174,12.146-4.203,15.36-9.358c1.325-2.125,2.286-5.89,1.44-9.358c-0.457-1.877-1.177-4.679-0.48-6.959
	c1.018-3.336,6.217-8.65,8.88-10.562C67.374,282.155,68.872,281.776,70.018,280.853z'
          />{' '}
        </a>
        <a xlinkTitle={`Kesra ${groupedData['Kesra']}`}>
          <path
            onClick={() => setSelectedVill('Kesra')}
            style={{ fill: getColorBasedOnCount(outputData['Kesra']) }}
            d='M153.058,331.011c0.782-6.542-0.386-13.099-0.479-19.681c2.146-1.369,7.442-5.412,10.08-5.761
	c0.534-0.346,1.188-0.135,1.68,0c3.845,1.062,2.38,3.164,3.601,6.479c1.061-0.357,1.32-0.982,2.641-1.199
	c0.969,1.107,2.29,3.68,3.84,4.08c0.469,0.344,1.691,0.336,2.16,0c1.314-0.467,1.572-1.682,2.88-2.16c0,1.521,0,3.041,0,4.563
	c0.737,1.191,0.829,5.957,1.2,7.438c1.352,0.58,8.172,3.402,8.64,4.318c0,0.643,0,1.281,0,1.922
	c-0.491,0.605-0.503,1.482-0.96,2.158c-1.012,1.498-3.547,2.178-3.84,4.32c-0.545,0.813,0.02,1.639,0.24,2.398
	c2.322,0.438,5.5,1.777,5.52,4.563c-6.357,1.875-11.408,4.801-11.279,13.199c5.224,3.465,6.944,10.645,15.601,10.801
	c0.646,1.789,1.677,1.92,1.68,4.799c-1.421,0.9-3.229,2.098-4.319,3.359c-0.545,0.635-0.394,1.258-1.199,1.682
	c-1.215,0.652-10.238-0.213-12.721,0.479c-2.436,0.683-5.156,2.021-6.479,3.84c-0.623,0.856-0.708,1.894-1.681,2.399
	c-0.965-0.61-3.598,0.054-4.561,0.479c-2.685,1.195-4.304,3.783-6.238,5.761c-1.121,0.961-2.24,1.92-3.36,2.881
	c-0.8,0-1.601,0-2.4,0c-1.132-3.933-7.007-6.84-13.2-5.521c-1.113,0.235-3.625,0.959-5.04,0.479c-0.871-0.295-1.855-1.439-2.64-1.92
	c-2.151-1.311-4.574-1.838-6.96-2.879c-1.626-0.709-2.991-2.16-5.04-2.398c-1.728,2.635-4.278,4.383-5.76,7.438
	c-0.421-0.15-0.233-0.048-0.48-0.24c-2.042-0.681-3.768-2.731-5.52-3.84c-4.457-2.813-8.921-4.938-16.08-5.041
	c-0.959-3.84-4.47-5.914-5.52-9.601c2.563-0.574,4.186-2.293,6-3.601c5.896-4.248,9.601-9.719,19.68-9.84
	c0.744-1.338,2.337-6.19,1.68-8.642c-0.333-1.244-0.914-2.011-0.96-3.84c0.08,0,0.16,0,0.24,0c0-0.08,0-0.16,0-0.24
	c5.136-0.188,10.327-3.18,12.479-6.479c0.723-1.106,1.043-3.657,1.68-4.56c0.16,0,0.32,0,0.48,0c0.366-0.399,1.023-1.446,1.2-1.92
	c0.608-1.631,0.588-5.726,0.96-7.44c1.21-0.576,5.038-2.545,6-2.159c1.536,0.487,2.435,1.792,3.601,2.641
	C143.724,327.167,147.271,330.688,153.058,331.011z'
          />{' '}
        </a>
        <a xlinkTitle={`Rohia ${groupedData['Rohia']}`}>
          <path
            onClick={() => setSelectedVill('Rohia')}
            style={{ fill: getColorBasedOnCount(outputData['Rohia']) }}
            d='M91.857,381.17c10.702-0.084,19.898,6.158,24.48,12.24c0.88,0.801,1.76,1.601,2.64,2.399
	c3.253,4.269,7.92,7.315,11.04,11.76c5.374,7.648,7.94,17.587,12.96,25.681c1.881,3.035,4.371,6.778,7.198,8.881
	c1.317,0.979,5.595,2.617,6.24,3.84c0.19,0.283,0.187,0.404,0.24,0.96c-2.646,2.067-2.655,8.093-2.642,12.722
	c1.342,0.918,2.377,2.538,3.36,3.84c2.325,3.079,5.373,5.19,6,10.08c-1.292,0.148-2.633,0.629-3.84,0.961
	c-1.452,0.396-3.263,0.141-4.56,0.479c-1.762,0-3.521,0-5.281,0c-2.41,0.555-5.358,0.473-7.439,1.2
	c-1.061,0.371-5.52,1.92-5.52,1.92c-0.761-1.076-0.704-2.748-1.2-4.08c-1.068-2.872-2.275-6.066-3.84-8.641
	c-1.876-3.082-4.629-6.502-7.68-8.399c-1.357-0.845-2.862-0.843-4.32-1.681c-0.493-0.283-0.607-0.979-0.96-1.439
	c-0.981-1.281-3.251-2.709-4.8-3.357c-5.452-2.291-20.431-0.609-22.32-5.761c-2.462-3.46,2.568-15.681-3.84-16.08
	c-2.16-0.399-4.32-0.802-6.48-1.2c-6.75-2.144-17.945-5.471-23.52,0.24c-4.612-1.98-4.602-9.309-8.4-10.08
	c-4.468-2.781-14.423,5.356-18.48-0.24c-1.83-2.441,1.368-6.725-0.24-9.357c-0.851-2.36-3.743-4.283-5.76-5.521
	c-1.712-1.052-4.38-1.261-4.56-3.843c1.135-1.088,6.236-6.526,7.44-6.719c1.043,0.789,2.511,0.955,3.6,1.681
	c3.141,2.09,5.454,4.715,9.12,6.479c0-0.16,0-0.32,0-0.479c-1.412-2.267-0.878-9.521-1.68-12.722c-0.56-2.317-1.12-4.64-1.68-6.959
	c-1.383-3.312-5.776-8.66-4.32-14.399c0.4-1.199,0.8-2.4,1.2-3.601c1.12,0,2.24,0,3.36,0c1.68-0.399,3.36-0.802,5.04-1.201
	c2.458-1.028,5.45-3.006,6.96-5.039c1.186-1.596,2.353-3.603,3.12-5.521c0.571-1.428,0.345-3.422,1.2-4.562
	c0.871-0.85,2.023-1.018,3.12-1.68c3.917-2.373,7.588-4.479,12.24-1.438c-0.684,2.016-1.253,4.202-2.64,5.521
	c1.542,6.86,6.047,5.185,11.76,7.438c2.031,3.67,2.811,8.646,5.04,12.239C85.385,371.229,91.103,376.79,91.857,381.17z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 27.6978 151.8682)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Krib
        </text>
        <text
          transform='matrix(1 0 0 1 39.6978 215.8682)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bourouis
        </text>
        <text
          transform='matrix(1 0 0 1 102.6978 178.8682)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Gaafour
        </text>
        <text
          transform='matrix(1 0 0 1 182.6978 148.8682)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bouarada
        </text>
        <text
          transform='matrix(1 0 0 1 196.3374 243.667)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bargou
        </text>
        <text
          transform='matrix(1 0 0 1 88.6978 242.8682)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Siliana{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 88.6978 259.667)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nord
        </text>
        <text
          transform='matrix(1 0 0 1 121.6978 283.8682)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Siliana Sud
        </text>
        <text
          transform='matrix(1 0 0 1 54.6978 332.8682)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Makthar
        </text>
        <text
          transform='matrix(1 0 0 1 132.6978 358.8682)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kesra
        </text>
        <text
          transform='matrix(1 0 0 1 56.2578 401.0264)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Rouhia
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Siliana;
