import './style.css';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import UserModal from '../UserModal/UseModal';
const ApexChart = ({ series, labels, data }) => {
  const [options, setOptions] = useState({
    chart: {
      type: 'polarArea',
    },
    stroke: {
      colors: ['#fff'],
    },
    colors: [
      '#FF1654',
      '#247BA0',
      '#70C1B3',
      '#B2DBBF',
      '#BA0FFC',
      '#cd3654',
      '#2073A0',
      '#7eeeB3',
      '#aaDBBF',
      '#03FFBD',
      '#b111bb',
      '#ccccdc',
      '#aaa111',
    ],
    fill: {
      opacity: 0.8,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    labels: labels || [],
  });
  const [openModal, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const groupedByDivision = data?.reduce((acc, cur) => {
    acc[cur?.Division] = (acc[cur?.Division] || 0) + 1;
    return acc;
  }, {});

  let divisions = [];
  let counts = [];
  if (groupedByDivision !== undefined) {
    divisions = Object?.keys(groupedByDivision);
    counts = Object?.values(groupedByDivision);
  }

  const getUniqueLabels = (arr = []) => {
    return [...new Set(arr)];
  };
  const [filtredUserData, setFiltredUserData] = useState();
  const handleDataPointClick = (event, chartContext, config) => {
    const clickedDataIndex = config.dataPointIndex;
    const clickedLabel = divisions[clickedDataIndex];
    const clickedCount = counts[clickedDataIndex];
    const filteredData = data.filter((item) => item.Division === clickedLabel);
    setModalOpen(true);
    // Set the filtered data to the state
    setFiltredUserData(filteredData);
  };
  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      chart: {
        ...prev.chart,
        events: {
          dataPointSelection: handleDataPointClick,
        },
      },
      labels: getUniqueLabels(labels),
    }));
  }, [labels]);
  useEffect(() => {
    setOptions((prev) => ({ ...prev, labels: getUniqueLabels(labels) }));
  }, [labels]);

  return (
    <div id='chart'>
      <ReactApexChart
        options={options}
        series={counts || []}
        labels={divisions || []}
        type='polarArea'
      />
      <UserModal
        handleCloseModal={handleCloseModal}
        isModalOpen={openModal}
        modalData={filtredUserData}
      />
    </div>
  );
};

export default ApexChart;
