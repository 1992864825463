import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Beja = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);

  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='567.734px'
        height='532.311px'
        viewBox='0 0 567.734 532.311'
        enablebackground='new 0 0 567.734 532.311'
      >
        <a xlinkTitle={`Menzel El Habib ${groupedData['Menzel El Habib']}`}>
          <path
            onClick={() => setSelectedVill('Menzel El Habib')}
            style={{
              fill: getColorBasedOnCount(outputData['Menzel El Habib']),
            }}
            d='M190.124,173.883c0.139,1.388,1.034,1.767,1.2,2.64c0.206,1.079-2.475,5.479-2.88,7.2
	c0.08,4.319,0.16,8.64,0.24,12.96c3.443,2.366,5.108,7.289,6.96,11.28c0.389,0.839,2.088,3.749,1.68,4.32
	c-2.296,3.206-7.216,4.292-7.68,9.36c2.08,2,4.16,4,6.24,6c0.24,1.04,0.48,2.081,0.72,3.12c1.664,2.583,6.087,2.856,6.24,6.96
	c-2.161-0.059-3.201-1.17-4.8-1.68c-0.96-0.08-1.92-0.16-2.88-0.24c-0.08-0.16-0.16-0.32-0.24-0.48c-0.4,0.08-0.8,0.16-1.2,0.24
	c-0.197-0.055-0.763-0.656-0.96-0.72c-3.3-1.078-7.941,1.453-10.56,0c-0.64-0.72-1.28-1.44-1.92-2.16
	c-2.018-1.47-4.727-2.398-7.44-3.12c-7.079-1.882-16.189-0.003-23.04-1.92c-0.58-0.162-2-0.356-2.64-0.72
	c-0.8-0.96-1.6-1.92-2.4-2.88c-1.341-0.867-3.639-0.417-5.28-0.96c-1.548-0.513-2.847-1.838-4.32-2.4
	c-3.157-1.204-7.162-0.404-9.6-2.16c-4.76-3.428-9.292-14.031-12.24-19.68c-1.897-3.635-3.027-7.797-7.2-9.12
	c-1.992-0.631-12.072-1.54-14.64-0.96c-2.78,0.627-8.757,0.758-11.52,0c-0.845,0.344-1.461,1.417-2.16,1.92
	c-2.644,1.905-5.412,3.834-8.16,5.52c-1.271,0.779-3.411,2.114-4.8,2.4c-0.81,0.167-1.082-0.901-1.92-0.24
	c-0.528,0.417,0.019,1.436-0.24,2.16c-0.304,0.849-1.693,2.354-2.4,2.88c-4.021,2.995-9.029,3.657-14.88,5.04c-1.2,0-2.4,0-3.6,0
	c-0.362,0.054-2.554,0.252-3.6,0c-4.438-1.069-7.256-4.339-8.88-8.16c-0.278-0.654-0.544-4.05-0.72-4.32
	c-0.32-0.08-0.64-0.16-0.96-0.24c-0.849-1.095-0.444-2.891-1.2-4.08c-1.025-1.613-7.633-5.022-9.6-6.48
	c-2.06-1.527-4.281-4.377-5.76-6.48c-0.592-0.841-0.896-2.251-1.68-2.88c-0.54-0.433-1.32-0.366-1.68-0.96
	c-0.344-0.569-0.116-1.171-0.48-1.68c-1.082-1.513-3.354-1.542-3.36-3.6c1.36-1.52,2.72-3.04,4.08-4.56
	c4.773-3.581,9.548-6.883,16.32-8.4c2.654-0.595,8.304,0.427,10.32,0.96c6.896,1.823,12.348,3.624,19.2,5.52
	c2.538,0.702,7.077,1.653,10.32,0.72c4.202-1.208,7.391-4.366,10.8-6.479c2.404-1.491,5.582-2.315,7.2-4.56
	c1.923-2.667,1.492-8.11,1.44-12.72c-0.08-0.8-0.16-1.6-0.24-2.4c0.403-2.358,0.676-5.129,1.44-7.44
	c1.522,0.076,4.848-1.371,6.96-1.92c3.768-0.98,16.254-0.493,19.44,0.72c1.469,0.559,2.361,2.161,3.6,2.88
	c0.9,0.522,1.808,0.416,2.64,0.959c1.842,1.204,3.19,3.345,5.04,4.56c1.28,0.48,2.56,0.96,3.84,1.44
	c-0.324,1.728,4.293,4.835,5.28,6.96c1.641,3.533,2.583,7.786,4.08,11.28c0.426,0.994,0.813,3.36,1.68,3.84c2.08,0,4.16,0,6.24,0
	c5.895-0.001,11.707,0.268,16.32-0.96c0.129-0.364,0.162-0.406,0.24-0.96c-1.58-1.794,1.68-5.417,3.12-6
	c3.963-1.605,9.127,1.179,11.52,2.4c1.029,0.525,2.375,0.638,2.88,1.68c-1.799,2.643,1.704,8.567,2.88,9.36
	c-0.25,0.595-0.478,0.699-0.24,1.2c1.616,1.636,3.46,2.602,4.56,4.8c0.971-0.251,1.206-0.604,2.4-0.72
	C188.024,172.617,188.654,173.656,190.124,173.883z'
          />
        </a>
        <a xlinkTitle={`El Metouia ${groupedData['El Metouia']}`}>
          <path
            onClick={() => setSelectedVill('El Metouia')}
            style={{ fill: getColorBasedOnCount(outputData['El Metouia']) }}
            d='M216.764,172.923c0.4,0,0.8,0,1.2,0c0.196,1.709,1.006,3.257,1.44,4.8c0.536,1.903,0.545,3.945,0.96,5.76
	c0.32,4.559,0.64,9.12,0.96,13.68c0.574,2.543,0.303,5.463,0.96,8.16c1.12,5.52,2.24,11.041,3.36,16.56
	c1.521,5.36,2.705,11.212,4.8,16.32c0.914,2.228,2.225,4.55,2.88,6.96c-3.371,0.908-6.917,3.85-8.64,6.48
	c-0.488,0.745-0.441,1.68-0.96,2.4c-0.82,1.139-4.537,2.854-6.72,2.4c-1.12-0.32-2.24-0.64-3.36-0.96
	c-1.527,2.316-3.35,3.354-4.08,6.72c1.268,0.813,5.869,6.552,4.8,8.399c-0.295,0.433-0.622,0.596-0.96,0.961
	c-3.614,0.326-5.783-1.457-8.64-2.4c-1.035-0.342-2.811,0.126-3.36-0.721c-0.427-0.606-0.478-1.623-0.48-2.64
	c-1.009-0.693-1.022-1.19-2.88-1.2c-1.539-0.988-10.604-1.439-13.44-1.44c0.273-4.203,3.721-7.336,5.52-10.32
	c0.863-1.432,0.801-3.331,1.68-4.8c0.74-1.237,2.337-1.713,2.88-3.12c-0.701-1.033-0.008-7.212,0.48-7.92
	c0.34-0.231,0.786-0.234,1.44-0.24c1.378,0.967,4.881,2.004,6.96,2.16c0-0.16,0-0.32,0-0.48c0.321-0.478,0.265-1.562,0.24-2.4
	c-0.934-0.954-1.565-2.319-2.64-3.12c-1.108-0.826-3.178-1.686-3.84-2.88c-0.725-1.307-0.282-2.614-1.2-3.6
	c-1.359-1.46-5.008-3.813-5.28-6c1.348-1.396,2.034-3.383,3.6-4.56c1.497-1.124,3.279-2.037,4.32-3.6
	c-0.599-0.677-0.555-1.461-0.96-2.4c-1.197-2.774-2.471-6.038-4.08-8.64c-0.828-1.338-1.914-2.773-2.88-4.08
	c-0.4-0.32-0.8-0.64-1.2-0.96c0-2.24,0-4.48,0-6.72c-0.639-1.008-0.25-4.229,0-5.28c0.287-1.207,0.061-1.884,0.48-2.88
	c1.311-3.113,2.603-2.464,1.44-6.48c1.04,0,2.08,0,3.12,0c2.918,2.542,6.66-2.522,6.96-5.28c-1.35-1.496-2.094-4.817-2.16-7.44
	c0.788-0.774,1.057-1.661,2.4-1.92c1.089,1.445,2.775,2.573,3.84,4.08c1.447,2.047,2.926,5.446,5.28,6.479
	c2.046,0.899,5.463-1.113,6.48-0.479c0.08,0,0.16,0,0.24,0C217.246,171.222,216.836,171.474,216.764,172.923z'
          />{' '}
        </a>
        <a xlinkTitle={`El Hamma ${groupedData['El Hamma']}`}>
          <path
            onClick={() => setSelectedVill('El Hamma')}
            style={{ fill: getColorBasedOnCount(outputData['El Hamma']) }}
            d='M183.404,264.122c5.86-0.089,11.18,0.628,16.08,1.68c0.329,0.61,0.46,0.841,0.48,1.92
	c0.97,1.373,0.544,5.068,0,7.439c-1.126,4.91-8.427,16.081-1.92,20.16c-0.218,0.26,0.019,0.02-0.24,0.24
	c-0.657,0.799-1.5,0.609-2.4,1.198c-0.48,0.562-0.96,1.12-1.44,1.681c-0.039,3.98,1.689,6.966,2.88,9.84
	c1.34,3.232,1.854,7.438,1.68,10.801c-3.515,2.773-6.273,7.381-9.84,10.08c-3.979,3.01-7.68,6.49-11.76,9.359
	c-3.275,2.303-6.644,4.063-10.08,6.239c-1.948,1.234-3.339,3.646-5.52,4.56c-2.645,1.106-5.784-0.313-8.64,0.479
	c-1.904,0.529-3.146,1.783-5.52,1.92c-0.276-1.204-0.841-2.676-1.2-3.84c-3.259-10.573-10.643,1.666-13.92,3.36
	c-1.243,0.643-2.846,0.664-4.56,0.72c0.345,1.953-1.808,2.778-3.36,3.601c-1.479,0.782-3.254,1.983-5.04,2.399
	c-1.867,0.437-5.847-1.985-8.64-2.16c0,0.08,0,0.16,0,0.24c0.752,1.137,0.739,2.713,1.44,3.84c1.204,1.938,3.412,3.056,3.6,6
	c-1.078,1.058-1.698,2.651-2.64,3.84c-2.056,2.595-9.843,6.73-14.16,6.962c-1.638-2.3-6.853-4.99-10.08-5.761
	c-1.85-0.44-4.68,0.854-5.28,0.479c-3.797-2.76-6.812-11.998-12.72-12c-1.017,0.828-2.583,1.398-4.32,1.439
	c-0.978-0.947-2.104-1.204-2.64-2.641c-0.24-2.959-0.48-5.92-0.72-8.881c-1.879-5.062-9.049-13.142-15.6-12.719
	c-0.879-3.214-6.027-4.228-10.32-3.121c-1.032,0.268-2.995,1.076-3.84,0.721c-2.28-1.887-1.854-6.184-3.12-9.119
	c-0.377-0.875-1.949-2.084-2.16-2.641c-0.344-0.908,0.379-3.06,0-4.32c-1.521-5.053-5.878-8.264-7.92-12.959
	c-0.881-2.027-0.08-5.34-0.96-7.68c-1.256-3.34-4.329-5.451-6.24-8.16c-4.788-6.787-9.578-14.072-12-23.041
	c-1.37-5.071,0.378-11.466,1.44-15.36c0.763-2.799,0.522-5.166,1.2-8.16c0.351-1.551,0.906-4.325,0.48-6.24
	c-1.04-4.681-5.69-7.936-3.12-14.16c2.048-4.961,8.642-14.123,12.96-16.8c4.739-2.938,11.851-2.304,13.44-8.4c0.16,0,0.32,0,0.48,0
	c0.051,2.153,0.882,3.447,1.68,4.8c1.1,1.866,1.92,4.036,3.6,5.28c3.364,2.491,10.727,3.054,16.08,1.68
	c4.691-1.204,8.684-2.33,12-4.8c0.832-0.62,1.892-1.958,2.4-2.88c0.08-0.4,0.16-0.8,0.24-1.2c0.646-0.735,2.713-0.979,3.6-1.44
	c2.587-1.343,5.294-2.889,7.68-4.56c1.419-0.994,3.573-3.441,5.04-4.08c4,0,8,0,12,0c1.139-0.706,3.555-0.042,4.8,0
	c5.456,0.185,10.378,0.118,12.72,3.36c1.307,1.809,1.934,3.827,2.88,6c0.676,1.551,1.864,3.063,2.64,4.56
	c2.551,4.917,4.66,10.42,8.4,14.16c3.549,3.549,7.372,1.899,12,3.84c1.257,0.527,2.436,1.699,3.84,2.16c1.6,0.24,3.2,0.48,4.8,0.72
	c1.742,0.961,1.624,2.84,4.32,3.6c7.083,1.997,16.878,0.209,24.24,2.16c2.44,0.646,5.123,1.544,6.96,2.88
	c1.168,0.849,1.247,2.148,3.12,2.4c0.757,0.469,8.984,0.082,10.8,0.48c0.329,0.61,0.46,0.841,0.48,1.92
	c-0.899,1.391-0.51,4.842-0.48,6.96c-2.376,0.876-2.375,2.94-3.36,5.28C188.168,255.09,183.878,258.104,183.404,264.122z'
          />{' '}
        </a>
        <a xlinkTitle={`Ghannouche ${groupedData['Ghannouche']}`}>
          <path
            onClick={() => setSelectedVill('Ghannouche')}
            style={{ fill: getColorBasedOnCount(outputData['Ghannouche']) }}
            d='M244.604,262.202c-0.64-0.08-1.28-0.16-1.92-0.24c-0.123,0.422-0.183,0.971-0.48,1.2
	c-0.742,0.572-1.969,0.314-2.88,0.72c-1.067,0.476-1.066,1.624-2.4,1.92c-1.135-0.802-2.698-0.92-3.84-1.68
	c-2.59-1.723-7.401-6.924-8.4-10.08c2.457-3.087,4.639-7.48,9.6-7.92c1.28,2.24,2.561,4.48,3.84,6.72c0.48,0.4,0.96,0.8,1.44,1.2
	c0.453,0.797,0.241,1.686,0.72,2.4C241.647,258.475,244.267,259.039,244.604,262.202z'
          />{' '}
        </a>
        <a xlinkTitle={`Gabes Ouest ${groupedData['Gabes Ouest']}`}>
          <path
            onClick={() => setSelectedVill('Gabes Ouest')}
            style={{ fill: getColorBasedOnCount(outputData['Gabes Ouest']) }}
            d='M237.644,266.763c-0.043,6.211,3.376,4.473,3.84,7.918c-2.087,2.229-1.936,6.992-1.92,11.28
	c-2.007,0.905-3.877,2.312-5.28,3.84c-4.569-0.151-3.327-5.319-7.44-6c-1.07,1.638-2.274,2.761-2.88,5.04
	c-0.834,3.141-0.065,6.674-1.2,9.359c-0.814,1.93-2.492,3.371-3.84,4.801c-0.801,0.85-2.654,3.369-2.16,4.561
	c1.4,6.026,6.962,10.504,12.24,12.72c1.68,0.479,3.36,0.961,5.04,1.44c-0.45,1.521-1.447,3.285-2.16,4.799
	c-3.629-1.609-7.733-2.541-12.24-3.6c-3.714-0.873-7.755-0.402-11.28-1.199c-2.393-0.543-8.391-0.354-9.36-1.921
	c1.261-0.993,0.816-5.089,0.24-7.2c-1.28-4.69-3.942-8.565-4.32-13.92c1.247-0.76,3.804-1.867,4.32-3.359
	c-0.206-0.907-2.372-2.323-2.88-4.079c-0.8-2.763,1.457-7.203,2.16-8.882c0.454-1.082,0.219-2.338,1.2-2.879
	c0.283,0.192,0.403,0.187,0.96,0.239c0.269-0.501,0.344-0.438,0.48-1.2c-1.444-1.067,0.107-6.313,0.24-8.64c0.72,0,1.44,0,2.16,0
	c2.186,0.575,11.564,5.886,12,0.24c-0.709-1.093-0.597-2.679-1.2-3.84c-0.93-1.79-2.908-3.103-3.6-5.04
	c0.483-0.615,0.511-1.473,0.96-2.16c0.565-0.864,1.571-1.52,2.16-2.4c2.113,0.479,4.672,1.102,6.96,0.24
	c1.147-0.432,1.962-1.397,3.36-1.68C225.509,259.375,232.5,266.415,237.644,266.763z'
          />{' '}
        </a>
        <a xlinkTitle={`Gabes Medina ${groupedData['Gabes Medina']}`}>
          <path
            onClick={() => setSelectedVill('Gabes Medina')}
            style={{ fill: getColorBasedOnCount(outputData['Gabes Medina']) }}
            d='M250.124,286.202c0.056-2.712-0.482-4.303-0.96-6.24c-0.56-0.398-1.12-0.8-1.68-1.199c-0.48,0-0.96,0-1.44,0
	c-0.835,0.872-2.135,1.461-2.88,2.399c-1.056,1.639-0.344,4.36-2.64,4.8c-0.047-2.761-0.223-5.756,0.48-7.92
	c0.461-1.421,1.402-2.475,1.68-4.08c-0.645-0.636-1.025-1.533-1.68-2.159c-1.974-1.891-2.995-2.178-2.16-6.241
	c1.835-1.041,4.313-2.22,6.24-2.4c2.863,4.563,6.853,8.418,10.08,12.72c1.612,2.148,4.359,3.446,5.04,6.479
	C255.621,280.817,254.271,286.535,250.124,286.202z'
          />{' '}
        </a>
        <a xlinkTitle={`Gabes Sud ${groupedData['Gabes Sud']}`}>
          <path
            onClick={() => setSelectedVill('Gabes Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Gabes Sud']) }}
            d='M261.404,306.603c-0.24-0.801-0.48-1.601-0.72-2.4c-0.48-0.398-0.96-0.799-1.44-1.199
	c-4.612-0.055-5.121,2.689-9.36,2.88c0.091,6.276,2.594,10.736,3.84,16.079c0.615,2.637-0.58,4.748-0.72,6.721
	c0.725,0.363,1.785,0.68,2.16,1.439c-0.852,0.57-0.861,0.926-2.4,0.961c-1.725-1.111-4.554-0.859-6.72-1.439
	c-3.983-1.069-8.479-2.387-12.96-2.641c0.156-2.711,1.875-4.133,2.88-6c-7.238-0.749-17.958-7.252-18.24-14.4
	c0.627-0.688,0.875-1.639,1.44-2.399c2.31-3.114,4.129-3.597,5.28-8.399c0.926-3.861-0.496-10.031,3.36-10.8
	c0.319,0.513,0.891,0.75,1.2,1.2c1.685,2.443,1.429,4.631,5.76,4.561c0.885-1.15,3.359-3.402,4.8-3.841
	c1.005-0.305,1.287,0.179,2.4-0.239c1.354-2.293,1.977-6.377,5.04-6.961c0.528,0.502,0.885,0.412,1.2,1.2
	c1.159,1.505,0.272,4.282,1.2,5.761c2.763,2.648,6.72-3.351,10.08-3.601c1.226,1.156,2.928,1.634,4.32,2.64
	c1.892,1.369,2.862,3.953,4.8,5.28c-0.488,2.822,1.181,6.816,0.24,10.32c-0.146,0.543-0.558,1.226-0.72,1.92
	C266.148,304.535,264.333,306.183,261.404,306.603z'
          />{' '}
        </a>
        <a xlinkTitle={`Mareth ${groupedData['Mareth']}`}>
          <path
            onClick={() => setSelectedVill('Mareth')}
            style={{ fill: getColorBasedOnCount(outputData['Mareth']) }}
            d='M269.324,292.202c2.003,0.691,2.989,3.13,4.56,4.318c4.412,3.34,8.207,7.408,12.721,10.562
	c1.178,0.821,7.795,3.494,8.159,4.08c1.506,1.604,2.459,4.862,3.841,6.72c0.619,0.834,2.063,2.053,3.119,2.398
	c0.641,0.08,1.279,0.16,1.92,0.24c1.029,0.57,1.924,2.166,2.641,3.121c1.488,1.981,4.832,6.277,6.721,7.68
	c2.963,2.197,7.559,2.63,10.561,4.8c2.541,1.838,4.051,4.932,7.199,6.239c2.16,0.48,4.32,0.961,6.48,1.44
	c1.85,0.767,3.611,2.497,5.52,3.12c2.641,0.08,5.279,0.16,7.92,0.24c0.641,0.238,1.279,0.479,1.922,0.719
	c0.258,0.422,0.604,0.869,0.959,1.201c0.174,2.193-1.02,7.234-3.119,7.92c-0.928,0.609-2.111,0.328-3.361,0.719
	c-1.893,0.594-7.09,2.952-8.16,4.32c-1.121,1.549-1.543,8.627-2.879,9.6c-0.791,0.577-3.273-0.09-4.08,0.24
	c-2.512,1.029-5.838,6.057-6.961,8.641c-0.787,1.816-1.219,3.989-1.68,6c-0.443,1.941,0.285,4.064-0.24,5.76
	c-0.479,0.961-0.959,1.921-1.439,2.881c-0.469,0.372-1.162,0.122-1.68,0.479c-0.25,0.174-2.148,3.195-2.4,3.602
	c-0.643,1.035-1.121,2.343-1.439,3.6c-0.211,0.835,0.121,1.559-0.24,2.16c-2.283,3.823-8.338,5.841-11.039,9.602
	c-2.623,3.647-2.475,9.675-5.041,13.438c-0.77,1.13-10.676,4.639-12.719,4.8c-4.471-6.555-17.991,0.05-21.84,2.399
	c-1.364,0.834-3.498,1.056-4.08,2.641c-0.23,0.34-0.234,0.785-0.24,1.439c1.018,0.911,1.858,1.692,1.92,3.601
	c-3.005,1.665-5.686,4.129-8.88,5.521c-1.76,0.559-3.52,1.118-5.28,1.68c-0.624,0.634-0.735,1.32-0.72,2.64
	c1.297,1.31,3.398,3.266,3.6,5.521c-1.973,0.623-3.282,2.481-4.8,3.6c-5.585,4.108-11.064,9.73-20.16,10.08
	c-0.961,1.446-2.646,2.18-3.6,3.6c-0.08,0-0.16,0-0.24,0c0-0.159,0-0.319,0-0.479c-1.164-1.699-0.686-4.668-1.2-6.959
	c-2.234-9.956,3.226-17.845,6.72-24.48c1.078-2.047,2.092-4.022,3.12-6c0.249-0.477,0.134-1.239,0.48-1.68
	c2.544-3.229,7.982-4.175,10.32-7.92c1.31-2.1,1.538-5.18,2.16-7.92c0.16-1.76,0.32-3.521,0.48-5.28
	c0.351-0.544,1.169-0.614,1.44-1.44c0.509-1.549-0.096-3.834,0.72-5.039c1.386-0.994,2.004-0.52,1.92-3.119
	c-0.211-0.413-0.213-0.309,0-0.721c0.853-2.422,3.841-4.311,5.76-5.76c1.233-0.933,1.917-2.671,3.12-3.601
	c0.875-0.675,2.674-1.646,3.12-2.64c0-0.802,0-1.602,0-2.4c1.206-0.254,2.493-0.805,2.88-1.92c0.982-1.328,1.517-5.275,2.16-6.961
	c-0.66-0.24-0.527-0.346-0.96-0.719c1.228-3.9-1.243-6.461-2.16-10.08c0-2.32,0-4.642,0-6.961c-0.64-1.279-1.28-2.56-1.92-3.84
	c-0.779-2.35-0.083-5.087-0.72-7.681c-0.587-2.39-1.804-4.453-2.4-6.719c-0.08-2.16-0.16-4.32-0.24-6.48
	c-0.713-3.19-1.008-5.268-2.4-7.438c0.934-0.607,0.97-1.209,0.72-2.4c-0.857-0.346-1.453-0.359-1.68-1.439
	c0.612-0.871,0.85-4.184,0.48-5.76c-0.788-3.363-1.894-6.547-2.88-9.602c-0.32-1.76-0.64-3.52-0.96-5.279
	c3.515-0.24,4.279-2.75,8.16-2.88c0.752,1.237,1.492,2.688,2.16,4.079c6.628-1.848,8.947-3.355,8.64-12.239
	C269.768,294.771,269.635,293.09,269.324,292.202z'
          />{' '}
        </a>
        <a xlinkTitle={`Nouvelle Matmata ${groupedData['Nouvelle Matmata']}`}>
          <path
            onClick={() => setSelectedVill('Nouvelle Matmata')}
            style={{
              fill: getColorBasedOnCount(outputData['Nouvelle Matmata']),
            }}
            d='M247.964,406.921c-1.522,0.145-2.189,0.628-2.16,2.398c-0.394,0.683-0.029,1.716-0.72,2.16
	c-1.199-1.063-3-1.471-4.32-2.398c-2.101-1.479-7.345-6.721-6.48-10.8c0.32-1.12,0.64-2.24,0.96-3.36
	c-1.36-0.791-2.964-1.581-4.56-2.158c-0.72-0.16-1.44-0.32-2.16-0.48c-2.177-1.413-1.946-4.838-5.28-5.76c-5.2,0-10.401,0-15.6,0
	c-7.257,0-15.819-0.924-22.32,0.479c-2.387,0.516-4.902,0.032-6.96,0.479c-3.089,0.674-6.151,0.313-8.88,0.961
	c-0.56,0-1.12,0-1.68,0c-2.005,0.813-3.832,2.611-6.48,2.881c-3.659-5.631-12.606-4.633-19.92-6.721
	c-5.386-1.537-10.583-3.397-15.6-5.521c-5.083-2.149-10.158-4.506-17.28-4.56c0-0.16,0-0.32,0-0.48
	c2.334-1.109,4.798-3.023,6.24-5.039c0.747-1.045,1.056-2.438,1.92-3.361c-0.809-3.652-4.182-5.068-4.8-8.639
	c2.19,0.318,3.696,1.455,5.76,1.92c2.648,0.594,8.143-2.755,9.6-3.84c0.608-0.453,0.827-1.796,1.44-2.16
	c1.23-0.732,3.312-0.387,4.56-1.201c3.641-2.375,4.218-6.252,9.84-6.959c0.554,0.633,1.318,1.084,1.68,1.92
	c0.928,2.144,0.331,5.735,2.88,6.24c1.845-1.255,4.801-1.917,7.2-2.4c0.782-0.157,1.114,0.189,1.68,0.24
	c2.56,0.227,4.999-0.232,6.48-1.199c1.424-0.933,2.439-2.646,3.84-3.602c3.007-2.051,6.176-3.455,9.12-5.52
	c3.861-2.707,7.505-5.797,11.28-8.641c1.671-1.259,2.903-3.308,4.56-4.56c0.907-0.688,1.966-1.271,2.64-2.16
	c1.097-1.451,2.507-3.423,4.32-4.08c1.144,0.833,2.666,0.546,4.32,0.959c4.904,1.229,10.846,0.692,16.08,1.921
	c3.176,0.745,6.761,0.955,9.6,2.159c0.999,0.424,2.181,1.544,3.12,1.92c0.72,0,1.44,0,2.16,0c1.842,0.451,3.936,0.646,6,1.201
	c4.55,1.223,9.673,2.696,14.64,3.118c0.437,1.351,1.282,2.646,1.68,4.32c0.16,2.959,0.32,5.92,0.48,8.881
	c0.622,2.349,1.792,4.494,2.4,6.959c0.615,2.49-0.033,5.168,0.72,7.439c0.424,1.28,1.522,2.572,1.92,3.84c0,2.4,0,4.801,0,7.201
	c0.632,2.507,2.849,4.957,2.88,7.68c0.009,0.756-0.879,1.141-0.96,2.399c0.608,0.358,0.759,0.362,0.96,1.2
	c-1.083,1.215-1.481,4.277-1.92,6c-0.973,0.41-2.479,0.9-2.88,1.92c-0.355,0.578-0.085,1.047,0,1.92
	c-4.438,2.927-8.381,8.305-12.24,12C247.804,405.001,247.884,405.961,247.964,406.921z'
          />{' '}
        </a>
        <a xlinkTitle={`Matmata ${groupedData['Matmata']}`}>
          <path
            onClick={() => setSelectedVill('Matmata')}
            style={{ fill: getColorBasedOnCount(outputData['Matmata']) }}
            d='M245.324,412.44c-0.08,0.399-0.16,0.801-0.24,1.199c-0.627,0.039-0.815,0.104-1.2,0.24
	c0.662,4.754-1.904,13.932-4.8,16.08c-2.131,1.58-6.846,3.402-8.16,5.52c-1.41,2.272-2.11,5.074-3.36,7.44
	c-2.772,5.249-5.935,10.841-7.68,17.04c-0.295,1.05,0.085,2.916,0.24,3.6c0.08,1.601,0.16,3.2,0.24,4.801
	c0.576,2.454,1.241,5.394,1.44,7.92c-0.547,0.294-0.54,0.394-1.44,0.479c-1.859-1.938-6.006-4.379-9.36-1.921
	c-1.574,1.154-1.87,3.47-4.08,4.08c-2.631,0.729-9.073-1.65-11.04-2.159c-1.205,1.593-3.275,3.561-5.52,4.079
	c-1.556-2.516-4.507-1.418-7.92-2.159c-4.399-0.956-12.383-0.995-15.6,0.96c-3.346,2.033-5.736,5.167-10.08,6.24
	c-3.265,0.807-8.062-2.301-10.32-3.121c-3.375-1.226-6.508,0.019-9.12,0.24c-1.327-2.042-3.595-3.553-5.04-5.52
	c-1.301-1.771-2.582-3.808-3.6-5.761c-0.424-0.813-0.408-1.871-0.96-2.641c-0.455-0.634-2.667-1.699-3.36-2.399
	c-0.64,0-1.28,0-1.92,0c-0.065-7.106-5.838-9.874-8.64-14.398c-0.784-1.269-0.808-2.599-1.44-4.08
	c-0.068-0.161-0.683-0.613-0.72-0.721c-0.491-1.404,0.96-3.265,1.2-4.32c0.442-1.949-1.146-4.446-1.92-5.521
	c-2.953-4.093-10.091-7.774-14.4-10.8c-3.509-2.464-6.407-5.879-9.84-8.398c-2.638-1.938-8.746-5.348-9.84-8.4
	c1.09-0.678,1.881-1.852,3.12-2.4c2.586-1.146,7.499-1.604,7.68-5.039c-1.134-1.752-0.26-5.588,0.24-7.201
	c1.96-6.325,6.515-8.418,10.32-12.719c0.364-0.412,1.26-1.485,1.44-1.92c0.472-1.141-0.292-1.918,0.48-2.641
	c4.118-2.953,11.371-3.271,17.52-1.199c7.313,2.463,14.147,5.686,21.6,8.159c5.494,1.824,12.212,1.438,17.28,3.601
	c2.138,0.912,3.132,3.16,5.04,4.08c0.627-0.586,5.83-3.065,6.72-3.361c2.56-0.238,5.121-0.479,7.68-0.719
	c2.906-0.637,6.408-0.041,9.6-0.721c6.375-1.357,14.719-0.479,21.84-0.48c5.2,0,10.401,0,15.6,0c2.859,0.84,2.82,4.488,5.04,5.762
	c1.819,1.042,5.034,0.558,6,2.398c-1.34,1.854-0.468,5.269,0.24,6.961C235.913,406.496,240.723,411.782,245.324,412.44z'
          />{' '}
        </a>

        <text
          transform='matrix(1 0 0 1 87.0137 278.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Hamma
        </text>
        <text
          transform='matrix(1 0 0 1 152.0137 367.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nouvelle Matmata
        </text>
        <text
          transform='matrix(1 0 0 1 119.7339 434.7773)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Matmata
        </text>
        <text
          transform='matrix(1 0 0 1 201.0137 223.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Métouia
        </text>
        <text
          transform='matrix(1 0 0 1 78.0137 182.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Menzel El Habib
        </text>
        <text
          transform='matrix(1 0 0 1 274.0137 368.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Mareth
        </text>
        <text
          transform='matrix(1 0 0 1 249.0137 246.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ghannouch
        </text>
        <text
          transform='matrix(1 0 0 1 274.0137 280.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Gabès Médina
        </text>
        <text
          transform='matrix(1 0 0 1 293.0132 299.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Gabès Sud
        </text>
        <text
          transform='matrix(1 0 0 1 260.0137 262.3398)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Gabès Ouest
        </text>
        <line
          fill='none'
          stroke='#666666'
          x1='232.124'
          y1='254.32'
          x2='244.124'
          y2='246.32'
        />
        <line
          fill='none'
          stroke='#666666'
          x1='223.124'
          y1='267.319'
          x2='251.124'
          y2='259.32'
        />
        <line
          fill='none'
          stroke='#666666'
          x1='250.124'
          y1='276.319'
          x2='267.124'
          y2='277.319'
        />
        <line
          fill='none'
          stroke='#666666'
          x1='249.124'
          y1='295.319'
          x2='288.125'
          y2='297.319'
        />
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Beja;
