import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Kasserine = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');

  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='563.207px'
        height='548.977px'
        viewBox='0 0 563.207 548.977'
        enable-background='new 0 0 563.207 548.977'
      >
        <a xlinkTitle={`Thala ${groupedData['Thala']}`}>
          <path
            onClick={() => setSelectedVill('Thala')}
            style={{ fill: getColorBasedOnCount(outputData['Thala']) }}
            d='M129.598,94.673c4.675-0.01,4.951,3.223,8.16,4.32c5.237,1.791,9.167-3.792,14.16-2.4
	c1.199,0.334,2.399,1.326,3.36,1.92c4.492,2.782,8.863,6.648,13.44,8.88c-2.354,6.176-0.106,11.832,0.72,17.52
	c-11.546,2.276-15.742,3.521-21.12,12.24c-1.18,1.912-3.293,5.13-2.4,8.88c0.449,1.888,1.163,4.236,2.16,5.76
	c0.519,0.793,1.624,1.379,1.92,2.4c0.533,1.838-0.645,4.16-0.96,5.52c-0.16,3.759-0.32,7.52-0.48,11.28
	c-0.16,1.36-0.32,2.72-0.48,4.08c0.461,1.576,1.978,3.287,2.64,4.8c1.597,3.646,2.764,7.317,4.8,10.56
	c1.431,2.279,3.218,3.866,4.08,6.96c1.633,5.858-0.978,13.243,0.48,19.44c1.99,8.458,4.929,15.484,5.04,25.44c-0.08,0-0.16,0-0.24,0
	c-1.338-1.229-3.463-1.662-5.04-2.64c-3.657-2.269-7.264-4.715-10.8-7.2c-2.319-1.63-5.931-5.404-8.88-6
	c-0.633,1.431-1.459,2.924-1.92,4.56c-1.656,5.875-0.478,14.198-7.68,14.4c-0.522-0.551-1.271-1.028-1.68-1.68
	c-0.582-0.927-0.701-2.125-1.44-2.88c-0.63-0.643-1.624-0.677-2.4-1.2c-1.399-0.942-2.445-2.65-3.84-3.6
	c-2.243-1.527-4.858-2.172-7.2-3.6c-2.529-1.542-4.782-3.669-7.2-5.28c-0.88-0.586-2.076-0.751-2.88-1.44
	c-0.24-0.64-0.48-1.28-0.72-1.92c-1.522-1.51-7.88-1.461-10.08-2.64c1.209-3.958,0.944-13.396,4.08-14.88c2.959,0,5.92,0,8.88,0
	c2.39-1.424,5.063-0.411,5.04-4.32c-0.302-0.326-0.327-0.424-0.48-0.96c-6.945-3.278-14.695-6.233-23.04-8.16c0-1.84,0-3.68,0-5.52
	c0.771-1.179,1.787-8.932,1.2-11.28c-1.67-6.685-6.587-9.309-6.72-17.76c0.364-0.128,0.406-0.162,0.96-0.24
	c1.174,1.033,3.783,1.295,5.52,1.68c1.698,0.376,3.714-0.649,4.8,0c1.924,0.72,1.402,2.354,4.32,2.4
	c0.963-1.414,2.426-2.632,3.12-4.32c2.47-6.008-3.352-8.898-3.84-13.44c3.465-0.9,6.966-3.771,8.16-6.96
	c0.16-0.88,0.32-1.76,0.48-2.64c0.731-1.346,2.419-2.349,2.88-4.08c0.568-2.137-1.22-7.364-1.68-9.12
	c-1.063-4.055-0.554-8.645,0.24-12.24c8.289-1.565,10.775-7.66,16.32-11.76c1.201-0.888,2.889-1.871,4.32-2.4
	C128.454,94.866,129.044,95.073,129.598,94.673z'
          />{' '}
        </a>
        <a xlinkTitle={`Jediliane ${groupedData['Jediliane']}`}>
          <path
            onClick={() => setSelectedVill('Jediliane')}
            style={{ fill: getColorBasedOnCount(outputData['Jediliane']) }}
            d='M169.438,108.113c5.038-0.348,8.137,3.336,11.76,5.76c3.229,2.159,9.092,5.297,12.961,6.48
	c2.971,0.908,6.252,1,9.119,1.68c1.474,0.349,3.659-0.248,4.801,0.48c1.566,0.546,1.865,2.046,2.879,3.12
	c-0.066,0.994,0.258,1.999,0,2.88c-0.551,1.891-3.529,4.002-2.398,7.44c0.355,1.084,1.428,1.45,2.16,2.16
	c3.035,0.015,3.966-1.524,6.238-1.44c0.287,2.049,1.383,1.945,1.441,4.32c-1.232,1.475-1.188,3.839-0.721,5.76
	c0.797,0.55,1.445,1.583,2.4,1.92c2.832,0.999,6.547-0.609,8.879-1.2c1.281-0.08,2.561-0.16,3.841-0.24
	c1.207-0.419,1.913-1.415,3.601-1.68c0,0.08,0,0.16,0,0.24c0.079,0,0.159,0,0.239,0c0.326,3.281,1.667,7.256,3.12,9.6
	c0.506,0.817,1.479,1.271,1.92,2.16c-3.171,1.229-0.813,8.773-0.721,12.24c-0.159,0-0.319,0-0.479,0
	c-2.452,1.556-10.659-0.637-13.2-1.2c-7.701-1.707-10.173,2.709-13.92,5.521c-1.928,1.446-3.929,2.268-4.08,5.52
	c0.863,0.902,1.354,2.167,2.4,2.88c1.255,0.853,3.234,1.171,4.08,2.4c0.874,1.269,1.05,2.875,1.68,4.32
	c2.483,5.698,3.567,7.924,3.6,16.56c-0.238,0.08-0.479,0.16-0.719,0.24c-1.145,0.839-2.856,0.632-4.32,1.2
	c-3.387,1.314-6.945,3.717-9.84,5.76c-1.572,1.111-3.444,2.691-4.801,4.08c-0.32,0.48-0.64,0.96-0.959,1.44
	c-0.775,0.483-1.654,0.253-2.16,0.96c-0.738,1.029-0.557,2.99-1.681,3.6c-0.8,0.16-1.601,0.32-2.399,0.48
	c-0.854,0.588-0.836,2.276-1.681,2.88c-1.348,0.963-3.544,0.619-5.28,1.2c-3.277,1.521-6.56,3.04-9.84,4.56
	c-3.338,2.07-5.397,5.054-7.92,7.92c-0.824,0.938-2.078,2.736-3.12,2.64c-0.661-1.128,0.103-3.298-0.24-4.8
	c-0.391-1.71-0.444-3.686-0.96-5.52c-1.62-5.753-2.914-11.492-4.32-17.76c-1.223-5.45,1.27-11.132,0-16.32
	c-1.083-4.423-2.846-5.971-4.8-9.12c-2.149-3.463-3.362-7.45-5.04-11.28c-0.412-0.94-1.64-2.222-1.92-3.12
	c-0.277-0.889,0.469-2.861,0.48-4.08c0.034-3.76-0.176-8.573,0.72-12c0.29-1.108,1.287-3.307,0.72-5.04
	c-0.311-0.95-1.42-1.635-1.92-2.4c-1.687-2.583-2.957-7.425-1.44-11.04c2.543-6.058,6.874-10.342,13.44-12.48
	c2.993-0.975,7.541-0.619,9.84-2.4c-0.979-1.275-2.923-9.227-2.4-11.76C168.845,111.857,169.296,109.897,169.438,108.113z'
          />{' '}
        </a>
        <a xlinkTitle={`Hidra ${groupedData['Hidra']}`}>
          <path
            onClick={() => setSelectedVill('Hidra')}
            style={{ fill: getColorBasedOnCount(outputData['Hidra']) }}
            d='M81.358,156.593c-0.08,1.6-0.16,3.2-0.24,4.8c1.364,5.637,4.93,8.842,6.48,13.92
	c1.143,3.745-0.249,8.684-0.96,11.76c0,2.08,0,4.16,0,6.24c5.589,2.821,13.149,3.627,18.72,6.48c1.819,0.932,4.543,0.853,4.8,3.36
	c-0.193,0.245-0.085,0.066-0.24,0.479c-3.152,1.827-7.958,1.691-13.2,1.68c-2.712,4.372-4.187,8.851-4.56,15.6
	c-4.16,0.32-8.32,0.64-12.48,0.96c-1.84,0.64-3.68,1.28-5.52,1.92c-1.76,0-3.52,0-5.28,0c-3.364-5.939-5.339-14.734-4.32-24.24
	c-2.838-1.027-4.933-3.888-9.84-1.92c-3.039,1.218-5.511,3.392-8.4,4.8c-0.801,0.39-2.66,0.805-3.6,0.24
	c-1.598-0.523-1.71-1.858-2.88-2.64c-0.971-0.649-3.026-0.283-3.84-0.479c-5.026-8.735-4.507-22.838-7.2-33.84
	c-0.725-2.96-0.833-10.504,0.96-12c0.588-0.378,1.691-0.188,2.4-0.48c1.178-0.485,2.879-1.89,3.6-2.88
	c3.73-5.125,4.575-10.33,10.32-13.68c2.708-1.579,5.942-1.116,9.6-1.92c15.902-3.494,27.661,3.751,35.76,11.76
	c2.2-0.011,2.905-0.963,4.56-1.44c0.903,5.244,6.697,7.07,2.88,13.68c-0.631,1.093-0.948,2.104-2.4,2.4
	c-0.836-0.914-1.795-1.739-2.64-2.64c-2.269,0.049-4.304,0.568-6.48,0C85.359,157.873,83.358,157.232,81.358,156.593z'
          />{' '}
        </a>
        <a xlinkTitle={`Sbiba ${groupedData['Sbiba']}`}>
          <path
            onClick={() => setSelectedVill('Sbiba')}
            style={{ fill: getColorBasedOnCount(outputData['Sbiba']) }}
            d='M221.997,206.272c0.117-9.988-1.483-12.479-4.319-18.96c-0.453-1.036-0.39-1.916-0.96-2.88
	c-1.497-2.531-5.833-2.213-6.48-5.76c2.131-2.564,6.905-7.696,10.08-8.88c4-1.49,10.049,0.348,13.68,1.2
	c2.688,0.631,5.283-0.081,7.682-0.24c0-0.16,0-0.32,0-0.48c1.727-2.891-2.102-8.075-0.479-11.04c0.771-1.413,2.324-2.119,3.84-2.88
	c7.531-3.784,16.022-0.294,22.08,2.16c1.098,0.445,2.571,0.349,3.6,0.72c2.143,4.59-1.063,11.925,2.88,14.88
	c1.671,1.252,6.214,3.396,8.399,3.84c0.641,0.13,2.117-0.367,2.641-0.48c2.002-0.434,5.104,0.199,6.721,0.72
	c9.112,2.931,20.365,13.518,20.883,24.72c-2.688,1.721-5.197,4.045-7.92,5.76c-5.807,3.657-11.497,6.803-16.803,10.8
	c-0.961,1.04-1.92,2.081-2.881,3.12c-4.436,4.438-8.08,9.158-11.76,14.4c-1.885,2.686-4.471,5.175-6,8.16
	c-0.16-0.08-0.32-0.16-0.48-0.24c-0.438-1.291-0.259-3.593-0.24-5.28c-0.858-1.434-0.264-4.042-0.72-5.76
	c-0.942-3.548-2.088-7.207-3.84-10.08c-1.06-1.739-5.905-8.427-7.44-9.12c-0.72,0-1.438,0-2.16,0
	c-1.199-0.301-3.313-0.939-4.318-1.68c-1.068-0.786-1.828-2.519-2.641-3.6c-2.018-2.685-5.256-4.418-9.121-5.28
	C231.395,203.104,226.012,206.123,221.997,206.272z'
          />{' '}
        </a>
        <a xlinkTitle={`Foussana ${groupedData['Foussana']}`}>
          <path
            onClick={() => setSelectedVill('Foussana')}
            style={{ fill: getColorBasedOnCount(outputData['Foussana']) }}
            d='M161.998,259.792c-2.885,0.272-5.18,1.895-7.44,2.88c-5.119,2.231-10.216,3.8-14.88,6.24
	c-2.125,1.111-5.935,1.929-6,5.04c0.905,0.947,1.332,2.339,2.4,3.12c2.552,1.867,7.739,5.673,8.64,8.88c-0.16,0-0.32,0-0.48,0
	c-1.726-1.593-6.824-3.056-10.08-3.119c-1.305,1.244-2.789,2.092-3.84,3.601c-0.56,1.041-1.12,2.08-1.68,3.121
	c-0.72,0.237-1.44,0.479-2.16,0.719c-1.12,1.041-2.24,2.08-3.36,3.121c-1.723,2.319-2.955,5.271-5.28,6.959
	c-2.662,1.938-6.16,2.666-9.36,4.08c-1.263,0.561-2.456,1.672-3.6,2.397c-0.74-2.703-3.314-2.164-5.28-3.601
	c-1.281-0.938-0.172-2.08-2.64-2.16c0,0.08,0,0.16,0,0.24c-0.242,0.234-0.237-0.235-0.24-0.24c-0.853-1.166-0.434-1.733-2.64-1.68
	c0-0.16,0-0.32,0-0.479c0.192-0.283,0.186-0.404,0.24-0.959c-0.642-0.313-1.977-0.408-2.16-0.722
	c-2.269-2.813-4.884-4.414-8.16-6.239c-0.672-0.375-2.621-1.804-3.12-1.439c-2.916,2.369-4.08,9.193-7.44,11.761
	c-0.732,0.563-2.022,1.688-2.88,1.92c-1.714,0.469-3.295-1.096-4.8,0c-0.4,0.643-0.8,1.279-1.2,1.92
	c-1.362,0.947-7.543,1.359-9.12,0.24c-0.729-0.804-1.212-1.484-1.2-2.396c1.351-1.092,1.695-3.287,2.64-4.805
	c1.878-3.006,7.56-4.89,6.72-9.601c-0.16,0-0.32,0-0.48,0c-0.257-0.182-0.227-0.146-0.72-0.239c-0.862,0.922-1.896,1.849-3.6,1.92
	c-1.489-1.412-4.039-3.043-6.24-3.601c-2.278-0.573-5.348,0.797-7.2,0.24c-2.926-0.882-3.998-5.041-6.24-6.722
	c-1.414-1.063-2.982-1.145-4.8-1.92c-0.975-0.416-1.997-1.387-2.88-1.92c-2.694-1.64-5.763-2.385-9.36-3.123
	c-0.62-2.973-1.717-5.787-2.88-8.4c-0.733-1.647-2.207-2.574-2.4-4.8c1.252-1.907,2.531-8.871,1.68-12
	c-0.505-1.858-2.35-3.605-1.68-6.48c1.15-4.932,4.335-8.181,4.8-13.44c-0.997-0.623-1.998-0.757-3.84-0.72c0-0.479,0-0.96,0-1.44
	c1.148-0.571,3.514-0.97,4.08-2.16c1.21-1.417,1.525-4.096,2.4-5.76c1.304-2.479,3.23-4.545,4.8-6.72
	c0.518-0.717,1.144-2.514,1.92-2.88c1.68,0,3.36,0,5.04,0c0.315-0.298,1.258-0.58,1.44-0.96c0-2.32,0-4.641,0-6.96
	c0.4,0,0.8,0,1.2,0c0.853,1.12,2.396,2.9,3.84,3.36c4.025,1.281,9.396-3.946,12-5.04c2.425-1.019,7.815-1.082,8.88,1.2
	c0,2.64,0,5.28,0,7.92c0.843,1.459,0.271,4.025,0.72,5.76c0.644,2.491,1.5,5.093,2.4,7.2c0.418,0.979,0.822,3.381,1.68,3.84
	c2.08,0,4.16,0,6.24,0c2.408-1.735,6.501-1.683,9.84-2.4c1.12,0,2.24,0,3.36,0c0.955-0.203,3.769-0.777,4.8-0.479
	c1.051,0.302,2.747,1.104,4.08,1.44c1.959,0.494,4.357,0.13,5.76,1.2c0.4,0.72,0.8,1.44,1.2,2.16c1.146,0.838,2.461,1.123,3.6,1.92
	c1.902,1.333,3.754,3.079,5.76,4.32c2.918,1.805,6.201,2.809,8.88,4.8c0.56,0.64,1.12,1.28,1.68,1.92
	c1.189,0.885,3.009,1.421,3.84,2.64c1.455,2.133,1.21,3.975,4.8,4.08c1.565-1.152,3.248-1.249,4.32-2.88
	c2.641-4.019,2.242-11.446,4.32-15.84c5.926,3.344,11.062,8.131,16.8,11.76c1.928,1.219,4.043,2.328,6,3.36
	c0.64,0.24,1.28,0.48,1.92,0.72c0.06,0.068-0.26,3.882-0.48,4.56C163.712,251.629,162.158,255.329,161.998,259.792z'
          />{' '}
        </a>
        <a xlinkTitle={`Sbeitla ${groupedData['Sbeitla']}`}>
          <path
            onClick={() => setSelectedVill('Sbeitla')}
            style={{ fill: getColorBasedOnCount(outputData['Sbeitla']) }}
            d='M241.917,325.792c-5.17,0.028-8.897-3.174-12.479-5.039c-1.151-0.601-3.485-0.767-4.08-1.92
	c1.056-1.142,2.613-5.834,2.882-7.681c-0.801-0.526-1.363-1.424-2.16-1.92c-2.994-1.867-7.373-1.91-12.24-1.92
	c-1.266-2.764-3.02-3.646-4.561-5.764c-1.918-2.635-2.777-6.879-5.521-8.641c-1.593-1.023-4.945-1.057-6.96-1.441
	c-0.52-1.057-0.62-2.018-2.16-2.156c-0.258,0.183-0.229,0.146-0.721,0.236c-1.529-4.086-3.93-6.262-6-9.602
	c-0.721-1.68-1.439-3.357-2.159-5.039c-1.397,0.506-3.127,0.057-4.32-0.24c-0.778-1.383-0.979-4.388-2.64-5.52
	c-1.516-1.033-4-0.854-5.521-1.92c-1.637-2.059-2.045-4.341-4.32-5.76c-1.566-0.978-4.25-0.947-5.758-1.92
	c-0.193-0.283-0.186-0.404-0.24-0.96c0.726-1.169,0.505-2.911,0.96-4.32c1.044-3.232,1.868-6.585,2.64-10.32
	c4.749-2.228,6.411-7.823,10.8-10.56c2.685-1.673,7.064-3.801,10.08-4.8c1.853-0.614,4.058-0.221,5.521-1.2
	c0.896-0.6,0.861-2.254,1.681-2.88c0.803-0.614,2.409-0.267,3.12-0.96c0.875-0.853,0.688-3.142,1.68-3.84
	c0.512-0.361,1.186-0.145,1.68-0.48c1.121-1.2,2.24-2.4,3.36-3.6c3.38-2.543,7.233-5.494,11.28-7.2
	c1.438-0.606,3.658-1.19,5.04-1.44c1.04,0.08,2.079,0.16,3.12,0.24c3.457-0.849,8.656-3.211,13.199-1.92
	c3.361,0.955,6.289,3.502,8.16,6c0.565,0.756,1.125,2.166,1.92,2.64c1.166,0.695,3.108,1.341,4.319,1.68c0.722,0,1.438,0,2.16,0
	c1.142,0.524,3.024,3.461,3.84,4.56c2.649,3.575,4.924,7.176,6.479,12c0.555,1.716,0.289,3.148,0.721,5.04
	c0.46,2.025,0.965,5.96,0.238,8.16c1.033,0.254,1.063,0.834,1.682,1.44c-0.948,2.069-2.249,4.113-3.12,6.24
	c-1.304,3.182-1.627,6.329-2.642,9.84c-0.375,1.301-1.581,4.574-0.959,5.76c1.596,4.315,9.383,5.283,13.439,7.2
	c1.645,11.818,16.694,14.314,24.722,20.158c0.896,0.654,1.389,1.846,2.158,2.643c0.021,1.416-0.233,2.107-0.479,3.119
	c-2.468,1.523-6.742,3.521-6.72,7.438c1.978,2.655,3.562,7.688,1.92,11.76c-0.832,2.063-2.478,3.863-2.88,6.24
	c1.787,1.229,3.283,3.315,5.277,4.563c4.152,2.59,12.629,5.029,13.438,10.563c-2.779,1.897-4.438,5.733-6.24,8.641
	c-2.751,4.424-5.964,8.781-8.64,13.199c-3.282-0.638-8.25-1.771-12-0.722c-2.178,0.606-7.896,4.063-9.84,3.603
	c-0.711-0.17-1.752-0.758-2.4-0.961c-2.126-0.666-4.986,1.016-5.04,0.961c-2.642-2.154-3.88-6.443-5.521-9.603
	c-3.445-6.631-6.514-13.647-10.8-19.438c-1.452-1.961-3.762-4.109-5.761-5.521c-1.042-0.731-2.132-0.59-2.398-2.157
	C241.479,329.311,241.891,327.193,241.917,325.792z'
          />{' '}
        </a>
        <a xlinkTitle={`Kasserine Sud ${groupedData['Kasserine Sud']}`}>
          <path
            onClick={() => setSelectedVill('Kasserine Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Kasserine Sud']) }}
            d='M185.278,276.111c0.586,4.479,4.566,7.688,6.721,10.802c0.684,0.985,0.332,2.772,1.439,3.358
	c0.791,0.545,1.313-0.145,1.68,0.479c0.504,0.652,0.322,1.086,1.201,1.439c1.682,1.26,5.207,0.514,6.959,1.682
	c2.566,1.709,3.467,5.896,5.28,8.398c1.483,2.049,4.05,2.94,4.32,6.237c4.627-0.438,13.446-0.188,14.159,3.603
	c-1.301,1.799-1.053,5.736-3.119,6.721c-1.621-2.283-5.631-1.691-9.36-1.92c-0.917-0.057-2.063-0.688-2.399-0.479
	c-0.297,0.438-0.262,1.365-0.239,2.156c0.965,1.389,1.53,3.748,1.92,5.521c-1.279,0.854-1.961,2.367-2.882,3.601
	c-1.764,2.365-4.16,4.399-6.238,6.479c-0.8,0.883-1.602,1.764-2.398,2.643c-2.443,1.838-5.207,2.879-7.92,4.563
	c-4.345,2.688-8.73,5.676-12.962,8.644c-1.92,1.346-3.84,2.879-5.76,4.315c-1.306,0.979-2.354,2.854-3.84,3.604
	c-1.543,0.776-3.515,0.041-5.52,0.479c-3.093,0.676-6.856,0.119-10.08,0.961c-3.116,0.813-4.591,3.033-8.16,2.879
	c-0.094,3.229,1.031,4.771,2.64,6.24c-0.066,3.072-3.769,7.031-3.36,8.156c0.913,3.115,4.92,3.024,5.28,6.725
	c-2.56,2.479-5.12,4.959-7.68,7.438c-0.879,0.961-1.76,1.92-2.64,2.881c-4.963,3.693-10.558,6.488-14.16,11.521
	c-0.821,1.146-1.669,2.729-2.16,4.08c-0.251,0.688-0.129,1.615-0.48,2.16c-0.629,0.58-7.072,2.985-7.44,2.64
	c-1.48-1.021-2.445-2.665-4.32-3.358c-2.847-1.053-6.316-0.094-9.36,0c-0.08-0.238-0.16-0.479-0.24-0.721
	c-2.702-3.574-0.023-10.82-1.2-16.08c-0.444-1.984-0.946-3.742-1.68-5.279c1.479-0.858,6.18-2.123,6.72-3.6
	c0.577-0.625,0.422-1.039,0.24-1.921c-0.48,0-0.96,0-1.44,0c-0.224,0.22-0.421,0.319-0.72,0.479
	c-0.416-2.486-2.712-2.189-3.84-3.844c-1.754-2.563-2.342-5.854-4.8-7.68c-2.181-1.621-4.297-1.143-4.32-5.039
	c-0.607-0.973-0.459-2.58-0.96-3.604c-1.492-3.039-4.442-6.979-4.56-11.041c6.669-3.459,17.1-5.406,19.68-12.722
	c1.94-5.498-2.712-8.188-4.56-10.799c-1.215-1.72-2.19-6.578-1.92-9.358c0.611-6.283,2.063-10.589,6.24-13.201
	c3.44-2.148,8.043-2.699,11.28-5.039c3.66-2.646,5.094-9.232,9.84-10.562c0.031,6.758,6.05,4.535,6.24,9.84
	c-0.447,0.601-0.65,1.451-0.72,2.399c1.119,0.353,2.137,1.138,3.36,1.438c2.071,0.516,7.719-0.636,9.12,0.239
	c0.24,0.802,0.48,1.603,0.72,2.397c1.745,3.414,3.814,6.74,3.84,12c4.092,2.365,7.482,4.556,12.96,5.763
	c0.929,0.204,2.324-0.121,2.88-0.24c3.735-0.81,7.219-0.967,11.28-0.961c1.567-4.885,6.854-7.813,5.04-15.356
	c-1.25-0.521-3.198-0.946-3.36-2.644c0.859-1.08,1.782-2.92,2.16-4.315c-3.483-1.748-5.039-2.528-10.319-2.16
	c-0.822-2.978-4.968-4.784-7.201-6.479c-0.963-0.729-1.022-2.029-2.4-2.398c-0.663,0.443-3.707,0.511-4.8,0.238
	c-2.704-0.662-5.127-1.924-7.44-2.881c-1.049-0.435-2.287-0.33-3.12-0.959c-0.91-0.965-1.131-2.506-1.92-3.602
	c-0.761-1.057-2.068-1.844-3.12-2.643c-0.72-0.88-1.44-1.761-2.16-2.64c-1.854-1.425-3.922-2.132-4.56-4.801
	c1.88-1.088,3.746-2.706,5.76-3.6c4.616-2.05,9.107-3.541,13.92-5.52c2.358-0.97,4.774-2.466,7.44-3.12
	c3.792-0.93,8.437,2.685,9.601,4.56c0.399,1.2,0.801,2.4,1.199,3.6c1.857-0.493,5.776,0.31,6.479,2.4
	c0.74,0.832,0.584,2.173,0.722,3.12C181.438,275.553,182.149,276.146,185.278,276.111z'
          />{' '}
        </a>
        <a xlinkTitle={`Kasserine Nord ${groupedData['Kasserine Nord']}`}>
          <path
            onClick={() => setSelectedVill('Kasserine Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Kasserine Nord']) }}
            d='M170.638,300.352c2.967-0.313,8.815-1.168,9.601,1.92c-1.391,1.104-2.072,3.104-3.121,4.563
	c-1.799,2.504-4.291,4.129-6.479,6.24c-4.8-0.013-7.423-3.134-12-2.16c-0.616,1.354-2.16,6.239-3.36,6.719
	c-0.709,0.563-2.037,0.517-3.36,0.479c-0.218-0.262,0.019-0.021-0.24-0.239c-0.164-4.479-1.985-7.795-3.6-10.799
	c-0.439-0.818-0.423-2.632-1.2-3.121c-2.238-1.382-6.416,0.25-9.36-0.479c-0.88-0.32-1.76-0.643-2.64-0.961
	c0.2-1.488,0.925-3.402,0-4.801c-2.003-3.023-5.683-1.523-5.76-7.199c1.98-2.18,2.679-6.445,6.48-6.721
	c1.657,1.059,4.846,1.336,6.72,2.16c6.084,2.672,10.873,5.459,19.68,5.52C163.797,294.87,170.063,296.346,170.638,300.352z'
          />
        </a>
        <a xlinkTitle={`Feriana ${groupedData['Feriana']}`}>
          <path
            onClick={() => setSelectedVill('Feriana')}
            style={{ fill: getColorBasedOnCount(outputData['Feriana']) }}
            d='M81.118,290.752c1.754,0.266,8.583,4.604,9.6,5.76c0.975,1.105-0.061,1.871,1.92,2.16
	c0.245-0.193,0.065-0.086,0.48-0.24c-0.089,1.904,0.274,2.033,2.16,1.92c0.261,1.256,0.592,1.892,2.16,1.92
	c0.443,0.021,0.215-0.227,0.48,0.24c0.355,0.355,0.363,1.09,0.72,1.439c1.483,1.457,4.237,0.65,5.04,2.881
	c0.21,0.311,0.214,0.592,0.24,1.199c-4.201,2.385-5.014,14.377-2.88,19.92c0.93,2.418,3.538,3.854,4.8,6
	c0.859,1.461,0.944,4.313,0.24,6c-1.649,3.957-8.432,7.451-12.48,9.121c-3.369,1.389-7.006,0.551-6.96,5.52
	c1.522,1.998,1.775,4.24,2.88,6.721c0.172,0.392,0.758,0.646,0.96,0.959c1.326,2.066,1.647,4.854,1.68,8.16
	c6.163,1.646,6.26,5.81,9.36,10.08c0.67,0.926,1.679,1.01,2.64,1.68c0.983,0.688,0.698,2.146,1.92,2.646
	c0.291-0.532,0.353-0.313,1.2-0.479c0,0.16,0,0.32,0,0.479c-0.08,0-0.16,0-0.24,0c-1.916,1.813-6.539,1.021-6.72,4.563
	c3.308,2.798,0.527,11.641,1.68,16.801c0.536,2.396,1.102,4.088,2.16,5.76c4.576-1.004,11.319-1.222,12.72,2.881
	c-0.08,0-0.16,0-0.24,0c-1.81,1.705-5.025,2.301-7.439,3.359c-4.6,2.018-12.608,7.901-20.16,5.041
	c-2.684-1.021-7.214-4.689-9.6-6.48c-0.72-0.801-1.44-1.6-2.16-2.398c-1.262-0.92-2.776-1.26-4.08-2.159
	c-0.72-0.8-1.44-1.603-2.16-2.396c-0.969-0.731-2.047-1.299-2.88-2.16c-3.396-0.026-7.567,0.539-10.56-0.237
	c-1.526-0.397-2.771-2.089-5.04-1.44c-3.819,1.09-6.108,5.369-9.12,7.44c-4.625,3.181-12.469,5.995-18.24,7.921
	c-3.323,1.104-16.465,3.213-18.479-0.24c-1.644-1.847-1.829-5.013-2.64-7.681c-1.363-4.485-2.225-9.5-4.08-13.44
	c4.108-1.006,8.028-5.149,11.52-7.199c1.496-0.877,4.386-0.77,5.76-1.68c0.804-1.271,0.503-3.172,0.96-4.801
	c0.868-3.094,1.796-6.863,1.92-10.563c0.061-1.82-0.741-7.729-0.48-8.396c0.836-2.158,2.417-4.658,3.36-6.962
	c2.921-7.13,4.071-14.798,8.16-20.642c1.785-2.553,4.727-4.597,7.2-6.479c0.486-0.371,0.579-0.938,0.96-1.438
	c2.1-2.771,4.845-5.088,6.72-8.157c1.091-1.787,1.64-4.209,2.16-6.479c0-1.117,0-2.238,0-3.357c0.943-3.434,3.341-6.528,5.04-9.358
	c0.26,0.22,0.021-0.021,0.24,0.239c0.473,0.441,0.38,1.078,0.96,1.438c1.68,1.305,8.791,1.147,10.56,0
	c0.692-0.449,0.824-1.701,1.44-2.158c1.214-0.899,3.966,0.674,5.52-0.24C76.294,301.182,78.863,295.313,81.118,290.752z'
          />{' '}
        </a>
        <a
          xlinkTitle={`Ezzouhour  (Kasserine) ${groupedData['Ezzouhour  (Kasserine)']}`}
        >
          <path
            onClick={() => setSelectedVill('Ezzouhour  (Kasserine)')}
            style={{
              fill: getColorBasedOnCount(outputData['Ezzouhour  (Kasserine)']),
            }}
            d='M178.319,306.592c1.021,0.955,3.443,2.018,3.84,3.119c1.619,2.547-2.937,9.445-3.84,10.801
	c-0.371,0.555-0.611,1.883-1.201,2.16c-0.959,0-1.92,0-2.879,0c-0.974,0.633-2.789,0.195-4.08,0.479
	c-6.536,1.438-13.74-0.383-17.279-4.076c1.458,0.018,2.276-0.232,3.36-0.48c1.202-1.994,2.059-4.766,3.12-6.961
	c3.562-0.271,8.502,3.371,11.52,2.4C173.821,313.086,176.825,309.105,178.319,306.592z'
          />{' '}
        </a>
        <a xlinkTitle={`Hassi Ferid ${groupedData['Hassi Ferid']}`}>
          <path
            onClick={() => setSelectedVill('Hassi Ferid')}
            style={{ fill: getColorBasedOnCount(outputData['Hassi Ferid']) }}
            d='M212.878,317.631c2.846,0.271,7.084-0.082,9.36,0.961c0.688,0.314,1.071,1.396,1.68,1.68
	c0.56,0,1.119,0,1.68,0c0.756,0.228,1.777,0.584,2.641,0.961c4.097,1.783,7.362,5.021,12.719,5.521
	c-0.053,1.674-0.234,4.066,0.48,5.039c0.936,0.922,2.29,1.371,3.36,2.16c2.269,1.674,4.616,4.412,6.239,6.721
	c2.869,4.076,6.108,10.438,8.16,15.119c0.787,1.797,1.178,3.42,2.16,5.041c1.391,2.291,3.662,4.92,4.561,7.438
	c-1.68,1.604-3.36,3.199-5.04,4.801c-1.694,1.046-4.757,0.925-7.2,1.199c-1.089,1.67-2.145,3.041-2.88,5.279
	c-0.261,0.795-0.087,2.041-0.72,2.4c-0.961,0.078-1.92,0.158-2.881,0.238c-2.257,0.813-4.461,2.063-5.761,3.84
	c-3.104,4.252-4.979,9.764-11.521,10.561c-0.437,4.629-5.576,7.275-2.881,12.479c0.51,0.979,0.347,1.5,1.44,1.92
	c1.999,1.691,7.638-0.039,8.64,2.643c0.297,0.439,0.263,1.369,0.24,2.16c-5.292,4.873-11.824,8.225-17.76,12.479
	c-1.646,1.187-3.633,4.646-5.279,5.281c0-0.08,0-0.16,0-0.24c-0.769-0.844-0.601-1.521-2.16-1.682
	c-2.627,4.221-6.332,1.283-10.801,3.121c-3.121,1.283-5.687,3.807-8.4,5.52c-5.42,3.431-10.928,6.257-16.318,9.604
	c-1.098,0.682-7.268,4.543-8.398,3.84c-1.396-1.25-1.875-3.897-2.882-5.521c-2.614-4.21-5.2-7.953-6.479-13.683
	c-4.842-2.768-9.574-6.371-9.84-13.68c-1.888-0.681-7.551-5.863-9.12-7.438c-1.18-1.188-1.673-3.174-3.84-3.358
	c-1.345,0.877-7.461,1.739-9.12,1.438c-0.134-0.389-0.192-0.578-0.24-1.201c0.803-1.002,0.986-2.504,1.68-3.602
	c1.149-1.813,4.289-5.207,6-6.479c2.07-1.541,4.353-2.584,6.48-4.08c2.073-1.457,3.746-3.506,5.52-5.277
	c1.263-1.266,6.809-5.912,7.2-7.201c1.063-3.502-4.762-4.758-5.28-7.68c1.914-1.462,3.364-4.318,3.36-7.682
	c-1.12-1.142-2.484-2.775-2.64-4.799c3.668-0.649,5.428-2.511,9.12-3.361c4.203-0.971,10.324,0.383,13.918-1.197
	c1.652-0.729,2.715-2.568,4.08-3.602c2.451-1.854,4.957-3.773,7.44-5.521c3.899-2.742,7.992-5.438,12-7.92
	c2.487-1.541,4.968-2.404,7.2-4.08c0.72-0.801,1.439-1.604,2.16-2.396c2.494-1.896,5.092-4.701,6.959-7.201
	c0.971-1.299,1.728-2.731,2.881-3.84C214.725,321.491,213.265,320.271,212.878,317.631z'
          />{' '}
        </a>
        <a xlinkTitle={`Mejel Bel Abbes ${groupedData['Mejel Bel Abbes']}`}>
          <path
            onClick={() => setSelectedVill('Mejel Bel Abbes')}
            style={{
              fill: getColorBasedOnCount(outputData['Mejel Bel Abbes']),
            }}
            d='M6.959,422.75c1.551,0.254,3.202,0.83,4.8,1.201c3.319,0.77,7.687-0.273,10.08-0.961
	c8.076-2.32,15.225-4.41,21.6-8.398c3.504-2.193,5.825-7.5,11.04-7.682c1.99,2.484,9.049,1.719,13.439,1.682
	c2.302,2.988,5.793,4.475,8.88,6.719c5.494,3.996,10.833,12,21.84,8.884c1.974-0.563,3.877-1.334,5.76-2.158
	c2.231-0.979,4.489-2.621,6.72-3.604c3.424-1.504,6.255-2.514,9.84-3.84c2.065-0.766,4.087-2.234,6.48-1.922
	c2.418,0.318,7.99-2.121,9.12-1.439c0.814,0.552,1.048,1.211,1.68,1.922c1.602,1.802,3.839,3.845,5.76,5.279
	c1.098,0.82,3.461,1.916,4.08,3.119c0.734,1.429,0.557,3.813,1.2,5.279c1.582,3.611,5.403,6.656,8.88,8.397
	c0.2,7.726,7.618,12.275,8.64,18.724c-1.823,0.396-2.311,1.688-3.6,2.396c-1.558,0.855-3.411,0.582-4.8,1.439
	c-2.891,1.785-5.171,4.604-8.16,6.24c-0.924,0.504-2.276,0.996-3.36,1.194c-0.64-0.08-1.28-0.157-1.92-0.235
	c-1.172,0.438-2.755,1.735-3.84,2.396c-2.533,1.543-5.799,2.938-8.4,4.08c-8.199,3.605-16.083,7.271-23.28,11.76
	c-2.985,1.863-5.482,3.767-8.16,5.765c-0.968,0.723-3.112,1.785-3.6,2.881c0,1.92,0,3.84,0,5.76c-0.321,0.512-0.198,1.285-0.48,1.92
	c-1.021,2.295-3.297,2.25-6.72,2.16c-1.524-0.912-4.038-0.627-5.76-1.199c-3.54-1.178-7.662-2.771-10.56-4.801
	c-4.231-2.959-6.983-7.73-11.04-10.801c-2.833-2.146-5.818-4.066-9.36-5.521c-4.519-1.854-9.572-2.186-13.92-4.08
	c-4.857-2.108-9.108-5.584-13.92-7.68c-1.68-0.559-3.36-1.119-5.04-1.68c-0.48,0-0.96,0-1.44,0c-1.977-0.904-6.017-5.535-7.2-7.439
	c-1.057-1.701-2.73-5.508-2.16-8.396c0.482-2.447,1.121-6.707,0.48-9.604c-0.4-2.396-0.8-4.801-1.2-7.201
	C8.448,430.057,7.14,426.592,6.959,422.75z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 50.7563 456.1484)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Majel Bel Abbès
        </text>
        <text
          transform='matrix(1 0 0 1 40.2173 368.3271)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Fériana
        </text>
        <text
          transform='matrix(1 0 0 1 50.7563 260.1484)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Foussana
        </text>
        <text
          transform='matrix(1 0 0 1 43.7563 177.1494)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hidra
        </text>
        <text
          transform='matrix(1 0 0 1 109.7563 178.1494)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Thala
        </text>
        <text
          transform='matrix(1 0 0 1 154.7563 168.1494)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Djedeliane
        </text>
        <text
          transform='matrix(1 0 0 1 247.7778 198.248)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sbiba
        </text>
        <text
          transform='matrix(1 0 0 1 118.7563 344.1484)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kasserine{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 118.7563 358.5488)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sud
        </text>
        <text
          transform='matrix(1 0 0 1 163.7563 393.1484)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hassi Ferid
        </text>
        <text
          transform='matrix(1 0 0 1 123.7563 294.1484)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kasserine{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 140.7563 304.5488)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nord
        </text>
        <text
          transform='matrix(1 0 0 1 206.7778 271.248)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sbeïtla
        </text>

        <text
          transform='matrix(1 0 0 1 167.7563 321.1484)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ezzouhour{' '}
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Kasserine;
