import React from 'react';
import './style.css';
const Legends = ({ legend }) => {
  return (
    <div className='label-container'>
      <div className='label'>
        <span className='label1'> </span> {`${legend[4].range}`}
      </div>
      <div className='label'>
        <span className='label2'></span>
        {`${legend[3].range}`}
      </div>
      <div className='label'>
        {' '}
        <span className='label3'></span>
        {`${legend[2].range}`}
      </div>
      <div className='label'>
        <span className='label4'></span>
        {`${legend[1].range}`}
      </div>
      <div className='label'>
        {' '}
        <span className='label5'></span>
        {`${legend[0].range}`}
      </div>
    </div>
  );
};

export default Legends;
