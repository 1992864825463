import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    csvData2: [],
    fileName: null,
    data: null,
    groups: null,
    division: [],
    totalCount2: 0,
    stateSums2: {},
    userNames: null

}

export const secondMapSlice = createSlice({
    name: 'secondMap',
    initialState,
    reducers: {
        setCsvData2: (state, action) => {
            state.csvData2 = action?.payload
        },
        setFileName: (state, action) => {
            state.fileName = action?.payload
        },
        setData: (state, action) => {
            state.data = action?.payload
        },
        setGroups: (state, action) => {
            state.groups = action?.payload
        },
        setDivision: (state, action) => {
            state.division = action?.payload
        },
        setTotalCount2: (state, action) => {
            state.totalCount2 = action?.payload
        },

        setStateSums2: (state, action) => {
            state.stateSums2 = action?.payload
        },

        setUserNames: (state, action) => {
            state.userNames = action?.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCsvData2, setFileName, setData, setGroups, setDivision, setTotalCount2, setStateSums2, setUserNames } = secondMapSlice.actions

export default secondMapSlice.reducer