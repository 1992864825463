import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const SidiBouzid = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)

  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='589.351px'
        height='556.168px'
        viewBox='0 0 589.351 556.168'
        enable-background='new 0 0 589.351 556.168'
      >
        <a xlinkTitle={`Jilma ${groupedData['Jilma']}`}>
          <path
            onClick={() => setSelectedVill('Jilma')}
            style={{ fill: getColorBasedOnCount(outputData['Jilma']) }}
            d='M186.721,176.242c0.21-2.137,1.42-2.83,0.96-5.28c-0.998-0.28-1.742-0.956-2.64-1.2
	c-2.24-0.61-3.924,0.587-5.28,0.96c-0.707,1.313-1.71,2.782-2.16,4.32c-0.414,1.416,0.301,3.261-0.96,3.84
	c-1.006,0.801-2.538,0.311-4.08,0.24c-0.007-4.379,0.773-7.508,1.68-10.8c0.24-1.76,0.48-3.521,0.72-5.28
	c0.368-1.623,0.51-4.809,0-6.48c-0.797-2.612-3.133-4.73-5.52-5.76c-0.48-0.08-0.96-0.16-1.44-0.24c-0.88-0.96-1.76-1.92-2.64-2.88
	c-1.712-1.154-5.368-0.982-7.68-1.44c-3.358-5.452-6.037-11.977-9.84-17.04c-1.329-1.769-5.448-3.224-5.76-5.52
	c3.116-2.881,6.672-9.945,4.08-16.08c-0.782-1.85-2.931-3.08-3.36-5.04c1.406-1.746,1.042-6.033,0.24-8.16
	c-2.114-5.605-7.408-9.224-8.4-15.6c2.717-1.321,5.136-3.672,7.68-5.28c4.764-3.012,9.85-5.209,14.4-8.4
	c1.611-1.13,3.147-3.125,4.8-4.08c4.564-2.637,11.312-1.511,17.28-2.88c1.12,0,2.24,0,3.36,0c2.772-0.617,9.785-2.266,12.48-1.44
	c5.601,1.716,7.522,7.378,12.24,9.84c-0.922,3.436-3.524,8.045-0.72,12c1.141,1.609,4.665,2.279,5.279,3.84
	c0.853,1.261-0.174,4.185-0.479,5.28c-0.524,0.164-7.521,4.337-7.68,4.56c-0.944,1.331-0.782,3.459-1.44,5.04
	c-0.209,0.503-1.029,1.238-1.199,1.68c0,0.96,0,1.92,0,2.88c4.105,1.894,3.815,4.171,6.239,7.438
	c3.363,4.535,9.826,6.504,13.2,11.04c2.986,4.016,4.223,9.587,7.2,13.68c1.167,1.604,2.911,1.718,4.56,2.88
	c-1.074,1.02-2.531,4.01-3.6,4.56c-1.76,0.4-3.52,0.8-5.28,1.2c-4.267,1.534-8.37,3.815-10.8,7.2
	c-2.008,2.796-3.532,6.11-4.561,9.84c-1.947,7.06-0.561,12.829-6.24,16.32C197.586,178.321,191.277,176.591,186.721,176.242z'
          />{' '}
        </a>
        <a xlinkTitle={`Cebbala ${groupedData['Cebbala']}`}>
          <path
            onClick={() => setSelectedVill('Cebbala')}
            style={{ fill: getColorBasedOnCount(outputData['Cebbala']) }}
            d='M133.44,80.002c1.038,0.572,2.14,4.329,2.88,5.52c1.616,2.601,7.458,9.17,6.48,13.68
	c-0.319,1.469-0.978,2.874-1.2,4.32c3.28,2.116,5.788,8.417,3.6,13.68c-0.911,2.191-4.143,5.208-4.32,7.2
	c1.785,2.137,4.595,3.336,6.24,5.52c2.591,3.438,4.675,7.59,6.72,11.52c1.292,2.482,1.726,5.993,5.52,6
	c1.207,0.708,3.564,0.16,4.8,0.72c1.324,0.601,1.96,2.36,3.12,3.12c2.908,1.906,4.952,1.328,6.479,5.04
	c1.846,4.486-0.738,12.153-1.68,15.84c-0.37,1.447,0.01,5.884-0.48,6.96c-0.458,1.005-1.912,1.819-2.4,3.6
	c-1.108,4.046,1.103,7.357-0.24,10.56c-1.307,3.118-3.354,5.836-5.28,8.399c-0.8,0.72-1.6,1.44-2.4,2.16
	c-1.084,1.431-2.525,3.69-4.32,4.32c-2.931-4.804-8.927-6.11-13.68-9.12c-2.087-1.322-3.146-3.304-4.8-5.04
	c1.297-2.538,2.374-4.756,3.12-8.16c1.153-5.257-1.922-9.422-2.16-13.68c0.646-0.41,7.087-5.485,7.2-5.76
	c0.93-1.13,0.465-2.117,0.24-3.6c-2.099-1.297-3.886-3.249-6-4.56c-2.081-1.291-4.366-1.837-6.48-3.12
	c-3.288-1.995-6.594-3.974-9.6-6.24c-3.906-2.944-5.206-8.907-9.12-11.76c-3.408-2.484-11.347-1.791-11.52-7.44
	c0.4-2.479,0.8-4.96,1.2-7.44c1.416-4.61,2.925-9.572,5.28-13.44c4.041-6.637,8.649-12.443,13.2-18.479
	C126.621,86.634,130.866,83.865,133.44,80.002z'
          />{' '}
        </a>
        <a xlinkTitle={`Sidi Bouzid Est ${groupedData['Sidi Bouzid Est']}`}>
          <path
            onClick={() => setSelectedVill('Sidi Bouzid Est')}
            style={{
              fill: getColorBasedOnCount(outputData['Sidi Bouzid Est']),
            }}
            d='M278.64,197.361c0,3.28,0,6.561,0,9.84c0.869,2.505,3.543,4.5,5.041,6.479
	c4.254,5.628,9.611,9.651,13.439,15.84c0.65,1.056,1.682,2.404,2.16,3.6c0.08,0.48,0.16,0.96,0.236,1.44
	c0.4,0.24,0.803,0.48,1.199,0.72c0.771,1.315,0.133,2.654,0.725,4.32c0.27,0.769,1.756,1.701,1.195,2.64
	c-1.732,1.206-4.232,1.31-5.998,2.4c-3.769,2.325-6.918,5.763-11.762,6.96c-3.582,0.886-6.021-1.101-8.158-1.68
	c-2.307-0.625-3.727,0.707-5.279,0.96c-3.377-4.911-10.838,0.226-16.563-1.2c-1.986-0.495-3.292-2.082-5.039-2.64
	c-0.996-0.318-2.523,0.435-3.119,0.24c-1.406-0.461-0.66-1.886-4.08-1.92c-1.266,1.188-3.178,1.842-4.562,2.88
	c-1.287,0.966-1.942,2.759-3.603,3.36c-0.381-1.162-1.398-1.833-2.397-2.4c-4.956-2.808-9.116,1.539-11.763,3.36
	c0.221,0.26-0.018,0.021,0.24,0.24c2.75,2.608,5.543-0.549,8.881,0.72c-0.805,4.349-2.564,8.271-3.84,12.24
	c-0.32,1.92-0.641,3.84-0.961,5.76c-1.047,0.937-2.688,1.07-4.08,1.68c-1.195,0.525-2.521,1.505-3.6,2.16
	c-2.24,0.96-4.48,1.92-6.721,2.88c-0.688,5.333-4.564,9.664-6.719,13.679c-0.422-0.149-0.233-0.045-0.48-0.239
	c-3.25-3.627-3.542-12.228-5.279-17.52c-1.021-3.105-2.193-6.522-3.841-9.12c-0.88-1.04-1.76-2.08-2.64-3.12
	c3.898-1.801,6.665-4.466,7.92-8.88c1.18-4.154,0.059-12.27-0.721-15.84c-0.568-2.607-0.002-4.755,0-7.2
	c0.945-0.504,2.482-0.51,4.08-0.48c0.281,0.192,0.401,0.186,0.961,0.24c1.332,2.334,3.602,2.577,5.76,0.72
	c2.642-2.273,4.537-6.753,4.561-11.52c-1.326-0.871-2.502-2.77-3.361-4.08c-3.59,1.456-6.069-0.621-9.119-1.44
	c-1.959-0.526-3.789,0.373-5.278,0.48c0-0.32,0-0.64,0-0.96c3.146-1.477,6.88-3.829,8.88-6.48c0.991-1.313,3.146-4.934,4.563-5.52
	c1.136-0.707,7.965,1.5,11.039,0.96c0-0.32,0-0.64,0-0.96c-1.775-2.701,1.437-8.752,1.681-10.8c-1.693-1.792-2.285-3.761-4.8-4.8
	c-3.873-1.601-10.242,0.354-14.643-0.96c-1.521-0.454-3.055-1.427-3.84-2.64c-0.16-0.479-0.318-0.96-0.479-1.44
	c-0.562,0-1.119,0-1.682,0c-2.704,2.627-4.595,4.824-9.84,5.04c-0.312-2.457-0.272-5.362-0.24-8.16c2.319,0.32,4.64,0.64,6.96,0.96
	c2.818,0.608,5.881-0.396,7.44-1.2c7.439-3.843,5.137-8.712,7.438-17.04c0.781-2.819,1.92-5.645,3.36-7.92
	c2.124-3.357,5.468-6.092,9.36-7.68c1.771-0.723,3.791-1.155,5.52-1.68c0.641-0.08,1.28-0.16,1.92-0.24
	c1.262-0.623,1.16-1.698,1.92-2.88c1.172-1.821,5.737-5.278,7.92-6c3.873,5.765,13.272,6.8,18.961,10.8
	c2.938,2.066,3.562,6.663,6.479,8.64c2.433,1.647,3.329,0.625,6,0.48c0.313,0.581,0.433,0.68,0.479,1.68
	c1.189,1.809,0.596,5.14,1.201,7.44c0.193,0.745,1.09,2.278,0.959,3.12c-0.024,0.165-0.652,0.977-0.722,1.2
	c-0.606,2.05-0.749,7.034-0.237,9.36c0.644,2.941,2.285,9.089,1.438,12.72c-0.35,1.493-2.531,5.598-2.398,6
	C271.593,198.351,275.206,197.708,278.64,197.361z'
          />{' '}
        </a>
        <a xlinkTitle={`Ouled Haffouz ${groupedData['Ouled Haffouz']}`}>
          <path
            onClick={() => setSelectedVill('Ouled Haffouz')}
            style={{ fill: getColorBasedOnCount(outputData['Ouled Haffouz']) }}
            d='M345.601,185.361c1.096,0.178,1.703,0.727,2.639,0.96c3.971,0.989,7.98-1.169,12-1.2
	c0.838-2.498,4.023-4.11,7.441-4.08c0,0.16,0,0.32,0,0.48c1.676,2.624-1.615,9.114-2.16,10.8c-0.08,2.08-0.16,4.161-0.24,6.24
	c-0.477,2.071-1.176,4.382-1.68,6c-0.564,1.811-0.088,5.731,0.24,7.2c-0.08,2.56-0.16,5.121-0.24,7.68c-0.16,0-0.32,0-0.48,0
	c-3.447-3.429-10.189-4.369-14.881-6.48c-0.852-0.384-4.063-1.924-4.314-1.68c-1.369,1.065-2.145,3.094-3.121,4.563
	c-2.422,3.633-5.07,7.695-7.92,11.04c-2.104,2.467-6.688,3.989-9.604,5.76c-5.438,3.304-12.129,8.641-19.438,9.6
	c-0.16-0.72-0.32-1.44-0.48-2.16c-0.318-0.24-0.641-0.48-0.961-0.72c-0.602-1.363-0.018-3.297-0.719-4.56
	c-0.48-0.4-0.961-0.8-1.441-1.2c-0.986-1.747-1.152-3.445-2.158-5.04c-2.113-3.36-5.081-5.999-7.438-9.12
	c-2.813-3.724-9.506-7.996-11.039-12.72c0-3.52,0-7.04,0-10.56c-2.494,0.197-4.373,0.743-6.722,0.24
	c-0.399-0.48-0.8-0.96-1.198-1.44c0.612-1.889,1.864-3.46,2.398-5.76c0.768-3.299-0.382-7.382-0.96-9.84
	c-1.011-4.293-0.699-9.447,0.239-13.2c0.16,0,0.32,0,0.479,0c0.699,0.543,1.667,0.681,2.398,1.2c2.261,1.6,4.909,4.126,7.682,5.04
	c0.802,0,1.603,0,2.397,0c0.543,0.263,0.992,1.011,1.921,1.2c2.304,0.469,4.608-1.459,7.2-0.72c1.605,0.459,2.285,2.146,2.881,2.4
	c0.801,0.339,3.564-0.395,4.801,0.24c1.227,0.629,1.826,2.379,3.359,2.88c2.031,0.664,3.357-1.025,4.801-0.72
	c1.395,0.295,3.707,1.069,5.039,1.44c2.465,0.687,4.834,0.187,6.961,0.96c2.762,1.003,5.371,2.49,7.92,3.6
	c1.438,0.48,2.879,0.96,4.32,1.44c2.68,1.33,5.406,3.607,9.355,2.88c0.08-0.24,0.16-0.48,0.24-0.72
	C345.427,186.843,345.531,186.02,345.601,185.361z'
          />{' '}
        </a>
        <a xlinkTitle={`Sidi Bouzid Ouest ${groupedData['Sidi Bouzid Ouest']}`}>
          <path
            onClick={() => setSelectedVill('Sidi Bouzid Ouest')}
            style={{
              fill: getColorBasedOnCount(outputData['Sidi Bouzid Ouest']),
            }}
            d='M219.36,201.202c-4.285,0.021-6.66-1.638-10.801-1.2c-2.035,3.194-4.918,6.888-7.92,9.12
	c-1.121,0.833-4.721,2.318-5.282,3.36c-0.274,0.406-0.255,1.175-0.24,1.92c2.355,1.277,2.771-0.747,5.76,0
	c2.036,0.509,3.859,1.66,6,2.16c1.313,0.306,2.799-0.581,3.359-0.24c1.16,0.854,1.443,2.518,2.641,3.36
	c-1.211,3.988-1.447,7.169-4.08,9.6c-0.604,0.561-2.139,1.848-2.88,1.44c-1.399-0.529-1.088-1.698-2.88-1.92
	c-0.975-0.651-4.001-0.228-5.04,0c-0.397,0.745-0.503,1.557-0.479,2.88c-0.689,1.125-0.039,4.489,0.24,5.76
	c0.563,2.571,1.403,10.959,0.719,13.92c-0.701,3.029-1.933,6.085-4.081,7.68c-1.213,0.901-4.092,1.541-4.8,2.88
	c6.924,4.673,6.728,15.388,9.839,24c0.791,2.188,3.56,6.619,2.879,8.881c-2.258,7.521-9.237,13-9.358,21.84
	c-2.635,0.67-4.564,1.863-8.16,1.92c0-0.16,0-0.318,0-0.479c-1.651-2.191-1.368-6.129-2.16-9.119c-1.24-4.68-2.35-9.063-4.8-12.479
	c-1.996-2.782-5.554-5.563-8.88-6.959c-2.594-1.09-5.44-1.244-7.68-2.641c-1.417-0.884-2.398-2.478-3.6-3.601
	c1.632-6.619,9.312-8.814,15.12-11.281c2.719-1.154,7.171-2.497,8.16-5.28c-1.266-1.378-1.796-3.497-2.88-5.04
	c-2.743-3.908-6.632-7.412-8.16-12.48c-0.951-3.155,1.068-5.902,0-8.4c-0.875-2.045-2.296-4.247-3.6-6
	c-1.141-1.534-3.531-2.234-3.84-4.56c-2.638-0.019-3.538,0.734-5.52,1.2c-0.88,0-1.76,0-2.64,0c-3.337,0.732-7.948,0.461-11.28,0.72
	c0-0.16,0-0.32,0-0.48c1.68-1.361,2.623-3.935,3.84-5.76c2.478-3.716,5.285-7.485,7.68-11.28c1.088-1.724,1.687-3.628,2.88-5.28
	c1.44-1.36,2.88-2.72,4.32-4.08c2.688-2.744,5.363-6.066,7.2-9.6c0.635-1.221,1.338-3.022,1.68-4.32
	c0.334-1.271-0.28-2.913-0.48-3.84c-0.872-4.036,0.253-7.448,3.84-7.44c0.966,0.661,2.691-0.014,3.6-0.24
	c0.557-0.886,0.75-1.461,0.72-3.12c0.561-0.855,1.415-3.816,1.68-4.8c1.659-0.921,6.638-2.496,6.96,0.96
	c-2.401,2.474-1.326,10.614-0.48,13.68c0.16,0,0.32,0,0.48,0c1.64-1.037,4.491-0.659,6.24-1.44c1.972-0.88,2.323-3.258,4.562-3.84
	c1.879,6.563,10.729,2.809,17.52,4.56c3.714,0.958,3.808,2.392,6,4.561c-0.313,1.211-1.072,2.219-1.439,3.36
	C219.167,195.856,219.379,198.544,219.36,201.202z'
          />{' '}
        </a>
        <a xlinkTitle={`Regueb ${groupedData['Regueb']}`}>
          <path
            onClick={() => setSelectedVill('Regueb')}
            style={{ fill: getColorBasedOnCount(outputData['Regueb']) }}
            d='M327.601,371.841c-3.189,0.216-6.896,0.753-10.08,1.438c-2.361,0.512-4.701-0.348-6.721-0.479
	c-1.057-1.805-3.148-2.469-4.32-4.079c-0.498-0.688-0.436-1.484-1.199-1.921c-1.551,0.902-4.012-1.108-4.801-1.922
	c-2.59-2.666-4.195-6.397-6.479-9.358c-0.979-1.271-4.551-2.567-6-4.08c-1.354,0.043-2.146,0.646-2.641,0.479
	c-0.93-0.435-1.959-2.244-2.159-3.358c0.452-0.604,0.259-1.229,0.479-2.16c1.396-5.905-0.974-11.748-3.361-15.358
	c-0.637-0.963-1.352-2.896-2.398-3.356c-0.88,0-1.76,0-2.64,0c-1.449-0.972-3.612-0.99-5.28-1.683
	c-3.902-1.614-7.891-3.646-11.76-5.278c-1.36-0.397-2.721-0.802-4.08-1.199c0.838-1.192,1.197-6.299,0.72-8.16
	c-0.164-0.639-1.323-2.588-1.198-3.118c0.213-0.916,1.375-2.166,1.68-3.358c0.73-2.875-0.5-5.435-0.479-8.16
	c0.007-0.744,0.722-3.121,0.722-3.121c-0.396-1.19-1.367-1.353-1.439-3.358c0.799-1,2.066-3.117,2.398-4.319
	c0.516-1.854-0.858-3.222-0.479-4.078c0.458-1.455,1.634-2.453,2.397-3.602c1.73-2.592,3.619-5.651,4.563-8.88
	c0.829-2.844-0.979-8.249-0.722-8.88c0.604-3.561,4.479-4.265,6.961-6c1.631-1.139,2.61-2.937,4.801-3.6
	c2.979-0.903,7.138,3,12,1.438c5.24-1.682,8.452-5.855,13.199-7.92c3.086-1.341,7.27-1.316,10.318-2.64
	c3.031-1.313,5.896-3.373,8.641-5.04c4.041-2.456,8.268-4.213,12.24-6.72c4.18-2.633,7.354-7.931,10.078-12
	c1.064-1.587,2.236-4.806,4.08-5.52c2.609,2.301,7.371,2.67,10.803,4.08c2.018,0.829,4.418,1.724,6.238,2.88
	c0.621,0.394,1.211,1.438,1.92,1.68c0.238,0.082,0.439-0.402,0.959-0.24c1.17,0.364,2.684,1.673,3.842,1.92
	c0.84,0.179,1.609-0.791,2.881-0.48c2.592,0.635,4.91,1.911,7.438,2.88c1.803,4.213,3.33,7.989,6.48,10.8
	c-0.063,2.176-1.238,3.676-1.92,5.28c-1.383,3.255-2.531,6.512-3.84,9.6c-2.533,5.973-3.645,12.593-6.24,18.72
	c-1.9,4.488-4.729,8.475-7.439,12.24c-4.438,6.17-13.229,12.66-13.438,22.561c0.758,0.469,1.102,0.74,1.201,1.922
	c-1.896,2.59-0.605,6.773-1.439,10.561c-0.439,1.986,0.02,4.57-0.48,6.721c-1.068,4.576-2.484,10.992-7.92,11.041
	c-1.377,0.811-4.24,0.063-5.041,1.438c3.207,4.504-0.404,17.021-2.16,20.397c-0.713,1.375-3.578,4.103-2.16,6.479
	c0.742,2.252,2.789,1.867,3.605,3.361c0.076,0,0.156,0,0.234,0c0,0.077,0,0.157,0,0.237c-0.158,0-0.314,0-0.48,0
	c-2.795,1.992-8.346-0.474-9.602,3.119C327.65,370.214,327.601,370.832,327.601,371.841z'
          />{' '}
        </a>
        <a xlinkTitle={`Bir El Haffey ${groupedData['Bir El Haffey']}`}>
          <path
            onClick={() => setSelectedVill('Bir El Haffey')}
            style={{ fill: getColorBasedOnCount(outputData['Bir El Haffey']) }}
            d='M128.881,280.641c-4.38,0.036-8.026,0.063-11.76,0.961c-1.32,0.315-4.071,1.517-5.76,0.959
	c-1.393-0.457-2.979-1.879-4.56-2.397c-4.612-1.517-8.293-1.011-11.76-3.601c-1.882-1.407-1.636-3.896-2.88-6
	c-0.88-1.49-2.954-1.962-3.12-4.08c-1.523-2.276-0.598-12.099,0.72-13.68c0.48-0.16,0.96-0.32,1.44-0.479
	c0.606-0.407,1.61-1.729,1.92-2.4c0.773-1.673,1.051-5.243,2.64-6c1.2,0,2.4,0,3.6,0c1.629-1.06,3.676-1.167,5.28-2.16
	c3.25-2.012,5.765-4.992,9.84-6.24c2.003-0.613,7.2,0.96,7.2,0.96c1.918-1.266,3.169-2.812,5.76-3.6
	c4.717-1.435,10.004,0.76,14.88,0.24c2.16-0.16,4.32-0.32,6.48-0.48c1.919,0,3.84,0,5.76,0c2.174-0.479,4.666-1.03,6.72-1.2
	c0.631,0.838,1.659,1.419,2.4,2.16c1.867,1.868,3.936,4.419,5.04,6.96c1.127,2.594-0.819,5.397,0.24,8.88
	c1.507,4.955,5.014,8.222,7.68,12c1.104,1.565,1.8,3.668,3.12,5.04c-0.152,0.421-0.046,0.233-0.24,0.48
	c-1.918,2.589-5.688,3.448-8.88,4.8c-3.834,1.624-7.301,3.434-10.32,5.76c-0.945,0.729-4.277,4.6-3.6,5.76
	c1.633,4.989,7.759,5.397,12.48,7.438c1.772,0.768,3.516,2.217,5.04,3.36c6.97,5.231,7.41,17.847,10.08,27.358
	c0.469,1.67,1.137,3.513,1.92,5.041c0.269,0.522,1.047,1.36,1.2,1.92c-0.08,1.279-0.16,2.56-0.24,3.841
	c-5.584,3.045-11.329,5.776-17.28,8.399c-4.066,1.791-8.272,4.375-12.96,5.521c-0.659-0.877-1.862-1.388-2.64-2.16
	c-2.209-2.198-4.224-5.097-5.52-8.157c-2.004-4.74-2.389-10.021-4.08-15.121c-1.238-3.734-2.969-7.004-5.04-9.843
	c-0.698-0.952-2.167-1.938-2.64-2.879c0-0.397,0-0.801,0-1.198c-0.298-0.738-2.489-3.697-3.36-3.843
	c-2.52-1.521-7.719,1.072-10.08-0.719c-2.301-3.229-0.984-11.369,1.44-13.201c1.071-0.81,3.271-1.114,3.84-2.399
	C129.529,285.755,128.925,282.748,128.881,280.641z'
          />
        </a>
        <a xlinkTitle={`Souk Jedid ${groupedData['Souk Jedid']}`}>
          <path
            onClick={() => setSelectedVill('Souk Jedid')}
            style={{ fill: getColorBasedOnCount(outputData['Souk Jedid']) }}
            d='M242.88,246.321c0.653-0.001,1.104,0.009,1.439,0.24c1.218,0.43,1.43,1.475,2.641,1.92
	c0.87-0.699,2.67-0.562,3.6,0c0.746,0.45,1.565,1.32,2.4,1.68c3.176,1.37,9.02,0.275,12-0.48c2.24-0.567,5.516,0.963,5.52,1.92
	c-1.479,2.343-4.244,2.963-6.479,4.56c-1.124,0.803-1.691,2.139-2.641,3.12c-0.129,2.826,1.773,4.973,0.959,8.4
	c-1.159,4.878-3.633,7.975-6,11.52c-0.668,1.004-0.981,2.16-1.92,2.881c0.731,1.129,1.207,1.561,1.201,3.6
	c-0.846,0.898-2.063,2.836-2.399,4.08c-0.687,2.521,1.175,3.021,0.72,5.04c-1.138,5.058,2.281,10.286-1.439,13.2
	c3.966,9.193-1.68,15.686-3.601,23.52c-5.354,0.313-7.463,4.898-12.479,5.279c-1.506-3.391-3.961-5.639-6-8.398
	c-0.467-0.635-1.008-2.297-1.68-2.641c-0.959,0-1.92,0-2.881,0c-0.863,0.885-3.854,2.873-5.76,2.4
	c-1.189-0.299-2.798-1.102-4.08-1.439c-3.617-0.965-11.921-1.385-16.08-0.479c-1.906,0.414-10.566,2.111-11.52,1.68
	c-0.636-0.662-3.778-7.463-3.12-8.156c2.513-1.457,7.282-0.115,8.4-2.883c0.426-0.725,0.205-1.979,0.48-2.881
	c0.669-2.188,1.5-4.604,2.641-6.479c1.633-2.683,3.747-5.451,5.039-8.397c0.746-1.703,0.786-4.396,1.682-6
	c0.763-1.371,2.486-2.675,3.358-4.08c1.87-3.012,2.912-6.719,4.32-10.08c3.889-1.445,7.178-3.735,10.8-5.28
	c1.139-0.485,3.219-0.496,3.84-1.44c0-0.88,0-1.76,0-2.64c0.729-1.169,0.5-2.912,0.961-4.32c1.256-3.847,3.401-7.786,3.6-12.479
	c-0.238-0.08-0.479-0.16-0.719-0.24c-2.307-1.73-5.404,1.487-7.44,0c-0.261-0.218-0.021,0.019-0.239-0.24
	c2.17-1.334,6.441-4.597,10.08-2.16c1.161,0.778,1.479,2.333,2.398,3.36c0-0.08,0-0.16,0-0.24c1.716-1.115,2.718-3.125,4.32-4.32
	C240.078,248.009,241.747,247.424,242.88,246.321z'
          />{' '}
        </a>
        <a xlinkTitle={`Sidi Ali Ben Aoun ${groupedData['Sidi Ali Ben Aoun']}`}>
          <path
            onClick={() => setSelectedVill('Sidi Ali Ben Aoun')}
            style={{
              fill: getColorBasedOnCount(outputData['Sidi Ali Ben Aoun']),
            }}
            d='M127.92,281.602c0.014,1.889,0.231,3.728,0,5.039c-4.195,0.532-7.381,5.539-6.24,10.562
	c0.488,2.147-0.144,5.254,1.68,6c2.17,1.653,7.489,0.151,10.56,0.721c0.329,1.324,1.092,1.517,1.68,2.397
	c0.437,0.658,0.069,1.354,0.48,2.16c0.88,0.802,1.76,1.603,2.64,2.399c0.832,1.113,1.753,2.414,2.4,3.601
	c3.583,6.582,4.113,15.765,7.2,22.802c1.092,2.489,2.87,4.975,4.56,6.961c0.933,1.096,2.688,1.514,3.36,2.879
	c-2.094,2.106-2.924,5.606-4.32,8.399c-3.086,0-6.95-0.407-9.36,0.479c-0.61,0.226-1.92,0-1.92,0
	c-1.482-1.224-3.561-1.601-5.28-2.644c-2.21-1.342-4.581-3.873-7.68-4.08c-1.38,1.029-3.603,0.976-5.28,1.683
	c-2.106,0.892-3.902,3.287-6.24,3.601c-2.286-1.565-6.734-1.263-9.12-2.879c-1.357-0.923-1.99-3.697-3.12-4.32
	c-1.939,1.755-5.603,1.484-8.4,2.4c-1.653,0.541-2.859,1.815-4.56,2.159c-3.418-3.159-9.476,1.341-10.8,3.601
	c-0.32,1.039-0.64,2.08-0.96,3.119c-1.096-1.031-2.582-1.519-3.84-2.397c-2.561-1.793-5.102-3.97-8.4-5.041
	c-1.758-0.57-4.643-0.371-6.24-0.722c-1.022-1.813-2.595-4.683-4.8-5.278c-1.04,0.08-2.08,0.159-3.12,0.239
	c-0.94,0.558-2.018,3.246-3.12,4.08c-2.009,1.519-4.54,2.729-6.24,4.562c-6.393,0.043-10.392-4.592-14.16-7.438
	c-0.908-0.688-1.472-2.306-2.4-2.881c-0.72-0.24-1.44-0.479-2.16-0.722c-0.298-0.181-0.819-0.871-1.2-0.961
	c-0.753-0.177-4.765,1.233-6.96,0.724c-6.294-1.478-10.098-4.099-13.92-7.923c-0.957-0.956-2.158-1.688-2.64-3.118
	c3.276-0.771,6.67-3.388,9.36-5.039c5.149-3.166,10.315-5.69,15.36-8.883c2.772-1.75,5.6-3.654,8.4-5.521
	c1.065-0.706,2.137-1.872,3.36-2.399c2.726-1.174,6.818-0.437,9.84-1.438c1.641-0.543,3.125-2.236,4.56-2.642
	c0.875-0.241,3.256,0.241,4.56,0.239c0.693-1.859,2.805-2.98,4.32-4.08c4.391-3.176,9.368-6.145,13.68-9.357
	c1.806-1.348,4.745-3.268,5.04-6c-0.587-0.799-0.474-1.695-1.44-2.16c-2.09-1.789-7.622-0.262-9.36-2.398
	c-1.885-2.385-0.7-7.799,0.48-10.08c2.277-4.398,5.103-5.437,8.88-8.16c3.321-2.395,4.375-7.466,7.68-9.84
	c1.078-0.774,2.445-0.959,3.84-1.44c0,0.32,0,0.64,0,0.96c-2.552,3.235-0.631,10.478-0.48,14.64c3.898,2.482,3.623,7.344,7.2,9.84
	c2.984,2.082,7.052,1.83,11.04,3.121c0.854,0.275,2.263,0.701,3.12,1.199c0.522,0.305,1.323,1.283,1.92,1.439
	c1.144,0.297,3.759-0.691,4.8-0.961C119.853,281.85,123.609,281.617,127.92,281.602z'
          />{' '}
        </a>
        <a xlinkTitle={`Maknassy ${groupedData['Maknassy']}`}>
          <path
            onClick={() => setSelectedVill('Maknassy')}
            style={{ fill: getColorBasedOnCount(outputData['Maknassy']) }}
            d='M253.92,320.48c8.286,2.873,14.423,6.443,23.761,8.398c0.676,1.135,1.729,2.033,2.399,3.121
	c1.506,2.438,1.871,4.896,2.64,8.16c0.195,0.822,0.668,2.592,0.48,3.6c-0.383,2.047-1.042,4.063-1.2,6
	c2.246,0.871,1.151,3.311,4.56,3.359c0.309-0.777,0.211-0.635,1.201-0.723c1.338,1.877,4.022,2.139,5.279,3.604
	c2.699,3.146,4.316,8.129,7.68,10.561c0.748,0.539,2.02,1.16,2.881,1.439c0.479,0,0.959,0,1.439,0
	c0.381,0.213,0.449,1.066,0.721,1.438c0.811,1.104,3.232,2.125,3.84,3.119c0.256,0.42,0.164,0.563,0,0.721
	c-3.814,5.438-13.613,4.916-20.16,7.683c-0.963,0.406-3.449,1.338-4.08,2.16c-1,1.305-0.99,2.478-2.398,3.356
	c-1.609,1.017-8.9,2.01-7.92,4.317c0.439,1.138,1.809,1.668,2.88,2.16c-1.882,10.942-8.407,15.678-13.198,23.521
	c-1.166,1.91-2.021,4.414-2.642,6.723c-0.362,1.354,0.071,2.742-0.96,3.357c-1.99,1.188-6.604,1.638-9.12,2.398
	c-6.896,2.094-14.433,3.125-21.603,5.279c-6.902,2.074-17.799,1.11-24.959-0.479c-2.221-0.493-6.028,0.957-7.198,0.24
	c-0.949-0.729-0.513-1.871-0.961-3.121c-0.732-2.037-2.152-2.364-2.159-5.278c0.859-0.979,0.521-1.834,2.398-1.92
	c0.838-0.495,2.869,0.049,3.84,0.239c1.313,0.26,2.73-0.381,3.602-0.722c-0.279-0.778-0.174-1.588,0-2.399
	c1.381-0.758,3.745-1.806,4.08-3.601c0.496-0.774,0.271-3.155,0-4.319c-0.855-3.647-1.352-7.289-1.438-11.761c0.08,0,0.16,0,0.238,0
	c0-0.079,0-0.159,0-0.239c3.188-0.381,8.659-0.707,11.762-1.438c0.479-0.562,0.959-1.119,1.438-1.681
	c0.033-1.946-0.369-3.118-0.723-4.562c-8.023-0.26-6.18-11.205-6-18.479c1.324-0.327,2.264-1.196,3.361-1.68
	c4.098-1.8,7.907-3.492,11.279-6c1.51-1.123,2.318-2.896,4.08-3.841c-1.555-3.45-3.583-6.729-5.04-10.08
	c-0.827-1.901-1.103-3.653-1.92-5.521c-1.33-3.036-6.981-10.228-6-14.642c0.282-1.27,0.901-2.631,1.2-3.84
	c2.625-0.588,3.688-2.303,6.72-2.398c2,2.561,4,5.119,6,7.68c0.735,1.107,0.806,2.613,2.16,3.121c0.39,0.357,0.485,0.372,1.2,0.479
	c0.717-0.628,1.785-0.685,2.64-1.201c3.394-2.058,5.396-3.021,9.601-4.562C251.041,328.458,253.284,324.645,253.92,320.48z'
          />{' '}
        </a>
        <a xlinkTitle={`Menzel Bouzaiene ${groupedData['Menzel Bouzaiene']}`}>
          <path
            onClick={() => setSelectedVill('Menzel Bouzaiene')}
            style={{
              fill: getColorBasedOnCount(outputData['Menzel Bouzaiene']),
            }}
            d='M219.601,394.162c0.147,1.215,0.449,1.887,0.48,3.359c-1.047,0.643-1.885,1.328-3.359,1.68
	c-2.928,0.697-8.699-0.768-10.08,1.199c0,1.521,0,3.041,0,4.561c0.604,0.963,0.18,2.564,0.479,3.842
	c0.581,2.482,1.472,5.926,0.96,8.641c-1.057,0.689-3.409,1.813-4.08,2.883c-0.16,0.801-0.32,1.602-0.48,2.397
	c-1.522,1.052-4.267-0.683-6.238-0.238c-0.504,0.11-1.231,0.396-1.919,0.479c-0.201,0.832-0.671,1.638-1.2,2.16
	c-0.054,4.217,2.171,4.438,2.4,8.16c-0.08,0-0.16,0-0.24,0c0,0.079,0,0.159,0,0.239c-3.207,0.231-6.569,0.79-9.12-0.722
	c-1.342-0.795-2.971-2.319-3.84-3.602c-0.336-0.494-0.246-1.111-0.72-1.438c-1.12-0.318-2.24-0.642-3.36-0.96
	c-2.901-1.064-7.621-3.295-12.24-2.16c-1.859,0.457-3.672,1.385-5.04,2.398c-0.738,0.549-1.02,1.489-1.92,1.92
	c-0.829-1.248-2.561-2.229-3.12-3.601c0.665-0.729,0.857-1.823,1.44-2.642c1.377-1.93,3.023-2.881,3.12-6.239
	c-0.514-0.653-0.695-1.491-1.2-2.16c-0.685-0.903-2.017-1.389-2.64-2.397c-0.524-0.853-0.106-1.866-0.96-2.399
	c-1.173-0.772-3.133,0.154-4.56,0.24c-1.311-2.236-2.251-4.818-3.12-7.681c-0.447-1.474-0.083-3.037-1.44-3.603
	c-2.817-1.903-5.942,2.071-6.72,3.358c-0.24-0.077-0.48-0.157-0.72-0.237c-1.778-3.72-0.373-8.127,0.96-11.281
	c2.384-5.639,5.314-10.731,7.2-16.559c1.803-5.572,0.407-11.259-5.76-11.281c-1.6,0.479-3.2,0.961-4.8,1.438
	c-2.506,1.086-4.809,2.91-8.16,3.119c-0.15-1.539-2.42-4.254-1.2-5.763c0.329-0.401,0.859-0.041,1.44-0.237
	c0.643-0.222,1.416-1.004,1.92-1.199c1.92,0.08,3.84,0.16,5.76,0.24c2.006-0.552,2.395-2.664,3.6-3.121
	c2.263-0.854,8.464-0.496,11.52-0.479c0.652-1.967,3.284-8.217,4.56-9.357c0.851-0.766,5.999-2.021,7.44-2.643
	c4.431-1.916,8.967-4.273,13.2-6.479c1.868-0.977,9.08-3.604,9.84-5.041c0.607-0.695,0.532-2.785,0.72-3.84
	c7.632-0.938,17.458-3.621,25.919-1.438c1.94,0.5,4.151,0.375,5.521,1.438c0.182,0.258,0.146,0.229,0.239,0.721
	c-1.8,2.223-0.757,7.324,0.24,9.602c1.438,3.283,3.827,6.283,5.278,9.602c0.91,2.078,1.271,3.979,2.16,6
	c1.112,2.525,3.329,4.943,3.84,7.92c-1.873,1.182-3.209,3.273-5.039,4.563c-2.735,1.918-6.121,3.242-9.121,4.801
	c-2.168,1.125-4.129,0.572-4.08,4.08c-0.627,0.961,0.029,2.971-0.238,4.318C211.944,386.643,214.196,393.833,219.601,394.162z'
          />{' '}
        </a>
        <a xlinkTitle={`Mezzouna ${groupedData['Mezzouna']}`}>
          <path
            onClick={() => setSelectedVill('Mezzouna')}
            style={{ fill: getColorBasedOnCount(outputData['Mezzouna']) }}
            d='M230.88,502.4c0.449-5.354-0.311-9.349-2.4-12.722c-1.012-1.633-3.145-2.983-3.6-5.04
	c2.32-1.501,3.904-3.332,5.76-5.278c-0.121-0.622-0.221-1.063-0.24-1.92c-3.145-1.875-9.229-2.942-11.277-5.762
	c-4.303-5.906-4.182-16.072-8.398-22.08c-3.638-5.177-10.313-7.198-12-14.397c0.588-0.205,1.482-0.25,2.398-0.24
	c1.479-0.894,5.496-0.094,6.96,0.24c1.919,0.159,3.84,0.317,5.761,0.479c2.256,0.502,6.274,1.206,8.881,0.96
	c5.25-0.496,9.447-1.584,13.68-2.88c4.453-1.361,9.031-1.851,13.201-3.12c3.486-1.063,13.096-1.885,13.199-5.76
	c0.52-0.82,0.354-2.113,0.721-3.12c0.811-2.234,1.891-4.531,3.119-6.48c3.561-5.644,13.604-14.178,12-23.278
	c-1.236-0.242-2.267-0.793-2.642-1.92c2.619-1.119,5.978-1.533,8.16-3.119c2.091-1.521,1.896-3.43,5.04-4.801
	c4.615-2.015,10.437-2.109,15.117-4.08c1.205-0.506,2.73-1.177,3.84-1.92c1.203-0.804,1.553-2.152,3.361-2.396
	c2.688,1.733,13.316-0.697,16.32-0.961c1.123,3.688,7.406,2.577,8.16,6.237c-2.285,2.271-5.496,7.683-5.521,12.237
	c0.682,0.32,0.998,0.578,1.92,0.722c1.328-2.076,4.492-1.696,6.721-0.722c1.83,0.808,3.654,2.298,5.521,3.121
	c3.461,1.529,6.691,2.912,9.84,4.562c1.727,0.899,5.592,1.709,5.76,4.08c0.602,0.842,0.65,1.985,1.201,2.879
	c1.041,1.688,2.521,2.834,2.879,5.281c-0.869,0.95-2.063,1.676-2.16,3.358c0.164,0.187,0.314,0.535,0.48,0.719
	c1.971,1.744,6.791,0.283,9.84,0.962c4.004,0.889,9.469,0.932,12,3.358c-1.506,1.881-3.402,3.383-4.799,5.28
	c-1.822,2.479-3.406,5.529-6,7.199c-1.439,0.926-5.051,0.188-5.521,0.479c-1.463,1.201-2.252,3.479-3.357,5.04
	c-2.361,3.343-5.766,6.258-9.119,8.643c-0.684,0.481-3.611,3.291-4.316,2.642c-0.99-0.385-2.184-1.875-2.881-2.642
	c-1.904-0.044-3.813,0.562-4.32,1.921c-1.482,1.641,0.787,3.856,0.961,5.76c-0.764,0.422-2.078,0.82-2.398,1.681
	c-0.4,1.073,0.371,1.569-0.48,2.16c-0.391,0.306-1.236,0.131-1.68,0.479c-0.781,0.617-1.986,4.004-2.639,5.038
	c-2.574,4.095-6.047,7.526-8.881,11.278c-0.49,0.646-0.785,1.47-1.439,1.92c-2.611,1.807-6.031,3.732-9.361,4.563
	c-4.197,1.043-7.975-2.108-12.236-0.48c-2.52,0.963-4.387,3.435-6.961,4.562c-6.263,2.741-12.775,4.916-20.158,6.479
	c-0.188,0.538-0.244,1.306-0.24,2.16c0.192,0.245,0.086,0.063,0.24,0.479c6.533,3.921,14.129,12.729,16.079,21.12
	c-1.358,0-2.72,0-4.079,0c-0.709-0.453-1.674-0.213-2.641-0.479c-4.426-1.225-7.832-1.81-13.439-1.683
	c-0.938-1.391-2.257-2.126-3.601-3.119c-4.11-3.037-8.442-5.645-13.92-7.438c-1.896-0.621-3.452-0.459-5.521-0.96
	c-2.646-0.64-7.609-1.486-11.041-0.72C237.593,501.66,233.938,502.17,230.88,502.4z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 38.9014 331.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi Ali Ben Aoun
        </text>
        <text
          transform='matrix(1 0 0 1 170.5522 358.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Menzel{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 156.3662 372.1563)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bouzaiane
        </text>
        <text
          transform='matrix(1 0 0 1 263.9014 451.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Mazouna
        </text>
        <text
          transform='matrix(1 0 0 1 220.9014 383.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Meknassi
        </text>
        <text
          transform='matrix(1 0 0 1 284.9014 296.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Regueb
        </text>
        <text
          transform='matrix(1 0 0 1 212.9014 297.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Souk{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 212.9014 314.1563)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          jedid
        </text>
        <text
          transform='matrix(1 0 0 1 216.9014 224.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi Bouzid{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 216.9014 238.1563)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Est
        </text>
        <text
          transform='matrix(1 0 0 1 150.9014 208.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi Bouzid{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 150.9014 225.1563)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ouest
        </text>
        <text
          transform='matrix(1 0 0 1 292.9014 197.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ouled{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 292.9014 214.1563)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Haffouz
        </text>
        <text
          transform='matrix(1 0 0 1 162.9014 116.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Jelma
        </text>
        <text
          transform='matrix(1 0 0 1 104.9014 268.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bir el Hfay
        </text>
        <text
          transform='matrix(1 0 0 1 97.874 124.3564)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Cebbala{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 78.2061 138.7568)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ouled Asker
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default SidiBouzid;
