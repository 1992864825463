import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Tozeur = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)

  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='592.773px'
        height='568.508px'
        viewBox='0 0 592.773 568.508'
        enable-background='new 0 0 592.773 568.508'
      >
        <a xlinkTitle={`Tamaghza ${groupedData['Tamaghza']}`}>
          <path
            onClick={() => setSelectedVill('Tamaghza')}
            style={{ fill: getColorBasedOnCount(outputData['Tamaghza']) }}
            d='M116.052,117.442c5.919-1.6,11.84-3.2,17.76-4.8c2.367-0.726,5.896-0.731,7.68-2.16
	c0.704-0.737,0.873-1.738,1.44-2.64c1.681-2.675,4.273-6.262,6.96-7.92c4.48-2.764,12.605-1.612,17.04-4.56
	c3.58-2.38,6.075-6.179,8.64-9.6c0.56-0.48,1.12-0.96,1.68-1.44c0.93-1.234,2.06-2.793,3.36-3.6c2.23-1.384,7.435-0.313,9.6-1.68
	c2.839-3.055,2.55-5.347,7.44-6.96c1.939-0.639,6.191,0.208,7.68-0.479c1.32-0.86,2.26-2.329,3.84-2.88c0,0.56,0,1.12,0,1.68
	c-0.561,2.8-1.12,5.6-1.68,8.4c-1.299,4.01-2.428,8.157-3.84,12.24c-0.39,1.124-1.785,3.622-1.44,5.04
	c0.198,0.816,1.165,2.514,1.68,3.36c2.017,3.307,4.515,6.674,7.2,9.36c1.163,1.163,3.684,2.282,4.08,4.08
	c-0.316,0.328-0.509,0.763-0.721,1.2c-3.961,0.274-13.189,2.79-15.839,4.56c-2.319,1.548-3.87,4.229-5.52,6.48
	c-2.686,3.663-5.85,7.209-8.4,11.04c-1.608,2.417-4.181,7.746-6.24,9.36c-1.469,1.032-4.221,0.791-6,1.44
	c-4.041,1.475-8.033,3.306-12,4.8c-2.351,0.885-5.896,0.438-6,3.6c1.364,2.074,1.963,7.833,2.88,10.56
	c1.833,5.453,3.892,11.125,6.48,16.08c2.78,5.322,6.124,10.429,9.6,15.12c2.592,3.498,6.172,6.162,7.44,11.04
	c-1.873,0.018-4.084,1.496-5.28,1.2c-0.726-0.18-1.703-1.14-2.4-1.44c-2.174-0.936-5.694-1.656-8.64-0.96
	c-1.354,0.32-3.154,0.423-4.56,0.96c-1.985,0.758-3.563,2.528-5.52,3.36c-4.003,1.701-9.083,1.66-12.96,3.36
	c-3.443,1.509-6.55,4.205-10.08,5.76c-8.277,3.645-17.277,6.65-26.16,9.6c-8.784,2.917-17.041,4.781-25.92,7.68
	c-2.96,1.12-5.92,2.24-8.88,3.36c-4.054,1.308-8.053,1.115-12,2.4c-9.889,3.218-20.529,6.389-30.24,9.6
	c-6.479,1.6-12.961,3.2-19.44,4.8c0-0.16,0-0.32,0-0.48c2.31-0.748,9.708-5.914,11.04-7.68c0.582-0.771,0.965-2.014,1.68-2.64
	c1.04-0.32,2.08-0.64,3.12-0.96c3.169-1.37,5.913-3.941,6.96-7.44c1.033-3.451-0.803-7.505,0-11.04
	c1.635-7.203,4.458-12.313,7.92-17.521c1.011-1.521,1.938-4.754,3.84-5.28c2.139-1.371,6.715-0.999,9.6-1.68
	c3.245-0.766,7.397-0.698,10.8-1.44c1.04,0,2.08,0,3.12,0c4.565-1.003,12.465-0.937,17.52-0.96
	c3.215-0.015,14.035,0.305,14.88-1.439c0-0.56,0-1.12,0-1.68c0.24-2.48,0.48-4.96,0.72-7.44c1.214-4.973,1.792-9.951,3.36-14.64
	c3.336-9.975,6.769-19.862,10.08-30c1.625-4.973,1.635-10.414,2.88-15.84C117.086,126.221,116.094,120.714,116.052,117.442z'
          />{' '}
        </a>
        <a xlinkTitle={`Degueche ${groupedData['Degueche']}`}>
          <path
            onClick={() => setSelectedVill('Degueche')}
            style={{ fill: getColorBasedOnCount(outputData['Degueche']) }}
            d='M177.972,146.722c0.755,0.675,0.53,2.902,0.48,4.32c1.06,1.036,1.742,2.396,2.64,3.6
	c2.101,2.815,4.966,5.207,7.44,7.68c1.04,1.12,2.08,2.24,3.12,3.36c2.953,2.231,5.264,5.251,8.161,7.44
	c3.026,2.287,6.375,5.375,8.639,8.4c1.972,2.633,3.979,5.39,5.521,8.4c1.45,2.831,1.89,5.415,4.32,7.2
	c9.757,7.17,22.662,11.708,34.56,16.56c1.904,0.776,3.706,0.779,5.76,1.44c6.25,2.012,13.28,3.896,16.801,8.64
	c2.437,3.283,3.779,7.219,6,10.8c1.336,2.155,3.23,3.5,3.84,6.48c0.723,3.537-3.564,8.817-2.88,10.56
	c0.459,1.69,1.792,2.091,2.88,3.12c3.15,0.164,5.879-0.313,8.64-0.96c1.355-0.317,2.388,0.58,3.12,0.24
	c1.09-0.847,1.67-2.698,2.88-3.36c1.384-0.757,3.273-0.096,5.04-0.48c3.498-0.76,7.338-0.383,11.04-1.2
	c2.102-0.463,4.563-0.196,6.96-0.72c7.007-1.528,21.365-1.514,28.08,0.24c2.641,0.72,5.28,1.44,7.92,2.16
	c1.045,0.424,2.54,1.377,3.602,1.68c1.466,0.418,3.506-0.649,4.56-0.96c3.36-0.64,6.721-1.28,10.08-1.92
	c4.729-1.031,9.057,1.031,12,1.92c2.56,0.773,4.33-0.939,6.24-1.2c0,0.16,0,0.32,0,0.48c-4.823,3.407-4.708,6.929-5.521,14.16
	c-4.201,1.44-3.663,8.651-6,12c-3.016,4.325-7.667,7.355-10.8,11.521c-4.9,6.518-9.418,13.151-14.16,19.92
	c-9.411,13.434-19.5,22.043-32.64,31.68c-5.133,3.765-10.127,8.078-15.36,11.761c-4.063,2.858-8.15,5.678-12.238,8.399
	c-1.854,1.232-4.235,2.035-5.761,3.601c-1.311-0.474-1.98-1.74-2.88-2.64c-2-1.922-4-3.842-6-5.762
	c-0.88-0.959-1.76-1.92-2.64-2.879c-4.413-3.336-10.098-9.256-13.44-13.681c-1.251-1.655-3.304-2.896-4.56-4.56
	c-4.67-6.188-10.229-11.604-14.881-17.761c-1.93-2.555-7.282-6.881-10.08-8.642c-1.895-1.19-4.535-1.77-5.76-3.6
	c-2.523-3.772,0.631-10.033-0.959-14.16c-2.873-1.617-5.023,1.389-8.16,1.439c-3.979,0.066-10.674-4.802-12.48-7.2
	c-5.77-7.656-9.83-17.051-13.2-27.12c-0.726-2.168-0.581-4.176-1.2-6.48c-1.933-7.188-2.304-15.267-4.56-22.08
	c-2.212-6.68-5.752-12.249-9.12-17.76c-1.843-3.017-2.356-6.61-4.32-9.36c-1.216-1.703-3.073-3.131-4.32-4.8
	c-1.474-1.972-2.702-3.92-4.08-6c-5.276-7.962-8.791-16.563-12.24-26.64c-0.412-1.203-2.848-8.771-2.4-9.36
	c1.2-1.564,4.486-1.648,6.48-2.4c3.454-1.303,7.001-3.145,10.56-4.32C174.27,147.401,176.646,147.614,177.972,146.722z'
          />{' '}
        </a>
        <a xlinkTitle={`Nefta ${groupedData['Nefta']}`}>
          <path
            onClick={() => setSelectedVill('Nefta')}
            style={{ fill: getColorBasedOnCount(outputData['Nefta']) }}
            d='M167.172,207.682c3.362,0.291,5.896,1.283,8.16,2.64c-2.97,8.188-4.885,16.534-8.16,24.479
	c-5.078,12.319-9.635,24.915-12.96,38.88c-1.282,5.385,2.12,13.559,3.12,17.76c0.08,2.08,0.16,4.16,0.24,6.24
	c0.525,2.309-0.097,5.169,0.48,7.68c1.05,4.566,1.239,9.465,3.36,12.961c3.927,6.471,9.286,11.789,13.68,17.76
	c8.988,12.211,19.266,23.197,28.32,35.279c3.309,4.416,7.441,8.262,10.8,12.721c4.62,6.135,10.716,11.09,15.36,17.279
	c1.86,2.48,5.435,5.071,6,8.641c-2.439,1.586-3.979,4.287-6.24,6c-4.653,3.521-8.346,8.271-12.96,11.76
	c-4.132,3.125-7.662,7.004-11.761,10.08c-10.802,8.107-20.742,17.166-31.68,25.201c-5.286,3.883-10.263,8.291-15.6,12.238
	c-1.321,0.979-5.129,4.838-6.24,4.801c-1.82-1.873-2.894-4.453-4.32-6.721c-2.467-3.92-4.902-7.953-7.44-12
	c-6.95-11.084-13.74-22.43-21.36-32.879c-2.803-3.844-6.257-7.225-9.12-11.041c-2.518-3.355-6.313-5.699-8.88-9.119
	c-1.678-2.236-4.044-3.955-5.76-6.24c-0.96-0.881-1.92-1.76-2.88-2.641c-2.479-2.479-5.086-5.104-7.2-7.92
	c-0.96-0.88-1.92-1.76-2.88-2.641c-3.734-4.948-8.915-9.188-11.52-15.119c-3.028-6.896-0.85-18.04,0.96-24.721
	c0.567-2.092,0.486-4.328,0.96-6.24c1.118-4.504,0.241-10.656,0.24-15.6c0-2.72,0-5.439,0-8.16c-0.366-1.674-0.708-5.394-0.24-7.439
	c0.24-3.119,0.48-6.24,0.72-9.359c1.058-4.383,2.262-8.479,3.6-12.479c2.026-6.055,2.202-12.797,3.84-19.2
	c0.655-2.563,0.374-5.305,0.96-7.68c0.858-3.479,0.157-7.278,0.96-10.56c0.767-3.134-0.004-6.878,0.72-9.84
	c0.419-1.715-0.408-3.819,0.48-4.32c1.721-1.272,4.291-1.344,6.48-2.16c5.699-2.123,11.768-3.509,17.76-5.52
	c7.927-2.661,16.072-5.357,23.52-8.64c4.254-1.875,8.03-4.932,12.24-6.72c4-0.96,8-1.92,12-2.88c1.925-0.827,3.459-2.659,5.52-3.36
	C162.692,208.482,164.932,208.082,167.172,207.682z'
          />{' '}
        </a>
        <a xlinkTitle={`Tozeur ${groupedData['Tozeur']}`}>
          <path
            onClick={() => setSelectedVill('Tozeur')}
            style={{ fill: getColorBasedOnCount(outputData['Tozeur']) }}
            d='M179.892,209.122c0.48,0,0.96,0,1.44,0c0.661,2.858,2.909,5.394,4.32,7.68c2.502,4.056,4.376,8.88,6,13.68
	c2.296,6.789,2.063,14.647,4.08,21.84c3.321,11.841,7.139,22.046,13.44,30.72c1.146,1.579,2.298,3.403,3.84,4.56
	c2.904,2.176,6.131,3.896,10.32,4.801c1.938,0.418,6.287-2.47,6.96-1.92c0.688,0.27,0.442,0.041,0.72,0.719
	c0.43,0.754-0.031,3.111-0.24,4.08c-0.718,3.33-0.172,7.572,1.2,9.602c1.255,1.854,3.821,2.396,5.761,3.601
	c2.404,1.492,7.719,5.75,9.359,7.92c2.264,2.994,5.213,5.429,7.439,8.399c6.5,8.677,13.998,16.64,21.6,24.24
	c3.641,3.64,7.4,7.158,11.04,10.799c1.38,1.381,3.858,2.861,4.56,4.801c-3.012,1.956-5.088,5.298-7.92,7.439
	c-5.752,4.351-10.996,9.416-16.8,13.68c-7.384,5.427-14.524,11.185-21.84,16.562c-2.813,2.067-6.04,3.953-8.399,6.479
	c-0.16,0-0.32,0-0.48,0c-1.199-3.896-4.954-6.629-7.2-9.602c-5.533-7.318-12.728-13.334-18.24-20.639
	c-10.858-14.393-23.302-27.443-34.08-42c-4.791-6.471-12.111-12.961-15.36-20.641c-1.769-4.18-1.519-9.116-2.64-14.16
	c-0.08-2.721-0.16-5.439-0.24-8.16c-0.4-2.4-0.8-4.801-1.2-7.199c-1.137-3.547-3.253-8.521-1.92-13.681
	c2.716-10.516,5.977-20.39,9.84-29.76c3.183-7.721,5.523-15.736,8.4-23.52c0.55-1.487,1.98-8.451,2.64-9.12
	C177.492,209.922,178.692,209.522,179.892,209.122z'
          />{' '}
        </a>
        <a xlinkTitle={`Hezoua ${groupedData['Hezoua']}`}>
          <path
            onClick={() => setSelectedVill('Hezoua')}
            style={{ fill: getColorBasedOnCount(outputData['Hezoua']) }}
            d='M81.492,238.402c0.078,4.587-0.054,9.6-0.96,13.68c-0.08,1.84-0.16,3.68-0.24,5.521
	c-0.56,4.799-1.12,9.601-1.68,14.4c-0.897,3.703-1.101,7.766-2.16,11.52c-1.905,6.753-3.652,13-5.28,20.16
	c-0.08,2.562-0.16,5.12-0.24,7.68c-0.439,2.021-0.444,5.901,0,7.921c0,4.079,0,8.159,0,12.239c0,2.722,0,5.44,0,8.16
	c-0.53,2.437-0.332,5.169-0.96,7.68c-1.669,6.668-4.379,18.981-1.44,26.16c1.713,4.185,4.719,6.791,7.2,10.08
	c5.168,6.853,11.915,12.181,17.04,18.961c1.28,1.199,2.56,2.399,3.84,3.601c5.68,7.504,13.351,13.252,18.96,20.879
	c8.524,11.592,15.76,23.986,23.52,36.48c2.351,3.783,4.693,7.742,7.2,11.52c1.276,1.922,3.314,3.949,4.08,6.24
	c-1.699,1.127-3.09,3.41-4.32,5.041c-1.521,2.014-3.969,3.982-6,5.52c-1.703,1.285-3.08,3.271-4.8,4.561
	c-8.793,6.582-18.39,12.859-29.04,17.52c-4.911,2.148-9.748,3.422-14.88,5.521c-4.23,1.729-11.596,4.59-16.56,5.041
	c-0.4-1.346-1.487-2.301-2.16-3.361c-2.02-3.182-4.046-6.281-6-9.359c-6.169-9.719-12.407-19.303-18.48-29.041
	c-6.751-10.824-12.43-22.086-17.76-34.318c-1.526-3.504-2.753-7.221-4.32-11.041c-0.607-1.479-1.966-3.883-2.16-5.52
	c-0.123-1.041,1.147-3.41,1.44-4.561c0.792-3.107,0.34-7.861-0.48-10.561c-1.822-5.995-4.548-11.059-6.96-16.561
	c-0.808-1.844-2.063-3.644-2.4-6c1.911-1.742,3.699-3.703,6.24-4.801c-2.593-6.982-6.224-11.459-11.76-15.359
	c-1.938-1.364-8.162-4.723-8.64-6.959c0.64-1.762,1.28-3.521,1.92-5.28c1.133-2.211,3.855-5.586,4.56-7.681
	c0.855-2.541-1.271-10.879-1.92-12.479c-1.598-3.938-3.277-7.58-4.8-11.761c-0.446-1.225-1.452-3.297-0.96-5.278
	c1.107-4.459,5.543-7.116,6.96-12.479c1.685-6.379-1.887-13.541-0.48-19.92c0.64-2.879,1.28-5.761,1.92-8.64
	c0.941-2.958,2.663-7.794,1.68-12.24c-0.968-4.378-3.28-7.62-3.36-12.24c3.819-0.062,7.595-1.428,10.8-2.4
	c3.225-0.979,6.183-1.113,9.36-2.16c3.781-1.247,7.772-2.838,11.52-4.08c2.45-0.812,4.642-0.844,7.2-1.68
	c5.073-1.659,10.513-3.552,15.84-5.04c3.262-0.911,6.072-0.677,9.36-1.68C74.344,240.961,77.583,238.801,81.492,238.402z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 109.4937 198.6958)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Tamaghza
        </text>
        <text
          transform='matrix(1 0 0 1 39.4937 423.6968)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hazoua
        </text>
        <text
          transform='matrix(1 0 0 1 108.4937 352.6968)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nefta
        </text>
        <text
          transform='matrix(1 0 0 1 184.4937 322.6968)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Tozeur
        </text>
        <text
          transform='matrix(1 0 0 1 236.4937 273.6968)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Deguech
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Tozeur;
