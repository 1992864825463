import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Gafsa = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='613.649px'
        height='491.364px'
        viewBox='0 0 613.649 491.364'
        enable-background='new 0 0 613.649 491.364'
      >
        <a xlinkTitle={`Sidi Aich ${groupedData['Sidi Aich']}`}>
          <path
            onClick={() => setSelectedVill('Sidi Aich')}
            style={{ fill: getColorBasedOnCount(outputData['Sidi Aich']) }}
            d='M263.56,204.057c0.24,1.52,0.479,3.041,0.72,4.56c-0.888,4.081-6.198,9.919-8.64,13.2
	c-1.59,2.135-5.988,5.379-5.041,9.6c0.842,3.747,4.141,4.409,6,6.96c0.674,0.923,0.342,2.246,0.961,3.36
	c1.049,1.892,3.289,2.494,4.317,4.801c-0.157,0-0.317,0-0.479,0c-4.354,2.463-11.746-0.199-14.641-1.681
	c-7.89-4.038-14.576-8.876-22.801-12.48c-2.188-0.959-6.6-3.942-9.12-3.36c-3.738,4.356,1.004,12.114,1.2,17.04
	c-0.48,0.08-0.96,0.16-1.44,0.24c-5.187-2.677-17.574-0.121-21.36,0.959c-0.498-1.389-0.937-0.58-1.44-1.199
	c-1.04-2.08-2.08-4.161-3.12-6.24c-3.779-7.304-6.938-14.673-11.52-21.12c-1.784-2.51-7.2-6.899-8.16-9.12
	c6.377-3.518,13.534-6.282,19.919-10.08c5.823-3.462,10.281-7.686,19.44-7.68c1.338-3.311,11.986-10.58,15.839-8.64
	c2.768,3.06,4.337,5.99,7.439,8.88c1.68-0.08,3.359-0.16,5.041-0.24c0.859,1.311,1.565,2.175,3.84,2.16
	c1.035-0.587,4.344-1.368,5.278-0.96C252.288,195.451,253.892,203.097,263.56,204.057z'
          />{' '}
        </a>
        <a xlinkTitle={`Moulares ${groupedData['Moulares']}`}>
          <path
            onClick={() => setSelectedVill('Moulares')}
            style={{ fill: getColorBasedOnCount(outputData['Moulares']) }}
            d='M69.642,191.817c2.699-0.032,3.501,0.974,5.28,1.44c1.68-0.08,3.36-0.16,5.04-0.24
	c2.318-0.353,5.592,0.938,7.44,1.68c1.36,0.72,2.72,1.439,4.08,2.16c0.32,0.72,0.64,1.44,0.96,2.16c3.439,1.6,6.88,3.2,10.32,4.8
	c3.449,1.437,11.07,1.136,13.92,3.12c2.8,2.479,5.6,4.96,8.4,7.44c1.44,0.64,2.88,1.28,4.32,1.92c2.422,2.045,2.814,5.552,5.76,7.2
	c-0.525,4.135-0.714,10.464-0.72,16.56c1.908,1.206,7.02,4.006,7.2,6.72c-1.6,1.92-3.2,3.841-4.8,5.761
	c-2.595,1.817-4.451,2.496-6.48,4.799c0.38,8.91,11.868,13.91,19.68,15.84c5.614,1.389,9.737-0.32,13.92,2.4
	c-0.08,0.08-0.16,0.16-0.24,0.24c-1.903,2.495-5.591,2.689-8.4,4.32c-1.68,1.279-3.36,2.56-5.04,3.84
	c-1.359,0.4-2.72,0.8-4.08,1.199c-6.416,3.949-10.444,14.217-14.4,20.641c-0.16,0-0.32,0-0.48,0
	c-2.179-2.807-9.745-10.406-15.12-8.88c-4.733,1.344-8.167,4.853-13.44,6c-4.834,1.052-9.37-0.812-13.92-0.96
	c-3.461-4.824-9.341-10.32-15.12-12.721c-1.44-0.319-2.88-0.639-4.32-0.959c-0.8-0.961-1.6-1.92-2.4-2.881
	c-2.229-1.701-6.374-4.192-10.8-3.359c-2.24,0.561-4.48,1.119-6.72,1.68c-2.303,0.991-3.354,2.568-6.72,2.641
	c-2.88-4.063-7.664-7.017-12-9.601c-2.034-1.212-4.554-1.806-6.24-3.36c1.195-1.867,0.347-6.252,1.2-7.92
	c3.027-2.849,6.596-4.789,8.88-8.4c1.437-2.27,1.676-5.23,3.12-7.68c3.12-0.08,6.24-0.16,9.36-0.24
	c0.88-0.238,1.76-0.479,2.64-0.719c0.08-0.641,0.16-1.281,0.24-1.92c-0.8-0.959-1.6-1.919-2.4-2.881
	c-0.667-3.559,7.983-10.397,12.72-8.4c0-0.8,0-1.6,0-2.4c-0.815-0.924-1.545-2.027-1.68-3.6c3.413-2.591,4.607-6.77,3.12-11.76
	c-2.27-1.27-4.649-1.735-4.56-5.52c1.151-1.523,1.39-3.593,2.16-5.52C62.133,199.419,63.637,195.792,69.642,191.817z'
          />{' '}
        </a>
        <a xlinkTitle={`Gafsa Nord ${groupedData['Gafsa Nord']}`}>
          <path
            onClick={() => setSelectedVill('Gafsa Nord')}
            style={{ fill: getColorBasedOnCount(outputData['Gafsa Nord']) }}
            d='M297.64,209.097c0.795-7.974,8.605-6.568,15.601-8.4c1.92-0.8,3.84-1.6,5.764-2.4
	c0.156,0.32,0.316,0.64,0.479,0.96c1.785,4.187-4.934,13.984-7.201,15.6c-0.83,0.591-3.457,0.898-4.08,1.68c0,1.2,0,2.4,0,3.6
	c-2.833,6.305,1.424,10.183-8.878,9.84c-2.586-1.291-7.596,1.071-8.398,2.64c-0.4,1.52-0.801,3.04-1.199,4.56
	c-1.271,1.071-2.639,1.749-3.602,3.12c-0.8,1.44-1.602,2.88-2.398,4.32c-1.041,0.56-2.08,1.12-3.121,1.68
	c-2.123,2.648-2.434,6.729-3.84,10.08c-0.881,1.279-1.761,2.561-2.641,3.84c-2.193,6.16-1.339,16.064,0.479,21.841
	c2.994,9.504,9.271,16.729,11.761,26.159c-0.158,0-0.318,0-0.479,0c-4.011,2.152-10.037,1.018-14.88,2.16
	c-5.04,1.438-10.079,2.879-15.12,4.317c-4.146,0.938-9.826-0.063-11.039-2.88c-1.406-2.563-1.953-6.887-2.16-10.318
	c-1.28-1.094-2.215-2.886-3.84-3.602c-1.869-0.825-4.709,0.34-6.24,0.96c-3.832-1.476-11.816-4.593-13.918-7.44
	c-3.143-4.254-3.053-9.901-4.562-16.08c-0.748-3.063,0.051-7.412-2.64-8.398c-2.732,1.34-5.85-0.928-7.2-1.92
	c-4.616-3.393-8.64-10.698-10.32-16.801c1.635,0.264,4.02-1.266,6-1.68c4.884-1.021,12.356-0.313,16.56,0.959c0-0.16,0-0.32,0-0.479
	c2.285-3.642-3.311-11.479-1.2-17.04c16.568,3.952,27.732,21.58,47.76,17.278c0-0.08,0-0.159,0-0.238
	c-0.318-0.88-0.641-1.76-0.96-2.64c-1.2-1.04-2.399-2.08-3.601-3.12c-0.616-1.003-0.332-2.471-0.961-3.36
	c-1.729-2.445-5.258-3.212-6-6.72c-0.698-3.307,2.813-5.981,4.08-7.68c3.467-4.646,10.547-11.258,9.121-18.72
	c3.471-2.4,5.83-5.829,8.879-8.64c0.16,0.08,0.319,0.16,0.479,0.24c1.75,1.843,3.214,3.743,5.761,4.8
	c3.77,1.565,8.236,1.802,11.52,3.84C293.716,206.455,293.884,208.941,297.64,209.097z'
          />{' '}
        </a>
        <a xlinkTitle={`Sned ${groupedData['Sned']}`}>
          <path
            onClick={() => setSelectedVill('Sned')}
            style={{ fill: getColorBasedOnCount(outputData['Sned']) }}
            d='M351.399,246.297c4.793,0.063,4.791-2.438,8.398-3.121c0.75,1.797,2.586,10.144,3.84,10.8
	c1.447,0.68,3.492-1.023,4.32-0.48c1.355,0.705,0.658,1.898,1.439,3.121c1.199,1.119,2.398,2.239,3.602,3.359
	c-0.105,4.706-3.463,4.962-3.602,9.84c0.58,0.641,0.984,1.143,1.68,1.68c-0.078,0.16-0.156,0.32-0.236,0.48
	c-5.104,2.961-7.055,4.372-6.961,12.48c-3.119,1.76-6.24,3.52-9.359,5.278c-0.193,4.929-3.428,6.854-5.521,9.841
	c-0.729,1.045-0.92,3.065-1.922,3.84c-2.314,0.721-4.639,1.439-6.959,2.16c-5.395,2.135-10.639,3.785-17.275,5.041
	c-5.348,1.008-11.791-0.955-16.08-1.922c-6.188-1.392-12.894-0.846-19.201-0.479c-4.262-10.93-10.031-18.971-12.959-31.678
	c-1.033-4.479-1.328-12.699,0.719-16.562c0.721-0.88,1.441-1.761,2.16-2.642c1.514-3.256,1.682-7.66,3.84-10.318
	c0.961-0.479,1.921-0.96,2.881-1.44c0.08-0.08,0.16-0.16,0.24-0.24c1.039-1.76,2.08-3.52,3.119-5.28
	c0.826-1.075,2.553-1.333,3.121-2.64c0.479-1.6,0.959-3.2,1.438-4.8c3.591-2.111,8.125-1.714,13.918-1.68c0.805-2,1.604-4,2.401-6
	c0.318-2.479,0.639-4.96,0.959-7.44c2.957-2.443,5.859-3.345,7.92-6.72c1.578-2.583,1.568-6.057,3.121-8.88
	c0.656-0.606,1.053-0.714,2.398-0.72c2.959,1.04,5.92,2.08,8.879,3.12c0.926-3.402,6.973-5.248,10.32-6
	c3.109,3.269,10.104,3.521,11.279,8.64c-0.08,0.16-0.16,0.32-0.24,0.48c-2.234,1.103-6.635,1.174-9.359,1.92
	c-0.996,1.469-1.479,2.333-1.438,5.04c0.16,0,0.318,0,0.479,0c2.229,1.073,5.258-0.979,6.721-1.68
	c3.238-1.556,6.453-2.336,10.318-1.44c0.76,1.086,1.012,1.745,0.961,3.84c-1.453,3.121-0.969,7.089-2.16,10.08
	C355.983,231.748,351.944,237.866,351.399,246.297z'
          />{' '}
        </a>
        <a xlinkTitle={`Gafsa Sud ${groupedData['Gafsa Sud']}`}>
          <path
            onClick={() => setSelectedVill('Gafsa Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Gafsa Sud']) }}
            d='M167.32,210.537c0.32,0,0.64,0,0.96,0c2.25,3.211,5.84,5.606,8.16,8.88
	c5.461,7.708,9.206,16.739,13.68,25.44c1.167,2.271,3.012,3.808,4.08,6.24c3.351,7.633,6.206,17.147,17.28,17.04
	c1.653,5.555,1.826,14.395,4.08,19.92c0.831,2.037,2.645,4.734,4.32,6c0.854,0.645,2.583,1.184,2.881,1.68
	c-5.537,6.373-9.707,10.781-19.917,12.721c-0.622,1.375-0.783,4.156-1.68,5.521c-2.009,3.055-6.233,4.486-8.16,7.682
	c-3.527,5.84-3.94,13.938-10.8,16.318c-9.199,3.193-18.36-2.248-27.6-2.398c-1.129,2.799-3.753,5.222-5.04,8.158
	c-2.968,6.778-6.612,18.735-14.88,19.682c0-0.159,0-0.319,0-0.479c0.951-1.9-0.049-6.013-0.48-7.681
	c-1.179-4.562-2.372-7.856-4.8-11.039c-1.564-2.05-4.859-2.961-6.24-5.041c1.6-2.238,3.2-4.479,4.8-6.72
	c3.377-4.321,8.563-7.715,12.96-11.041c2.954-2.231,5.516-5.18,8.4-7.438c2.962-2.32,7.843-6.07,7.92-11.04
	c-0.08-0.079-0.16-0.159-0.24-0.239c-2.282-0.082-5.119,1.182-6.48-0.721c1.44-1.521,2.88-3.04,4.32-4.561
	c4.948-3.857,15.879-11.775,25.92-9.84c3.021,0.582,5.858,1.058,8.4,2.16c2.91,1.262,3.829,3.43,7.92,3.119
	c3.235-3.65,7.815-4.771,7.44-11.52c-3.925-3.262-7.104-6.121-12.72-7.92c-6.682-2.144-14.312-2.201-19.68-5.521
	c-6.958-4.301-10.391-12.75-17.52-17.04c-3.52-1.278-7.041-2.562-10.56-3.84c-1.02-0.702,0.152-0.771-0.48-1.68
	c-0.622-0.896-3.181-2.226-4.08-2.88c-1.36-0.96-2.72-1.92-4.08-2.88c0-2.88,0-5.76,0-8.64c0.764-1.711,0.085-4.891,1.44-6
	c2.487,2.154,20.152,8.144,20.88,0.72C154.644,219.482,165.064,213.021,167.32,210.537z'
          />
        </a>
        <a xlinkTitle={`Metlaoui ${groupedData['Metlaoui']}`}>
          <path
            onClick={() => setSelectedVill('Metlaoui')}
            style={{ fill: getColorBasedOnCount(outputData['Metlaoui']) }}
            d='M142.36,247.256c1.68,0.801,3.36,1.601,5.04,2.4c2.557,0.909,5.12,1.053,7.2,2.399
	c9.191,5.954,12.259,16.024,24.24,19.68c7.4,2.259,23.593,3.522,24.72,11.04c0.95,3.053-5.664,9.104-8.64,9.359
	c-2.358-1.828-5.542-3.604-8.64-4.561c-16.803-5.191-29.744,6.985-37.2,14.641c0.08,0.16,0.16,0.32,0.24,0.479
	c2.219,1.863,4.545,0.685,6.96,0.961c0,0.399,0,0.802,0,1.199c-3.464,3.034-5.673,7.444-9.36,10.317
	c-7.117,5.549-22.499,16.061-25.2,24.961c8.045,2.104,10.526,10.564,12.24,19.199c0.775,3.906-0.413,5.815,0.72,9.359
	c0.666,2.083,2.186,3.707,3.36,5.28c1.856,2.487,3.928,6.458,8.16,6.479c1.457-1.568,1.977-3.369,3.6-4.803
	c4.585-0.309,7.75,1.121,12,1.438c0.322,0.621,0.436,0.84,0.48,1.922c-0.48,2.238-0.96,4.479-1.44,6.721
	c-1.922,6.313-3.498,12.579-5.04,19.682c-0.844,3.887,0.309,8.756,1.2,11.278c0.32,1.04,0.64,2.08,0.96,3.12
	c-4.498,2.916-12.717-0.555-16.56-1.92c-1.84-0.563-3.68-1.12-5.52-1.682c-3.491-0.705-7.08,0.438-9.84,0.959
	c-8.186,1.553-16.172,0.49-23.52,2.399c-5.253,1.367-8.985,4.636-15.12,3.12c-0.08-0.721-0.16-1.439-0.24-2.159
	c0.515-0.991,2.306-7.563,1.92-8.881c-0.464-1.582-2.518-2.569-3.36-3.84c-0.4-1.279-0.8-2.562-1.2-3.841
	c-1.233-3.096-3.296-5.771-5.76-7.68c-7.814-6.063-17.861-8.479-27.839-12.723c-4.007-1.702-7.712-4.12-11.28-6.239
	c-2.24-0.959-4.48-1.92-6.72-2.879c-1.513-1.028-6.207-10.972-6.72-13.2c2.16-0.88,4.32-1.761,6.48-2.641
	c9.63-2.704,16.427-1.348,22.56-7.682c-1.223-6.104-4.431-9.395-7.44-13.682c-1.108-1.578-3.712-3.666-2.88-6.959
	c0.54-2.143,2.206-3.39,3.12-5.041c0.24-0.879,0.48-1.761,0.72-2.64c1.969-1.601,6.726-3.556,10.56-2.882
	c8.534,1.5,28.877,5.147,29.76-5.76c-1.475-2.146-2.17-7.968-0.48-10.32c2.709-1.821,8,1.277,12.24,0.48
	c1.92-0.4,3.84-0.802,5.76-1.199c3.638-1.479,8.432-6.398,13.44-5.041c4.179,1.133,5.865,5.146,8.88,7.438
	c0.562,0.429,2.834,1.981,3.12,1.681c5.576-5.783,7.364-15.625,13.92-20.397c1.481-1.079,3.477-1.095,5.28-1.921
	c1.36-1.119,2.72-2.239,4.08-3.359c3.511-2.277,7.674-2.416,10.08-5.76c-1.277-0.881-2.394-2.145-4.08-2.641
	c-3.6-0.24-7.2-0.479-10.8-0.72c-8.021-1.95-17.847-5.94-19.2-14.399C134.638,253.684,139.727,252.239,142.36,247.256z'
          />{' '}
        </a>
        <a xlinkTitle={`Belkhir ${groupedData['Belkhir']}`}>
          <path
            onClick={() => setSelectedVill('Belkhir')}
            style={{ fill: getColorBasedOnCount(outputData['Belkhir']) }}
            d='M435.637,317.577c-0.447,3.399-5.838,3.629-5.039,7.199c0.564,2.523,4.021,3.133,4.801,5.52
	c1.572,4.817,0.045,11.35-0.721,15.361c0.16,4.318,0.318,8.639,0.48,12.959c-1.906,7.645-14.504,16.205-24.234,15.84
	c-9.127-0.344-16.553-3.606-24.24-6c-3.672-1.143-9.564-2.854-14.16-1.438c-4.16,1.275-6.775,4.156-9.84,6.479
	c-8.549,6.479-18.385,16.146-34.08,12.961c0.104-1.391-0.639-2.569-0.961-4.08c-1.092-5.147,0.953-10.633-0.48-15.121
	c-1.232-3.866-3.662-7.243-4.559-11.521c-1.287-6.124,2.641-11.084,4.08-15.118c0.395-1.602,0.799-3.201,1.199-4.802
	c9.119-3.12,18.236-6.239,27.355-9.358c0.08-1.281,0.16-2.562,0.238-3.84c-0.719-2.08-1.438-4.16-2.158-6.24
	c-1.355-2.642-2.721-5.28-4.08-7.92c-0.645-1.492-1.396-3.813-0.721-5.762c1.67-4.759,6.381-6.314,6.961-12.48
	c1.158-0.307,9.063-4.4,9.359-5.039c0-1.119,0-2.239,0-3.359c1.51-3.013,0.934-6.165,3.602-7.92c1.52-0.398,3.039-0.8,4.563-1.2
	c2.188-1.372,3.068-3.636,6.234-4.319c4.109-0.889,7.631,1.772,9.84,3.121c4.564,2.776,9.742,6.77,16.805,6
	c1.068,7.111,8.018,8.969,11.521,13.68C423.917,299.938,421.411,316.445,435.637,317.577z'
          />
        </a>
        <a xlinkTitle={`Redeyef ${groupedData['Redeyef']}`}>
          <path
            onClick={() => setSelectedVill('Redeyef')}
            style={{ fill: getColorBasedOnCount(outputData['Redeyef']) }}
            d='M24.282,274.618c7.078,3.289,13.116,7.485,18.24,12.719c6.199-0.008,9.031-5.612,16.08-4.318
	c2.476,0.454,4.972,1.15,6.72,2.398c1.655,1.185,2.276,3.588,4.8,3.84c1.92,0.722,3.84,1.44,5.76,2.16
	c2.648,1.617,11.246,8.652,12,11.521c-2.919,3.495,0.755,9.01,0,12c-5.031,12.95-32.087-1.641-40.08,7.2
	c-0.16,0.8-0.32,1.6-0.48,2.399c-0.785,1.836-2.828,3.137-3.36,5.521c-1.554,6.965,9.641,12.848,10.08,20.88
	c-1.407,0.993-2.644,2.387-4.32,3.12c-5.303,2.319-12.308,2.032-18.24,3.84c-2.331,0.709-5.225,3.003-7.44,2.16
	c-1.702-1.728-3.093-3.808-5.04-5.28c-7.576-5.734-21.91-12.015-18.48-25.439c1.818-0.35,2.295-0.771,2.16-3.119
	c1.6-1.438,3.2-2.881,4.8-4.318c0.96-2,1.92-4,2.88-6c3.729-6.08,9.507-9.514,18.96-9.84c0-0.16,0-0.32,0-0.479
	c0-0.563,0-1.121,0-1.683c-2.232-5.54-9.29-7.563-10.08-14.88C21.963,285.95,23.151,278.974,24.282,274.618z'
          />{' '}
        </a>
        <a xlinkTitle={`El Ksar ${groupedData['El Ksar']}`}>
          <path
            onClick={() => setSelectedVill('El Ksar')}
            style={{ fill: getColorBasedOnCount(outputData['El Ksar']) }}
            d='M223.48,295.977c3.556,0.427,5.711,3.105,8.64,3.84c1.919-0.4,3.84-0.8,5.76-1.199
	c1.55,1.248,3.494,1.855,3.841,4.319c0.719,3.278,1.439,6.56,2.159,9.84c1.279,0.88,2.458,2.076,4.08,2.64
	c1.2,0.24,2.399,0.48,3.601,0.722c-8.871,6.032-22.633,22.204-23.521,35.762c-1.315,0.913-2.396,2.089-4.08,2.644
	c-0.69-5.826-4.163-18.57-9.12-19.684c-4.477,3.771-11.354-0.412-17.76,0.96c-1.52,0.563-3.04,1.12-4.56,1.683
	c-1.738,0.514-4.338,0.291-5.52-0.479c-0.08-0.399-0.16-0.8-0.24-1.2c3.865-2.949,4.654-8.85,6.96-13.439
	c2.879-2.799,5.76-5.6,8.64-8.396c0.32-1.521,0.64-3.041,0.96-4.563C211.296,307.231,221.269,303.426,223.48,295.977z'
          />{' '}
        </a>
        <a xlinkTitle={`El Guettar ${groupedData['El Guettar']}`}>
          <path
            onClick={() => setSelectedVill('El Guettar')}
            style={{ fill: getColorBasedOnCount(outputData['El Guettar']) }}
            d='M347.321,304.616c0.195,7.711,7.078,11.877,7.197,21.12c-9.277,3.12-18.561,6.24-27.84,9.36
	c-0.123,6.34-3.633,10.785-5.039,16.56c-1.563,6.413,3.998,13.831,5.279,18.96c1.154,4.639-0.848,9.971,0.48,13.92
	c0,0.881,0,1.76,0,2.641c-0.803,0-1.602,0-2.398,0c-1.783,0.98-10.871,5.359-12,5.039c-3.461-1.582-3.533-6.801-7.44-7.92
	c-3.569-1.021-7.246,1.67-9.602,2.881c-5.766,2.963-11.936,5.813-18.72,7.439c0,0.08,0,0.16,0,0.24c0.399,1.68,0.8,3.359,1.198,5.04
	c1.524,4.188,3.771,7.263,2.642,13.2c-5.315,3.557-26.17,6.345-29.278-0.961c1.813-3.219-1.213-6.877-4.32-6.719
	c-0.479,0.16-0.961,0.316-1.439,0.479c-0.08-0.08-0.16-0.16-0.238-0.238c0.045-0.527-0.246-3.264-0.962-3.842
	c-2.56,0-5.118,0-7.68,0c-8.394,0.004-21.766,0.048-20.64-8.641c0.484-3.733,3.04-4.416,3.122-10.08
	c-1.439-1.039-2.879-2.079-4.322-3.119c-4.042-5.396,4.171-9.389,5.281-12.479c1.215-3.39-1.375-5.537-0.721-8.883
	c2.498-1.257,8.938-4.279,9.12-7.681c1.647-3.14,1.998-6.988,3.358-10.08c3.104-7.04,8.548-13.104,13.682-18.237
	c2.061-2.062,4.95-6.359,8.398-6.723c2.879-0.643,5.762-1.277,8.642-1.92c2.571-0.816,5.563-1.946,8.399-2.643
	c4.479-0.561,8.959-1.121,13.438-1.68c2.942-0.67,7.729-1.837,11.28-1.201c4.879,0.641,9.762,1.28,14.641,1.92
	c3.236,0.68,8.133,1.965,12.238,1.201c5.043-0.938,9.404-1.668,13.682-3.361C339.987,306.939,343.393,305.057,347.321,304.616z'
          />{' '}
        </a>
        <a xlinkTitle={`El Mdhilla ${groupedData['El Mdhilla']}`}>
          <path
            onClick={() => setSelectedVill('El Mdhilla')}
            style={{ fill: getColorBasedOnCount(outputData['El Mdhilla']) }}
            d='M244.359,402.536c0.08,0.721,0.16,1.44,0.24,2.16c-1.279,0.16-2.562,0.32-3.84,0.48
	c-1.218,1.654-0.537,5.398-3.359,5.52c-1.674,1.708-2.771,3.917-4.563,5.52c-4.396,0.16-8.8,0.32-13.197,0.48
	c-10.583,0.955-21.327-0.633-30,1.68c-2.4,0.48-4.8,0.961-7.2,1.44c-1.52,0.64-3.04,1.28-4.56,1.92
	c-0.08-0.079-0.16-0.159-0.24-0.239c-2.088-0.621-4-1.685-6.48-2.16c-4.08,0-8.16,0-12.24,0c-0.72-2.479-1.44-4.961-2.16-7.438
	c-1.753-7.208,2.626-18.332,4.32-23.277c0.459-1.344,2.684-10.824,2.16-11.521c-1.383-1.469-11.649-3.533-14.4-2.159
	c-2.013,1.007-1.8,4.228-4.32,4.562c-3.671-3.612-9.812-8.15-9.12-15.121c8.155-2.146,11.502-11.271,14.64-18.479
	c1.455-3.343,2.734-9.338,7.44-9.359c1.84,0.32,3.68,0.642,5.52,0.961c7.05,1.686,16.574,3.451,22.8-0.239
	c0.701,1.263,3.683,1.955,6,1.438c1.84-0.641,3.68-1.279,5.52-1.92c3.052-0.682,6.736,0.277,9.12,0.721
	c3.911,0.728,6.389-1.049,9.12-1.438c3.295,4.575,4.2,6.942,6,13.438c0.477,1.721,1.813,4.242,0.959,5.76
	c-1.539,3.127-3.914,0.809-3.84,6.722c4.254,6.03-3.953,8.53-5.042,12.96c-1.772,7.201,5.042,5.652,5.042,11.521
	c-0.896,1.256-2.042,3.287-2.642,4.56c-1.401,2.979,0.388,6.364,1.92,7.921C223.011,404.063,234.728,402.668,244.359,402.536z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 111.5278 485.3638)'
          font-family="'MyriadPro-Regular'"
          font-size='24'
        >
          Gouvernorat de Gafsa
        </text>
        <text
          transform='matrix(1 0 0 1 58.1235 258.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Om El Araies
        </text>
        <text
          transform='matrix(1 0 0 1 36.1235 311.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Redeyef
        </text>
        <text
          transform='matrix(1 0 0 1 71.1235 365.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Métlaoui
        </text>
        <text
          transform='matrix(1 0 0 1 170.1235 368.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Mdhila
        </text>
        <text
          transform='matrix(1 0 0 1 255.1235 358.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Guettar
        </text>
        <text
          transform='matrix(1 0 0 1 203.1235 315.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Ksar
        </text>
        <text
          transform='matrix(1 0 0 1 216.1235 265.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Gafsa Nord
        </text>
        <text
          transform='matrix(1 0 0 1 136.1255 333.0708)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Gafsa Sud
        </text>
        <text
          transform='matrix(1 0 0 1 194.1235 221.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi Aïch
        </text>
        <text
          transform='matrix(1 0 0 1 311.1235 269.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sened
        </text>
        <text
          transform='matrix(1 0 0 1 371.1235 337.6304)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Belkhir
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Gafsa;
