export default function useGetColor(dataValues) {

    const maxDataValues = Math.max(...dataValues)
    const quantiles = [Math.floor(maxDataValues / 5),
    Math.floor(maxDataValues / 5) * 2,
    Math.floor(maxDataValues / 5) * 3,
    Math.floor(maxDataValues / 5) * 4]


    return {
        quantiles
    }


}