import React, { useState } from 'react';
import './style.css';
import { DataGrid } from '@mui/x-data-grid';
import { Modal } from 'antd';
import Nabeul from '../../States/Nabeul';
import Beja from '../../States/Beja';
import Bizerte from '../../States/Bizerte';
import Gabes from '../../States/Gabes';
import Gafsa from '../../States/Gafsa';
import Jendouba from '../../States/Jendouba';
import Kairouan from '../../States/Kairouan';
import Kasserine from '../../States/Kasserine';
import Kebili from '../../States/Kebili';
import Kef from '../../States/Kef';
import Mahdia from '../../States/Mahdia';
import Medenine from '../../States/Medenine';
import Sfax from '../../States/Sfax';
import SidiBouzid from '../../States/SidiBouzid';
import Siliana from '../../States/Siliana';
import Soussa from '../../States/Soussa';
import Tataouine from '../../States/Tataouine';
import Tozeur from '../../States/Tozeur';
import Zaghouan from '../../States/Zaghouan';

const columns = [
  { field: 'State', headerName: 'State', width: 230 },
  { field: 'Ville', headerName: 'Ville', width: 230 },
  { field: ' Subville ', headerName: 'SubVille', width: 230 },
  { field: 'Code', headerName: 'Postle Code', width: 130 },
  { field: 'Count', headerName: 'User Count', width: 130 },
];

const CustomModal = ({
  handleCloseModal,
  isModalOpen,
  modalData,
  Icon,
  title,
}) => {
  const formattedData = modalData?.map((data, index) => ({
    ...data,
    Count: Number(data.Count),

    id: index,
  }));
  let componentToRender;

  if (Icon === 'Nabeul') {
    componentToRender = <Nabeul data={modalData} />;
  } else if (Icon === 'Beja') {
    componentToRender = <Beja data={modalData} />;
  } else if (Icon === 'Sidi Bouzid') {
    componentToRender = <SidiBouzid data={modalData} />;
  } else if (Icon === 'Zaghouan') {
    componentToRender = <Zaghouan data={modalData} />;
  } else if (Icon === 'Sousse') {
    componentToRender = <Soussa data={modalData} />;
  } else if (Icon === 'Tozeur') {
    componentToRender = <Tozeur data={modalData} />;
  } else if (Icon === 'Kasserine') {
    componentToRender = <Kasserine data={modalData} />;
  } else if (Icon === 'Gafsa') {
    componentToRender = <Gafsa data={modalData} />;
  } else if (Icon === 'Gabes') {
    componentToRender = <Gabes data={modalData} />;
  } else if (Icon === 'Kef') {
    componentToRender = <Kef data={modalData} />;
  } else if (Icon === 'Medenine') {
    componentToRender = <Medenine data={modalData} />;
  } else if (Icon === 'Mahdia') {
    componentToRender = <Mahdia data={modalData} />;
  } else if (Icon === 'Kebili') {
    componentToRender = <Kebili data={modalData} />;
  } else if (Icon === 'Sfax') {
    componentToRender = <Sfax data={modalData} />;
  } else if (Icon === 'Siliana') {
    componentToRender = <Siliana data={modalData} />;
  } else if (Icon === 'Tataouine') {
    componentToRender = <Tataouine data={modalData} />;
  } else if (Icon === 'Kairouan') {
    componentToRender = <Kairouan data={modalData} />;
  } else if (Icon === 'Jendouba') {
    componentToRender = <Jendouba data={modalData} />;
  } else if (Icon === 'Bizerte') {
    componentToRender = <Bizerte data={modalData} />;
  }
  return (
    <>
      <Modal
        centered
        title={title}
        open={isModalOpen}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        width={1100}
      >
        <div style={{ maxHeight: 400, overflow: 'scroll' }}>
          <DataGrid
            rows={formattedData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 4 },
              },
            }}
            pageSizeOptions={[4, 10, 20, 30, 50]}
          />
        </div>
        <div className='image-container'>{componentToRender}</div>
      </Modal>
    </>
  );
};

export default CustomModal;
