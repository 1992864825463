import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Sfax = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='632.91px'
        height='527.109px'
        viewBox='0 0 632.91 527.109'
        enable-background='new 0 0 632.91 527.109'
      >
        <a xlinkTitle={`El Hencha ${groupedData['El Hencha']}`}>
          <path
            onClick={() => setSelectedVill('El Hencha')}
            style={{ fill: getColorBasedOnCount(outputData['El Hencha']) }}
            d='M307.918,95.34c2.682-0.057,4.354,0.452,6.24,0.96c0.135,2.076,1.297,4.306,0.719,6.96
	c-0.652,3.004-0.908,8.034,0,11.04c1.588,5.246,4.412,9.297,6.721,13.68c0.807,1.526,4.516,9.032,3.359,11.04
	c-0.564,1.734-2.096,3.003-3.359,4.08c-4.5,3.835-12.254,9.057-12.479,16.8c2.705,2.702,4,7.194,6.24,10.32
	c1.201,1.677,3.271,3.703,5.041,4.8c0.836,0.519,2.406,0.52,3.117,1.2c0.16,0.48,0.32,0.96,0.48,1.44
	c0.988,1.432,3.096,3.121,4.561,4.08c0,0.08,0,0.16,0,0.24c-0.08,0-0.158,0-0.238,0c-1.264,1.856-2.141,2.553-1.92,5.52
	c-2.254-0.128-5.404-2.767-7.922-1.2c-3.553,1.271-4.984,8.196-5.041,12.96c-1.197,0-2.398,0-3.6,0c0,2.879,0,5.76,0,8.64
	c0.424,0.23,0.402,0.301,0.959,0.479c0.824,0.675,2.488,0.489,3.604,0.24c0,1.12,0,2.24,0,3.36c1.074,3.863,5.66,5.735,4.799,10.56
	c-0.08,0-0.16,0-0.24,0c-1.025-1.331-3.146-1.36-5.039-1.2c-0.397,0.529-0.682,0.486-0.961,1.2c-0.357,0.39-0.373,0.486-0.479,1.2
	c-0.399,0-0.802,0-1.2,0c-1.203-2.044-2.893-5.104-4.8-6.48c-1.28-0.924-3.073-1.151-4.319-2.16c-0.791-0.64-0.426-1.87-1.439-2.4
	c-1.639-1.38-5.329-0.241-7.682-0.96c-1.877-0.574-2.768-2.415-3.84-3.84c-0.852-1.134-2.655-2.336-3.84-3.12
	c-1.158-0.768-2.99-0.828-3.359-2.4c0.48-0.56,0.96-1.12,1.439-1.68c1.018-0.69,2.394-0.726,3.36-1.44c0.399-0.56,0.8-1.12,1.2-1.68
	c0.045-3.812-0.785-6.315-0.961-9.36c1.5-0.067,2.498-0.74,2.881-1.92c1.768-2.264-0.645-9.39-0.721-12.24
	c-1.278,0.19-2.39,0.698-3.6,0.96c-2.586,0.561-4.688-1.058-6-1.92c-3.906-2.563-6.551-6.232-10.8-8.4
	c-0.045-9.618-4.87-16.212-7.2-24c-1.477-4.937-0.326-11.438-1.92-16.32c-1.097-3.361-3.99-4.74-4.08-8.64
	c-0.488-0.748-0.225-2.678-0.24-3.84c-0.061-4.601,2.834-5.91,6.48-7.44c0.938-0.393,2.652-1.616,3.6-1.2
	c1.795,1.763,3.006,4.247,5.041,5.76c3.543,2.635,11.77,6.887,16.799,7.68c1.789,0.282,6.211-2.381,7.44-3.12
	C301.189,109.687,307.938,105.603,307.918,95.34z'
          />{' '}
        </a>
        <a xlinkTitle={`Menzel Chaker ${groupedData['Menzel Chaker']}`}>
          <path
            onClick={() => setSelectedVill('Menzel Chaker')}
            style={{ fill: getColorBasedOnCount(outputData['Menzel Chaker']) }}
            d='M288.238,175.5c0.265,3.6,1.652,7.013,0.72,10.8c-0.423,0.26-0.868,0.604-1.2,0.96c-0.721,0-1.439,0-2.16,0
	c0.415,3.313,1.967,6.463,1.2,10.563c-2.149,1.223-4.078,2.416-5.761,4.081c0,0.64,0,1.28,0,1.919c0.24,0.08,0.48,0.16,0.721,0.24
	c0.391,0.359,0.484,0.373,1.2,0.48c-0.386,3.806-2.431,5.455-4.08,7.92c-2.294,3.43-4.03,7.001-6.24,10.56
	c-0.841,1.354-2.364,2.997-2.88,4.56c-1.273,3.854,1.696,6.81,2.16,9.12c-0.958,0.734-1.43,1.906-2.16,2.88
	c-1.133,1.509-3.287,3.435-4.8,4.56c-4.586,3.41-9.345,6.448-13.44,10.32c-0.988-1.165-2.656-1.656-4.08-2.4
	c-4.168-2.177-9.082-3.234-14.399-4.56c-3.365-0.839-6.378-0.53-9.6-1.44c-15.886-4.486-30.559-8.925-44.64-14.88
	c-1.746,7.305-6.572,13.411-10.56,18.72c-1.026,1.366-2.611,2.379-3.6,3.84c-2.915-0.599-6.538-0.982-9.6-1.68
	c-1.84-0.16-3.68-0.32-5.52-0.48c-1.71-0.404-3.925-1.209-5.52-1.44c-1.76,0.56-3.52,1.12-5.28,1.68
	c-3.729,1.029-8.482,0.139-11.04-1.44c-13.25-8.18-13.28-34.43-19.92-49.68c-0.88-1.6-1.76-3.2-2.64-4.8
	c-0.238-0.326-0.979-0.428-1.2-0.72c-0.16-0.72-0.32-1.44-0.48-2.16c-0.474-1.112-1.234-2.511-1.92-3.6
	c-1.359-2.159-3.396-3.876-3.6-7.2c1.186-1.155,1.879-2.73,2.88-4.08c2.959-3.04,5.92-6.081,8.88-9.12
	c5.132-3.878,9.785-8.32,14.4-12.72c1.223-1.166,4.44-3.562,5.04-5.04c0.373-0.918,0.1-2.855,0.24-3.6
	c1.853-0.242,3.772-1.193,5.52-1.68c3.073-0.855,6.066-0.656,8.88-1.68c1.93-0.702,3.651-2.121,5.76-2.4
	c1.037,1.274,2.882,1.748,4.32,2.64c3.205,1.988,10.905,6.894,17.28,5.28c2.311-0.584,4.248-2.251,6.24-2.64
	c2.12,3.155,7.699,0.678,11.28,1.44c3.703,5.298,5.873,7.21,11.28,10.8c1.14,0.757,2.483,2.19,3.84,2.64
	c4.292,1.424,12.33-1.496,15.12-2.88c9.75-4.837,21.606-12.625,27.84-20.88c2.042-2.704,7.81-7.38,8.399-11.04
	c-2.293-2.4-6.205-8.901-5.76-13.2c1.826-0.568,2.912-1.883,5.041-2.4c0,1.44,0,2.88,0,4.32c0.344,0.516,0.268,1.754,0.239,2.64
	c3.784,2.614,3.87,7.138,5.04,12.48c0.08,1.92,0.16,3.84,0.24,5.76c0.574,2.509,0.814,5.097,1.68,7.44
	c1.454,3.933,3.473,7.976,4.801,12c0.594,1.798,0.91,7.934,1.68,9.12c1.068,1.008,2.656,1.52,3.84,2.4
	c3.163,2.354,7.695,9.64,14.16,8.16C286.691,175.84,287.371,175.563,288.238,175.5z'
          />{' '}
        </a>

        <a xlinkTitle={`Jebeniana ${groupedData['Jebeniana']}`}>
          <path
            onClick={() => setSelectedVill('Jebeniana')}
            style={{ fill: getColorBasedOnCount(outputData['Jebeniana']) }}
            d='M345.357,127.98c4.428,0.324,7.771,5.593,8.158,10.08c0.201,2.337-1.803,3.608-2.158,5.52
	c0.629,0.694,0.742,1.759,1.199,2.64c0.729,1.406,1.693,2.819,2.641,4.08c4.273,5.695,14.438,8.136,24,8.4
	c0.949,1.543,0.758,4.617,0.721,7.2c0.736,1.173-0.143,5.659-0.479,6.959c-1.505,5.799-3.241,9.238-7.438,12.24
	c-1.283,0.917-1.754,1.918-4.08,1.92c-1.389-0.846-5.705-0.032-6.961-0.72c-0.684-0.375-3.15-5.113-3.6-6.24
	c-0.721,0.16-1.439,0.32-2.16,0.48c-1.554-0.368-2.783-3.251-4.32-2.64c-0.76,0.213-0.789,0.451-1.438,0.72
	c-1.796-2.575-5.771-3.484-7.921-5.76c-1.229-0.037-1.759-0.418-2.88,0c-1.286,0.479-2.411,2.98-3.842,2.4
	c-0.572-0.763-0.444-1.521-1.438-1.92c-2.148-1.284-5.787,2.735-8.4,2.4c-6.49-0.832-9.377-7.563-12.238-12.24
	c-0.881-1.438-2.402-2.785-2.641-4.8c1.039-1.605,1.184-4.063,2.4-5.76c2.497-3.485,6.656-5.803,9.84-8.64
	c1.861-1.662,2.293-5.03,5.76-5.04c0.715-0.447,1.672-0.208,2.641-0.479c1.841-0.72,3.682-1.44,5.521-2.16
	C340.023,134.318,342.467,131.149,345.357,127.98z'
          />{' '}
        </a>
        <a xlinkTitle={`El Amra ${groupedData['El Amra']}`}>
          <path
            onClick={() => setSelectedVill('El Amra')}
            style={{ fill: getColorBasedOnCount(outputData['El Amra']) }}
            d='M310.797,200.22c1.201,0,2.4,0,3.604,0c-0.135-5.918,1.697-10.246,4.559-13.2
	c3.725-0.006,5.146,1.842,8.641,1.2c-0.273-2.294-0.49-3.964,1.201-5.28c0.756-0.589,0.949-0.257,1.199-1.44
	c-1.584-0.967-4.398-2.96-5.041-4.8c3.396-0.339,4.805-2.299,7.92-2.64c0.646,1.195,0.664,2.384,2.641,2.4
	c0.467-0.381,1.154-0.358,1.682-0.72c1.885-1.293,0.838-2.027,4.08-1.92c0.623,0.907,7.734,5.861,8.881,6.24
	c0.268-0.855,0.461-1.035,1.438-1.2c1.043,1.651,2.486,2.682,5.279,2.64c0.307,1.309,2.777,5.107,3.842,5.52
	c1.523,0.887,5.773-0.228,6.721,1.2c-0.08,0-0.16,0-0.24,0c-1.119,1.076-2.75,1.522-4.08,2.4c-4.461,2.947-8.752,6.66-10.561,12.24
	c-1.768,5.451,2.225,10.431,0.721,17.041c-1.471,6.463-3.047,11.795-6,16.56c-1.141,1.84-3.104,6.502-5.279,6.96
	c-0.93-0.756-2.385-0.985-3.121-1.92c-2.188-2.782-2.979-6.655-5.039-9.6c-0.816-1.171-2.104-1.674-3.119-2.64
	c-0.654,0.302-0.828,0.924-1.439,1.2c-1.525,0.688-3.922,0.875-5.761,0.96c-0.837-1.628-2.771-2.541-3.358-4.32
	c-0.938-2.831,1.108-4.767,0.24-7.44c-1.453-4.467-5.578-4.839-4.803-11.76c-0.49-0.171-1.12-0.239-1.92-0.24
	c-0.668,0.481-2.113,0.176-2.641-0.24C310.205,206.122,310.766,202.067,310.797,200.22z'
          />{' '}
        </a>
        <a
          xlinkTitle={`Bir Ali Ben Khelifa ${groupedData['Bir Ali Ben Khelifa']}`}
        >
          <path
            onClick={() => setSelectedVill('Bir Ali Ben Khelifa')}
            style={{
              fill: getColorBasedOnCount(outputData['Bir Ali Ben Khelifa']),
            }}
            d='M163.679,254.46c0.372,9.204,6.528,17.047,10.32,23.28c1.245,2.047,0.641,5.977,1.44,8.398
	c1.5,4.547,4.177,8.241,5.28,13.2c-4.417,0.418-5.88,2.134-8.16,4.562c0.603,2.008,2.169,3.908,2.88,6
	c1.99,5.852,2.193,12.416,5.28,17.039c0.684,1.024,2.124,2.639,3.12,3.36c1.093,0.794,2.954,1.189,3.6,2.4
	c-2.001,1.188-3.891,3.204-5.28,5.039c-2.24,2.159-4.48,4.319-6.72,6.479c-4.91-0.252-7.093-3.627-8.88-6.961
	c-1.463-2.729-0.704-5.766-5.04-5.76c-1.361-0.85-3.598-0.516-5.28-0.961c-3.394-0.898-6.709-1.414-11.28-1.439
	c-2.139,4.18-5.965,6.457-9.12,9.603c-0.962,0.959-2.625,3.733-3.6,4.317c-0.72,0.16-1.44,0.32-2.16,0.48
	c-2.155,0.797-4.489,1.463-6.72,2.398c-8.321,3.496-16.149,7.797-19.92,15.842c-2.798-1.24-5.854-2.092-9.36-2.883
	c-2.414-0.543-7.09,0.662-8.88,0.24c-1.113-0.263-1.398-0.957-1.44-2.398c1.602-2.6-1.305-7.221-2.16-8.4
	c-3.662-5.063-10.1-6.563-16.32-9.119c-2.701-1.109-5.44-2.967-8.16-3.84c-0.73,0.719-1.724,1.146-3.12,1.199
	c-0.157-0.449-0.229-0.938-0.24-1.682c0.406-0.521,0.357-1.297,0.72-1.92c1.59-2.728,3.576-3.902,3.6-8.398
	c-2.378-0.543-7.229-2.568-7.68-5.039c1.064-1.359,1.428-2.602,3.36-3.121c0,0.24,0,0.479,0,0.721c0.32,0,0.64,0,0.96,0
	c0.216-0.883,0.531-1.309,0.48-1.92c0-0.16,0-0.32,0-0.479c0.416-0.561,1.135,0.162,1.68-1.201c0.858-1.418,0.216-4.436,0.72-6
	c1.305-4.049,5.494-10.879,4.08-16.799c-0.284-1.188-0.904-2.234-1.2-3.361c2.557-1.219,6.553-2.15,8.88-3.84
	c0.955-0.689,1.357-2.021,2.16-2.879c-0.091-2.063-1.027-4.113-0.48-6.479c0.904-3.916,2-7.668,2.88-11.521
	c0.936-4.097-1.66-7.029-0.48-9.84c2.76-6.576,8.05-10.763,12-16.08c3.15-4.241,5.771-9.194,7.92-14.4
	c2.593-6.283,4.157-13.184,6.72-19.2c0.894-2.098,1.863-6.008,3.84-6.96c0,0.08,0,0.16,0,0.24c3.549,3.581,4.24,9.724,6,15.12
	c4.386,13.441,5.993,32.333,16.56,39.36c2.865,1.905,8.205,3.125,12.72,1.92c1.411-0.376,3.478-1.849,5.28-1.44
	C150.15,252.626,157.532,254.001,163.679,254.46z'
          />{' '}
        </a>
        <a xlinkTitle={`Sakiet Ezzit ${groupedData['Sakiet Ezzit']}`}>
          <path
            onClick={() => setSelectedVill('Sakiet Ezzit')}
            style={{ fill: getColorBasedOnCount(outputData['Sakiet Ezzit']) }}
            d='M309.357,272.221c-2.16,0.079-4.32,0.159-6.48,0.239c-0.34-2.51-2.223-4.44-3.359-6.239
	c-1.705-2.698-3.016-5.3-4.32-8.4c1.037-0.566,2.021-1.129,2.16-2.64c-1.215-1.625-1.238-4.381-1.92-6.48
	c-1.06-3.263-2.472-6.775-4.32-9.36c-0.762-1.066-6.262-4.742-5.76-5.28c1.037-1.302,3.34-0.767,3.601-2.88
	c-1.142-1.641-1.356-3.831-2.4-5.52c-2.149-3.479-5.767-6.81-6.479-11.52c-0.32,0-0.641,0-0.961,0c0-0.08,0-0.16,0-0.24
	c1.984-1.563,4.166-5.653,4.801-8.64c0.08,0,0.16,0,0.24,0c0.893,0.911,2.082,1.38,3.119,2.16c1.83,1.375,2.504,4.088,4.801,5.04
	c2.146,0.891,6.189,0.495,8.398,0.96c0.729,3.232,3.283,3.176,5.521,4.8c1.914,1.39,2.588,4.164,4.32,5.76
	c-0.385,1.077-1.342,1.733-1.682,2.88c-0.994,3.351,0.549,6.894,0.238,10.32c-0.238,1.44-0.479,2.88-0.719,4.32
	c0.238,0.16,0.479,0.32,0.719,0.48c0.16,0.703-1.549,2.914-0.959,5.04c0.754,2.716,3.691,3.277,3.84,5.52
	c0.717,1.125-0.111,4.345-0.24,5.76C311.084,263.074,309.547,267.05,309.357,272.221z'
          />{' '}
        </a>
        <a xlinkTitle={`Sfax Sud ${groupedData['Sfax Sud']}`}>
          <path
            onClick={() => setSelectedVill('Sfax Sud')}
            style={{ fill: getColorBasedOnCount(outputData['Sfax Sud']) }}
            d='M301.438,289.5c-0.219,1.336-0.879,2.887,0,4.08c0.396,1.318,1.488,2.309,2.643,2.881
	c-0.246,0.191-0.064,0.084-0.48,0.24c-1.004,0.578-3.434-0.254-4.08,0.719c1.771,1.66,3.553,6.748,1.439,9.359
	c-0.84,0.742-2.16,0.789-3.119,1.438c-0.561,0.642-1.119,1.281-1.68,1.921c0,0.399,0,0.801,0,1.2
	c-2.465-0.037-5.176,0.021-6.961,0.479c-1.178,1.82-2.455,5.353-4.56,6.238c-2.702,1.141-6.825,0.369-9.12,1.92
	c-1.033,0.699-1.093,2.492-2.16,3.121c-0.726-0.693-1.66-0.709-2.64-1.201c-1.51-0.758-3.17-2.233-4.08-3.6
	c-2.307-3.459-3.063-8.969-6.479-11.279c0,0.652-0.01,1.104-0.24,1.439c-0.419,0.305-0.986,0.359-1.2,0.24
	c-0.681-0.725-0.856-1.805-1.44-2.643c-1.492-2.142-3.291-3.234-3.359-6.959c3.709-0.504,6.313-3.722,10.8-2.16
	c0-0.722,0-1.439,0-2.16c-0.356-0.582-0.086-1.113,0-1.68c0.396-2.593,1.052-4.656,2.64-6c1.26-1.064,3.121-1.513,3.36-3.601
	c-0.267-0.332-0.358-0.688-0.479-1.201c-2.355-1.526-3.307-0.849-6.48-1.438c0-0.16,0-0.319,0-0.479
	c1.581-1.674,1.467-5.666,1.439-8.879c0.24,0,0.48,0,0.721,0c0.591-1.82,1.645-2.332,1.68-5.041
	c-0.699-0.718-1.113-1.799-1.92-2.397c-0.639-0.477-1.365-0.663-1.92-1.201c-0.48,0-0.96,0-1.439,0c0-0.64,0-1.28,0-1.92
	c-1.201,0-2.4,0-3.601,0c-0.497-1.024-1.045-2.243-1.92-2.88c-1.535-1.117-3.792-1.223-4.08-3.6c4.876-3.113,9.333-7.349,13.92-10.8
	c2.361-1.777,5.504-4.396,6.479-7.44c0.272-0.221,0.25-0.143,0-0.48c-1.02-1.376-3.59-4.644-2.399-7.92
	c0.276-0.759,1.253-1.718,1.681-2.4c1.518-2.428,2.857-4.897,4.319-7.2c0.836-1.318,1.024-2.583,2.64-3.12
	c0.819,6.692,7.701,9.629,8.4,16.56c-1.691,0.591-3.521,0.846-3.6,3.12c0.303,0.326,0.328,0.424,0.479,0.96
	c5.783,2.754,7.29,7.142,9.601,13.44c0.731,1.996,1.789,4.244,1.92,6.72c-1.064,0.577-1.859,0.987-1.92,2.64
	c1.006,1.301,1.291,3.141,2.16,4.56c1.832,2.993,4.586,5.819,5.52,9.601c-3.58,0.354-5.213,1.867-7.439,3.359
	c-4.959,3.317-7.106,3.073-6.721,11.278c0.16,0,0.32,0,0.48,0c0.824-0.683,1.742-0.387,2.64,0c0.336,0.574,2.512,4.394,2.88,4.562
	c0.801,0,1.6,0,2.4,0C297.285,290.373,298.697,289.475,301.438,289.5z'
          />{' '}
        </a>
        <a xlinkTitle={`Sakiet Eddaier ${groupedData['Sakiet Eddaier']}`}>
          <path
            onClick={() => setSelectedVill('Sakiet Eddaier')}
            style={{ fill: getColorBasedOnCount(outputData['Sakiet Eddaier']) }}
            d='M341.758,244.14c0.045,3.487-1.465,3.652-3.119,5.28c-0.646,0.635-1.039,1.521-1.682,2.16
	c-0.02,3.889,1.641,4.705,1.441,8.16c-11.781,1.438-15.928,12.478-25.439,16.56c-0.607-2.272-2.508-2.709-2.641-5.521
	c0.736-1.196,0.33-3.315,0.721-4.801c1.275-4.855,1.686-10.042,1.68-15.839c-0.799-0.02-1.086-0.117-1.68-0.24
	c-1.209-1.799-2.176-2.49-2.16-5.76c0.611-0.594,1.297-1.356,1.439-2.4c-0.748-0.475-0.922-0.678-0.959-1.92
	c2.463-4.046-2.494-11.919,1.92-14.88c0.42-0.275,1.623-0.38,2.16-0.48c0.035-1.076,0.199-1.56,0.959-1.92
	c1.42-1.098,3.596,0.554,4.801,1.2c-0.158,5.534,1.373,4.842,3.357,7.68c0.488,0.697,0.145,1.034,0.963,1.44
	c1.199-0.924,2.771-0.497,4.559-0.959c0.896-0.232,1.717-0.848,2.4-1.2c0.363-0.188,0.252-0.349,0.479,0
	c3.074,1.642,3.48,5.686,5.279,8.64c1.438,2.36,2.721,3.036,4.561,4.8C341.117,244.14,341.438,244.14,341.758,244.14z'
          />{' '}
        </a>
        <a xlinkTitle={`Agareb ${groupedData['Agareb']}`}>
          <path
            onClick={() => setSelectedVill('Agareb')}
            style={{ fill: getColorBasedOnCount(outputData['Agareb']) }}
            d='M179.279,232.62c2.907,0.355,6.963,2.739,9.6,3.84c6.061,2.531,12.495,4.393,18.72,6.48
	c6.186,2.074,12.708,3.109,19.2,4.8c1.44,0.16,2.88,0.32,4.32,0.479c3.616,0.915,7.655,1.465,10.8,2.64
	c3.633,1.357,6.737,2.476,9.601,4.56c0.56,0.64,1.119,1.28,1.68,1.92c1.416,1.026,3.449,1.322,4.08,3.12
	c0.322,0.404,0.393,0.754,0.479,1.44c1.2,0,2.399,0,3.601,0c0,0.8,0,1.6,0,2.4c0.986-0.1,1.399-0.46,1.92-0.24
	c1.762,0.438,3.31,1.875,3.359,4.08c-0.443,0.553-0.527,1.34-0.96,1.92c-0.36,0.482-1.208,0.57-1.44,1.199
	c-0.08,2.08-0.159,4.16-0.239,6.24c-0.337,1.314-1.201,2.182-1.44,3.602c1.801,1.119,3.744,0.732,6.24,1.199
	c0.342,0.557,0.957,1.252,0.721,1.68c-2.455,3.41-7.06,4.771-5.761,11.521c-5.112-0.088-6.538,2.365-10.8,2.641
	c-0.105,6.74,4.045,6.911,4.8,11.76c1.019-0.02,3.062-0.561,3.12-0.479c3.425,3.377,3.707,9.617,7.68,12.479
	c1.67,1.205,4.41,0.812,4.561,3.601c-1.045,0.558-2.906,1.235-3.36,2.399c-0.231,0.341-0.233,0.785-0.239,1.439
	c-0.641,0-1.281,0-1.92,0c-0.709-0.9-5.102-5.285-6.721-4.08c-0.662-0.518-0.958-1.672-1.68-2.158
	c-2.137-1.451-5.449-1.278-7.92-2.399c-2.897-1.315-5.894-3.668-8.4-5.521c-1.984-1.469-3.447-3.875-5.76-5.041
	c-1.722-0.867-2.882,0.433-3.84,0.721c-0.477,0.144-0.801-0.396-1.44-0.479c-0.128,0.363-0.162,0.406-0.24,0.96
	c1.326,1.063,1.013,4.331,0.96,6.721c-0.08,0-0.16,0-0.24,0c-2.073-2.172-3.607-3.517-3.6-7.92c-4.38-0.308-7.849,0.408-11.28,1.199
	c-3.183,0.733-4.648-1.185-7.68-1.199c-0.433,0.616-0.582,0.395-0.72,1.438c0.553,0.323,0.438,0.293,0.48,0.479
	c-0.532,0.78-1.307,0.803-1.92,1.439c-0.732,0.764-0.816,2.802-1.2,3.84c-0.621-0.43-0.803-0.957-1.44-0.721
	c-0.908,0.994-1.334,3.08-1.92,4.32c-5.869-0.008-8.665,1.932-10.08,6.24c-0.529,1.608-0.162,5.606-1.44,6.479
	c-1.66-2.471-7.154-3.449-9.36-6.479c-3.183-4.371-3.373-10.215-5.28-16.08c-0.815-2.513-2.633-4.955-3.12-7.439
	c2.613-1.735,3.597-3.291,8.16-3.36c-0.585-5.856-4.093-10.216-5.76-15.358c-1.121-3.459-0.039-5.713-1.68-8.399
	c-3.515-5.756-9.451-13.183-9.6-21.84c1.681-1.135,2.866-2.942,4.08-4.56C172.237,245.772,178.277,238.896,179.279,232.62z'
          />{' '}
        </a>
        <a xlinkTitle={`Kerkennah ${groupedData['Kerkennah']}`}>
          <path
            onClick={() => setSelectedVill('Kerkennah')}
            style={{ fill: getColorBasedOnCount(outputData['Kerkennah']) }}
            d='M464.156,279.898c-5.813-0.754-4.863,2.328-8.16,4.08c-1.619,0.86-3.92,0.229-5.279,1.201
	c-1.525,1.09-2.27,3.506-4.08,4.319c-1.52,0.239-3.041,0.479-4.561,0.719c-3.234,0.869-6.912,1.55-9.602,3.121
	c-2.494,1.459-5.965,3.481-7.68,5.761c-1.057,1.401-2.141,3.112-3.6,4.079c-1.867,1.234-4.707,1.119-6.48,2.4
	c-1.039,1.198-2.08,2.397-3.119,3.6c-1.723,0.962-4.236,0.445-5.762,1.439c-3.162,2.063-4.928,5.932-7.92,8.159
	c-1.09,0.813-4.83,2.066-5.279,3.119c-0.457,0.748-0.203,2.16-0.959,2.642c-1.016-0.843-2.34-1.095-3.121-2.16
	c-0.471-0.642-0.803-2.224-1.438-2.64c-0.48,0-0.961,0-1.439,0c-1.682-0.561-3.359-1.12-5.041-1.682
	c-2.537-1.305-4.512-3.236-6.721-4.799c-1.248-0.885-3.725-1.658-4.318-3.121c2.133-2.301,1.867-6.117,5.039-7.439
	c2.59-1.077,14.457-0.42,17.521,0.24c4.74,1.021,7.93-1.385,11.521-1.438c1.557,1.315,3.646,2.993,6.238,3.118
	c0.326-0.303,0.424-0.327,0.961-0.479c1.791-4.032,3.783-6.297,3.84-12.239c3.306-0.183,6.072-3.359,6-6.959
	c-0.487-0.615-0.521-1.457-0.961-2.16c-0.635-1.021-1.715-1.611-2.158-2.881c1.391-0.574,2.354-1.459,3.359-2.398
	c0.057-2.176-0.35-3.387-0.961-4.801c3.438,0.084,4.229-0.967,6.24-2.158c-0.16-0.48-0.32-0.961-0.48-1.439c0.16,0,0.32,0,0.48,0
	c0.775,0.775,1.241,0.65,2.16,0.238c0.475,2.152,1.844,5.811,3.84,6.479c1.26,0.824,1.991-0.64,2.881-0.479
	c0.908,0.164,2.51,1.762,4.08,0.721c0.938-0.275,1.391-0.75,1.68-1.682c0.697-1.109,0.812-4.512,0.959-5.521
	c-1.311-1.312-3.979-3.189-4.317-5.278c0.388-0.459,0.452-0.837,0.959-1.2c0.774-0.615,2.414-0.517,3.841-0.48
	c-0.02-0.607-0.029-0.892-0.24-1.2c-0.779-2.111-3.572-1.822-4.32-3.84c0.08,0,0.16,0,0.24,0c0.25-0.74,0.231-0.701,0.961-0.96
	c0.588-0.398,1.26-0.148,1.92,0c0.52,1.214,1.084,2.907,2.4,3.36c0.34,0.23,0.785,0.234,1.438,0.24c0-0.16,0-0.32,0-0.48
	c2.302-3.504-1.063-12.65,3.602-13.68c2.286,2.092,3.875,1.352,5.279,4.8c5.171-2.184,13.421,1.893,13.921,6
	c-0.159,0-0.319,0-0.479,0c-1.34,0.766-4.809-1.494-5.041-1.2c-4.443,5.431,1.574,8.912,3.603,12c0.56,0.852,0.591,3.286,1.438,3.84
	c1.348-2.103,6.193-2.105,8.639-0.959c-0.077,0.16-0.156,0.319-0.236,0.479C471.012,272.4,464.813,273.973,464.156,279.898z'
          />{' '}
        </a>
        <a xlinkTitle={`Sfax Ville ${groupedData['Sfax Ville']}`}>
          <path
            onClick={() => setSelectedVill('Sfax Ville')}
            style={{ fill: getColorBasedOnCount(outputData['Sfax Ville']) }}
            d='M307.678,273.182c0.801,0,1.602,0,2.4,0c0.48,1.698,1.723,1.965,1.92,4.077
	c-0.803,0.886-1.709,3.013-1.92,4.32c1.613,0.662,3.986,1.387,4.08,3.603c1.678,2.707-4.684,6.979-7.201,6.959
	c-0.74-0.816-1.389-1.824-2.16-2.642c-0.141-0.147-0.871-0.342-0.959-0.479c0-0.561,0-1.121,0-1.682
	c-0.262-0.943-0.684-2.285-1.199-3.119c-1.217-1.959-3.219-3.477-4.561-5.279c-0.707-0.948-1.086-2.073-1.92-2.881
	c0-0.08,0-0.16,0-0.238c0.08,0,0.16,0,0.24,0c1.4-1.731,4.641-1.84,7.199-2.399C304.898,273.134,306.738,273.768,307.678,273.182z'
          />{' '}
        </a>
        <a xlinkTitle={`Sfax Est ${groupedData['Sfax Est']}`}>
          <path
            onClick={() => setSelectedVill('Sfax Est')}
            style={{ fill: getColorBasedOnCount(outputData['Sfax Est']) }}
            d='M302.877,288.541c-1.482-0.018-3.441-0.225-4.559,0.238c-1.598,0.662-1.785,2.029-4.08,2.16
	c-0.705-1.463-1.578-3.689-2.881-4.562c-2.267-1.512-2.889,1.009-2.879-3.358c0.385-0.519,0.34-1.334,0.719-1.92
	c1.029-1.589,2.92-2.341,4.561-3.359c0.525-0.326,1.16-1.336,1.68-0.961c0.793,0.826,1.227,1.951,1.92,2.881
	C299.354,282.335,302.529,284.229,302.877,288.541z'
          />{' '}
        </a>
        <a xlinkTitle={`Mahras ${groupedData['Mahras']}`}>
          <path
            onClick={() => setSelectedVill('Mahras')}
            style={{ fill: getColorBasedOnCount(outputData['Mahras']) }}
            d='M223.198,310.859c1.44,0,2.88,0,4.32,0c0.218,0.262-0.019,0.021,0.24,0.24
	c-0.048,4.274,2.41,7.803,5.76,8.641c0-2.32,0-4.643,0-6.961c-0.193-0.281-0.186-0.402-0.24-0.961
	c1.125-0.389,1.198-0.904,2.88-0.959c0.763,0.717,1.785,1.045,2.64,1.682c1.313,0.977,2.302,2.619,3.601,3.6
	c2.684,2.025,5.918,4.394,9.119,5.76c2.071,0.888,4.557,0.656,6.48,1.683c1.124,0.599,2.496,3.562,2.64,3.601
	c0.589-0.629,0.347-0.968,1.44-1.201c0.801,0.76,1.748,1.248,2.64,1.92c0.56,0.643,1.12,1.281,1.681,1.922
	c0.76,0.504,2.365,0.189,2.879,0.959c-2.115,1.497-7.989,10.543-6.959,15.119c0.371,1.652,1.092,2.988,1.199,4.801
	c-0.693,0.521-0.912,1.219-1.439,1.92c-1.242,1.652-3.762,3.451-5.52,4.561c-2.658,1.679-6.642,2.414-8.881,4.562
	c-2.715-1.297-7.658-2.599-11.76-1.199c-1.99,0.679-3.193,2.783-5.04,3.601c-1.717,0.761-4.518,0.104-6.24,0.959
	c-2.955,1.471-5.264,4.877-7.2,7.44c-1.116,1.478-2.96,2.593-4.08,4.08c-2.531,3.358-5.179,6.752-7.68,10.08
	c-1.771,2.354-3.964,4.438-5.52,6.959c-0.846,1.371-0.373,3.088-2.4,3.358c-1.063,0.658-3.899,0.705-5.04,0.961
	c-0.239,0.396-0.816,0.791-0.96,1.199c-0.364,1.037,0.26,2.33-0.24,3.119c-0.729,1.152-2.606,1.476-3.84,2.16
	c-1.679,0.936-3.097,3.477-6.48,2.641c-4.089-1.01-6.43-4.162-11.28-3.119c0-1.278,0-2.561,0-3.84c-0.903-1.481-0.283-4.157-0.72-6
	c-0.961-4.051-1.642-8.571-1.68-13.438c2.205-0.967,9.282-4.676,9.6-6.961c1.836-3.11-0.036-8.772,4.32-9.601
	c1.455-0.918,10.275-0.268,12.72-0.239c0-0.16,0-0.32,0-0.479c-2.987-2.935-2.508-11.943-1.44-16.8c0.619-2.817,0.508-8.555,0-11.28
	c-0.252-1.354,0.457-3.257,0.72-4.32c1.586-6.416,1.922-9.23,10.08-9.117c0.718-1.158,1.325-2.215,1.68-3.842
	c0.622,0.121,1.063,0.223,1.92,0.24c0.35-1.369,0.883-3.791,1.92-4.562c0.347-0.258,0.904-0.185,1.2-0.479
	c0.23-0.229,0.828-1.959,0.24-2.16c0.4,0,0.8,0,1.2,0C213.557,312.895,221.152,312.307,223.198,310.859z'
          />{' '}
        </a>
        <a xlinkTitle={`Ghraiba ${groupedData['Ghraiba']}`}>
          <path
            onClick={() => setSelectedVill('Ghraiba')}
            style={{ fill: getColorBasedOnCount(outputData['Ghraiba']) }}
            d='M166.559,379.74c-0.031,6.021,0.768,11.346,1.92,16.318c0.08,1.44,0.16,2.881,0.24,4.319
	c0.427,1.953,1.054,6.104,0.48,7.92c-1.942,6.138-8.123,6.845-13.44,9.601c-1.914,0.994-3.591,2.906-5.52,3.602
	c-1.16-1.521-2.974-2.979-4.8-3.84c-0.208-7.646-3.716-12.832-7.68-16.801c-0.959-0.959-2.13-2.775-3.36-3.359
	c-1.28-0.158-2.56-0.318-3.84-0.479c-2.747-0.875-5.088-2.795-6.48-5.041c-1.83-2.949-2.06-6.512-3.12-10.318
	c-1.64-5.895-2.583-11.617-5.76-15.84c-1.9-2.525-9.591-1.887-10.8-4.08c1.13-1.104,1.687-2.797,2.64-4.08
	c1.524-2.053,4.34-4.338,6.48-5.762c3.733-2.479,8.392-4.24,12.72-6c2.096-0.852,4.526-0.803,6.48-1.92
	c0.96-1.199,1.92-2.398,2.88-3.601c2.433-2.481,5.832-4.416,7.92-7.198c0.388-0.521,1.547-2.877,1.92-3.121c1.44,0,2.88,0,4.32,0
	c3.28,0.721,6.561,1.438,9.84,2.16c1.268,0.303,2.891-0.134,3.84,0.479c0.635,0.825,0.699,1.873,1.2,2.879
	c2.239,4.502,4.479,9.638,11.28,9.603c3.04-2.961,6.081-5.92,9.12-8.881c1.181-1.181,2.273-3.111,4.32-3.359
	c0.706,1.016,2.049,1.315,2.4,2.641c1.059,1.721,0.11,6.752,0,8.641c-0.231,3.941-1.65,9.461-0.72,13.681
	c0.392,1.778,1.036,3.459,1.44,5.04c-3.839,0-7.68,0-11.52,0c0,0.08,0,0.16,0,0.24c-3.947,1.097-3.998,6.539-4.8,10.799
	C173.185,375.837,170.623,379.089,166.559,379.74z'
          />{' '}
        </a>
        <a xlinkTitle={`Esskhira ${groupedData['Esskhira']}`}>
          <path
            onClick={() => setSelectedVill('Esskhira')}
            style={{ fill: getColorBasedOnCount(outputData['Esskhira']) }}
            d='M20.88,453.66c-0.308-4.301-5.775-12.678-8.16-15.359c-1.696-1.906-5.048-2.977-5.76-5.76
	c2.434-0.316,4.692-1.994,6.72-2.881c3.136-1.369,6.023-2.236,9.12-3.603c2.689-1.184,5.765-3.575,8.4-4.559
	c1.04,0.16,2.08,0.317,3.12,0.479c3.369,0.322,6.846-0.021,9.12-0.958c8.988-3.707,13.377-13.213,18.48-20.881
	c2.162-3.25,4.924-4.621,5.04-10.08c0.35-0.205,0.688-0.437,0.96-0.722c0.744-0.021,0.965-0.142,1.2,0
	c1.586,0.563,2.001,1.856,4.08,2.16c0.991-1.259,2.782-1.914,4.08-2.879c3.194-2.375,8.964-8.836,10.8-12.479
	c6.355-0.015,8.914-3.968,11.52-7.681c1.217-1.733,2.27-4.955,4.8-5.278c3.399,2.397,8.171,0.639,10.8,4.317
	c5.001,7.004,4.032,19.789,9.12,26.642c1.441,1.938,4.311,3.383,6.96,4.079c0.88,0.08,1.76,0.16,2.64,0.24
	c2.063,1.039,6.494,6.692,7.68,8.879c0.716,1.322,3.587,8.326,2.88,10.562c-0.39,1.229-1.808,3.735-2.4,5.28
	c-0.856,2.232-1.353,5.215-4.08,5.521c-0.813,0.52-2.15,0.371-3.12,0.721c-2.416,0.869-4.962,1.965-6.96,3.359
	c-9.99,6.969-16.191,18.692-21.12,30.721c-1.994,4.865-2.576,10.207-3.84,15.84c-0.777,3.461,1.598,7.437-3.12,7.439
	c-0.674-0.64-1.641-0.879-2.4-1.439c-0.28-0.209-0.471-1.043-0.72-1.201c-0.8-0.08-1.6-0.16-2.4-0.237
	c-1.011-0.574-1.742-1.972-2.88-2.4c-1.604-0.605-2.657-0.348-3.84-1.199c-2.181-1.569-3.425-4.555-5.04-6.721
	c-0.7-0.938-3.432-3.074-4.56-2.639c-1.489,0.383-1.759,1.227-2.64,2.157c-0.007,4.015,1.392,6.318,0.96,10.32
	c-10.099,6.768-22.831-5.051-22.8-15.84c-3.888-1.252-8.616-4.689-14.16-2.4c-2.197,0.906-4.22,2.697-5.76,4.32
	c-1.021,1.074-0.845,2.229-2.88,2.398c-0.954-0.613-2.229-0.181-3.6-0.479c-4.117-0.898-10.172-0.686-14.64-0.721
	c-1.103-1.808-2.484-3.687-3.6-5.521c-1.927-3.168-2.864-6.721-4.8-9.842c-1.115-1.795-2.961-2.715-4.08-4.559c2.24,0,4.48,0,6.72,0
	c0.717,0.473,1.76,0.395,2.64,0.719C12.99,452.832,16.003,453.839,20.88,453.66z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 411.1406 322.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kerkennah
        </text>
        <text
          transform='matrix(1 0 0 1 66.1401 435.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Skhira
        </text>
        <text
          transform='matrix(1 0 0 1 203.1401 348.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Mahrès
        </text>
        <text
          transform='matrix(1 0 0 1 126.1401 369.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ghraiba
        </text>
        <text
          transform='matrix(1 0 0 1 75.1401 300.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bir Ali Ben Khelifa
        </text>
        <text
          transform='matrix(1 0 0 1 198.1401 283.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Agareb
        </text>
        <text
          transform='matrix(1 0 0 1 168.1401 202.1377)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Menzel Chaker
        </text>
        <text
          transform='matrix(1 0 0 1 268.1401 142.1377)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Hencha
        </text>
        <text
          transform='matrix(1 0 0 1 319.1406 166.1377)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Jebiniana
        </text>
        <text
          transform='matrix(1 0 0 1 316.1401 204.1377)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Amra
        </text>
        <text
          transform='matrix(1 0 0 1 294.1401 328.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sfax Sud
        </text>
        <text
          transform='matrix(1 0 0 1 348.1406 259.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sakiet Eddaïer
        </text>
        <text
          transform='matrix(1 0 0 1 336.1406 276.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sakiet Ezzit
        </text>
        <text
          transform='matrix(1 0 0 1 323.1406 291.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sfax Ville
        </text>
        <text
          transform='matrix(1 0 0 1 310.1401 306.1387)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sfax Ouest
        </text>
        <line
          fill='none'
          stroke='#000000'
          x1='345.359'
          y1='258.54'
          x2='324.359'
          y2='247.54'
        />
        <line
          fill='none'
          stroke='#000000'
          x1='333.359'
          y1='274.259'
          x2='302.359'
          y2='251.821'
        />
        <line
          fill='none'
          stroke='#000000'
          x1='321.359'
          y1='290.541'
          x2='307.359'
          y2='282.541'
        />
        <line
          fill='none'
          stroke='#000000'
          x1='307.797'
          y1='305.5'
          x2='295.798'
          y2='285.5'
        />
        <line
          fill='none'
          stroke='#000000'
          x1='291.359'
          y1='327.541'
          x2='278.359'
          y2='292.541'
        />
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Sfax;
