import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';
const Soussa = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);

  const { quantiles } = useGetColor(sortedCounts)

  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='380.459px'
        height='552.165px'
        viewBox='0 0 380.459 552.165'
        enable-background='new 0 0 380.459 552.165'
      >
        <a xlinkTitle={`Bou Ficha ${groupedData['Bou Ficha']}`}>
          <path
            onClick={() => setSelectedVill('Bou Ficha')}
            style={{ fill: getColorBasedOnCount(outputData['Bou Ficha']) }}
            d='M129.598,178.662c-6.43,0.041-9.781,5.872-15.119,4.56c-8.507-2.091-11.611-9.623-22.32-7.2
	c-1.527,3.741-0.676,13.951-5.04,14.4c-0.48-0.4-0.96-0.8-1.44-1.2c-0.709-1.089-0.363-2.333-0.72-3.84
	c-0.604-2.546-0.949-4.193-2.4-5.76c-0.421,0.151-0.233,0.046-0.48,0.24c-1.079,1.122-1.264,3-3.12,3.36
	c-2.129-2.997-8.205-3.914-8.88-8.16c-0.213-1.341,1.394-2.15,1.92-2.88c1.607-2.23,3.133-4.609,3.84-7.68
	c-1.444-0.361-3.035-4.045-1.68-6c0.649-1.869,2.835-2.552,3.84-4.08c0.486-0.739,0.31-1.821,0.48-2.16
	c0.44-0.875,4.728-3.31,5.76-4.08c3.963-2.956,10.888-11.417,12.24-16.8c-0.75-0.259-2.02-0.255-3.12-0.24
	c-1.584-0.993-9.341-0.973-12.24-0.96c0-0.16,0-0.32,0-0.48c-1.418-2,0.261-7.099,2.16-7.44c0.512,0.333,1.455,0.356,1.92,0.72
	c1.441,1.941,0.707,2.055,4.32,1.92c0.08-0.24,0.16-0.48,0.24-0.72c-1.112-1.211-1.758-4.712-1.44-6.48c0.8,0,1.6,0,2.4,0
	c1.13,0.797,2.709,0.288,3.84,0c0.535-0.8,0.885-0.767,0.96-2.16c-0.56-1.28-1.12-2.56-1.68-3.84
	c-0.852-1.382-2.193-2.753-2.64-4.56c-0.547-2.214,1.835-3.369,1.44-4.56c-0.803-2.658-3.864-3.152-4.56-5.76
	c-0.528-1.979,0.552-5.381,0.96-6.96c0.873-3.381,0.891-6.417,1.92-9.6c0.655-2.025,1.984-4.301,2.4-6.24
	c1.314-0.172,2.332-0.472,4.08-0.479c0.249,0.587,0.491,1.291,0.96,1.68c2.409,1.766,8.992,0.609,12,1.92
	c2.428,1.058,3.512,3.815,5.52,5.28c2.293,1.672,9.811,5.989,12.721,6.48c1.046-1.671,2.691-3,3.84-4.56
	c2.438-3.315,4.787-6.833,6.96-10.32c1.174-1.884,2.43-5.95,4.8-6.479c1.593,1.48,3.797,2.54,5.52,3.84
	c2.336,1.763,4.056,4.509,6.48,6.24c0.935,0.667,2.078,0.9,2.88,1.68c-1.009,0.899-1.163,2.941-1.92,4.08
	c-0.645,0.969-1.777,1.886-2.399,2.88c-2.639,4.214-4.183,9.004-6.24,13.68c-2.162,4.912-4.703,10.038-6.48,15.36
	c-2.27,6.794-3.254,13.664-5.52,20.64C132.543,148.304,130.07,162.698,129.598,178.662z'
          />{' '}
        </a>
        <a xlinkTitle={`Enfidha ${groupedData['Enfidha']}`}>
          <path
            onClick={() => setSelectedVill('Enfidha')}
            style={{ fill: getColorBasedOnCount(outputData['Enfidha']) }}
            d='M77.279,152.742c0,0.32,0,0.64,0,0.96c-1.777,1.171-2.867,3.059-4.32,4.56
	c-0.096,4.543,0.404,4.496,1.68,7.2c-0.999,1.226-1.278,2.99-2.16,4.32c-0.881,1.329-2.897,2.705-3.36,4.32
	c-0.315,1.1,0.554,2.708,0.96,3.36c0.977,1.568,7.638,6.601,9.84,6.72c0.75-0.831,1.874-2.635,2.88-3.12c0,0.16,0,0.32,0,0.48
	c1.396,1.62,0.644,4.662,1.44,6.96c0.477,1.379,1.491,1.959,2.4,2.88c0.757,0.021,1.251,0.114,1.44,0
	c3.664-1.902,4.345-9.219,4.56-14.4c0.16,0,0.32,0,0.48,0c1.744-1.551,4.683-0.25,6.24,0.24c0.72,0,1.44,0,2.16,0
	c1.017,0.359,1.604,1.581,2.4,2.16c2.961,2.153,7.716,4.823,12.24,5.04c5.191,0.249,7.228-4.748,13.439-4.8
	c0.109,3.749-0.053,9.387,0.721,12.96c0.666,3.074-0.115,6.379,0.48,9.12c0.746,3.437-0.039,6.56,0.72,9.84
	c0.603,2.603,0.687,5.451,1.44,8.16c0.59,2.125,1.46,4.604,1.68,6.96c-3.126-0.251-6.368-1.137-9.601-1.68
	c-1.491-0.25-10.317,1.385-11.76,1.92c-0.396,0.147-1.04,0.833-1.44,0.96c-6.719,0.08-13.44,0.16-20.16,0.24
	c-2.16,1.04-4.32,2.08-6.48,3.12c-0.783,0.568-4.761,6.09-5.04,7.2c-1.093,4.35,2.979,12.02-2.4,12.72
	c-1.063,0.701-3.217,0.06-4.56,0c-0.974-3.225-4.383-3.361-8.64-3.36c-1.2,0-2.4,0-3.6,0c-6.986,1.449-13.786-1.616-18.479-3.6
	c-1.772-0.749-5.035-1.137-5.76-2.88c1.704-1.845,3.484-8.607,3.6-12c-2.682-2.123-3.834-8.124-6-11.04
	c-0.911-1.226-2.897-2.456-4.56-2.88c-0.8,0-1.6,0-2.4,0c-0.532-0.226-1.28-1.171-1.92-1.44c-4.729-1.984-10.977-1.458-17.04-1.44
	c0-0.16,0-0.32,0-0.48c1.059-1.561,0.254-3.997,0.72-6.24c0.601-2.892,0.073-7.685-0.48-10.56c0.08-0.96,0.16-1.92,0.24-2.88
	c-0.356-1.347-1.2-2.456-1.44-4.08c0.385-0.134,0.577-0.192,1.2-0.24c1.313,1.278,3.486,2.937,5.76,3.12
	c2.072-1.633,4.642-2.542,6.72-4.08c2.119-1.568,4.005-4.7,7.2-5.04c7.311,5.252,14.029-3.5,16.08-8.4
	c0.642-1.533,0.684-2.823,1.2-4.56c0.405-1.362,1.588-3.968,1.44-5.52c-0.126-1.323-1.737-2.47-2.4-3.36
	c-2.273-3.052-5.336-4.841-5.28-9.6c1.721-0.433,3.886-0.603,5.76-1.2c3.849-1.227,9.35-4.009,14.64-2.64
	C65.933,148.34,69.498,152.632,77.279,152.742z'
          />{' '}
        </a>
        <a xlinkTitle={`Hergla ${groupedData['Hergla']}`}>
          <path
            onClick={() => setSelectedVill('Hergla')}
            style={{ fill: getColorBasedOnCount(outputData['Hergla']) }}
            d='M150.719,265.062c-3.797-0.558-6.926-3.13-10.801-4.08c-3.475-0.852-5.826,0.992-8.16,1.2
	c-1.596-1.539-5.966-4.886-8.399-5.28c-1.735-0.281-2.968,1.04-4.8,0.72c-0.129-0.364-0.162-0.406-0.24-0.96
	c0.971-1.091,1.685-1.986,1.68-4.08c-0.521-0.512-0.338-0.997-0.479-1.92c-1.366-0.369-3.7-1.728-6-0.96
	c-0.85,0.284-1.28,1.216-1.92,1.68c-1.732,1.255-3.498,2.173-5.28,3.36c-1.203,0.801-4.196,3.505-5.76,2.88
	c-5.061-1.355-7.09-7.021-11.04-9.6c-2.107-1.375-4.785-1.191-7.92-1.2c-0.16-2.72-0.32-5.44-0.48-8.16
	c0.238-0.868,1.025-2.369,1.68-2.88c0.387-0.302,1.179-0.324,1.44-0.72c0.377-0.573,0.12-1.37,0.48-1.92
	c0.898-1.373,5.637-3.583,7.44-4.08c6.719-0.08,13.44-0.16,20.16-0.24c6.029-2.005,11.931-3.686,18.96-1.68
	c1.373,0.392,2.868,0.213,3.84,0.96c0.685,0.881,0.712,1.995,1.2,3.12c1.07,2.466,2.762,4.885,4.32,6.96
	c1.609,2.144,4.639,4.111,6.959,5.52c1.412,0.857,3.605,1.111,4.561,2.4c1.743,2.352,1.246,6.094,2.16,9.36
	c1.435,5.129,4.795,9.437,5.279,15.12c-0.239,0.08-0.479,0.16-0.719,0.24c-0.734,0.575-7.563,2.266-8.16,1.92
	C149.459,271.005,150.661,267.437,150.719,265.062z'
          />{' '}
        </a>
        <a xlinkTitle={`Kondar ${groupedData['Kondar']}`}>
          <path
            onClick={() => setSelectedVill('Kondar')}
            style={{ fill: getColorBasedOnCount(outputData['Kondar']) }}
            d='M65.999,260.742c7.301-0.027,8.379,4.287,10.32,9.6c0.463,1.268,1.222,2.586,1.44,4.08
	c-2.109,2.009-5.28,5.204-5.76,8.64c3.357,2.144,6.139,6.644,7.2,11.04c1.124,4.66-2.584,11.087-5.76,11.279c0-0.08,0-0.159,0-0.239
	c-0.08,0-0.16,0-0.24,0c-0.16-0.96-0.32-1.921-0.48-2.88c-4.182-2.029-8.11-1.521-12.24,0c-1.597,0.588-3.284,0.344-4.56,1.199
	c-2.675,1.795-4.819,4.791-7.44,6.721c-4.93,3.63-10.032,6.906-14.88,10.561c-4.423,3.334-7.1,8.894-11.52,12.239
	c-3.409,2.581-7.501,6.56-7.44,11.761c0.015,1.273-1.257,3.547-0.72,5.76c1.502,6.18,4.521,11.054,7.44,15.84
	c2.45,4.018,4.051,8.267,6.24,12.48c1.749,3.367,4.433,7.299,4.8,11.76c-0.56,0.479-1.12,0.959-1.68,1.439c-0.08,0-0.16,0-0.24,0
	c0-0.08,0-0.16,0-0.24c-6.612-2.223-8.75-13.207-12.24-18.959c-1.475-2.433-3.269-5.768-5.52-7.441
	c-1.122-0.834-2.571-1.848-3.84-2.399c-0.862-0.375-1.847-0.206-2.64-0.719c-2.527-1.638-7.369-11.504-6-17.28
	c0.32-1.119,0.64-2.24,0.96-3.359c1.555-0.566,2.441-1.885,3.36-3.119c1.756-2.361,3.401-4.605,4.32-7.92
	c1.318-4.758,0.305-8.406,2.64-12.24c2.057-3.377,6.713-5.417,7.44-9.84c4.903-0.688,6.24-5.174,8.64-8.4
	c0.88-0.8,1.76-1.6,2.64-2.4c0.4-0.08,0.8-0.16,1.2-0.24c1.175-1.018,1.953-4.557,2.64-6.238c1.151-2.814,1.888-6.186,3.12-9.12
	c0.548-1.308,1.749-2.794,2.16-4.08c0-3.2,0-6.4,0-9.6c0-5.645,0.436-11.415-0.72-16.32c-0.721-3.06-1.519-6.199-2.16-9.12
	c0.16,0,0.32,0,0.48,0c3.81,2.931,12.396,4.729,18,6c1.809,0.41,5.045,0.08,6.48-0.24c2.907-0.647,8.023-0.114,9.6,0.96
	c0.4,0.48,0.8,0.96,1.2,1.44c0,0.16,0,0.32,0,0.48C68.245,252.168,65.887,255.865,65.999,260.742z'
          />{' '}
        </a>
        <a xlinkTitle={`Sidi Bou Ali ${groupedData['Sidi Bou Ali']}`}>
          <path
            onClick={() => setSelectedVill('Sidi Bou Ali')}
            style={{ fill: getColorBasedOnCount(outputData['Sidi Bou Ali']) }}
            d='M104.399,305.622c-0.461-8.386-10.081-16.771-14.16-22.32c-1.179-1.604-3.551-6.747-4.8-7.679
	c-1.204-0.899-2.97-1.765-4.56-2.16c-0.963-0.24-1.717,0.192-2.16,0c-0.858-1.155-0.886-2.851-1.44-4.32
	c-1.762-4.677-3.863-9.363-10.32-9.36c-0.054-6.969,5.873-7.528,12.24-7.68c0.961-1.439,2.099-2.087,2.4-4.32
	c1.28-0.08,2.56-0.16,3.84-0.24c0.11,0.039,0.553,0.661,0.72,0.72c0.8,0.08,1.6,0.16,2.4,0.24c2.448,1.15,5.365,5.938,7.68,7.68
	c1.281,0.964,3.161,1.527,4.32,2.64c3.203-0.681,11.176-5.962,12.96-8.16c2.071-0.188,3.686-0.032,5.28,0.72
	c-0.695,1.583,0.677,1.193,0.24,2.4c-0.544,1.55-1.417,1.696-1.44,4.08c0.853,0.541,1.171,0.906,2.64,0.96
	c4.316-3.477,8.648,3.706,12,4.56c0,0,3.352-1.555,3.84-1.68c3.744-0.959,8.799,2.121,11.04,3.12c0.731,0.326,2.206,0.376,2.64,0.96
	c0,0.72,0,1.44,0,2.16c-0.732,1.181,0.129,4.451-0.24,5.28c-0.283,0.193-0.402,0.186-0.959,0.24c-0.881-0.698-2.434-1.104-3.84-1.2
	c-0.08,0.24-0.16,0.48-0.24,0.72c-0.817,1.354,0.365,3.315,0.72,4.319c0.665,1.887,0.938,6.254,0.239,8.4
	c-1.256,3.86-5.678,6.383-8.639,8.641c-2.064,1.572-2.818,4.301-4.32,6.479c-1.847,2.68-5.275,2.334-8.641,3.602
	c-1.316,0.496-1.913,1.845-3.359,2.16c-1.242-1.351-4.158-3.014-7.44-1.92c-1.087,0.36-1.928,1.588-3.12,1.92
	c-1.685,0.469-2.08-0.684-2.88-0.961C106.159,305.622,105.279,305.622,104.399,305.622z'
          />{' '}
        </a>
        <a xlinkTitle={`Akouda ${groupedData['Akouda']}`}>
          <path
            onClick={() => setSelectedVill('Akouda')}
            style={{ fill: getColorBasedOnCount(outputData['Akouda']) }}
            d='M170.399,329.861c0.046-4.604,2.447-7.104,3.359-10.8c1.564-6.339-2.838-13.314-5.039-16.318
	c-3.133-4.275-7.554-7.793-11.281-11.521c-1.551-1.553-2.822-3.975-4.559-5.28c-0.5-0.375-1.186,0.001-1.921-0.239l-4.319-1.681
	c0-1.278,0-2.562,0-3.84c-0.463-0.737-1.429-5.664-1.44-6.96c1.14,0.176,1.775,0.727,2.64,0.96c3.16,0.851,9.876-2.198,12.48-2.64
	c1.185,2.431,2.818,4.765,4.08,7.2c0.484,0.938,0.52,2.516,1.199,3.36c0.432,0.534,1.552,0.567,1.92,1.2c0,0.318,0,0.64,0,0.96
	c0.596,0.934,2.776,1.852,3.601,2.64c1.22,1.169,2.534,3.628,3.601,5.04c2.455,3.254,6.2,5.578,7.439,10.08
	c-0.986,0.259-1.079,0.633-1.68,1.2c0.082,1.934,0.995,2.061,1.199,3.84c-1.821,1.085-4.71,2.206-5.039,4.8
	c3.27,3.132,5.404,6.853,6.479,12.24c-0.24,0.16-0.479,0.319-0.72,0.479c-1.273,0.822-3.125,0.631-4.32,1.439
	c-1.393,0.941-1.541,3.086-3.6,3.359C173.612,329.984,171.865,329.893,170.399,329.861z'
          />{' '}
        </a>
        <a xlinkTitle={`Kalaa El Kebira ${groupedData['Kalaa El Kebira']}`}>
          <path
            onClick={() => setSelectedVill('Kalaa El Kebira')}
            style={{
              fill: getColorBasedOnCount(outputData['Kalaa El Kebira']),
            }}
            d='M79.199,313.062c-0.64-0.08-1.28-0.16-1.92-0.239c-0.243-0.93-0.934-1.262-1.2-1.921c0-0.96,0-1.92,0-2.88
	c-0.156-0.492-0.709-1.386-0.96-1.92c4.886-3.15,6.876-11.445,3.12-17.52c-1.043-1.688-4.692-4.916-5.04-6.48
	c1.669-2.154,4.123-7.559,7.44-7.68c1.238,0.895,2.887,1.033,4.08,1.92c0.949,0.706,1.037,2.079,1.68,3.12
	c1.417,2.293,3.183,4.535,4.8,6.721c2.945,3.979,6.689,7.443,9.36,11.76c1.536,2.482,2.013,5.448,3.12,8.4
	c0.649,0.213,2.012-0.059,2.88,0.24c1.246,0.428,1.914,1.535,4.08,0.719c1.104-0.416,2.118-1.559,3.36-1.92
	c0.611-0.178,1.445,0.197,1.68,0.24c0.721-0.08,1.441-0.16,2.16-0.24c1.71,0.59,0.952,1.904,4.08,1.92
	c1.093-2.963,7.678-2.783,10.08-4.559c1.662-1.229,2.373-4.072,3.6-5.762c2.334-3.21,6.824-4.736,9.121-7.92
	c0.64-1.359,1.279-2.721,1.92-4.08c1.402,0.516,2.8,1.282,4.08,1.682c0.479,0,0.959,0,1.439,0c0.818,0.401,1.595,2.131,2.16,2.879
	c1.205,1.598,3.129,3.129,4.561,4.561c4.73,4.73,9.674,8.906,12.479,15.6c0.816,1.945,2.289,5.494,1.44,8.881
	c-0.569,2.271-1.815,4.461-2.64,6.479c-0.566,1.388-0.321,3.886-1.2,5.04c-2.171,1.916-7.702,3.588-10.56,4.8
	c-1.635,0.694-3.699,0.39-3.84,2.64c-0.935,1.562,0.336,3.593-0.24,5.281c-0.514,1.502-2.34,2.524-3.36,3.6
	c-3.499,0.009-5.49-2.336-9.12-1.439c-3.416,0.844-4.928,4.688-10.56,3.36c-4.046-0.953-7.901-3.601-12-3.841
	c-1.074,0.982-2.787,1.136-4.08,1.92c-3.436,2.084-6.396,4.117-8.16,7.921c1.321,0.065,1.972,0.512,2.88,0.959
	c0,0.079,0,0.159,0,0.239c-1.019,0.104-2.628,0.539-3.6,0c-0.16-0.239-0.32-0.479-0.48-0.719c-1.141-0.474-3.988,0.047-4.8,0.238
	c-3.59,0.85-6.119,1.34-7.92,3.842c-0.501,0.695-0.634,1.551-1.2,2.158c0-0.08,0-0.158,0-0.238c-2.958-1.674-5.623-4.09-8.88-5.521
	c-4.619-2.029-9.558-3.512-13.68-6c-2.042-1.232-6.396-2.832-7.2-5.039c3.353-0.242,4.924-2.542,6.96-4.08
	c3.636-2.748,7.099-6.445,9.84-10.08c1.408-1.867,3.378-3.102,4.08-5.76C84.17,320.055,79.462,317.536,79.199,313.062z'
          />{' '}
        </a>
        <a xlinkTitle={`Hammam Sousse ${groupedData['Hammam Sousse']}`}>
          <path
            onClick={() => setSelectedVill('Hammam Sousse')}
            style={{ fill: getColorBasedOnCount(outputData['Hammam Sousse']) }}
            d='M182.399,302.743c4.742,1.77,7.938,11.676,10.081,16.559c1.021,2.332,2.938,4.011,4.08,6.24
	c-5.69,1.504-3.797,4.252-6.241,8.16c-0.818,1.309-6.917,5.707-8.641,6c-1.357-2.203-1.985-5.175-3.359-7.439
	c-0.498-0.822-1.761-1.992-1.44-2.881c1.427-4.16,6.953-2.389,7.2-6c-0.29-0.473-0.046-1.188-0.24-1.92
	c-0.384-1.448-1.141-3.301-1.92-4.561c-1.157-1.87-3.754-3.496-4.08-6c1.622-1.035,3.416-2.294,4.801-3.602
	c-0.004-2.129-0.867-2.246-1.201-3.84C181.79,303.256,182.126,303.027,182.399,302.743z'
          />{' '}
        </a>
        <a xlinkTitle={`Sousse Ville ${groupedData['Sousse Ville']}`}>
          <path
            onClick={() => setSelectedVill('Sousse Ville')}
            style={{ fill: getColorBasedOnCount(outputData['Sousse Ville']) }}
            d='M196.56,326.502c0.498,0.088,0.461,0.058,0.719,0.238c1.718,1.713,2.427,4.469,3.84,6.479
	c2.14,3.045,5.518,5.171,7.681,8.159c1.747,2.416,2.794,5.306,4.56,7.681c1.201,1.614,3.18,3.097,4.802,4.319
	c2.378,1.798,4.339,4.408,6.479,6.48c0.938,0.908,2.736,0.93,3.359,2.16c-0.479,0.359-0.881,0.932-1.2,1.439
	c-1.096-0.563-1.71-1.527-2.64-2.16c-1.729-1.174-3.426-1.906-5.041-3.119c-1.963-1.475-3.32-3.771-4.799-5.76
	c-0.971-1.307-2.633-2.299-3.602-3.602c-1.357-1.828-2.217-3.938-3.601-5.76c-0.449-0.594-1.438-0.664-1.92-1.2
	c-0.757-0.844-0.79-2.459-1.439-3.358c-0.614-0.854-2.12-2.211-3.12-2.642c-0.56-0.08-1.119-0.159-1.68-0.239
	c-1.359-1.439-2.72-2.881-4.08-4.32c-0.902-0.652-1.927-0.567-2.398-1.68c0.08,0,0.16,0,0.238,0
	C193.367,327.771,195.167,327.596,196.56,326.502z'
          />{' '}
        </a>
        <a xlinkTitle={`Kalaa Essghira ${groupedData['Kalaa Essghira']}`}>
          <path
            onClick={() => setSelectedVill('Kalaa Essghira')}
            style={{ fill: getColorBasedOnCount(outputData['Kalaa Essghira']) }}
            d='M181.155,347.206c-3.723,6.549-6.398,5.217-12.196,9.295c-1.077,0.76-2.187,1.938-3.12,2.881
	c-0.08,0.24-0.16,0.479-0.24,0.721c-0.64,0.08-1.279,0.16-1.92,0.24c-2.289,0.832-4.799,1.309-7.439,2.158
	c-3.129,1.008-6.953,1.846-10.32,2.641c-1.119,0.08-2.24,0.16-3.359,0.24c-2.975,1.068-6.23,2.676-8.881,4.32
	c-5.246,3.256-10.067,6.986-15.359,10.319c-2.135,1.345-3.765,3.015-7.2,3.119c-2.805-2.668-6.577-3.986-11.04-5.039
	c0.971-7.87-6.949-9.168-7.2-16.562c2.591-2.742,2.347-4.332,6.96-5.52c4.232-1.09,7.135,1.661,10.8,0.24
	c0.144-0.414,0.214-0.758,0.24-1.44c-0.648-0.386-1.827-0.905-2.4-1.44c0.193-0.244,0.085-0.064,0.24-0.479
	c1.719-1.182,2.962-3.158,4.8-4.32c1.828-1.154,4.467-1.646,6-3.119c4.12,0.94,10.965,5.444,16.8,3.84
	c2.285-0.629,3.438-2.778,5.76-3.359c3.229-0.809,6.578,2.898,9.359,1.199c1.789-0.623,2.611-2.619,3.841-3.84
	c0.002-2.352,0.066-4.174,0.239-6.479c4.825-0.577,10.131-4.642,14.4-6c1.28,0,2.561,0,3.84,0c0.613-0.146,1.297-0.401,2.16-0.479
	c0.715,1.613,1.951,2.998,2.641,4.561c0.613,1.393,0.601,3.251,1.439,4.561c0.4,0.625,1.906,3.744,2.156,4.869'
          />{' '}
        </a>
        <a
          xlinkTitle={`Sousse Sidi Abdelhamid ${groupedData['Sousse Sidi Abdelhamid']}`}
        >
          <path
            onClick={() => setSelectedVill('Sousse Sidi Abdelhamid')}
            style={{
              fill: getColorBasedOnCount(outputData['Sousse Sidi Abdelhamid']),
            }}
            d='M182.155,340.706c0.729-0.677,1.812-0.652,2.644-1.244c3.43-2.439,5.825-4.035,7.681-8.16
	c0.42,0.152,0.232,0.047,0.479,0.24c1.864,0.625,3.496,2.776,4.8,4.08c0.146,0.146,0.342,0.868,0.48,0.959
	c0.72,0.08,1.438,0.16,2.16,0.24c1.686,0.857,3.363,3.194,3.84,5.28c-2.275,1.335-3.488,3.767-5.521,5.278
	c-0.97,0.724-2.257,1.093-3.119,1.92c-3.554-1.588-8.534-2.771-12.633-3.861c-0.273-0.073,0.328-2.411,0.063-2.482'
          />{' '}
        </a>
        <a xlinkTitle={`Sousse Jaouhara ${groupedData['Sousse Jaouhara']}`}>
          <path
            onClick={() => setSelectedVill('Sousse Jaouhara')}
            style={{
              fill: getColorBasedOnCount(outputData['Sousse Jaouhara']),
            }}
            d='M204.718,342.821c2.545,0.747,3.829,4.766,5.28,6.721c4.214,5.679,9.599,10.481,15.601,14.399
	c0,0.16,0,0.32,0,0.479c-2.828,0.856-4.72,3.395-7.199,4.32c-0.459-1.912-1.812-3.705-2.4-5.281c-0.16-1.119-0.32-2.239-0.48-3.358
	c-0.422-1.263-1.33-2.25-1.92-3.36c-2.271-0.033-6.924,0.793-9.358,0c-3.043-0.988-7.863-3.035-8.16-6.72
	C198.131,349.499,203.591,344.446,204.718,342.821z'
          />{' '}
        </a>
        <a xlinkTitle={`Sidi El Heni ${groupedData['Sidi El Heni']}`}>
          <path
            onClick={() => setSelectedVill('Sidi El Heni')}
            style={{ fill: getColorBasedOnCount(outputData['Sidi El Heni']) }}
            d='M15.359,345.221c4.724,0.01,9.896-0.192,14.16,0.721c3.887,0.832,8.891-0.258,12.48-0.24
	c2.61,0.014,4.033,1.993,7.44,1.2c3.8-0.885,6.476-2.386,11.28-2.399c4.524,6.641,18.412,9.248,25.68,13.681
	c1.053,0.642,5.06,3.146,5.52,4.08c0,0.399,0,0.799,0,1.198c0.429,1.334,1.158,2.933,1.92,4.08c2.647,3.987,5.426,4.773,5.28,11.281
	c4.379,1.002,8.878,2.486,11.52,5.279c5.803,0.125,9.262-3.805,12.72-6.24c5.261-3.705,13.401-9.908,20.159-11.52
	c-0.052,2.752,0.096,5.605,0.961,7.68c0.534,1.281,1.603,2.295,1.92,3.84c-1.074,1.213-1.295,3.141-2.16,4.561
	c-1.798,2.951-3.948,5.67-6,8.398c-4.81,6.398-12.558,11.352-16.32,18.721c-1.039,0.08-2.08,0.16-3.119,0.24
	c-0.938,1.369-2.332,2.272-2.881,4.08c-1.921,6.33,3.254,12.887,3.36,19.68c-0.08,0-0.16,0-0.239,0c0,0.08,0,0.16,0,0.24
	c-1.92,0.318-3.841,0.641-5.761,0.959c-1.84,0.08-3.68,0.16-5.52,0.24c-3.589,0.811-6.904,1.096-9.84,2.4
	c-2.377,1.057-4.682,3.023-6.72,4.561c-0.8,0.879-1.6,1.76-2.4,2.639c0,0-2.657,0.135-3.12,0.24c-0.1,0.662-0.066,1.34-0.24,1.68
	c-1.496,1.842-5.852,2.434-8.88,2.641c-0.934,1.436-2.623,2.258-3.6,3.602c-0.413,0.566-0.489,1.195-0.96,1.68
	c-0.026,3.355,1.476,2.836,1.92,5.041c-0.724,0.688-0.65,1.674-0.96,2.16c-1.454,1.367-3.685,2.162-6,2.639
	c-3.477-6.227-7.208-12.398-11.04-18.479c-1.463-2.322-4.646-6.383-5.52-8.881c0-1.68,0-3.359,0-5.039
	c-0.32-0.24-0.64-0.48-0.96-0.721c-1.159-1.592-2.555-3.459-3.36-5.279c-1.714-3.875-2.292-8.045-3.84-12.24
	c-0.54-1.463-1.556-3.047-1.44-4.561c1.328-0.979,4.773-5.87,3.12-8.16c-0.509-1.102-1.712-1.297-2.4-2.158
	c-1.81-2.27-3.457-4.707-5.52-6.721c-0.88-0.641-1.76-1.281-2.64-1.92c-0.24-1.041-0.48-2.08-0.72-3.121c-1.84-3.999-3.68-8-5.52-12
	c-2.423-3.986-4.247-8.413-6.72-12.479C17.324,357.445,14.397,353,15.359,345.221z'
          />{' '}
        </a>
        <a xlinkTitle={`Msaken ${groupedData['Msaken']}`}>
          <path
            onClick={() => setSelectedVill('Msaken')}
            style={{ fill: getColorBasedOnCount(outputData['Msaken']) }}
            d='M149.518,487.542c0.499-0.09,0.463-0.059,0.721-0.24c0.395-0.596,0.27-2.137,0.24-3.121
	c-1.385-1.412,1.476-5.096,0.72-7.92c-0.468-1.744-1.578-3.715-1.681-5.76c-0.239,0-0.479,0-0.719,0
	c0.184-4.816,0.706-7.76,0.719-12.959c-0.424-0.23-0.402-0.303-0.959-0.48c-0.258-0.18-0.228-0.145-0.721-0.24
	c0.004-16.131-14.143-20.566-27.6-23.279c0-1.039,0-2.08,0-3.119c-0.678-0.99-0.528-2.629-0.96-3.842
	c-1.344-3.77-6.071-15.858,2.16-15.84c0.282-0.191,0.403-0.186,0.96-0.238c2.424-4.15,6.137-6.938,8.88-10.561
	c1.929-2.548,4.59-4.461,6.479-6.961c3.793-5.016,7.228-9.471,9.84-15.84c-2.377-1.866-3.121-6.613-3.119-10.799
	c0.08,0,0.16,0,0.24,0c0-0.08,0-0.16,0-0.24c3.178-0.023,6.317-0.82,9.119-1.68c2.028-0.623,4.307-0.967,6.48-1.682
	c1.473-0.482,2.986-1.357,4.8-1.439c0,0,1.667,0.756,2.88,0.48c2.138-0.486,4.756,0.075,7.2-0.48
	c6.773-1.541,18.944,1.48,19.44,6.721c-1.816,1.863-2.644,4.861-1.922,7.92c5.68,2.479,11.36,4.96,17.04,7.439
	c0.08,1.6,0.16,3.199,0.24,4.801c0.543,2.068,2.479,5.779,1.438,8.879c-0.531,1.591-1.653,2.001-1.68,4.561
	c1.032,0.629,1.465,1.127,1.439,2.881c-0.399,1.439-0.799,2.879-1.199,4.318c-1.473,2.324-4.293,1.694-4.801,5.281
	c1.42,0.945,2.209,2.613,3.361,3.84c1.385,1.477,4.033,3.9,4.559,6c0.443,1.771-3.986,5.445-5.039,6.238
	c-3.107,2.342-5.803,5.576-8.881,7.92c-1.539,1.174-3.357,2.613-3.6,5.041c0.586,0.803,3.276,6.244,3.119,6.48
	c-1.58,2.369-5.548,4.145-10.319,3.119c-1.394-0.299-3.744-0.871-5.521-0.48c-2.661,0.588-4.85,1.281-6.72,2.641
	c-3.834,2.785-5.271,7.473-6.48,12.961c-0.657,2.98-0.152,7.658,0.48,10.08c0.248,0.951,1.02,2.646,0.48,3.6
	c-1.914,5.639-9.584,6.256-15.36,8.16c-1.862,0.613-3.706,1.809-5.521,2.398c0-0.158,0-0.318,0-0.479
	C149.262,490.864,149.487,488.723,149.518,487.542z'
          />{' '}
        </a>

        <text
          transform='matrix(1 0 0 1 81.0059 163.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bouficha
        </text>
        <text
          transform='matrix(1 0 0 1 49.0059 211.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Enfidha
        </text>
        <text
          transform='matrix(1 0 0 1 37.0059 296.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kondar
        </text>
        <text
          transform='matrix(1 0 0 1 101.0059 245.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hergla
        </text>
        <text
          transform='matrix(1 0 0 1 86.0059 274.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi Bou Ali
        </text>
        <text
          transform='matrix(1 0 0 1 92.0059 332.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kalaâ Kebira
        </text>
        <text
          transform='matrix(1 0 0 1 55.0059 400.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi el Héni
        </text>
        <text
          transform='matrix(1 0 0 1 149.0063 416.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          M&apos;saken
        </text>
        <a xlinkTitle={`Sousse Riadh ${groupedData['Sousse Riadh']}`}>
          <path
            onClick={() => setSelectedVill('Sousse Riadh')}
            style={{ fill: getColorBasedOnCount(outputData['Sousse Riadh']) }}
            d='M182.44,346.352c2.33,0.156,11.307,2.143,12.479,3.359c0.16,0.561,0.32,1.121,0.479,1.681
	c0.763,1.487,2.217,2.976,3.602,3.84c2.243,1.399,4.643,1.728,7.68,2.399c2.34,0.519,4.549-0.393,6.24-0.239
	c1.863,2.956,2.537,7.202,4.08,10.562c1.204,2.621,3.507,3.073,3.6,7.198c-0.361,0.472-0.363,1.153-0.72,1.681
	c-1.524,2.254-6.017,4.787-9.12,5.279c-1.484,0.235-3.474-1.449-4.56-1.92c-4.406-1.906-8.597-3.511-12.722-5.521
	c-0.468-3.789,2.028-4.604,2.16-8.398c-0.611-0.496-0.494-1.018-0.959-1.68c-0.721-1.025-3.146-2.371-4.32-2.881
	c-6.514-2.822-15.912-1.41-24-1.199c0-0.08,0-0.16,0-0.24c0.193-0.245,0.085-0.064,0.24-0.48c1.633-1.053,2.889-2.781,4.56-3.84
	C175.084,353.469,181.137,351.327,182.44,346.352z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 242.2295 374.5854)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sousse Riadh
        </text>
        <text
          transform='matrix(1 0 0 1 241.3369 353.4595)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sousse Jawhara
        </text>
        <text
          transform='matrix(1 0 0 1 232.501 333.9448)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sousse Médina
        </text>
        <text
          transform='matrix(1 0 0 1 211.3643 315.0132)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sousse Sidi Abdelhamid
        </text>
        <text
          transform='matrix(1 0 0 1 199.8799 295.6343)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hammam Sousse
        </text>
        <line
          fill='none'
          stroke='#000000'
          x1='238.349'
          y1='373.726'
          x2='209.222'
          y2='372.754'
        />
        <line
          fill='none'
          stroke='#000000'
          x1='238.028'
          y1='352.542'
          x2='206.31'
          y2='351.396'
        />
        <line
          fill='none'
          stroke='#000000'
          x1='230.26'
          y1='333.124'
          x2='208.798'
          y2='341.381'
        />
        <line
          fill='none'
          stroke='#000000'
          x1='206.31'
          y1='314.502'
          x2='193.688'
          y2='342.659'
        />
        <line
          fill='none'
          stroke='#000000'
          x1='196.601'
          y1='296.056'
          x2='187.863'
          y2='318.385'
        />
        <text
          transform='matrix(1 0 0 1 182.5864 275.5474)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Akouda
        </text>
        <line
          fill='none'
          stroke='#000000'
          x1='179.308'
          y1='275.968'
          x2='170.571'
          y2='298.299'
        />
        <text
          transform='matrix(1 0 0 1 106.0059 367.3521)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kalaâ Sghira
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Soussa;
