import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Kef = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='553.59px'
        height='533.668px'
        viewBox='0 0 553.59 533.668'
        enable-background='new 0 0 553.59 533.668'
      >
        <a xlinkTitle={`Nebeur ${groupedData['Nebeur']}`}>
          <path
            onClick={() => setSelectedVill('Nebeur')}
            style={{ fill: getColorBasedOnCount(outputData['Nebeur']) }}
            d='M257.998,177.301c-0.842,0.097-1.18,0.302-1.92,0.48c-0.313,0.581-0.432,0.68-0.48,1.68
	c0.303,0.326,0.328,0.424,0.48,0.96c1.22,0.276,3.641,0.491,4.8,0.96c-2.082,5.171,4.659,6.108,5.76,9.84
	c-1.03,0.19-0.82,0.68-1.439,0.96c-0.721,0-1.44,0-2.16,0c-0.08,0-0.16,0-0.24,0c-0.345,0.244-0.454,1.314-0.72,1.68
	c-0.684,0.938-2.17,2.213-3.12,2.88c-1.375,0.964-3.854,1.212-5.04,2.4c-0.08,0.32-0.16,0.64-0.24,0.96
	c-0.6,1.061-1.439,2.42-2.4,3.12c-3.418,2.495-8.326,2.994-12.479,4.8c-2.882,1.253-4.853,3.406-7.44,5.04
	c-2.75,1.736-5.518,3.493-8.399,5.28c-0.597,0.37-2.091,1.874-2.64,1.44c-3.113-1.139-5.242-6.552-6.961-9.36
	c-3.025-4.947-5.75-8.252-11.52-10.56c-0.068,13.719-10.592,15.667-19.2,20.88c-1.878,1.138-3.957,2.292-5.76,3.6
	c-0.677,0.49-0.737,1.1-1.681,1.44c-0.16-0.24-0.319-0.48-0.479-0.72c-0.755-0.294-2.241,0.611-2.88,0.72
	c-2.87,0.489-5.836-0.274-7.92-0.96c-3.327-1.095-6.773-2.748-9.84-4.08c-1.111-0.482-2.194-0.525-3.12-1.2
	c-1.231-0.898-1.2-3.331-2.4-4.32c-1.974-1.465-6.527-0.412-8.16-1.44c-4.932-1.893-2.321-12.528-8.64-12.48
	c-0.434,0.365-1.68,0.96-1.68,0.96c-1.977-1.277-5.314-3.757-6.24-6c-0.693-1.678-0.314-3.285-1.2-4.56
	c-2.373-3.415-5.799-7.091-9.12-9.6c-1.567-1.184-2.741-3.131-4.32-4.32c-2.035-1.533-7.875-4.948-7.92-7.92
	c0.981-1.495,0.852-4,1.44-5.76c0.658-1.971,1.849-4.163,2.64-6c0.395-0.917,1.072-2.351,0.48-3.36c-0.454-1.335-4.143-5.243-5.28-6
	c-1.737-1.156-2.622,0.206-2.64-2.88c0.952-1.302,1.128-3.375,2.4-4.32c2-0.72,4-1.44,6-2.16c2.057-1.259,3.424-3.4,5.28-4.8
	c1.09-0.822,2.417-1.431,3.36-2.4c3.198,0.091,9.265,5.086,11.04,6.96c2.01-0.084,4.113-0.739,5.04-1.92
	c1.141-1.453,0.792-3.223,3.36-3.36c1.427-0.961,3.823-0.824,5.52-1.44c1.687-0.612,3.54-1.163,5.28-1.92
	c1.845-0.803,2.978-2.271,5.52-2.4c2.802,3.064,4.019,1.169,7.68,0.96c0.924,1.321,3.4,2.836,5.28,3.12
	c0.411-0.403,0.887-0.641,1.2-0.48c1.718,1.153,2.149,3.136,3.36,4.8c0.64,0.56,1.279,1.12,1.92,1.68
	c4.439,0.057,5.5-2.239,8.64-3.12c0.94-0.265,2.546,0.109,3.12,0.24c2.369,0.542,9.627,1.372,12.479,0.72
	c5.338-1.22,10.385-1.951,15.36-3.6c1.901-0.63,3.614-1.911,5.521-2.4c3.562-0.914,6.842,1.177,10.08,0.24
	c2.664-0.771,4.106-3.466,6.239-4.8c1.714-1.072,3.608-0.865,5.761-1.68c3.512-1.331,5.879-5.281,7.439-8.64
	c2.527,0.063,3.758-0.504,5.52-1.2c0.16,0.72,0.32,1.44,0.48,2.16c-0.832,0.603-1.788,1.567-1.92,2.88
	c-1.902,3.005,1.914,7.6,2.88,8.88c2.272,3.011,6.437,4.359,8.64,7.44c1.2,2.16,2.4,4.32,3.601,6.48
	c0.579,1.562,0.003,3.657,0.72,5.04c0.766,1.478,1.999,1.167,2.16,3.6c-0.783,0.897-0.988,1.838-0.96,3.6
	c2.431,0.634,4.105,4.467,4.32,7.2c-1.762,0.698-0.552,2.835-1.44,4.08c-1.915-1.366-1.789,1.114-2.16,2.16
	c-3.085,0.257-7.896,0.881-7.92,4.32c0.298,0.376,0.154,0.123,0,0.479c-0.771,0.474-1.27,0.029-1.68,0.96
	C256.59,174.094,257.836,175.829,257.998,177.301z'
          />{' '}
        </a>
        <a
          xlinkTitle={`Sakiet Sidi Youssef ${groupedData['Sakiet Sidi Youssef']}`}
        >
          <path
            onClick={() => setSelectedVill('Sakiet Sidi Youssef')}
            style={{
              fill: getColorBasedOnCount(outputData['Sakiet Sidi Youssef']),
            }}
            d='M112.799,236.101c-0.129,2.949-0.702,4.905-1.92,6.72c-0.26-0.218-0.02,0.02-0.24-0.24
	c-0.543-0.52-3.469-2.788-4.08-2.16c-0.24,0.08-0.48,0.16-0.72,0.24c0.457,1.097,0.416,2.027,0.24,3.36
	c-0.922,0.634-3.551,3.648-4.8,2.88c-0.898-0.295-2.028-1.27-2.64-1.92c-1.1-0.017-2.088,0.077-2.88,0.24c0,0.96,0,1.92,0,2.88
	c0.663,0.896,2.059,4.466,1.68,5.28c-0.392,1.806-1.614,1.218-1.68,3.6c1.006,1.045,1.785,2.158,2.88,3.12
	c-0.151,0.421-0.046,0.233-0.24,0.48c-0.42,0.606-0.441,0.633-1.44,0.72c-1.865-1.582-5.53-2.157-6.24,0.72
	c1.162,0.243,1.813,0.543,1.92,1.92c-1.156,0.611-4.043,0.423-4.56,0.96c-0.55,0.571-1.26,3.783-1.68,4.8
	c-1.727,4.182-3.174,3.508-2.88,9.84c-3.305,1.471-4.167,1.877-6.48,4.32c-0.08,0.72-0.16,1.438-0.24,2.16
	c1.144,0.023,1.725,0.271,2.64,0.479c0,0.32,0,0.641,0,0.96c-0.436,0.604-0.619,1.756-0.72,2.642c1.28,0,2.56,0,3.84,0
	c0.187,0.537,0.245,1.304,0.24,2.16c-0.511,0.301-1.024,0.781-1.68,0.959c-0.446,0.12-2.16-0.24-2.16-0.24c0,0.16,0,0.319,0,0.479
	c-1.473,2.325,2.402,4.731,1.92,7.681c-6.138,2.424-9.584,6.146-11.04,13.2c-3.709,0.919-5.485,4.822-8.88,6.239
	c-1.026,0.43-3.729,0.07-4.32,0.96c0.274,0.406,0.256,1.176,0.24,1.92c-1.745,1.022-3.865,1.252-6,2.16
	c-2.3,0.979-4.598,2.558-7.2,3.358c-1.377,0.427-2.383,0.222-3.6,0.722c-3.398,1.396-6.555,3.266-11.28,3.358
	c-0.897-1.569-1.306-3.811-3.84-3.84c-0.937,2.232-2.188,5.993-4.8,6.479c-0.693-0.675-1.423-0.665-2.4-0.479
	c-0.143,0.879-0.239,1.563-0.96,1.92c-0.938,0.83-2.882,1.186-4.08,1.682c-0.233-1.198-0.679-1.256-0.72-2.881
	c1.162-1.857,0.625-5.106,1.2-7.439c1.042-4.225,3.835-10.499,2.64-16.08c-0.552-2.574-1.949-8.111-1.2-11.279
	c0.824-3.484,2.502-5.711,4.32-8.16c0.496-0.668,1.629-1.324,1.92-2.159c0-1.279,0-2.562,0-3.841c0.4-1.438,0.8-2.879,1.2-4.318
	c1.722-4.061,5.813-7.253,8.88-10.08c-0.005-4.438-1.378-3.957-2.16-6.48c-0.817-2.638,2.647-5.338,0.72-8.64
	c-0.997-1.708-2.716-2.956-4.08-4.32c-2.017-2.017-3.838-4.116-5.52-6.48c-0.793-1.116-2.71-3-1.92-5.52
	c0.18-0.574,1-1.351,1.2-1.92c0.314-0.894,0.219-1.69,0.48-2.4c2.219-1.032,5.533-2.271,6.479-4.56c0.16-1.12,0.32-2.24,0.48-3.36
	c0.489-0.899,2.022-1.357,2.4-2.4c0.837-2.316-0.232-5.412,0.96-7.2c1.26-1.891,3.917-2.693,4.8-5.04c0.08-0.96,0.16-1.92,0.24-2.88
	c0.201-0.913,0.521-3.033,0.24-4.32c-0.343-1.565-0.464-3.374-1.2-4.56c-1.207-1.945-3.031-2.553-3.12-5.76
	c1.144-1.535,1.543-3.524,2.64-5.04c1.056-1.459,2.765-2.633,3.84-4.08c0.827-1.114,1.386-3.2,2.88-3.6
	c2.413-1.572,6.575-0.771,7.68-3.6c-2.437-1.93-4.444-9.444-5.28-13.2c-0.759-3.41,0.872-8.364,3.12-8.88
	c3.3,2.541,14.052,0.696,19.44,0.72c1.626,0.007,6.102,1.043,6.24,0.96c2.815-1.707,4.543-5.856,6-8.88
	c4.277,0.03,4.835-2.886,7.68-4.08c2.13-0.895,5.545,0.022,7.92-0.72c1.814-0.567,2.992-2.045,4.56-2.88c0,0.16,0,0.32,0,0.48
	c-1.014,1.266-0.646,3.69-0.48,5.52c0.658-0.23,1.911-0.402,2.64,0c1.036,0.571,5.087,5.076,5.28,6.48
	c-2.382,3.085-4.408,11.156-5.04,16.08c2.457,1.56,4.444,3.815,6.72,5.52c5.093,3.815,10.405,8.557,14.16,13.68
	c0.533,0.727,1.429,1.803,1.68,2.64c0.389,1.296-0.09,2.376,0.48,3.36c1.526,2.637,4.994,4.89,7.2,6.96
	c0.245-0.193,0.066-0.085,0.48-0.24c0.16-0.48,0.32-0.96,0.48-1.44c0.72-0.08,1.44-0.16,2.16-0.24c1.945,3.565,2.616,10.055,6,12.24
	c0,0.08,0,0.16,0,0.24c-2.2,0.513-4.614,2.428-4.56,5.28c1.205,1.214,2.162,2.614,2.16,5.04c-1.704,1.654-2.235,3.15-5.76,3.12
	c-0.218-0.26,0.019-0.02-0.24-0.24c-0.114-1.809-0.16-3.173-0.24-5.04c-0.497-0.308-0.552-0.499-1.2-0.72
	c-0.836-0.59-1.637-0.062-1.92,0.72c-1.103,1.817,0.875,3.687,0.24,5.76C126.323,233.874,119.745,236.118,112.799,236.101z'
          />{' '}
        </a>
        <a xlinkTitle={`Le Kef Est ${groupedData['Le Kef Est']}`}>
          <path
            onClick={() => setSelectedVill('Le Kef Est')}
            style={{ fill: getColorBasedOnCount(outputData['Le Kef Est']) }}
            d='M265.919,192.661c1.342,1.453,1.794,3.826,2.64,5.76c2.042,4.674,4.354,9.868,7.2,13.92
	c2.015,2.869,5.321,6.179,8.64,7.68c1.394,0.629,2.898,0.461,4.32,1.2c1.694,0.88,3.295,4.683,4.563,6.24
	c-0.518,2.445-3.598,5.827-5.521,6.72c-0.959,0.445-1.854,0.015-2.641,0.48c-2.006,1.187-1.75,3.348-3.12,4.8
	c-0.756,0.802-2.474,0.773-3.12,1.68c-0.32,1.12-0.64,2.24-0.96,3.36c-1.143,1.837-3.36,2.999-4.561,4.8
	c-0.88,1.84-1.76,3.68-2.64,5.52c-0.774,1.021-2.385,1.553-3.12,2.64c-0.657,0.972-0.541,2.348-1.2,3.36
	c-1.039,1.594-3.5,1.766-5.039,2.88c-1.737,1.256-2.593,3.746-3.84,5.52c-1.095,1.557-2.77,2.508-4.08,3.84
	c-4.139-1.939-9.459-3.918-11.761-7.679c-0.843-1.377-0.196-2.862-0.72-4.8c-0.733-2.713-2.627-5.093-3.84-7.44
	c-0.479-1.28-0.961-2.56-1.44-3.84c-0.405-0.274-1.175-0.256-1.92-0.24c-1.925,1.299-4.888,1.529-5.04,4.56
	c0.193,0.245,0.085,0.066,0.24,0.48c-4.801,0.091-3.086,4.994-7.92,5.04c0,0.08,0,0.16,0,0.24c-0.562,0.813,0.126,1.556-0.48,1.92
	c-0.861,0.729-2.191,0.454-3.359,0.96c-2.193,0.95-3.164,2.522-4.08,4.8c-4.355,0.133-10.352,1.347-14.4,0.48
	c-1.439,0-2.88,0-4.32,0c-2.479-0.48-4.959-0.96-7.439-1.44c-0.571,0.852-0.926,0.861-0.96,2.4c1.044,0.771,2.64,2.309,2.88,3.841
	c-0.219,0.224-0.32,0.421-0.48,0.72c-4.675-2.002-10.289-0.229-11.279,4.319c-0.16,0-0.32,0-0.48,0c-0.298-2.325-1.822-4.157-2.64-6
	c-2.231-5.024-4.242-9.477-6.24-14.88c-0.822-2.223-2.495-6.164-1.681-9.6c0.755-3.178,1.81-6.029,3.841-7.92
	c1.165-1.084,3.771-1.755,4.08-3.6c0.815-1.331,0.852-6.656,1.2-8.4c1.336-0.269,5.422-3.866,6.959-4.8
	c5.981-3.636,13.299-5.466,17.281-11.04c1.166-1.633,2.271-3.949,2.879-6c0.396-1.327,0.065-2.83,0.721-3.84
	c1.896,1.558,4.291,2.112,5.76,4.08c2.627,3.52,4.654,7.738,7.2,11.28c0.86,1.199,3.071,4.802,4.56,4.32
	c2.764-0.817,5.174-3.122,7.44-4.56c1.927-1.223,3.927-2.025,5.76-3.36c1.329-0.968,2.344-2.479,3.84-3.36
	c3.122-1.839,7.095-2.122,10.56-3.6c1.875-0.799,4.379-2.259,5.521-3.84c0.399-0.8,0.8-1.6,1.2-2.4
	c1.48-1.186,3.943-1.733,5.52-2.88c0.793-0.578,2.733-3.629,3.12-3.84C264.498,192.605,265.237,193.381,265.919,192.661z'
          />{' '}
        </a>
        <a xlinkTitle={`Le Kef Ouest ${groupedData['Le Kef Ouest']}`}>
          <path
            onClick={() => setSelectedVill('Le Kef Ouest')}
            style={{ fill: getColorBasedOnCount(outputData['Le Kef Ouest']) }}
            d='M174.238,226.261c-0.399,3.12-0.799,6.24-1.199,9.36c-5.314,2.558-9.84,9.406-7.44,18
	c2.033,7.28,5.627,13.119,8.4,19.439c1.026,2.34,1.854,3.926,1.92,7.199c-2.104,1.398-9.332,7.23-13.2,4.801
	c-0.572-0.961,0.323-2.053-0.24-3.119c-1.703-3.229-6.834-5.26-10.56-6.479c-1.12-0.239-2.24-0.479-3.36-0.72
	c-1.268-0.637-2.398-1.744-4.08-2.16c-2.8,0.16-5.601,0.32-8.4,0.48c-2.313-0.088-5.341-2.862-7.68-1.44
	c-1.207,0.457-1.492,1.481-2.4,2.16c-1.808,1.351-4.008,2.755-6.48,3.36c-6.478,1.584-10.97-3.949-14.64-5.521
	c-3.468-1.484-7.822-1.049-11.76-2.16c-1.746-0.493-3.12-1.42-4.56-2.16c0.144-0.414,0.214-0.756,0.24-1.44
	c0.501-0.27,0.439-0.344,1.2-0.479c0.863,0.587,2.058-0.022,2.88-0.24c0.497-0.771,0.85-0.888,0.96-2.16
	c-0.56-0.5-1.387-1.173-1.68-1.92c2.37-0.173,3.909,1.257,6.72,0.96c0.298-0.944,0.646-1.071,0.72-2.4
	c-1.134-1.004-2.466-1.865-2.88-3.6c1.115-0.698,1.817-1.626,1.92-3.36c-1.141-1.604-1.895-4.197-1.92-6.72c0.56,0,1.12,0,1.68,0
	c2.163,3.336,6.681,1.635,8.4-1.2c0.471-0.777,0.243-2.293,0.72-3.12c0.477,0.444,1.469,0.489,1.92,0.96
	c0.557,0.581,0.194,1.237,0.96,1.68c0.885-0.827,1.951-1.107,2.4-2.4c0.766-1.05,0.231-3.449,0.96-4.56
	c1.384-0.813,3.938-0.238,5.52-0.72c3.98-1.211,8.497-3.622,9.6-7.68c0.576-2.118-0.43-3.716-0.48-5.76c0.16,0,0.32,0,0.48,0
	c0.371-0.169,0.033-0.031,0.72,0c0.552,1.563,0.341,3.75,0.72,5.52c0.24,0.08,0.48,0.16,0.72,0.24c2.775,1.856,5.94-2.354,6.96-3.36
	c0.053-4.208-1.848-3.907-2.16-6.96c0.96-0.88,1.92-1.76,2.88-2.64c2.178-1.331,7.435-1.297,9.36,0.24
	c0.99,1.058,0.991,3.209,2.16,4.08c1.63,1.215,3.777,1.548,5.76,2.4C161.708,225.114,166.249,227.199,174.238,226.261z'
          />{' '}
        </a>
        <a xlinkTitle={`Le Sers ${groupedData['Le Sers']}`}>
          <path
            onClick={() => setSelectedVill('Le Sers')}
            style={{ fill: getColorBasedOnCount(outputData['Le Sers']) }}
            d='M312.958,344.102c-3.866,0.01-6.16,2.211-9.601,1.92c-1.291-4.609-3.699-6.192-2.159-12.238
	c-0.08,0-0.16,0-0.24,0c-1.678,1.212-4.298,0.691-6.722,1.197c-7.875,1.65-19.538-1.354-24.959-3.6
	c-2.227-4.742-3.663-7.385-2.88-14.16c-2.646-1.768-3.614-4.846-7.92-5.039c-0.339-2.699-2.575-2.98-3.36-5.041
	c-0.239-3.039-0.479-6.08-0.719-9.119c-1.117-4.484-2.17-9.022-4.561-12.238c-0.837-1.127-2.372-1.656-3.359-2.643
	c-9.543,0.037-11.604,12.959-19.441,13.682c-0.504-3.527-7.252-10.205-10.559-10.801c-0.605,0.939-1.568,1.664-1.921,2.879
	c-1.063,3.68,0.104,8.313-1.2,11.521c-2.707,1.732-6.096,0.542-10.32,0.479c-0.773-3.238-3.271-5.961-4.559-8.879
	c-2.33-5.271-3.031-11.916-6.24-16.318c-0.72-0.987-1.994-1.563-2.881-2.398c0.452-0.457,0.715-1.484,0.24-2.16
	c-0.392-1.158-1.792-2.348-2.64-3.121c0-0.16,0-0.319,0-0.479c0.414-0.144,0.757-0.213,1.44-0.24
	c1.476,0.987,3.721,0.512,5.76,0.959c6.043,1.327,13.328-0.156,19.68-0.24c0.633-2.333,1.758-3.929,3.84-4.8
	c1.846-0.773,4.01-0.038,4.32-2.4c-0.182-0.258-0.145-0.227-0.24-0.72c0.641,0,1.279,0,1.92,0c0.948-1.554,2.954-4.993,5.28-5.04
	c0.37,0.17,0.032,0.031,0.72,0c0.264-0.745,0.166-1.374,0-2.16c1.723-1.153,2.491-2.659,5.28-2.88c0.5,3.958,3.457,6.77,4.8,10.08
	c0.16,1.6,0.32,3.2,0.48,4.8c1.375,3.826,6.096,5.625,9.6,7.441c0.888,0.459,2.559,2.141,4.08,1.68
	c2.816-0.854,5.449-6.334,7.199-8.641c1.388-1.825,4.7-1.926,6.001-3.84c0.479-1.2,0.96-2.4,1.439-3.6c0.96-0.8,1.92-1.6,2.88-2.4
	c1.257-1.691,1.666-4.02,2.88-5.76c1.235-1.771,3.438-2.96,4.561-4.8c0.24-1.04,0.479-2.08,0.721-3.12
	c0.594-0.822,2.066-0.806,2.879-1.44c2.235-1.745,0.854-4.709,5.041-5.28c0.722,0.58,1.24,0.119,1.682,0.72
	c1.359,2.296-1.286,6.641-0.48,9.84c0.865,3.434,3.438,5.078,5.04,7.68c0.673,1.092,0.466,3.043,1.681,3.6
	c0.766,0.466,3.07,0.085,4.079-0.24c2.732-0.881,3.823-1.67,7.2-0.96c0,0.08,0,0.16,0,0.24c0.08,0,0.16,0,0.24,0
	c0.556,4.395-1.837,5.999-1.92,10.56c0.664,0.684,1.272,1.472,1.92,2.16c5.005,0.058,5.06-3.319,9.359-3.6
	c0.589,0.742,1.6,1.171,2.158,1.92c1.708,2.277,3.277,4.766,5.763,6.24c4.677,2.778,10.652-0.672,10.56,7.44
	c-0.572,0.875-0.343,7.104,0,8.159c0.439,1.351,1.377,2.395,1.68,3.841c-1.59,1.142-2.465,4.754-2.64,7.2
	c0.807,0.852,1.034,2.09,1.68,3.119c2.688,4.287,2.488,4.662,1.44,10.318c-0.204,1.104,0.754,2.705,0.479,3.12
	c-1.664,2.472-4.806,4.229-7.198,6c-4.336,3.21-8.45,7.011-12.239,10.8c-1.369,1.369-4.332,3.985-5.04,5.762
	C312.958,340.74,312.958,342.421,312.958,344.102z'
          />{' '}
        </a>
        <a xlinkTitle={`Tajerouine ${groupedData['Tajerouine']}`}>
          <path
            onClick={() => setSelectedVill('Tajerouine')}
            style={{ fill: getColorBasedOnCount(outputData['Tajerouine']) }}
            d='M42.719,329.939c4.063-0.067,6.87-2.664,10.08-3.84c1.667-0.61,3.938-0.558,4.8-1.92
	c0.16-0.722,0.32-1.439,0.48-2.16c4.828,0.006,6.613-3.234,9.36-5.279c1.025-0.764,2.981-1.008,3.36-2.4
	c0.48-1.52,0.96-3.041,1.44-4.561c1.538-3.526,5.125-7.115,9.6-7.68c0.416-5.109-1.797-4.238-2.16-7.92
	c1.416,0.02,2.11-0.234,3.12-0.479c0.716-1.11,0.994-1.771,0.96-3.841c-0.908-0.491-3.02-0.439-3.84-0.959
	c0.606-0.799,0.466-2.1,0.24-3.121c-1.038-0.387-2.008-0.242-2.16-1.68c1.041-0.99,1.934-2.016,3.36-2.642
	c1.02-0.448,3.146-0.747,3.6-1.681c0.276-0.567-1.117-2.618-0.72-4.079c0.574-2.108,2.319-3.613,3.12-5.521
	c0.36-0.856,0.192-1.556,0.72-2.159c2.718,3.381,9.509,2.33,14.16,3.84c3.762,1.223,6.19,3.834,9.6,5.281
	c7.263,3.08,13.553-1.051,17.04-4.803c2.584-0.008,3.842,1.143,5.76,1.682c3.372,0.947,6.259-1.276,9.36-0.479
	c1.71,0.44,2.931,1.562,4.32,2.159c1.596,0.688,3.132,0.582,4.8,1.201c2.541,0.941,4.554,2.367,6.48,3.84
	c0.619,0.473,1.556,1.018,1.92,1.68c0.288,0.521,0.292,3.027,0.48,3.84c-1.296,0.83-2.784,3.146-2.16,5.76
	c0.72,2.643,1.44,5.281,2.16,7.92c0.079,2.32,0.159,4.643,0.239,6.961c0.287,0.932,1.098,1.455,1.44,2.4
	c-0.542,0.633-0.7,1.465-1.2,2.159c-0.975,1.351-2.724,2.396-3.36,4.08c-1.192,3.155,0.614,6.215,1.68,7.92
	c1.564,2.501,3.214,5.229,5.52,6.96c1.795,1.348,4.119,2.617,5.28,4.561c1.886,3.156,1.82,8.498,2.88,12.479
	c0.439,1.649,2.531,8.735,2.16,9.601c-0.419,1.51-1.324,1.59-2.4,2.399c-0.623,0.47-0.891,1.37-1.439,1.92
	c-0.016,1.392-0.441,2.019,0,3.36c0.287,0.873,1.406,2.138,0.96,3.119c0,0.08,0,0.16,0,0.24c-3.052-0.869-3.851,2.814-6.479,3.358
	c-3.461-2.663-13.442-0.438-18.96-0.96c-1.417-0.135-4.639-1.586-5.28-1.2c-3.161,1.896-6.731,3.945-9.84,6
	c-1.27,0.839-3.02,1.371-3.36,3.12c2.734,2.053-0.628,9.021-2.16,10.563c-4.013-3.729-17.733-3.447-25.439-3.603
	c0,0.08,0,0.16,0,0.24c-0.08,0-0.16,0-0.24,0c-0.16,4.229-0.182,8.479,0.96,11.76c0.287,0.824,0.918,3.397,0.72,3.603
	c-2.333,1.617-8.093,0.029-10.08-0.723c-7.131-2.697-12.554-7.09-22.56-5.039c-0.421-0.553-2.623-3.107-3.12-3.359
	c-0.961-0.485-2.728,0.055-3.6-0.479c-0.643-0.666-0.879-1.644-1.44-2.399c-1.883-2.543-6.95-6.453-9.6-8.398
	c-1.585-1.165-4.754-2.464-5.04-4.803c1.03-1.524,0.716-3.959,1.2-6c1.646-6.936,0.748-16.655-2.16-21.357
	c-0.907-1.467-2.464-3.538-3.84-4.563c-0.561-0.416-2.087-0.852-2.4-1.438c0-0.643,0-1.277,0-1.92
	c0.513-0.84-0.018-2.418,0.24-3.602C41.784,334.584,42.591,332.162,42.719,329.939z'
          />{' '}
        </a>
        <a xlinkTitle={`Dahmani ${groupedData['Dahmani']}`}>
          <path
            onClick={() => setSelectedVill('Dahmani')}
            style={{ fill: getColorBasedOnCount(outputData['Dahmani']) }}
            d='M226.318,298.02c9.172-0.393,10.715-13.393,19.681-13.92c0.924,0.906,2.35,1.346,3.12,2.4
	c2.297,3.15,3.232,7.66,4.32,12c0.239,2.96,0.479,5.92,0.719,8.88c0.66,1.976,2.82,2.317,3.121,4.56
	c0.142,0.231,0.025,0.459,0,1.201c3.615-0.104,7.938,2.701,7.92,6c-1.569,2.451,1.074,8.019,1.68,9.359
	c0.313,0.697,1.105,2.514,0.96,2.641c-0.772,1.984-3.299,2.537-5.04,3.602c-4.943,3.018-18.116,8.201-12.48,16.8
	c1.102,1.683,2.756,2.364,4.32,3.603c-1.168,1.81,0.61,4.324,1.2,5.277c2.01,3.247,4.67,5.563,4.8,10.563
	c-0.4,0.64-0.8,1.277-1.199,1.92c-0.422-0.151-0.234-0.047-0.48-0.24c-3.659-1.137-8.555-7.055-11.76-9.359
	c-1.4-1.008-4.496-1.82-5.28-3.361c-0.694-0.979-0.808-2.984-1.2-3.6c-2.257-2.01-7.559-1.683-8.641-4.8
	c-0.858-1.341-0.271-5.542-0.239-7.44c-4.479-0.149-11.531-1.86-12,3.121c1.36,1.77,2.002,4.414,3.36,6.239
	c1.811,2.435,6.879,5.427,7.439,8.64c-2.847,3.049,1.604,7.133,2.16,9.36c0.572,2.296-1.583,3.909-2.16,5.28
	c-0.775,1.841-0.25,3.895,0,5.76c-2.24,0.561-4.48,1.12-6.72,1.68c0,1.92,0,3.84,0,5.76c-0.08,0-0.16,0-0.24,0
	c-0.608,0.49-1.483,0.502-2.16,0.961c-2.511,1.697-2.663,4.953-6.72,5.279c-0.053,8.567-7.37,11.854-10.801,17.28
	c-0.916,1.449-1.7,3.521-2.159,5.28c-0.285,1.092,0.336,1.617-0.479,2.16c-1.312-1.37-3.522-3.289-5.521-3.84
	c-6.884-1.899-11.663,5.41-18.479,2.879c-1.559-0.578-2.519-2.039-3.6-3.119c-0.869-0.869-2.313-1.502-2.641-2.881
	c0.291-0.506,0.117-0.535,0-1.199c3.49-0.436,7.918-3.535,10.801-5.04c1.42-0.741,3.431-1.097,4.56-2.16
	c-6.446-2.983-7.007-9.53-10.56-15.36c-0.967-1.584-3.954-4.137-4.32-5.76c0.633-1.001-0.109-3.082-0.48-4.08
	c-1.271-3.419-1.931-4.197-1.92-8.64c1.177-0.401,2.11-1.789,2.881-2.64c1.166-0.057,1.584-0.145,2.64,0.238
	c0.08-0.801,0.16-1.601,0.24-2.398c-0.304-1.279-2.091-3.459-0.96-5.28c0.914-2.429,3.67-1.961,3.84-5.521
	c-0.875-1.189-0.736-2.932-1.2-4.562c-1.092-3.839-1.731-8.485-2.88-12.239c-0.32-1.438-0.641-2.881-0.96-4.318
	c-1.203-2.401-3.912-3.953-6-5.521c-1.987-1.49-3.41-3.882-4.8-6c-0.914-1.393-2.979-3.835-2.16-6.72
	c0.96-3.382,4.479-4.474,5.04-8.4c-2.806-1.679-1.089-4.856-1.92-8.64c-0.911-4.146-4.131-11.063,0.239-13.682
	c2.27,1.465,6.312-0.597,7.92-1.438c1.416-0.74,5.07-2.397,5.761-3.601c0.905-1.576,0.927-3.814,1.92-5.279
	c1.92-2.832,6.965-3.055,10.319-1.439c2.326,1.117,3.271,4.01,4.32,6.479c2.088,4.915,3.689,10.446,6,14.88
	c0.871,1.669,2.111,6.22,4.08,6.479c2.551-1.563,6.582,1.666,9.12,0c3.944-1.418,1.629-6.785,2.64-11.278
	c0.235-1.046,0.715-2.146,0.961-3.119c0.424-0.229,0.402-0.304,0.959-0.479c0.672,0.935,1.703,1.246,2.641,1.92
	C222.43,291.004,225.8,294.176,226.318,298.02z'
          />{' '}
        </a>
        <a xlinkTitle={`Kalaat Sinane ${groupedData['Kalaat Sinane']}`}>
          <path
            onClick={() => setSelectedVill('Kalaat Sinane')}
            style={{ fill: getColorBasedOnCount(outputData['Kalaat Sinane']) }}
            d='M63.36,392.581c1.484-0.019,2.907,0.095,4.08,0.239c0.986,1.531,2.369,2.349,3.6,3.601
	c8.667-3.589,17.813,3.466,24.48,5.521c2.058,0.636,8.451-0.064,9.12,0.24c1.001,1.184,1.001,3.313,1.68,4.799
	c1.075,2.354,3.175,4.385,5.04,6c0.173,7.543-3.792,10.627-6.24,15.603c-3.382,0.196-4.45,2.444-6.72,3.601
	c-1.388,0.707-3.471,0.855-5.04,1.199c-3.655,0.804-7.822-0.871-10.32-1.68c-5.667-1.835-11.203-3.726-18.24-3.84
	c-1.08,2.188-1.498,5.498-2.88,7.438c-1.474,2.071-4.666,3.709-5.04,6.723c2.667,1.387,5.103,4.553,6,7.68
	c1.026,3.58-1.021,7.311-0.24,10.8c0.331,1.479,1.047,2.606,1.44,3.84c-13.449,0.599-15.528,21.291-26.88,24
	c-0.288-8.836-0.582-18.771-4.56-24c-1.003-1.315-3.548-2.6-5.28-3.12c-0.96-0.08-1.92-0.158-2.88-0.238
	c-1.44-1.521-2.88-3.039-4.32-4.562c-4.335-3.272-8.495-7.649-11.76-12c-1.013-1.349-3.997-3.2-4.56-4.56
	c-3.805-9.197-1.804-18.278,0.48-27.842c0.932-3.898,0.72-8.256,0.72-12.721c0-2.879,0-5.76,0-8.64
	c-1.083-4.978,0.402-11.343-1.2-15.601c-0.747-1.983-3.811-4.644-3.84-6c1.827-0.897,2.503-2.275,3.84-3.602c0-0.88,0-1.76,0-2.643
	c-1.235-0.006-1.719-0.254-2.64-0.479c-0.638-0.958-1.137-1.21-1.2-2.88c0.268-0.415,2.079-5.179,2.16-5.521
	C2.08,353.46,2,352.979,1.92,352.5c-0.01-1.636,0.297-3.438,0.96-4.561c2.133-3.612,6.481-5.217,8.64-8.399
	c1.257,0.694,6.219-0.963,6.72-2.399c0.193-0.283,0.186-0.404,0.24-0.959c1.105,0.137,1.697,0.777,2.4,0.479
	c2.462-0.871,3.731-4.167,4.8-6.479c0.16,0.08,0.32,0.158,0.48,0.238c1.126,2.258,1.137,3.725,4.8,3.602
	c1.115-0.691,2.757-0.517,4.08-0.961c2.214-0.746,4.267-2.043,6.72-2.641c0,0.08,0,0.16,0,0.24
	c-0.817,1.015-2.344,10.834-1.68,12.238c2.599,2.619,5.07,3.508,6.959,7.201c2.629,5.139,2.938,15.479,1.2,22.08
	c-0.385,1.461-0.982,3.146-1.2,4.799c4.023,2.604,7.473,6.483,11.28,9.359C60.275,387.816,62.955,389.635,63.36,392.581z'
          />{' '}
        </a>
        <a xlinkTitle={`El Ksour ${groupedData['El Ksour']}`}>
          <path
            onClick={() => setSelectedVill('El Ksour')}
            style={{ fill: getColorBasedOnCount(outputData['El Ksour']) }}
            d='M299.518,335.46c-0.131,5.187,1.656,8.246,3.36,11.521c3.863,0.41,6.213-1.752,10.08-1.92
	c0.104,4.119,1.241,9.537-0.721,12.722c-3.236,5.254-13.401,4.962-18.239,8.64c-1.424,1.083-2.648,3.78-3.359,5.521
	c-0.434,1.061-0.316,2.272-0.961,3.121c-0.666,0.875-4.02,2.563-4.56,3.357c-0.503,0.741-0.043,1.962-0.479,2.643
	c-1.232,1.914-4.16,2.502-6,3.84c-0.641,0.72-1.281,1.438-1.921,2.16c1.267,3.26,2.552,6.312,4.32,9.119
	c2.221,3.526,4.887,6.557,6,11.278c1.899,8.067-6.632,13.435-5.04,18.96c1.309,4.544,3.559,8.979,5.279,13.2
	c1.658,4.063,2.203,8.151,3.601,12.479c1.099,3.405,2.302,7.215,2.399,11.521c-0.159,0-0.319,0-0.479,0
	c-1.407-1.431-3.188-2.623-4.8-3.84c-2.473-1.868-4.502-4.56-7.2-6.24c-1.296-0.81-2.591-0.692-4.08-1.44
	c-0.917-0.459-1.694-1.563-2.88-1.92c-3.605-1.072-8.254,1.664-11.28,0.723c-1.985-0.619-4.169-1.955-6.239-2.643
	c-2.875-0.951-5.504-1.199-8.4-2.16c-10.774-3.572-18.598-9.432-27.12-15.117c-4.208-2.812-8.558-5.721-12.96-8.16
	c-1.582-0.877-4.779-1.438-5.04-3.6c1.172-1.729,1.254-4.117,2.399-5.763c2.523-3.622,6.299-6.25,8.641-10.08
	c1.05-1.717,0.945-4.546,2.16-6.237c0.775-0.568,1.871-0.644,2.64-1.201c2.304-1.674,2.172-4.688,6.24-4.799c0-2.08,0-4.162,0-6.24
	c2.396-0.248,4.604-1.155,6.72-1.68c0.128-0.363,0.162-0.406,0.24-0.961c-2.438-3.143,1.212-6.632,1.92-8.882
	c1.066-3.388-2.251-5.438-2.88-7.438c-0.642-2.045,0.726-3.383,0.96-4.799c-1.204-0.854-1.988-2.418-2.88-3.603
	c-2.556-3.39-7.617-5.492-7.921-11.04c3.828-2.588,4.471-1.729,9.841-1.438c-0.039,1.785-0.563,5.715,0.239,6.959
	c0.91,2.521,5.955,4.096,8.641,4.801c0.066,1.287,0.865,3.621,1.2,4.08c1.879,1.688,4.7,2.599,6.72,4.08
	c2.95,2.164,8.275,8.474,12,9.119c0.6-0.906,1.6-1.723,1.92-2.879c1.978-7.145-8.627-10.017-5.52-17.041
	c-3.756-0.23-6.291-4.1-6.24-8.16c1.048-1.232,1.58-2.871,2.88-3.84c3.563-2.656,7.595-4.326,11.52-6.722
	c1.558-0.95,2.941-2.854,5.04-3.12c1.651,1.27,4.239,1.254,6.48,1.92c4.341,1.295,9.24,1.922,14.88,1.922c1.28,0,2.563,0,3.84,0
	C295.986,335.855,297.82,335.527,299.518,335.46z'
          />{' '}
        </a>
        <a xlinkTitle={`Kalaa El Khasba ${groupedData['Kalaa El Khasba']}`}>
          <path
            onClick={() => setSelectedVill('Kalaa El Khasba')}
            style={{
              fill: getColorBasedOnCount(outputData['Kalaa El Khasba']),
            }}
            d='M169.919,412.98c-3.851-0.06-6.44,1.537-9.12,2.64c-1.109,0.457-2.67,0.301-3.6,0.96
	c-1.684,1.66-2.895,4.109-4.32,6c-2.646,3.512-7.213,6.055-11.28,8.16c-0.973,0.504-6.071,3.025-6.72,2.64
	c-5.999-4.014-5.276-13.942-9.12-20.159c-4.057-6.563-12.829-9.136-20.64-12c-1.759-3.996-2.776-9.297-2.16-14.881
	c5.49,0.551,12.176-0.338,17.28,0.961c2.758,0.701,5.988,2.621,8.4,2.879c0.659-2.861,4.358-6.683,3.36-10.32
	c-0.185-0.672-0.733-1.196-0.96-1.92c1.709-0.838,3.178-2.364,4.8-3.357c2.43-1.484,5.767-2.109,7.44-4.32
	c2.605,0.99,9.111,2.032,13.2,1.2c1.602-0.325,4.638-0.368,6.24,0c2.033,0.472,4.734,0.021,5.28,1.92
	c-0.792,1.466,2.161,7.052,2.64,8.642c0.451,1.5-0.052,2.666,0.24,4.08c2.266,0.721,3.162,3.615,4.32,5.521
	c2.596,4.269,4.123,11.369,8.64,13.681c0,0.159,0,0.319,0,0.479c-2.097,0.507-3.981,1.952-5.761,2.88
	c-2.647,1.381-5.408,2.301-8.159,3.6C169.919,412.5,169.919,412.74,169.919,412.98z'
          />{' '}
        </a>
        <a xlinkTitle={`Kalaa El Khasba ${groupedData['Kalaa El Khasba']}`}>
          <path
            onClick={() => setSelectedVill('Kalaa El Khasba')}
            style={{
              fill: getColorBasedOnCount(outputData['Kalaa El Khasba']),
            }}
            d='M106.079,402.661c6.383,0.845,16.443,7.174,19.2,11.76c2.311,3.845,2.507,9.481,4.32,13.681
	c1.406,3.258,4.799,4.564,4.8,8.881c0.901,1.432,0.917,7.672,1.44,9.841c0.579,2.401,0.894,5.409,1.44,7.92
	c2.197,10.104-6.521,18.043-11.52,21.359c-1.266,0.841-7.153,5.214-8.88,4.8c-2.507-0.604-3.448-3.229-4.8-5.039
	c-1.294-1.735-5.058-4.322-6.96-5.521c-9.81-6.18-24.045-7.948-39.6-6.239c-0.754-1.183-1.538-2.168-1.92-3.841
	c-0.314-1.377,0.242-3.45,0.48-4.561c0.467-2.178,0.098-5.604-0.48-7.2c-0.62-1.714-1.833-3.411-2.88-4.8
	c-0.785-1.041-2.131-1.27-2.64-2.642c1.712-1.259,5.135-5.416,6-7.438c0.847-1.979-0.12-4.621,2.64-4.8
	c1.203,0.733,6.215,0.891,7.92,1.438c6.01,1.938,14.916,6.024,22.8,3.359c1.747-0.59,2.937-1.976,4.32-2.88
	c1.104-0.723,3.647-0.792,4.32-1.921c0.633-0.67,0.691-1.564,1.2-2.399c1.761-2.892,4.121-5.626,5.04-9.358
	c0.267-1.082,0.171-3.895-0.24-4.562c-0.501-1.345-2.087-1.813-2.88-2.88C107.847,407.805,106.463,405.313,106.079,402.661z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 50.7061 527.668)'
          font-family="'MyriadPro-Regular'"
          font-size='24'
        >
          Gouvernorat de Kef
        </text>
        <text
          transform='matrix(1 0 0 1 50.5801 204.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sakiet{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 50.5801 219.0977)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi Youssef
        </text>
        <text
          transform='matrix(1 0 0 1 12.5801 415.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kalâat Snane
        </text>
        <text
          transform='matrix(1 0 0 1 66.5801 452.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kalâat Khasbah
        </text>
        <text
          transform='matrix(1 0 0 1 84.5801 339.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Tajerouine
        </text>
        <text
          transform='matrix(1 0 0 1 112.5801 257.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kef Ouest
        </text>
        <text
          transform='matrix(1 0 0 1 206.5801 242.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Kef Est
        </text>
        <text
          transform='matrix(1 0 0 1 166.5801 175.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Nebeur
        </text>
        <text
          transform='matrix(1 0 0 1 276.5801 299.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Es-Sers
        </text>
        <text
          transform='matrix(1 0 0 1 236.5801 411.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Ksour
        </text>
        <text
          transform='matrix(1 0 0 1 181.5801 337.6973)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Dahmani
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Kef;
