import React, { useState } from 'react';
import './styles.css';
import { Card } from 'antd';
import Legends from '../components/Legends/Legends';
import useGetColor from '../utils/useGetColor';

const Mahdia = ({ data }) => {
  const [selectedVill, setSelectedVill] = useState('');
  function filterByVille(villeName) {
    return data.filter((item) => item.Ville === villeName);
  }
  const outputData = data.reduce((acc, curr) => {
    if (curr.Ville) {
      acc[curr.Ville] = (acc[curr.Ville] || 0) + parseInt(curr.Count);
    }
    return acc;
  }, {});

  let minCount = 0;
  let maxCount = 'and more';

  const dataValues = Object.values(data)
    .filter((data) => data.count !== 'Total' && data?.Ville !== '')
    .sort((a, b) => a - b);
  const counts = dataValues.map((record) => parseInt(record.Count));

  const sortedCounts = [...counts].sort((a, b) => a - b);
  const { quantiles } = useGetColor(sortedCounts)
  const getColorBasedOnCount = (count) => {

    const colors = ['#b2e3fa', '#50bef1', '#11a3ec', '#0d83c9', '#085293'];

    for (let i = 0; i < quantiles?.length; i++) {
      if (count <= quantiles[i]) {
        return colors[i];
      } else if (count === undefined) {
        return colors[0];
      }
    }

    return colors[colors?.length - 1];
  };

  const legend = [];
  for (let i = 0; i < 5; i++) {
    const maxDataValues = Math.max(...sortedCounts)
    const lowerBound =
      i === 0
        ? minCount
        : Math.floor(maxDataValues / 5) * i;
    const upperBound =
      i === 4
        ? maxCount
        : Math.floor(maxDataValues / 5) * (i + 1);
    const color = getColorBasedOnCount((upperBound + lowerBound) / 2);

    legend.push({
      range: `${lowerBound} - ${upperBound}`,
      color: color,
    });
  }

  let groupedData = {};

  Object.values(data).forEach((entry) => {
    const ville = entry.Ville.trim();
    const count = parseInt(entry.Count, 10);

    if (ville) {
      if (!groupedData[ville]) {
        groupedData[ville] = 0;
      }
      groupedData[ville] += count;
    }
  });
  const result = filterByVille(selectedVill);
  return (
    <>
      <div className='legend-container'>
        <Legends legend={legend} />
      </div>
      <svg
        version='1.0'
        id='Calque_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='558.867px'
        height='476.583px'
        viewBox='0 0 558.867 476.583'
        enable-background='new 0 0 558.867 476.583'
      >
        <a xlinkTitle={`Ouled Chamakh ${groupedData['Ouled Chamakh']}`}>
          <path
            onClick={() => setSelectedVill('Ouled Chamakh')}
            style={{ fill: getColorBasedOnCount(outputData['Ouled Chamakh']) }}
            d='M77.519,253.256c-0.4-0.08-0.8-0.16-1.2-0.24c-0.514-0.813-0.733-1.123-0.72-2.639
	c1.35-1.063,1.915-2.289,4.32-2.4c0.847,0.859,2.43,2.49,3.6,2.88c2.436,0.812,4.789-0.28,7.44,0.479
	c1.827,0.521,3.526,2.146,5.28,2.881c3.395,1.422,6.783,0.055,6.72,5.039c0.404,0.639,0.173,1.584,0.48,2.398
	c0.508,1.35,1.888,2.063,2.4,3.359c-0.08,0-0.16,0-0.24,0c-1.351,2.029-5.889,2.895-9.36,1.92c-0.999-0.278-2.617-1.232-3.36-0.959
	c-3.503,0.592-6.385,7.367-4.8,11.277c1.022,0.604,2.933,1.422,3.36,2.643c1.419,2.447-3.151,2.811-3.84,4.08
	c-0.85,1.566-0.591,3.344-1.44,4.799c-2.521,4.321-6.106,7.988-6.96,13.92c-5.113,0.08-10.271,0.197-13.2-1.92
	c-1.304-0.939-1.586-2.646-2.64-3.84c-1.032,0.9-3.588,0.916-5.28,0.479c-2.782-0.717-9.461-1.787-13.2-0.959
	c-4.151,0.916-8.387,0.916-12.24,2.16c-4.221,1.359-8.488,3.293-12.72,4.318c-0.934-3.088-5.562-7.967-7.44-10.801
	c-0.789-1.189-0.991-3.037-2.4-3.601c-0.714-0.514-2.269-0.655-3.36-0.72c0.046-6.051,4.042-8.381,7.68-10.801
	c1.808-1.201,4.456-1.637,4.56-4.561c-1.067-1.658,0.479-6.133,0.96-7.439c1.895-5.152,4.249-8.541,7.2-12.479
	c1.131-1.51,2.757-2.706,3.84-4.317c-1.856-1.482-2.936-4.273-4.32-6.24c-2.786-3.958-6.153-7.714-9.6-11.04
	c0.256-3.191,2.577-4.737,4.32-6.48c4.127-4.127,8.989-7.471,13.2-11.52c3.375,0.047,5.833,2.19,9.36,1.2
	c2.05-0.576,2.4-2.367,3.84-3.36c0.88-0.32,1.76-0.64,2.64-0.96c1.494-0.93,3.127-2.552,4.32-3.84c0.48,0,0.96,0,1.44,0
	c1.036,0.919,2.214,0.786,2.4,2.64c-3.215,2.548-11.562-0.189-7.68,6.24c-1.057-0.01-1.546,0.029-1.92,0.72
	c-0.93,1.215-0.814,2.996-1.44,4.56c-0.678,1.695-2.139,3.714-2.64,5.28c0,0.8,0,1.6,0,2.4c0.541,2,3.234,3.927,2.4,6.96
	c-0.242,0.882-0.926,1.435-1.2,2.4c0.24,0,0.48,0,0.72,0c1.655-1.199,3.717-0.015,4.8,0.72c2.908,1.975,4.23,5.406,6.96,7.439
	c0.922,0.688,4.618,1.686,5.04,2.398c0.732,0.938,0.773,2.609,1.68,3.357c0.812,0.672,3.009,0.574,3.6,1.441
	c0.514,0.77,0.497,2.381,0.48,3.6c1.021,0.66,2.243,1.771,3.12,2.642c0.622-0.132,4.571-1.313,4.8-1.683
	C77.75,256.381,77.55,254.39,77.519,253.256z'
          />{' '}
        </a>
        <a xlinkTitle={`Mahdia ${groupedData['Mahdia']}`}>
          <path
            onClick={() => setSelectedVill('Mahdia')}
            style={{ fill: getColorBasedOnCount(outputData['Mahdia']) }}
            d='M307.438,214.137c0.159,0,0.319,0,0.479,0c0.066,9.268,5.873,19.038,13.439,20.88
	c2.096,0.51,3.461-1.252,5.041-1.2c0.112,0.594,0.204,0.884,0.237,1.68c-2.696,1.683-4.944,1.228-6.721,4.08
	c-2.58,4.149-3.271,10.332-4.562,15.6c-0.557,2.277-0.02,5.646-1.198,7.441c-0.258,0.18-0.228,0.146-0.722,0.239
	c-1.262-1.468-0.284-2.114-0.959-3.36c-1.805,1.438-4.864,2.367-7.92,2.4c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.239,0
	c-0.146-2.104,0.223-3.477,0.239-6c-0.16,0-0.319,0-0.479,0c-0.879,0.584-1.896,0.389-3.119,0.721
	c-4.078,1.104-9.238,1.406-11.281,4.561c-0.479-0.158-0.959-0.318-1.438-0.479c-1.563-2.834-1.317-3.688-3.84-5.76
	c-2.768,0.635-5.024,2.379-6.48,4.318c-1.099,1.467-1.709,3.65-4.08,3.841c-1.524-1.307-2.937-2.646-4.32-4.079
	c-5.737,0.16-13.575,3.811-16.08,7.199c-0.848,1.146-1.297,2.473-2.16,3.602c-0.924,1.207-2.796,1.045-4.08,1.92
	c-1.109,0.754-0.92,2.244-1.68,3.357c-0.283,0.192-0.403,0.188-0.96,0.24c-0.376-0.773-0.434-2.629-0.96-3.357
	c-1.723-1.439-3.219-0.609-3.12-4.08c0.745-1.068,1.955-4.818,2.16-6.24c-1.822-0.596-2.908-2.539-2.88-5.041
	c1.475-2.039,1.855-4.271,3.84-5.76c2.239-1.682,5.158-2.457,7.44-4.08c0.756-1.939,1.363-4.613,1.92-6.721
	c2.495-0.104,2.399-1.544,4.8-1.68c2.484,1.854,10.085,2.84,11.28-0.72c1.551-1.937-0.724-4.12-0.96-6
	c3.125-2.034,4.317-6.723,7.68-8.4c2.144-1.07,3.675,0.945,5.28,0.72c2.713-0.38,13.616-1.84,14.399-3.6
	c0.685-1.045-0.285-2.713-0.479-3.84c1.44-0.959,2.129-1.469,4.801-1.44c0.828,1.237,2.232,2.396,4.32,2.4
	C305.126,216.169,306.515,215.347,307.438,214.137z'
          />{' '}
        </a>
        <a xlinkTitle={`Bou Merdes ${groupedData['Bou Merdes']}`}>
          <path
            onClick={() => setSelectedVill('Bou Merdes')}
            style={{ fill: getColorBasedOnCount(outputData['Bou Merdes']) }}
            d='M234.718,284.456c-0.8,0.08-1.6,0.16-2.4,0.239c-0.824,1.354-1.672,2.81-2.4,4.32
	c-1.36,0.239-2.72,0.479-4.08,0.721c-0.647,1.12-1.364,3.074-2.88,3.359c-0.08-0.24-0.16-0.479-0.24-0.72
	c-0.449-0.771,0.662-4.84,0.72-6.722c-0.08,0-0.16,0-0.24,0c-0.879,0.931-1.967,1.224-3.84,1.199
	c-0.787-0.789-1.969-1.263-2.64-2.16c-1.362-1.819-2.25-4.938-5.28-5.039c-0.753,0.488-7.53,2-8.64,2.16
	c0.204,4.509,4.857,3.974,5.52,7.681c-2.722,0.526-10.275,2.659-11.04,4.801c-1.009,1.353-0.805,4.646-1.44,6.24
	c-0.56,0.397-1.12,0.799-1.68,1.198c-1.73,0.44-2.517-2.06-5.04-1.438c-2.444,0.599-4.096,2.469-6.48,3.119
	c-0.768-3.082-4.225-8.894-6.48-10.562c-0.642-0.476-1.357-0.668-1.92-1.199c-1.578,0.925-3.019,1.224-5.76,1.199
	c-3.927-7.142-5.811-19.774-18.24-16.799c-0.933,1.481-1.796,3.354-2.64,5.038c-1.526,0.183-2.283-0.102-3.84-0.239
	c0.063-5.104,1.836-8.886,1.92-14.397c2.334-0.709,4.727-2.604,6.72-3.84c4.677-2.898,9.005-4.707,8.88-12.48
	c5.41-0.006,10.378-0.479,16.08-0.479c1.76,0,3.521,0,5.28,0c3.12,0.239,6.24,0.479,9.36,0.721c0.587,0.129,2.129,0.688,2.88,0.479
	c1.894-0.526,1.454-2.521,4.08-2.642c0.487,0.334,1.441,0.373,1.92,0.722c0.766,1.231,0.162,4.175,0.96,5.521
	c1.058,1.785,3.387,3.373,5.28,4.319c0.56,0.159,1.12,0.319,1.68,0.479c2.29,1.511,3.033,5.291,5.28,6.961
	c0.809,0.601,2.126,1.404,3.12,1.681c1.12,0,2.24,0,3.36,0c0.757,0.335,1.503,1.544,2.4,1.92c4.376,1.833,9.625-1.129,14.4,0
	c1.469,0.349,2.309,1.099,3.36,1.681c0.964,0.533,1.707,0.371,2.16,1.438c0.193,0.282,0.186,0.403,0.24,0.961
	c-1.088,0.554-1.724,2.306-2.64,3.119C238.061,279.165,235.015,280.152,234.718,284.456z'
          />{' '}
        </a>
        <a xlinkTitle={`Ksour Essaf ${groupedData['Ksour Essaf']}`}>
          <path
            onClick={() => setSelectedVill('Ksour Essaf')}
            style={{ fill: getColorBasedOnCount(outputData['Ksour Essaf']) }}
            d='M303.597,257.096c-0.188,1.924-0.944,4.051,0.24,5.521c0.555,0.368,1.945,0.269,2.881,0.238
	c0.91-0.602,3.717-1.396,4.799-1.682c0.345,3.712,3.013,1.186,3.121,4.802c-0.77,1.19-0.069,4.237,0,5.521
	c0.31,5.642,1.465,10.041,1.92,14.881c-4.459,2.101-4.969,2.896-4.801,9.601c-0.961,1.481,0.06,8.925,0.48,10.319
	c2.262,7.47,5.793,11.404,9.84,16.8c1.516,2.022,3.037,4.968,5.039,6.479c0,0.08,0,0.159,0,0.239c-0.08,0-0.16,0-0.24,0
	c-0.08,0.159-0.16,0.317-0.24,0.479c-0.639-0.289-0.901-0.438-1.92-0.479c-0.862,1.949-2.217,5.765-4.317,6.479
	c-1.261,0.932-3.076-0.028-4.32-0.479c-2.813-1.021-7.674-2.567-11.039-1.2c-2.908,1.182-4.23,3.692-6.24,5.76
	c-0.5,0.515-1.23,0.353-1.92,0.722c-1.07,0.573-2.432,1.896-3.121,2.88c-1.039,1.486-1.498,4.675-3.117,5.521
	c-0.604-2.125-1.369-5.164-1.922-7.44c-1.014-0.656-1.354-1.164-3.119-1.2c-0.6,0.969-2.188,4.118-3.119,4.561
	c0-0.08,0-0.16,0-0.238c-1.614-1.033-2.879-2.855-2.881-5.521c1.053-1.1,1.418-2.646,2.159-4.08
	c2.994-5.771,4.721-10.895,2.883-18.961c-3.451-0.379-6.564-1.701-10.321-1.92c-0.079-0.783-0.298-0.916,0-1.439
	c1.007-2.939,5.065-5.33,8.399-6c1.199,0.16,2.4,0.32,3.602,0.48c0-0.08,0-0.16,0-0.24c0.08,0,0.16,0,0.24,0
	c0.229-4.207-0.192-9.375,0.238-13.92c0.24-1.039,0.479-2.08,0.721-3.119c-0.229-1.5-1.561-3.256-3.358-3.359
	c-0.933,0.934-2.322,2.396-3.841,2.643c-0.332-0.268-0.689-0.359-1.2-0.48c-0.404-1.871-2.493-8.525-3.84-9.119
	c-0.56,0-1.12,0-1.68,0c-0.767,0.486-1.978,0.383-2.88,0.719c-2.458,0.917-4.178,2.123-7.68,2.16
	c-1.486-2.145-4.237-3.341-5.76-5.52c-1.006-1.438-1.821-3.545-2.4-5.279c-0.08-0.561-0.16-1.121-0.24-1.682
	c-0.382-0.701-1.356-1.129-1.92-1.681c0.08,0,0.16,0,0.24,0c2.847-5.614,9.239-7.704,16.32-9.118c1.306,2.072,2.724,4.129,6.24,4.08
	c1.549-2.425,5.563-7.76,9.121-7.922c0.787,1.303,2.221,5.035,3.6,5.521c0.527,0.391,1.242,0.459,2.16,0.479
	C292.083,258.667,299.07,257.297,303.597,257.096z'
          />{' '}
        </a>
        <a xlinkTitle={`Souassi ${groupedData['Souassi']}`}>
          <path
            onClick={() => setSelectedVill('Souassi')}
            style={{ fill: getColorBasedOnCount(outputData['Souassi']) }}
            d='M153.599,355.736c0.56,0,1.12,0,1.68,0c0,0.159,0,0.319,0,0.479c-0.325,0.511,0.553,0.775,0.48,1.438
	c-0.24,0.88-0.48,1.761-0.72,2.64c-2.011,1.322-6.624,5.34-9.36,5.521c-0.581-2.418-2.075-4.834-3.12-6.96
	c-2.077-0.131-4.297-0.379-6.24,0.24c-0.909,0.287-2.471,1.75-3.6,1.438c-1.716-0.471-5.437-6.156-7.2-7.438
	c-1.2-0.643-2.4-1.28-3.6-1.922c-0.829-0.271-1.77-0.066-2.4-0.479c-1.792-1.672-3.835-3.521-3.84-6.959
	c-3.136,0.104-4.838-0.574-6-2.398c5.854-4.063,2.354-9.475,4.08-16.801c0.495-2.104,0.995-6.568-0.24-8.4
	c-0.406-0.273-1.175-0.256-1.92-0.238c-1.037,0.744-2.367,0.939-3.36,1.68c-3.19,2.373-3.009,5.5-7.68,7.439
	c-0.21,2.635,0.314,6.675-1.92,7.439c-3.411,2.646-6.202-1.959-8.64-2.641c-2.879,0-5.76,0-8.64,0
	c-0.849-2.854-2.77-5.372-3.6-8.398c0.582-0.371,5.857-4.928,6-5.281c-0.974-1.26-1.217-3.076-2.16-4.318
	c-0.701-0.924-2.087-1.051-2.64-2.16c0.468-1.051-0.897-1.422-0.48-2.881c0.265-0.926,1.353-2.188,1.68-3.119
	c0.587-1.67,0.33-3.076,0.96-4.561c1.328-3.129,3.579-5.855,5.28-8.641c1.138-1.861,1.529-4.119,2.4-6.238
	c1.705-0.758,2.628-1.479,3.84-2.641c0.015-1.037-0.086-1.898-0.24-2.643c-1.047-0.637-3.16-1.627-3.6-2.879
	c-0.628-1.049,1.1-6.322,1.68-7.2c0.52-0.787,1.493-1.269,2.16-1.921c2.672,0.227,5.819,2.17,9.6,1.199
	c0.987-0.254,4.32-2.158,4.32-2.158c0.655,1.018,0.036,5.861,0,7.68c0.421,0.151,0.233,0.049,0.48,0.24
	c1.833-0.42,5.825-3.576,6.96-1.682c0.549,0.596,0.696,1.512,0.72,2.641c-1.809,0.928-3.214,0.846-3.12,3.841
	c1.605,1.242,4.453,4.697,5.52,6.479c0.08,0,0.16,0,0.24,0c0.265-1.17,2.072-2.762,3.12-2.159c0.08,0,0.16,0,0.24,0
	c-0.492,0.872-0.794,1.767-1.2,2.881c0.24,0,0.48,0,0.72,0c3.8-2.927,8.652,2.032,10.08,4.08c0.327,0.468,1.124,2.661,1.44,2.879
	c1.04,0,2.08,0,3.12,0c0.666-0.492,4.41-1.867,4.8-1.681c1.352,0.269,1.861,1.675,2.64,1.681c1.475-1.638,0.291-7.828,0.24-10.8
	c0.08,0,0.16,0,0.24,0c0.85,0.455,4.097,0.146,5.52-0.239c0.805-1.528,1.444-4.83,3.6-5.041c2.948-1.791,6.775,1.259,7.92,2.399
	c2.536,2.533,4.24,6.334,5.76,9.841c0.396,0.911,1.546,4.465,2.16,4.801c1.52,0,3.04,0,4.56,0c0.447-0.342,1.843-0.813,1.92-0.722
	c3.258,1.072,6.922,8.168,7.68,12c-0.24,0-0.48,0-0.72,0c-0.992-0.801-2.688-1.369-4.32-1.438c-0.671,0.684-1.067,0.588-1.2,1.92
	c2.215,1.412,3.423,3.168,3.36,6.959c-1.545,0.81-6.492,2.879-9.12,2.159c-1.548-0.423-4.333-3.151-6.72-1.68
	c-0.748,0.226-1.088,0.603-1.68,0.961c1.093,3.586,1.307,7.526,4.08,9.358c-0.218,0.26,0.019,0.021-0.24,0.24
	c-1.007,1.645-2.591,2.188-2.64,4.801c0.303,0.324,0.328,0.424,0.48,0.959c-0.16,0-0.32,0-0.48,0
	c-0.391,0.262-1.406,0.383-1.92,0.479c-2.088,4.646-4.702,4.207-11.28,4.079c0,0.159,0,0.318,0,0.479
	c1.085,1.597,0.882,4.153,1.44,6.24C152.17,345.146,153.405,350.646,153.599,355.736z'
          />{' '}
        </a>
        <a xlinkTitle={`Sidi Alouene ${groupedData['Sidi Alouene']}`}>
          <path
            onClick={() => setSelectedVill('Sidi Alouene')}
            style={{ fill: getColorBasedOnCount(outputData['Sidi Alouene']) }}
            d='M285.598,306.536c-1.64-0.082-2.947-0.479-4.562,0c-2.702,0.805-7.915,4.338-7.919,7.68
	c0.193,0.283,0.186,0.404,0.24,0.961c0.58,0.314,0.68,0.434,1.68,0.48c1.837,1.077,8.095,0.659,8.88,2.398
	c0.969,1.354,0.965,5.253,0.479,7.199c-0.846,3.396-1.399,5.81-2.643,8.641c-0.797,1.822-2.521,3.62-3.12,5.521
	c-1.235,3.932,3.101,5.215,3.601,7.68c-1.332,1.488-1.543,4.047-2.4,6c-0.914,2.082-2.642,3.902-3.359,6.24
	c-0.16,2.08-0.32,4.16-0.48,6.24c-1.021,2.992-4.319,6.34-6,9.117c-1.333,2.203-0.434,5.146-1.68,7.2
	c-4.391,7.229-14.082,14.164-24.96,14.88c-0.329-0.609-0.46-0.841-0.48-1.92c1.005-1.246,0.873-3.247,0.72-5.04
	c-2.055-1.642-3.27-4.538-4.8-6.722c-2.662-3.797-5.334-7.852-6.96-12.721c-0.864-2.584-1.045-5.41-1.68-8.16
	c0.08-3.678,0.16-7.357,0.24-11.039c7.147-0.104,12.963-2.799,19.2-3.357c0.162-5.52,0.79-8.984-4.08-9.841
	c-1.421-3.152-3.229-2.734-6.24-4.08c-0.022-1.306-0.269-2.673-1.2-3.12c-0.779-0.477-2.871-0.4-3.6-0.72
	c-0.08-0.479-0.16-0.962-0.24-1.44c0.16,0,0.32,0,0.48,0c0.287-0.188,1.155-0.285,1.44-0.479c0.21-0.311,0.214-0.592,0.24-1.199
	c-0.878-0.891-2.054-1.993-2.64-3.119c1.446,0.07,2.024,0.094,2.4-0.961c0.08,0,0.16,0,0.24,0c0.238-0.242-0.236-0.236-0.24-0.24
	c-0.702-1.877-3.15-1.91-3.36-4.08c1.059-0.549,3.988-0.555,4.56-1.68c0.193-0.283,0.186-0.402,0.24-0.959
	c-0.537-0.557-0.817-1.461-1.44-1.92c-0.74-0.547-1.619-0.721-2.16-1.439c-0.632-0.844-0.823-1.748-1.44-2.641
	c-0.761-1.102-2.419-1.711-2.88-3.121c-0.923-2.822,0.85-5.332-0.48-7.92c-1.281-2.492-3.4-3.124-3.84-6.721
	c0.573-0.612,0.519-1.104,1.44-1.439c0.808-0.533,2.916-0.73,3.84-0.959c0.527-1.16,1.405-3.6,2.64-4.08
	c0.72-0.438,1.477,0.081,2.4,0.24c-0.03-6.479,5.265-6.453,7.44-10.561c0.844,0.293,1.648,1.066,2.4,0.721
	c1.535-0.605,0.843-1.879,1.68-3.121c0.406-0.604,4.935-2.545,6-2.641c1.608,5.533,5.458,13.818,12.479,13.682
	c1.773-1.23,6.503-2.674,9.12-2.881c1.417,2.332,2.626,5.645,3.36,8.641c1.102,0.529,1.623,0.938,3.36,0.959
	c0.961-0.879,1.92-1.76,2.881-2.639c0.42,0.15,0.231,0.046,0.479,0.238c0.646,0.457,0.89,1.143,1.438,1.682
	c-0.014,1.242-0.287,2.043-0.479,2.879c0,1.279,0,2.561,0,3.84C285.837,299.952,285.941,303.661,285.598,306.536z'
          />{' '}
        </a>
        <a xlinkTitle={`El Jem ${groupedData['El Jem']}`}>
          <path
            onClick={() => setSelectedVill('El Jem')}
            style={{ fill: getColorBasedOnCount(outputData['El Jem']) }}
            d='M224.638,294.057c0.39,2.551,2.92,3.594,3.84,5.76c0.921,2.169-0.665,4.732,0.24,7.439
	c0.506,1.512,2.304,2.164,3.12,3.359c0.16,0.479,0.32,0.961,0.48,1.438c0.558,0.85,4.637,3.786,4.08,4.319
	c-0.561,0.64-1.891,0.529-3.12,0.479c-0.245,0.192-0.066,0.086-0.48,0.239c-0.494,0.742-0.831,0.703-0.96,1.921
	c-0.585,1.383,2.606,2.749,3.36,3.84c-1.778-0.104-2.377-0.056-2.4,1.681c0.902,0.834,1.992,1.809,2.64,2.881
	c-1.821-0.017-2.239,0.502-2.16,2.399c0.387,0.459,0.454,0.836,0.96,1.199c0.743,0.59,2.224,0.519,3.6,0.479
	c0.24,1.039,0.48,2.08,0.72,3.119c0.593,0.266,5.053,1.727,5.28,1.92c0.646,0.555,0.438,1.905,1.2,2.399
	c0.794,0.517,2.332-0.038,2.88,0.722c1.038,1.436,0.765,4.838,0.72,7.198c-0.16,0-0.32,0-0.48,0
	c-1.017,0.703-2.499,0.558-3.84,0.961c-1.951,0.586-11.579,2.899-13.44,2.159c-0.722-0.939-0.457-7.027-0.479-8.881
	c-0.16,0-0.32,0-0.48,0c-2.381-1.704-6.993-1.35-10.08-0.959c0.015,4.199-1.592,14.08-3.84,15.84c-1.36,1.095-3.382,1.41-4.8,2.398
	c-3.536,2.467-6.183,6.309-10.8,7.682c-4.542,1.352-8.003-1.428-10.8-2.641c-4.984-2.16-9.823-3.541-13.92-6.479
	c-1.791-1.284-4.003-3.483-5.28-5.279c-0.696-0.979-0.825-2.305-1.92-2.881c-2.72,1.761-5.44,3.521-8.16,5.28
	c-0.627,0.341-3.152,0.804-3.36,1.439c-0.781-0.414-0.881-0.712-2.4-0.722c-0.8-4.799-1.6-9.601-2.4-14.399
	c-0.465-1.736-1.071-3.488-1.2-5.521c3.117,0.015,6.554,0.292,8.4-0.96c1.427-0.969,1.154-3.15,3.36-3.359
	c0.336-0.148,1.333-0.082,1.92,0c0-0.398,0-0.8,0-1.199c-0.605-0.566-0.876-1.026-0.96-2.16c1.208-0.787,2.729-2.504,2.88-4.319
	c-2.116-1.437-3.611-5.155-3.84-8.399c0.35-0.205,0.688-0.435,0.96-0.72c3.322,0.06,4.394,1.796,7.2,2.16
	c2.273,0.295,7.028-1.655,8.88-2.399c0.434-3.32-0.109-5.55-1.68-7.439c-0.488-0.59-0.853-0.314-1.2-1.2
	c0.26-0.218,0.021,0.021,0.24-0.239c1.923,0.426,2.959,1.348,5.28,1.439c1.998-2.229,4.336-4.696,8.4-4.801
	c0.697,0.627,2.556,2.054,3.84,1.199c3.014-0.959,2.854-5.07,3.6-8.16c0.933-0.301,1.557-1.069,2.4-1.438
	c2.96-0.879,5.92-1.76,8.88-2.642c-0.028-1.125-0.269-1.527-0.48-2.397c-1.337-0.851-4.766-3.522-5.04-5.278c0.16,0,0.32,0,0.48,0
	c1.631-1.229,5.628-1.636,7.92-1.92c1.787,2.754,4.226,4.91,6.48,7.198c1.352,0.017,2.541-0.106,3.6-0.239c0,0.08,0,0.159,0,0.239
	c-1.007,1.386-0.871,3.905-0.72,5.761c0.24,0.08,0.48,0.159,0.72,0.239C222.919,294.113,223.847,294.077,224.638,294.057z'
          />{' '}
        </a>
        <a xlinkTitle={`Hbira ${groupedData['Hbira']}`}>
          <path
            onClick={() => setSelectedVill('Hbira')}
            style={{ fill: getColorBasedOnCount(outputData['Hbira']) }}
            d='M6.72,288.775c1.12,0.24,2.24,0.479,3.36,0.722c0.974,3.977,5.461,8.369,7.68,11.521
	c0.744,1.058,0.997,2.737,2.4,3.118c1.936-1.481,4.979-1.727,7.44-2.639c7.032-2.606,17.228-5.433,26.88-3.601
	c0,0.16,0,0.319,0,0.479c-0.08,0-0.16,0-0.24,0c-1.375,4.713-10.456,4.41-15.36,5.521c-0.15,1.444,0.423,3.082,0,4.799
	c-1.354,5.492-3.991,7.084-2.4,14.16c0.654,0.41,0.6,0.608,1.68,0.722c0.8,0.539,2.161,0.1,3.12,0
	c0.31-3.229,1.905-6.482,4.32-7.681c-0.021,1.228,0.055,2.457,0.24,3.358c0.588,0.205,1.484,0.25,2.4,0.24
	c0.637-0.519,1.513-0.688,2.64-0.722c0.083,1.577,1.122,3.849,2.16,4.562c-0.458,0.974-1.002,1.641-1.2,2.881
	c0.414,0.144,0.756,0.213,1.44,0.238c0.402-0.389,0.793-0.552,1.44-0.72c0,0.08,0,0.16,0,0.239c-1.453,1.803-1.926,3.689-4.08,4.8
	c-0.966,0.498-2.108,0.168-3.12,0.722c-0.588,0.319-3.329,3.809-4.08,4.561c-3.032,3.033-10.55,9.65-8.88,16.8
	c0.296,1.269,0.887,2.632,1.2,3.84c0.32,0,0.64,0,0.96,0c0.72-0.239,1.44-0.479,2.16-0.72c1.649-0.852,3.342-2.273,4.56-3.602
	c3.948,0.01,4.745,2.883,6.96,4.562c2.897,2.192,5.903,4.433,8.88,6.479c1.541,1.06,4.31,2.039,4.56,4.318
	c1.218,1.915,0.333,5.824,1.2,7.92c0.646,1.563,2.122,2.865,3.6,3.602c0.64,0.16,1.28,0.32,1.92,0.479c0,1.359,0,2.721,0,4.08
	c-1.097,1.63-1.158,3.9-2.16,5.521c-2.039,3.289-5.185,5.817-7.44,8.879c-1.055,1.435-2.386,3.199-3.12,4.802
	c-0.319,0.696-0.095,1.267-0.48,1.92c-2.546,4.323-12.16,5.084-17.52,2.88c-6.027-2.479-11.071-6.1-16.8-8.88
	c-0.059-3.422-0.994-6.248-1.92-9.12c-0.386-1.197-1.63-3.314-1.2-5.04c1.164-4.67,5.355-6.629,5.52-12.479
	c-0.24-0.08-0.48-0.158-0.72-0.238c-1.655-1.115-6.983-1.738-9.12-2.641c-1.2-2.16-2.4-4.319-3.6-6.479
	c-3.168-4.522-9.745-10.74-6.72-18.96c1.593-4.327,4.606-7.956,6.96-11.762c0.868-1.399,2.085-2.871,2.4-4.799
	c-1.389-1.449-1.948-3.883-3.12-5.521c-1.617-2.261-4.335-4.524-6.72-6c-2.06-1.274-4.778-0.629-4.8-4.08
	c1.224-1.935,1.376-6.278,2.16-8.642C3.683,298.586,5.978,293.965,6.72,288.775z'
          />{' '}
        </a>

        <a xlinkTitle={`Chorbane ${groupedData['Chorbane']}`}>
          <path
            onClick={() => setSelectedVill('Chorbane')}
            style={{ fill: getColorBasedOnCount(outputData['Chorbane']) }}
            d='M112.799,316.855c0.471,3.586,0.249,7.06-0.48,10.319c0.08,2.721,0.16,5.441,0.24,8.16
	c-0.569,2.097-2.445,4.666-4.08,5.761c0.846,1.792,2.045,3.028,4.08,3.6c0.727,0.204,1.446-0.051,1.92,0.24
	c0.32,0.88,0.64,1.76,0.96,2.642c0.212,0.342,3.44,4.242,3.6,4.318c0.56,0,1.12,0,1.68,0c2.15,0.615,4.112,1.952,5.52,3.359
	c1.601,1.601,4.25,5.899,6.48,6.479c1.211,0.313,2.335-1.116,3.12-1.44c1.779-0.732,4.516-0.901,6.24-0.238
	c2.283,5.124,4.902,9.229,7.68,13.68c1.344,2.154,2.27,4.796,4.08,6.479c-0.013,7.852-7.14,13.795-11.76,17.281
	c-2.702,2.039-4.959,4.926-7.68,6.959c-10.615,7.935-25.501,18.801-42.72,19.68c-1.45-2.004-6.645-4.871-8.88-6.479
	c-4.476-3.218-8.281-7.294-12.24-11.039c-2.842,2.362-11.748,1.534-12.24-2.16c1.414-1.522,2.126-3.852,3.36-5.521
	c4.956-6.706,9.761-8.079,9.84-20.16c-5.845-0.412-5.314-4.453-6.48-9.601c-0.276-1.217,0.074-2.239-0.48-3.119
	c-1.368-2.17-4.84-3.555-6.96-5.04c-2.16-1.515-4.333-3.188-6.48-4.8c-1.12-1.2-2.24-2.4-3.36-3.603
	c-0.781-0.532-1.533-0.559-2.16-1.198c-4.926-0.043-4.788,3.657-9.12,4.08c0-0.08,0-0.16,0-0.24c-0.08,0-0.16,0-0.24,0
	c-0.14-0.971-0.529-1.813-0.72-2.642c-1.532-6.633,5.35-11.563,7.68-14.64c1.325-1.751,2.857-2.989,4.32-4.561
	c0.16-0.32,0.32-0.642,0.48-0.961c1.444-0.85,3.204-0.703,4.56-1.681c1.408-1.015,2.409-4.179,3.6-4.8
	c3.88,3.71,5.921-4.73,6.479-7.439c0.411-1.99-1.012-2.447-0.72-4.32c0.424-0.229,0.403-0.301,0.96-0.479
	c2.554,2.242,5.268,2.572,8.4,0.959c-0.127-1.137-0.15-1.625,0.24-2.641c1.023,0.207,2.507,0.824,3.84,0.48
	c0.745-0.191,3.005-1.643,3.12-1.439c1.274,0.293,4.775,4.029,3.84,5.039c-1.599,1.633-4.944,3.02-5.76,5.281
	c1.175,1.599,2.916,8.545,4.08,9.357c3.04,0,6.081,0,9.12,0c2.255,1.917,8.245,5.652,11.04,1.439
	c0.856-1.293,0.425-4.776,0.72-6.479C106.099,323.563,107.68,317.292,112.799,316.855z'
          />
        </a>
        <a xlinkTitle={`La Chebba ${groupedData['La Chebba']}`}>
          <path
            onClick={() => setSelectedVill('La Chebba')}
            style={{ fill: getColorBasedOnCount(outputData['La Chebba']) }}
            d='M296.639,349.736c0.397-0.079,0.799-0.159,1.198-0.239c0.494-0.777,1.172-1.351,1.439-2.399
	c-0.08-1.761-0.16-3.521-0.24-5.279c1.18-2.526,3.879-5.178,6.479-6.239c3.129-1.279,7.527,0.282,10.32,1.198
	c1.23,0.402,3.297,1.582,5.041,0.722c2.256-1.115,3.313-4.578,4.559-6.722c0.404,0.24,0.785,0.81,1.201,0.961
	c0.492,0.181,1.054-0.306,1.199-0.239c1.92,0.851,4.031,4.209,5.76,5.521c5.121,3.882,9.521,8.558,15.121,12
	c2.119,1.304,4.646,2.401,6.959,3.358c0.722,0.16,1.439,0.32,2.16,0.479c0.604,0.365,0.658,0.942,0.721,1.92
	c-0.5,0.716-0.348,1.271-1.198,1.681c-1.888,1.356-5.429-1.112-8.16,0c-4.113,1.678-7.92,5.242-9.359,9.602
	c-0.459,1.386,0.152,4.408-0.24,5.279c-0.516,0.563-1.453,0.854-1.92,1.438c-1.396,1.757-2.535,3.966-3.6,6
	c-0.689,1.313-1.084,3.423-2.644,3.841c-0.979-0.771-2.282-1.005-3.356-1.681c-2.269-1.426-4.535-3.569-7.201-4.562
	c-2.875-1.067-6.342,0.438-8.639-0.721c-2.996-1.513-4.188-5.191-6-7.92C302.603,362.269,296.777,358.654,296.639,349.736z'
          />{' '}
        </a>
        <a xlinkTitle={`Melloulech ${groupedData['Melloulech']}`}>
          <path
            onClick={() => setSelectedVill('Melloulech')}
            style={{ fill: getColorBasedOnCount(outputData['Melloulech']) }}
            d='M298.078,342.056c0.037,2.386,0.186,4.065-0.24,6c-0.527,0.274-1.604,0.663-1.92,1.2
	c-0.67,1.076-0.033,3.341,0.24,4.32c1.094,3.896,2.563,5.898,4.561,8.639c1.388,1.902,2.996,3.632,4.32,5.521
	c2.301,3.282,3.498,7.495,7.438,9.12c2.156,0.889,4.462-0.492,7.199,0.24c3.313,0.886,5.74,3.422,8.4,5.039
	c1.199,0.73,3.242,0.851,3.84,2.16c0.303,0.326,0.328,0.425,0.48,0.961c-14.24,1.672-20.83,18.346-20.884,34.318
	c-0.157,0-0.317,0-0.479,0c-1.23-0.951-6.582-2.984-9.119-2.398c-1.182,0.271-2.605,1.644-3.84,1.439
	c-4.662-0.771-14.164-5.402-16.32-8.643c-0.641-1.919-1.279-3.842-1.92-5.762c-1.08-1.963-3.112-1.338-3.12-4.56
	c1.526-1.392,3.537-2.95,3.601-5.76c-1.842-1.95-2.538-5.231-4.081-7.44c-1.776-2.541-5.366-4.611-6.24-7.199
	c-0.584-1.729,1.562-5.278,2.16-6.238c1.355-2.178,3.548-4.059,4.56-6.479c0.819-1.961,0.047-4.775,0.72-6.959
	c0.584-1.896,2.007-3.613,2.88-5.281c1.646-3.146,2.593-6.744,4.32-9.602c0.781-1.293,0.377-2.527,2.158-2.879
	c0.244,0.191,0.064,0.084,0.479,0.238c0.772,1.48,2.152,9.764,4.08,8.641c1.432-1.447,1.768-3.725,2.881-5.521
	c0.82-1.323,2.061-2.051,3.119-3.118C297.598,342.056,297.837,342.056,298.078,342.056z'
          />{' '}
        </a>
        <text
          transform='matrix(1 0 0 1 272.5669 246.9937)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Mahdia
        </text>
        <text
          transform='matrix(1 0 0 1 267.7012 277.5132)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ksour Essef
        </text>
        <text
          transform='matrix(1 0 0 1 305.5664 355.9937)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Chebba
        </text>
        <text
          transform='matrix(1 0 0 1 288.4688 399.272)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Melloulèche
        </text>
        <text
          transform='matrix(1 0 0 1 237.9482 313.5532)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Sidi{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 237.9482 327.9536)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Alouane
        </text>
        <text
          transform='matrix(1 0 0 1 183.5669 325.9937)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          El Jem
        </text>
        <text
          transform='matrix(1 0 0 1 155.5669 271.9937)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Bou Merdès
        </text>
        <text
          transform='matrix(1 0 0 1 103.5669 307.9937)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Essouassi
        </text>
        <text
          transform='matrix(1 0 0 1 79.9482 376.5532)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Chorbane
        </text>
        <text
          transform='matrix(1 0 0 1 30.4683 267.272)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Ouled{' '}
        </text>
        <text
          transform='matrix(1 0 0 1 30.4683 281.6724)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Chamekh
        </text>
        <text
          transform='matrix(1 0 0 1 27.5669 387.9937)'
          font-family="'MyriadPro-Regular'"
          font-size='12'
        >
          Hebira
        </text>
      </svg>

      <div className='result-container'>
        {result?.map(
          (data) =>
            data?.Code !== 'NaN' && (
              <Card size='small' title={data?.Ville} style={{ width: 300 }}>
                <p>{data?.[' Subville ']}</p>
                <p>{data?.Code}</p>
                <p>{data?.Count}</p>
              </Card>
            )
        )}
      </div>
    </>
  );
};

export default Mahdia;
