import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    GiftOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import './style.css';
import { Link } from 'react-router-dom';
const { Header, Sider, Content } = Layout;
export const CustomLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{
                background: colorBgContainer,
                boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.25)',
                height: '100vh',
                position: 'sticky',
                top: "0"
            }} >
                <div className='logo-container' >
                    <img src="taki_academy_thumbnail.svg" alt="taki" width='60px' />

                </div>
                <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={[
                        {
                            key: '1',
                            icon: <UserOutlined />,
                            label: <Link to='/' >Users</Link>,

                        },
                        {
                            key: '2',
                            icon: <GiftOutlined />,
                            label: <Link to='/offers' >Offers</Link>,
                        },

                    ]}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                    }}
                >
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                </Header>
                <Content
                    style={{
                        background: colorBgContainer,

                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};