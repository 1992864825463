import React, { useState } from 'react';
import { Modal } from 'antd';
import { Card } from 'antd';
import './style.css';
const UserModal = ({
  handleCloseModal,
  isModalOpen,
  modalData,

  title,
}) => {
  return (
    <>
      <Modal
        centered
        title={title}
        open={isModalOpen}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        width={1100}
      >
        <div className='data-list'>
          {modalData?.map((data, index) => (
            <div className='data-item' key={index}>
              <h4>{data.FullName}</h4>
              <p>
                <strong>Phone:</strong> {data?.Phone}
              </p>
              <p>
                <strong>Group:</strong> {data?.Division}
              </p>
              <p>
                <strong>State:</strong> {data?.State}
              </p>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default UserModal;
